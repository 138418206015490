import React from "react";
import styles from "../ReportStyleSheet";
import {
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
function ReportSummary(params) {
  const {
    // responseList = [
    //   { relationship: "Self", nomination: 1, completed: 1, rate: 70 },
    //   {
    //     relationship: "Manager",
    //     nomination: 1,
    //     completed: 1,
    //   },
    //   { relationship: "Peer", nomination: 3, completed: 2, rate: 70 },
    // ],
    responseList,
    summary = "A town hall different from bala blu, blue blu bulaba. broom broom broom brooooooooom. Bala blu blue blu bulaba.",
  } = params;
  return (
    <View style={{ fontSize: 12 }} break>
      <Text style={styles.header}>2.0 RESPONDENT SUMMARY</Text>
      <Text style={{ marginTop: 10, marginBottom: 20 }}>{summary}</Text>

      <View
        style={[
          {
            flexDirection: "row",
            padding: 3,
            fontSize: 12,
            marginBottom: 10,
            marginTop: 20,
          },
        ]}
      >
        <Text style={[{ width: "30%" }]}>RELATIONSHIP</Text>
        <Text style={[{ width: "20%" }]}>NOMINATION</Text>
        <Text style={[{ width: "20%" }]}>COMPLETED</Text>
        <Text style={[{ width: "30%" }]}>COMPLETION RATE</Text>
      </View>

      {responseList?.map((e) => (
        <View
          style={[
            {
              flexDirection: "row",
              borderStyle: "solid",
              borderColor: "#e3e3e3",
              borderWidth: 1,
              // padding: 5,
              paddingHorizontal: 10,
              paddingVertical: 15,
              fontSize: 12,
              marginBottom: 10,
            },
          ]}
        >
          <Text style={[{ width: "30%" }]}>{e?.relationship}</Text>
          <Text style={[{ width: "20%", textAlign: "center" }]}>
            {e?.nominated}
          </Text>
          <Text style={[{ width: "20%", textAlign: "center" }]}>
            {e?.completed}
          </Text>
          <Text style={[{ width: "30%", textAlign: "center" }]}>
            {Math.floor((e?.completed / e?.nominated) * 100, 2)}%
          </Text>
        </View>
      ))}
    </View>
  );
}

export default ReportSummary;
