import styles from "../ReportStyleSheet";
import { Text, View, Image, Svg, Path } from "@react-pdf/renderer";

function Cover({
  title = "TestAssessify 360 Degree",
  name = "Yemi Alade",
  campaignName = "360 Degree Feedback",
  date = "17.12.2022",
}) {
  return (
    <div>
      <View
        style={{
          minWidth: "70vw",
          height: "90vh",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 14,
        }}
      >
        <Image src="/assets/logo.png" style={{ height: 100 }} />

        <Text
          style={{
            color: "green",
            marginBottom: 12,
            marginTop: 12,
            fontSize: 28,
          }}
        >
          {title}
        </Text>
        <Text style={{ fontSize: 22, marginBottom: 10 }}>Feedback Report</Text>
        <Text style={{ fontSize: 22, marginBottom: 10 }}>{campaignName}</Text>
        <Text style={{ fontSize: 22, fontWeight: 800, marginBottom: 10 }}>
          {name}
        </Text>
        <Text style={{ fontSize: 22, marginBottom: 10 }}>{date}</Text>
        <Text style={{ color: "red" }}>Confidential Report</Text>
      </View>
    </div>
  );
}

export default Cover;
