import React from "react";
import { Avatar, Box, Stack, Tooltip, Typography } from "@mui/material";
function SliderProgress(props) {
  const {
    imageList = [
      { score: "50%", src: "/assets/9ija.png" },
      { score: "65.5%", src: "/assets/9ija.png" },
    ],
    color,
  } = props;
  return (
    <Box
      component="div"
      position="relative"
      fullWidth
      sx={{
        backgroundColor: !color ? "#EFF1E1" : color,
        height: "16px",
        borderRadius: "4px",
      }}
    >
      {imageList?.map((image, index) => (
        <Box
          key={index}
          component="div"
          position="absolute"
          sx={{
            top: "50%",
            left: image?.score,
            bottom: "50%",
            zIndex: "2",
            height: "40px",
            width: "40px",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Tooltip title={image?.score} arrow>
            <Avatar
              alt="Remy Sharp"
              src={image?.src}
              sx={{ height: "40px", width: "40px" }}
            />
          </Tooltip>

          <Typography
            variant="body2"
            align="center"
            sx={{
              visibility: "hidden",
              "&.MuiTypography-root:hover": { visibility: "visible" },
            }}
          >
            {image?.score}
          </Typography>
        </Box>
      ))}

      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        pt={2}
      >
        <Typography variant="h5">0</Typography>
        <Typography variant="h5">5</Typography>
      </Stack>
    </Box>
  );
}

export default SliderProgress;
