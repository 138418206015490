import React from "react";
import "./Header.css";
function Header(params) {
  const { title = "360 " } = params;
  return (
    <div className="header mt-5 md:mt-8">
      <h1 className="header__Title text-lg">{title}</h1>
    </div>
  );
}

export default Header;
