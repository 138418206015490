import styles from "../ReportStyleSheet";
import { Text, View, Image, Svg, Path } from "@react-pdf/renderer";
function DetailedFeedback({
  summary = `The detailed statement-wise rating provides your complete group-wise breakdown of your feedback on each statement.
  
    `,
  progress = 70,
  detailedList,
  // = [
  //   {
  //     title: "Story telling",
  //     score: 1.9,
  //     question: "Come up with innovative solutions to common problems",
  //     raters: [
  //       {
  //         role: "Manager",
  //         score: 3.7,
  //       },
  //       {
  //         role: "Self",
  //         score: 2.3,
  //       },
  //       {
  //         role: "Peer",
  //         score: 2.3,
  //       },
  //       {
  //         role: "Direct Report",
  //         score: 2.3,
  //       },
  //     ],
  //   },
  //   {
  //     title: "Decision Making",
  //     score: 3.9,
  //     question: "Come up with innovative solutions to common problems",
  //     raters: [
  //       {
  //         role: "Manager",
  //         score: 3.7,
  //       },
  //       {
  //         role: "Self",
  //         score: 2.3,
  //       },
  //       {
  //         role: "Peer",
  //         score: 2.3,
  //       },
  //       {
  //         role: "Direct Report",
  //         score: 2.3,
  //       },
  //     ],
  //   },
  // ],
}) {
  return (
    <View style={{ fontSize: 12 }} break>
      <Text style={[styles.header]}> 6.0 DETAILED FEEDBACK</Text>
      <View style={{ marginBottom: 25 }}>
        <Text style={[{ marginBottom: 10, fontSize: 12 }]}> {summary}</Text>
      </View>

      {detailedList?.map((e) => (
        <View
          style={[
            {
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#e3e3e3",
              padding: 10,
              paddingHorizontal: 0,
              fontSize: 12,
              borderRadius: 5,
              marginBottom: 10,
            },
          ]}
        >
          <View
            style={[
              {
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 15,
                paddingHorizontal: 10,
              },
            ]}
          >
            <Text>{e?.category_name}</Text>
            <Text
              style={[
                {
                  backgroundColor: `${e?.score > 2.5 ? "green" : "red"}`,
                  padding: 5,
                  paddingHorizontal: 10,
                  color: "white",
                  borderRadius: 5,
                },
              ]}
            >
              {e?.score}
            </Text>
          </View>

          <View style={[styles.detailTable]}>
            <Text style={{ width: "43%" }}>Questions</Text>
            <Text style={{ width: 60 }}>Raters</Text>
            <Text style={{ width: "40%", marginLeft: 15, textAlign: "center" }}>
              Rating Given
            </Text>
          </View>
          {e?.question?.map((f) => (
            <View
              wrap={false}
              style={[
                {
                  flexDirection: "row",
                  marginBottom: 10,
                  padding: 10,
                  alignItems: "center",
                },
              ]}
            >
              <Text style={{ width: "40%" }}>{f?.title}</Text>
              <View style={{ width: "60%" }}>
                {f?.raters?.map((g) => (
                  <View style={{ flexDirection: "row", marginBottom: 5 }}>
                    <Text style={{ width: 160, marginLeft: 15 }}>
                      {g?.role}
                    </Text>
                    <View
                      style={{
                        marginLeft: 15,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {/* progress start */}
                      <View
                        style={[
                          styles.progressBarContainer,
                          { height: 10, maxWidth: 150 },
                        ]}
                      >
                        <View
                          style={[
                            styles.progressBarBackground,
                            {
                              width: `${(g?.rating || 0) * 20}%`,
                              backgroundColor: `${
                                g?.rating > 2.5 ? "green" : "red"
                              }`,
                            },
                          ]}
                        />
                        {/* <Text style={styles.progressBarText}>{`${progress}%`}</Text> */}
                      </View>
                      <Text style={{ marginLeft: 7 }}>{g?.rating}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}

export default DetailedFeedback;
