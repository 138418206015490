import React from 'react'
import Switch from 'react-switch'
import { useSelector, useDispatch } from 'react-redux'
import { 
           toggleRole, 
           incrementRoleMinValue,decrementRoleMinValue,
           incrementRoleMaxValue, decrementRoleMaxValue 
        } 
        from '../../../../../features/create-project/create-project-slice'

const Evaluators = ({ className }) => {

   const { formData,  roles }  = useSelector( state => state.createProjectForm )
   const dispatch = useDispatch()
   const switchRole = (id) => {
      dispatch( toggleRole( id ) )    
    }

    const incrementMinValue = id => {
      dispatch( incrementRoleMinValue( id ) )
    }

    const decrementMinValue = id => {
      dispatch( decrementRoleMinValue( id ) )
    }

    const incrementMaxValue = id => {
      dispatch( incrementRoleMaxValue(id) )
    }

    const decrementMaxValue = id => {
      dispatch( decrementRoleMaxValue(id) )
    }
     
   const totalMinValue = formData.roles.reduce( (total,value) => value.min_response + total , 0)
   const totalMaxValue = formData.roles.reduce( (total,value) => value.max_response + total , 0)

  return (
    <>
      <h6 className='mt-4 text-md pl-2'>Roles </h6>
        <p className='py-2 ml-2 border-b  text-green-700 border-gray-300 text-sm w-[80%]'> Select evaluator roles </p>
        <div className= { `md:ml-8  ${ className } grid grid-cols-3 gap-8 items-center mt-4` }>
              <span className='text-sm font-semibold text-gray-700 ml-3'> Roles </span>
              <div>
                   <span className='text-sm font-semibold text-gray-700 block text-center'> Min </span>
                   <p className='text-gray-800 text-xs text-center'>
                      Minimum number of responses for report availability.
                   </p>
              </div>
              <div>
                   <span className='text-sm font-semibold text-gray-700 block text-center'> Max </span>
                   <p className='text-gray-800 text-xs text-center'>
                       Maximum number of responses for report availability.
                   </p>                    
              </div>
        </div>
         {
             roles.map( (role,id) => {
                 return(
                      <div key={ role.id } className={ `md:ml-8 ${ className } grid grid-cols-3 gap-8 items-center mt-4 py-2 bg-gray-100 px-2` }>
                         <span className='text-sm text-gray-700 mt-2 flex items-center'> 
                              <Switch                         
                                onChange = { () => switchRole(id) }
                                checked={ role.status } 
                              /> 
                              <span className='text-sm ml-5 capitalize'> { role.role.project_role } </span>
                         </span>
                         <div className='ml-4 sm:ml-[80px] md:ml-[94px] xl:ml-[95px]'>
                              <span
                                   onClick={ () => decrementMinValue(id) } 
                                   className='p-1 bi bi-chevron-left text-sm bg-gray-200 hover:cursor-pointer active:bg-green-500 active:text-white'
                               ></span> 
                              <span className='text-sm mx-2'> {  role.role.min_response } </span>
                              <span 
                                     onClick={ () => incrementMinValue(id) }
                                      className='p-1 bi bi-chevron-right text-sm bg-gray-200 hover:cursor-pointer active:bg-green-500 active:text-white'>                                        
                             </span>
                         </div>
                         <div className='ml-4 sm:ml-[80px] md:ml-[94px] xl:ml-[95px]'>
                              <span 
                                  onClick={ () => decrementMaxValue(id) }
                                 className='p-1 bi bi-chevron-left text-sm bg-gray-200 hover:cursor-pointer active:bg-green-500 active:text-white'>
                              </span> 
                              <span className='text-sm mx-2'> { role.role.max_response } </span>
                              <span 
                                    onClick={ () => incrementMaxValue(id) }
                                    className='p-1 bi bi-chevron-right text-sm bg-gray-200 hover:cursor-pointer active:bg-green-500 active:text-white'>                                    
                              </span>
                         </div>
                    </div>
                 )
             } )
          }
         <div className={`md:ml-8 mb-5 mt-8 ${ className } grid grid-cols-3 border-t border-b border-green-500 gap-8 items-center mt-4 py-2  px-2`}>
              <span className='text-sm text-gray-700 mt-2 flex items-center'> 
                  <span className='text-sm md:ml-16 pl-2'> Total </span>
              </span>
              <div className='sm:ml-[80px] md:ml-[94px] xl:ml-[95px] pl-8 text-xs'>
                     { totalMinValue }
              </div>
              <div className='sm:ml-[80px] md:ml-[94px] xl:ml-[95px] pl-8 text-xs'>
                    { totalMaxValue }
              </div>
        </div>
    </>
  )
}

export default Evaluators