import styles from "../ReportStyleSheet";
import { Text, View, Image, Svg, Path } from "@react-pdf/renderer";
function ProgressBar({ rating, height, width }) {
  return (
    <div>
      {/* progress start */}
      <View
        style={[
          styles.progressBarContainer,
          {
            height: height,
            maxWidth: 150,
            width: `${width ? width : "100%"}`,
          },
        ]}
      >
        <View
          style={[
            styles.progressBarBackground,
            {
              width: `${(rating || 0) * 20}%`,
              backgroundColor: `${rating > 2.5 ? "green" : "red"}`,
            },
          ]}
        />
        <Text style={[styles.progressBarText, { color: "blue" }]}>{`${
          rating * 20
        }%`}</Text>
      </View>
    </div>
  );
}

export default ProgressBar;
