import React from "react";
import "./Feedback.css";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
function Feedback(params) {
  const { user = "Tiwa Savage", pendingTask = "1" } = params;
  return (
    <div className="feedback">
      <h1 className="feedback__Title">360 Degree Feedback</h1>
      <div className="feedback__Content">
        <h2 className="feedback__Header">
          Welcome <span> {user}</span>
        </h2>
        <div className="feedback__Notification">
          <div className="feedback-notify">
            <div className="feedback-notify-icon">
              <MarkUnreadChatAltIcon />
            </div>
            <p>
              You have not completed your all your tasks. There are{" "}
              {pendingTask} tasks oustanding
            </p>
          </div>
          <a href="/participant/campaign" className="feedback-link">
            Click here to view the remaining task
          </a>
        </div>

        <div className="feedback__Current"></div>
      </div>
    </div>
  );
}

export default Feedback;
