import React, { useEffect, useState } from "react";
import axios from "axios";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import { object, string, array, ValidationError } from "yup";
import _ from "lodash";

function Forms(params) {
  const {
    title,
    questionsList,
    data,
    buttonLabel,
    formKey,
    setCurrentStep,
    currentStep,
    totalStep,
    setOpen,
    isLoading,
    setIsLoading,
    topPageRef,
    handleFocus,
  } = params;
  const project_id = localStorage.getItem("project_id");
  const participant_id = localStorage.getItem("participant_id");
  const evaluator_id = localStorage.getItem("evaluator_id");
  const evaluator_role = localStorage.getItem("evaluator_role");
  const scrollers = document.querySelectorAll('*')

  const validationSchema = object({
    questions: array(
      object({
        answer: string(),
      })
    )
      .min(1)
      .test((e) => {
        if (
          questionsList.length * 0.3 >=
          e.filter((e) => e?.answer !== undefined)?.length
        ) {
          console.log(
            "test",
            e.filter((e) => e?.answer !== undefined)
          );
          return new ValidationError(
            "Please provide more answer to proceed",
            undefined,
            "questions"
          );
        }
        console.log(
          "yee",
          e.filter((e) => e?.answer !== undefined)
        );
        return true;
      }),
  });

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          questions: [{ answer: "" }],
        }}
        key={formKey}
        validationSchema={validationSchema}
        onSubmit={(values, { isSubmitting, setSubmitting }) => {
          setSubmitting(true);
          setIsLoading(true);
          let questionsPayload = [];
          for (let i = 0; i < values.questions.length; i++) {
            if (values?.questions[i]?.answer) {
              questionsPayload.push({
                id: questionsList[i].id,
                answer: values?.questions[i]?.answer,
              });
            }
          }
          let postPayload = axios
            .post(
              " https://sandboxapi.360degree.testassessify.com/survey/question/submit",
              {
                project_id,
                participant_id,
                evaluator_id,
                evaluator_role,
                category_name: title,
                current_step: currentStep,
                questions: questionsPayload,
                domain: title,
              }
            )
            .then((e) => {
              if (totalStep === currentStep) {
                localStorage.setItem("completedAssessment", true);
                localStorage.setItem("progress", 100);
                setOpen(true);
                return null;
              }
              handleFocus(topPageRef);
              setCurrentStep((e) => currentStep + 1);
              setSubmitting(false);
              scrollers.forEach( element => {
                element.scroll({top: 0, behavior: 'smooth'})
              } )
            });
          // .finally((e) => setSubmitting(false));
        }}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
             <header className="bg-white sticky-top md:flex justify-between top-20">
                   <h2 className="assessment-title">{formKey + " " + title}</h2>
                    <fieldset
                      id="title"
                      className="assessment-radio text-xs md:text-sm px-3 md:px-0 assessment-header-label"
                    >
                      <div className="">Never</div>
                      <div className="">Rarely</div>
                      <div className="">Sometimes</div>
                      <div className="">Often</div>
                      <div className="">Always</div>
                    </fieldset>
                 </header>
            <div className="assessment__Questions">
              <div className="questions">
                {_.orderBy(questionsList, ["quesstion_type"], ["desc"])?.map(
                  (e, i) => (
                    <div className="question" key={e?.id}>
                      <p className="assessment-question">
                        {i + 1}. {e?.qusetion}
                      </p>
                      {e?.quesstion_type.toLowerCase() === "radio" && (
                        <>
                          <fieldset
                            id={e?.id}
                            className="assessment-radio"
                            name={`questions.${i}.answer`}
                          >
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="never"
                            />
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="rarely"
                            />
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="sometimes"
                            />
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="often"
                            />
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="always"
                            />
                          </fieldset>
                        </>
                      )}

                      {e?.quesstion_type.toLowerCase() === "input" && (
                        <>
                          <Field
                            name={`questions.${i}.answer`}
                            placeholder={`Enter Answer`}
                            className="assessment-text"
                            required
                          />

                          <ErrorMessage
                            name={`questions.${i}.answer}`}
                            component="div"
                            className="error-message"
                          />
                        </>
                      )}
                    </div>
                  )
                )}
                {/* </FieldArray> */}
              </div>
            </div>
            <div className="assessment-paginate">
              {/* {!disableBack && (
                    <button
                      className="assessment-back"
                      type="button"
                      onClick={backFunc}
                    >
                      Back
                    </button>
                  )} */}

              {/* {!disableNext && (
                    <button
                      className="assessment-next"
                      // type="submit"
                      type="button"
                      onClick={nextFunc}
                    >
                      Next
                    </button>
                  )} */}
              {/* {!disableSubmit && ( */}

              <div className="assessment-submit-error">
                <button
                  className="assessment-submit"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                  // onClick={submitFunc}
                >
                  {!isSubmitting &&
                    !isLoading &&
                    (totalStep === currentStep ? "Submit" : "Next")}
                  {(isSubmitting || isLoading) && "Submitting"}
                </button>
                {/* <Modal open={open} setOpen={setOpen} /> */}
              </div>

              <ErrorMessage
                name="questions"
                className="assessment-error-message"
              />
              {/* )} */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Forms;
