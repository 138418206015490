import { createSlice } from "@reduxjs/toolkit";
import { email_subject, email_template } from "./client-admin-templates";

const initialState = {
    step : 1,
    errors : [],
    formData : {              
           company_name : "",
           industry_type : "",
           email_suffix:"",
           phone_number:"",
           company_logo : "",
           default_password : '',
           email_subject,
           email_template,
           address:"",
           admin:{
               admin_name: "",
               admin_email: "",
               admin_phone: "",
               password: "",
               password_confirm:""
           }        
    }
}

export const createCompany = createSlice({
    name : 'evaluator_roles' ,
    initialState,
    reducers : {
         clearCreateCompanySlice : (state) => {
              state.errors = [] ;
              state.step = 1 ;
              state.formData = initialState.formData;
         },
         moveToNextStep : (state) => {
            state.step = state.step + 1 ;
         },
         setErrors : (state,{ payload }) => {
            state.errors = payload
         },
         moveToPreviousStep : (state) => {
             state.step = state.step - 1 ;
         },
         clearErrors : (state) => {
             state.errors = [] ;
         },
         updateAdminLogo : (state,{payload}) => {
            state.formData.email_template =  state.formData.email_template.replace('{logo}', payload) ; 
         },
         updateInput : (state, { payload }) => {
              state.formData = {...state.formData, [payload.name] : payload.value }
         },
         updateAdminInput : (state, { payload }) => {
            state.formData = {
                                ...state.formData, 
                                 admin :
                                        { 
                                             ...state.formData.admin,
                                              [ payload.name ] : payload.value 
                                       }
                                 }
        },       
       serverError : (state) => {
           state.step = 3 ;
       },        
    }//reducer end
  })

export const {
     moveToNextStep,
    setErrors,
    moveToPreviousStep,
    clearErrors,
    updateInput ,
    updateAdminInput,    
    serverError,
    clearCreateCompanySlice,
    updateAdminLogo,
} = createCompany.actions
 
export default createCompany.reducer