import React, {useEffect, useState} from 'react'
import CustomDropdown from '../../../../../../components/CustomDrpdown' 

const UserProfileDropDown = ({country, handleInputChange, formData, participant, userProfle, id, name }) => {
   
   const [filteredBranch, setFilteredBranch] = useState(userProfle)
    
   useEffect( () => {
       if(name==='branch'){
          const branches = JSON.parse(sessionStorage.getItem('participant_branches_list'))
          let filter = branches.filter( branch =>  branch.country.toLowerCase() === country.toLowerCase() )
           .map( branch => {  
               return { label: branch.branch_name, value: branch.branch_name }
           } )
           if(filter.length){
             setFilteredBranch(filter)
           }
           else {
            if(participant) {
               let branch_name_filter = branches.filter( 
                       branch =>  branch.branch_name.toLowerCase() === participant.toLowerCase() 
                    )
                    .map( branch => {  
                        return { label: branch.branch_name, value: branch.branch_name }
                    } )
               if(branch_name_filter.length) setFilteredBranch(branch_name_filter)
               else setFilteredBranch([]) 
               handleInputChange(id,name,participant)
            }
            else {
                   handleInputChange(id,name,'')
                   setFilteredBranch([])
               }
           }
       }
   }, [country] ) 


   if(name !== 'branch'){
      return (
         <>    
            <CustomDropdown 
                onChange={ option => {
                   handleInputChange(id,name,option.value)
                }}
                value={ { label: participant, value: participant} }
                 options = { userProfle.length ? userProfle?.map( profile =>  {
                              return { label: profile, value: profile }
                         } ) : [] }
                disabled={formData}
             />
          </>
       )
   }
   else{
      return(
      <> 
       { (filteredBranch.length || participant) ? 
       <CustomDropdown 
            onChange={ option => {
               handleInputChange(id,name,option.value)
            }}
            value={ { label: participant, value: participant}}
            options = {  filteredBranch  }
            disabled={formData}
       />   
       :
       <CustomDropdown 
            onChange={ option => {
               handleInputChange(id,name,option.value)
            }}
            value={ { label: `No Branch found in ${country}`, value: ''} }
            options = {  filteredBranch  }
            disabled={formData}
       /> 
       }
      </>
      )
  }
}

export default UserProfileDropDown