import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ className, links, isAdmin = true }) => {
  const { adminType } = useSelector((state) => state.adminLogin);

  return (
    <div className={className}>
      {/* <NavLink to={"/admin/dashboard"} path="dashboard" className="navlink">
        <span className="text-2xl bi bi-speedometer2 ml-2 text-red-500"></span>
        <span className="text-sm ml-2">Dashboard</span>
      </NavLink> */}
      {adminType !== "client admin" && isAdmin && (
        <NavLink
          to={"/admin/create-company"}
          path="company"
          className="navlink"
        >
          <span className="text-2xl bi bi-building ml-2 text-red-500"></span>
          <span className="text-sm ml-2">Company</span>
        </NavLink>
      )}

      {links?.map((e) => (
        <NavLink to={e?.link} path={e?.path} className="navlink">
          <span className={`text-2xl bi ${e?.icon}  ml-2 text-red-500`}></span>
          <span className="text-sm ml-2">{e?.title}</span>
        </NavLink>
      ))}
      {/* <NavLink
        to={"/admin/create-campaign"}
        path="campaign"
        className="navlink"
      >
        <span className="text-2xl bi bi-bag-plus ml-2 text-red-500"></span>
        <span className="text-sm ml-2">Campaign</span>
      </NavLink>
      <NavLink className="navlink" to={"/admin/survey"} path="survey">
        <span className="text-2xl bi bi-pencil-square ml-2 text-red-500"></span>
        <span className="text-sm ml-2">Survey</span>
      </NavLink>
      <NavLink className="navlink" to={"/admin/report"} path="report">
        <span className="text-2xl bi bi-journals ml-2 text-red-500"></span>
        <span className="text-sm ml-2">Report</span>
      </NavLink> */}
    </div>
  );
};

const NavLink = ({ className, to, children, path }) => {
  const location = useLocation();
  //simulate a click event to hide sidebar in mobile devices after a link is clicked.
  const closeMobileSidebar = () => {
    if (window.matchMedia("(max-width:500px)")) {
      document.querySelector("button.mobile-sidebar-close-btn").click();
    }
  };

  return (
    <Link
      to={to}
      className={className}
      onClick={closeMobileSidebar}
      id={location.pathname.includes(path) ? "active-link" : ""}
    >
      {children}
    </Link>
  );
};
export default Sidebar;
