import useObjectToArray from "./useObjectToArray";

export default function useConvertToMainReportPayload(payload) {
  let data = { ...payload };
  let newData = {
    countries: useObjectToArray(data.country),
    subsidiaries: useObjectToArray(data.subsidiary),
    states: data.state.map((data) => data.state_name),
    branch: data.branch.map((data) => data.branch_name),
    domains: data.domain.map((data) => data.domain_name),
    project_id: data.projectId,
  };
  return newData;
}
