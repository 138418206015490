import * as React from "react";
import "./Modal.css";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ open, setOpen }) {
  const navigate = useNavigate();
  const user = localStorage.getItem("username");
  const handleClose = () => {
    setOpen(false);
    navigate("/participant/campaign");
  };
  React.useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <div className="modal">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal__Modal"
      >
        <Box sx={style} className="modal__Box">
          <button onClick={handleClose} className="modal-button">
            X
          </button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Hello {user}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Thanks for taking the assessment
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
