import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { updateProject } from '../../../../features/view-projects/view-projects-slice'
import Campaigns from './components/Campaigns'
import ReactPaginate from 'react-paginate'
import SearchCampaign from './components/SearchCampaign'
import NoCampaign from './components/NoCampaign'
import FilterCampaignByCompany from './components/FilterCampaignByCompany'

const ViewProjects = () => {

  const [ loading, setLoading ] = useState(false)
  const { company_id, project  }  = useSelector( state => state.viewProjects ) 
  const { adminName, adminType } = useSelector( state => state.adminLogin )
  const dispatch = useDispatch()

  //pagination using react paginate package
  const [ pageNumber , setPageNumber ] = useState(0)
  const [ totalCampaign , setTotalCampaign ] = useState(1)
  const NumberOfcampaignsPerpage = 3 ;  
  const campaignsPerpage = Math.ceil(  totalCampaign / NumberOfcampaignsPerpage )
  const pagesVisited = ( NumberOfcampaignsPerpage * pageNumber )
  const activeCampaigns = (project.campaigns !== undefined) && project.campaigns.slice( pagesVisited , pagesVisited + NumberOfcampaignsPerpage )

  /* a function that handles pagination actions like next prev and active link. */
  const handlePageChange = ({ selected }) => {
     setPageNumber( selected )
  }
  
  const fetchCampains = (url=`${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }`) => {
     axios.get(url)
     .then(
     res => {
             if( res.data.success ){     
                 setTotalCampaign( res.data.campaigns.length )                                   
                 dispatch( updateProject( res.data ) )
                 setLoading(false)
             }
         }) 
  }

  useEffect( () => {
     setLoading(true)
     window.sessionStorage.setItem('in-filter-mode', 0)
     let url = '';
     if( (adminType !== "client admin")) url = `${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }`
     else  url = `${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }?access=true`
     
     fetchCampains(url);

  }, [])

  const filterCampains = (url) => {
      setLoading(true)
      let urlFilter = parseInt(window.sessionStorage.getItem('in-filter-mode'))
      let _url = urlFilter ? url + '&access=true' : url
      fetchCampains(_url)
  }

  return (
    <section className='md:mr-2 h-full'> { loading && <LoadingSpinner /> }  
       <h1 className='py-3 text-xl text-white bg-green-small font-semibold pl-3 uppercase'>
          View Campaigns
       </h1>
       <div className='mt-8 px-2 pb-8'>
            <div className='text-xl md:flex justify-between items-center'>  
                 <h6 className='font-semibold tracking-[2px]'> 
                     Welcome,  <span className='text-red-500'> { adminName } </span> 
                  </h6>
                  <div>
                    <Link className='bg-red-500 py-2 mt-3 sm:mt-0 sm:py-[4px] inline-block  mx-auto w-[280px] sm:w-[300px] sm-inline flex items-center px-5 text-white rounded-md text-sm'
                         to={'/admin/add-new-campaign'}
                         >
                    <span className='bi bi-plus-circle mr-2 text-xl'></span>    Add New Campaign
                    </Link>
                  </div>
             </div>
            <div className='grid py-3 grid-cols-1 md:grid-cols-4 gap-3 md:gap-5 mt-5'>
                 <div  onClick={ () => filterCampains(`${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }`) } 
                       className='border hover:cursor-pointer border-gray-200 justify-center rounded-lg flex items-center py-8'>
                       <span className='bi bi-bag-plus text-3xl text-red-500 mr-4'> </span>
                       <strong className='text-4xl text-gray-700 mr-4'>
                            { loading ? 0 : project.totalCampaign }
                         </strong>
                       <span className='text-sm text-gray-800'> Campaign </span>
                 </div>
                 <div className='border border-gray-200 justify-center rounded-lg flex items-center py-8'>
                       <span className='bi bi-boxes text-3xl text-red-500 mr-4'> </span>
                       <strong className='text-4xl text-gray-700 mr-4'> 
                       { loading ? 0 : project.closedCampaign }
                        </strong>
                       <span className='text-sm text-gray-800'> Closes </span>
                 </div>
                 <div 
                  onClick={ () => filterCampains(`${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }?status=active`) } 
                  className='border border-gray-200 hover:cursor-pointer  justify-center rounded-lg flex items-center py-8'
                >
                       <span className='bi bi-activity text-3xl text-red-500 mr-4'> </span>
                       <strong className='text-4xl text-gray-700 mr-4'> 
                           { loading ? 0 : project.activeCampaign }
                        </strong>
                       <span className='text-sm text-gray-800'> Active </span>
                 </div>
                 <div 
                 onClick={ () => filterCampains(`${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }?status=draft`) } 
                 className='border border-gray-200 hover:cursor-pointer justify-center rounded-lg flex items-center py-8'>
                       <span className='bi bi-archive text-3xl text-red-500 mr-4'> </span>
                       <strong className='text-4xl text-gray-700 mr-4'> 
                           { loading ? 0 : project.draftCampaign }
                        </strong>
                       <span className='text-sm text-gray-800'> Draft </span>
                 </div>
            </div>
            <div className='md:flex justify-between items-center mt-5 bg-gray-100 p-2'>
                <h4 className='text-md'> 
                      All Campaigns
                       <span className='text-red-500 ml-2'> ({ loading ? 0 : project.totalCampaign }) </span>
                </h4>
                 { (adminType !== "client admin") && <div className='mt-3 sm:mt-0'>
                    <FilterCampaignByCompany  setTotalCampaign = { setTotalCampaign } />
                </div>}
                <div className='mt-3 sm:mt-0 md:w-[300px]'>
                   <div className='input-group relative flex flex-wrap items-stretch w-full'>
                        <SearchCampaign setTotalCampaign = { setTotalCampaign } />
                   </div>
                </div>
            </div>

            <div className='m-hidden md:flex justify-between text-sm sm:text-[13px] mt-8'>
                 <span>Campaign start date</span>
                 <div className='md:flex justify-between w-full md:w-[74%] view-campaign-full-width-span-container'>
                      <span className='block sm:inline mt-2 sm:mt-0'>
                          Participants
                      </span>
                      <span className='block sm:inline mt-2 sm:mt-0'>
                           Status
                      </span>
                      <span className='block sm:inline mt-2 sm:mt-0'>
                           Response Rate
                      </span>
                      <span className='block sm:inline md:mr-8  mt-2 sm:mt-0'>
                           Closing Date
                      </span>
                 </div>
            </div>
            { 
              !loading && 
               ( project.campaigns !== undefined && project.campaigns.length) ?
                    <>
                       <Campaigns  campaigns={ activeCampaigns } />
                       <ReactPaginate 
                            previousLabel='Prev'
                            nextLabel = 'Next'
                            pageCount={ campaignsPerpage }
                            onPageChange = { handlePageChange }
                            containerClassName='pagination flex items-center justify-center mt-2  py-2'
                            previousLinkClassName='text-sm pagination-btn bg-green-small py-1 px-3 text-white rounded-lg'
                            nextLinkClassName='text-sm ml-3 pagination-btn bg-green-small py-1 px-3 text-white rounded-lg'
                            activeLinkClassName='border border-green-small text-sm px-2 py-1 rounded-full text-green-small'
                            breakLabel='...'
                            pageLinkClassName='text-sm ml-3'
                      /> 
                    </>   
                  :
                 <NoCampaign /> 
             }                     
        </div>
    </section>
  )
}

export default ViewProjects