import React from "react";
import {Navigate} from "react-router-dom";
function ProtectedRoute(props) {
    const {
        children,
        redirectTo = "/login"
    } = props;
    const userLogged = localStorage.getItem("username");

    return userLogged ? children : <Navigate to={redirectTo}/>;
}

export default ProtectedRoute;
