import React,{ useState, useEffect } from 'react'
import SearchParticipants from './SearchParticipants'
import { useSelector, useDispatch } from 'react-redux'
import { updateInput } from '../../../../../../features/evaluator-for-Participants/evaluatorForParticipantsSlice'
import { deleteEvaluator, addMoreEvaluator, readExcelFile } from '../../../../../../features/evaluator-for-Participants/evaluatorForParticipantsSlice'
import { v4 as uuid } from 'uuid'
import * as XLSX from 'xlsx/xlsx.mjs'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import axios from 'axios'
import DownloadFile from 'js-file-download'
import CustomDropdown from 'components/CustomDrpdown'

const EvaluatorForParticipantsForm = () => {

    const [ downloadLoading, setDownloadLoading ] = useState(false)
    const [ isFileDownloaded, setIsFileDownloaded ] = useState(false)
    const [ isExcelFile, setIsExcelFile ] = useState(true)
    const [participants,setParticipants] = useState([])
    const dispatch = useDispatch()
    const { participants: uploadedParticipants } = useSelector( state => state.addParticipants.formData );
    const { formData, errors, maxEvaluatorRoles } = useSelector( state => state.evaluatorForParticipant )
    
    useEffect( () => setParticipants(uploadedParticipants) , [])
    useEffect( () => window.scroll(0,0) , [] )
    
    const handleInputChange = (id,field,value) => dispatch( updateInput( { id, field, value } ) )
    
    const handleDeleteEvaluator = id => {
        dispatch( deleteEvaluator(id) )
    }

    const handleAddMoreEvaluators = id => {
         dispatch( addMoreEvaluator(id) )
    }

    const downloadEvaluators = () => {
        //download evaluators for participants.
        setDownloadLoading(true)        
        const fileType = { responseType : 'blob' }
        axios.get(`${ process.env.REACT_APP_BASE_URL }/project/download-evaluator-template`,fileType)
        .then( response => {
            DownloadFile(response.data,`360-degree-feedback-evaluators.xlsx`)
            setIsFileDownloaded(true)
            setDownloadLoading(false)
        } )
    }

    const uploadExcelTemplate =  e => {

        const excelSheet = e.target.files[0]
 
        if(!excelSheet.name.includes('.xl')){
            setIsExcelFile(false)
        }
        else{
           setIsExcelFile(true)
           const promise = new Promise( ( resolve , reject ) => {
               const fileReader = new FileReader();
               fileReader.readAsArrayBuffer(excelSheet);
 
               fileReader.onload = event => {
                   const bufferArray = event.target.result;
                   const workBook = XLSX.read(bufferArray, { type : 'buffer' });                    
                   const workSheetName = workBook.SheetNames[0]
                   const workSheet = workBook.Sheets[ workSheetName ]
                   const dataArray = XLSX.utils.sheet_to_json( workSheet) ;                  
                   resolve(dataArray)
               }
 
               fileReader.onerror = error => {
                   reject(error)
               }
           })
 
           promise.then( data => {
              //check if the data is in the rigth format
              const checkKey = (key) => data[0].hasOwnProperty(key)
              if( 
                     !checkKey('PARTICIPANT EMAIL') || 
                     !checkKey('EVALUATOR EMAIL') || 
                     !checkKey('EVALUATOR NAME') 
                     || !checkKey('EVALUATOR ROLE') ) 
                {
                  setIsExcelFile(false)                                 
                }
              else {
                  dispatch( readExcelFile( data ) )                                
                  setIsExcelFile(true)                
                  window.scrollTo({ top : 700 , behavior:'smooth'})     
                  e.target.value = null;             
              }
           })
        }
    }

  return (
    <section className='px-2 md:px-8'> { downloadLoading && <LoadingSpinner /> }
         <h5 className='text-md md:text-xl text-red-500 md:ml-8 bi bi-people'> 
            &nbsp; Upload Evaluators For Participants
          </h5>
          <div className='flex justify-center mt-8'>
               <label htmlFor='file' className='py-2  px-12  text-sm bi bi-plus-circle'>
                  &nbsp; Bulk Upload Evaluators
               </label>
          </div>
          <div className='mt-5 md:flex justify-between'>
              <div>
                    <span className='block text-md'> 1. Download Evaluators Excel Template </span>
                    <div className='w-[70px] h-[70px] mt-3 mx-auto bg-green-small flex items-center justify-center md:ml-16'>
                        <span onClick={ downloadEvaluators } className='text-4xl text-white bi bi-cloud-arrow-down'></span>                      
                    </div>
                    <p className='text-sm md:text-[13px] md:w-[290px] mt-3'>
                             <button onClick={ downloadEvaluators } className='text-red-500 mr-2'> Download </button> 
                             excel template to upload evaluators for  participants                          
                     </p>
                     { isFileDownloaded &&
                        <div className='border-l border-l-2 border-green-500 text-[13px] bg-green-100 py-3 pl-2 text-green-500 mt-2'>
                              Your file is successfully downloaded. &nbsp;                              
                              <label htmlFor='file' className='text-[14px] mr-3 hover:cursor-pointer'> Click here to upload  file </label>
                              <input type='file' onChange={ uploadExcelTemplate } name='file'  id='file' className='hidden' />                              
                         </div>
                      }                     
              </div>

              <div className='mt-4 sm:mt-0'>
                    <span className='block text-md'> 2. Upload Evaluators Using Template </span>
                    <div className='w-[70px] h-[70px] mt-3 mx-auto bg-green-small flex items-center justify-center'>
                        <label htmlFor='file' className='text-4xl text-white bi bi-cloud-arrow-up'></label>                      
                    </div>
                    <p className='text-sm md:text-[13px] md:w-[290px] mt-3'>
                             <input type='file' onChange={ uploadExcelTemplate } name='file' id='file' className='hidden' />
                             <label htmlFor='file' className='text-red-500'> Browse </label>
                              and upload evaluators for participants.
                     </p>
                      { !isExcelFile &&
                         <div className='border-l border-l-2 border-red-500 text-[13px] bg-red-100 py-3 pl-2 text-red-500 mt-2'>
                             Error:  File is not an valid excel document.
                         </div>
                      }
              </div>
          </div>
          <div className='flex justify-center mt-8'>
               <button className='py-2 rounded-lg text-red-500 px-5  text-sm bi bi-plus-circle'>
                  &nbsp;  OR
               </button>
          </div>
          <h5 className='text-md md:text-lg md:ml-8 bi bi-people my-5'> 
              &nbsp; Add Evaluators For An Existing Participants  
          </h5>
          <div className='flex flex-col '>
                    <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
                        <div className='py-2 inline-block min-w-full px-2 md:px-8 bg-gray-100'>
                        <div className='overflow-hidden'>
                            <table className='min-w-full text-center sm:mt-3'>
                               <thead>
                                    <tr>
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-2'>
                                            S/N
                                        </th>
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-12 md:px-3 py-2'>
                                             Participant Email
                                        </th>
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-12 md:px-3 py-2'>
                                            Evaluator Name
                                        </th>                                       
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-12 md:px-3 py-2'>
                                             Evaluator Email
                                        </th>
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-12 md:px-3 py-2'>
                                           Evaluator Role
                                        </th>                                         
                                    </tr>
                            </thead>
                            <tbody>                                                           
                                { formData.evaluators.map( (evaluator,id) => {
                                     return(
                                        <tr key={id}>
                                        <td className='px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                            { id + 1 }
                                        </td>
                                        <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                            <CustomDropdown
                                               placeholder={'Search & Select Participant...'}
                                               value={ {label: evaluator?.participantEmail, value: evaluator?.participantEmail} }
                                               width={'w-full'}
                                               onChange={ (value) => handleInputChange(id,"participantEmail", value?.value ) }
                                               options={ participants?.map( participant => {
                                                 return { value: participant?.email, label: participant?.name }
                                               })}
                                            />
                                        </td>
                                        <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                            <input 
                                                type="text" name="evaluatorName" id=""                                                                                                                    
                                                className='admin-form-input' 
                                                value={ evaluator.evaluatorName }
                                                onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }
                                                style={{ paddingTop : 7 , paddingBottom : 7}}
                                              />
                                        </td>  
                                        <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                            <input                                                               
                                                  type="email" name="evaluatorEmail" id="" 
                                                  className='admin-form-input' 
                                                  value={ evaluator.evaluatorEmail }    
                                                  onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }                                                                                                                       
                                                  style={{ paddingTop : 7 , paddingBottom : 7}} 
                                            />
                                        </td>                                                                                                          
                                        <td className='text-sm flex items-center text-gray-900 font-light px-3 py-2 whitespace-nowrap'>                                             
                                              <select class='industry-type-input form-select form-select-xs'
                                                      name='evaluatorRole'
                                                      aria-label='.form-select-sm example'
                                                      style={{ paddingTop : 7 , paddingBottom : 7}}
                                                      value={ evaluator.evaluatorRole } 
                                                      defaultValue = { evaluator.evaluatorRole }
                                                      onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) } 
                                                >                                                                 
                                                    <option value='manager'>manager</option>
                                                    <option value='peer'>peer</option>
                                                    <option value='direct report'>direct report</option>                        
                                             </select>
                                              { id > 0 && <span                                                           
                                                className='bi bi-trash ml-3 text-red-500 text-md'
                                                onClick={ () => handleDeleteEvaluator(evaluator.id) }
                                                ></span>}
                                        </td>                                                                                           
                                      </tr>                    
                                     )
                                } ) }                       
                           </tbody>
                    </table>
                  </div>
               </div>
             </div>
         </div>          
         {
            errors.evaluator &&
            <div className='border-l border-l-2 px-2 border-red-500 text-red-500 mt-4 text-sm text-center py-3 px-2 md:w-[480px] mx-auto bg-red-100'> 
                 { errors.evaluator }
             </div>
         }     
         <div className='flex justify-end mt-5 md:w-[100%]'>
            { 
               (formData.evaluators.length < maxEvaluatorRoles ) && <button 
                  className='py-2 text-white text-sm bi bi-plus-circle px-8 rounded-lg bg-green-small'
                  onClick={() => handleAddMoreEvaluators( uuid() ) }
               >
                &nbsp; Add more
               </button>
            }
         </div>       
    </section>     
  )
}

export default EvaluatorForParticipantsForm