import React from "react";
import "./EvaluatorTab.css";
import UpdateEvaluator from "./MapEvaluator/MapEvaluator";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";
import axios from "axios";

function EvaluatorTab(params) {
  const { data, deleteEvaluator, page, row, refetch, setFilterCharacter } =
    params;
  const [openModal, setOpenModal] = useState(false);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const [isUpdateFailed, setIsUpdateFailed] = useState(false);
  const [updateFormInitialValue, setUpdateFormInitialValue] = useState({
    // project_id,
    evaluators: [
      {
        evaluator_name: "",
        evaluator_email: "",
        evaluator_role: "Manager",
        id: "",
        password: "",
      },
    ],
  });
  const updateEvaluator = async (values, resetForm) => {
    let res = await axios.patch(
      "https://sandboxapi.360degree.testassessify.com/project/evaluator/update",
      values
    );
    if (await res.data.success) {
      setIsUpdateSuccessful(true);
      setTimeout(() => setIsUpdateSuccessful, 5500);
      refetch();
    } else {
      setIsUpdateFailed(true);
      setTimeout(() => setIsUpdateFailed, 2500);
    }
  };
  const setEvaluator = (e) => {
    console.log("dt", e);
    setOpenModal(true);
    setUpdateFormInitialValue({
      // project_id,
      evaluators: [
        {
          evaluator_name: e?.evaluator_name,
          evaluator_email: e?.evaluator_email,
          evaluator_role: e?.evaluator_role,
          id: e?.id,
          password: e?.password || "11111111",
        },
      ],
    });
  };
  return (
    <div className="evaluatorTab">
      <UpdateEvaluator
        submitFunc={updateEvaluator}
        updateFormInitialValue={updateFormInitialValue}
        open={openModal}
        setOpen={setOpenModal}
        isSuccessful={isUpdateSuccessful}
        setSuccess={setIsUpdateSuccessful}
        isError={isUpdateFailed}
        setError={setIsUpdateFailed}
        title="Update Evaluator"
      />
      <div className="participant-table">
        <div className="table-row table-header evaluator-table-header">
          <div className="table-td"></div>
          <div className="table-td text-left">Participant Name</div>
          <div className="table-td">Email</div>
          <div className="table-td">Campaign</div>
          <div className="table-td">Status</div>
          <div className="table-td">Response Rate</div>
          {/* <div className="table-td table-sub-td"> */}
          <div className="table-td">Date</div>
          {/* <input
              type="search"
              name="tab-table-action"
              id="tab-table-action"
              className="tab-table-action"
              placeholder="Participant Name Search"
              onChange={(e) => setFilterCharacter(e.target.value)}
            /> */}
          {/* </div> */}
        </div>

        <div className="table-row evaluator-accordion-row">
          <div className="evaluators-datas">
            {data?.slice(page * row, (page + 1) * row).map((e, i) => (
              <Accordion className="dashboard-accordion">
                <AccordionSummary className="accordion-summary">
                  <div className="neumorph report-header">
                    <div className="accord-td evaluator-sn">
                      <ArrowDropDownIcon />
                    </div>
                    <h5 className="accord-td evaluator-name">
                      {i + 1}. {e?.name}
                    </h5>
                    <h5 className="accord-td evaluator-email">{e?.email}</h5>

                    <h5 className="accord-td evaluator-camaign">
                      Feedback Survey
                    </h5>
                    <h5 className="accord-td evaluator-status">Pending</h5>
                    <div className="accord-td evaluator-progress">
                      <p className="evaluator-progress-text">
                        {e?.totalSubmittedResponse}/{e?.totalMinimumExpected}
                      </p>
                      <progress
                        value={Math.floor(
                          (e?.totalSubmittedResponse /
                            e?.totalMinimumExpected) *
                            100
                        )}
                        min={0}
                        max={100}
                      ></progress>
                    </div>
                    <h5 className="accord-td evaluator-date">
                      {e?.updatedAt.split("T")[0].replaceAll("-", "/")}
                    </h5>
                    {/* icon */}
                    {/* <div className="report-icon-arrow">
                  <ArrowDropDownIcon />
                </div>
                <h3 className="report-title">Reports Not Ready</h3> */}
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  <div className="accordion-details-content">
                    <div className="accordion-details-row accordion-details-header">
                      <div className=""></div>
                      <div className="accordion-col">Evaluator Role</div>
                      <div className="accordion-col">Evaluator</div>
                      <div className="accordion-col">Email</div>
                      <div className="accordion-col accordion-status">
                        Status
                      </div>
                      <div className="accordion-col">Date</div>
                    </div>
                    <div className="accordion-details-rows">
                      {e?.evaluator?.map((e, i) => (
                        <div className="accordion-details-row">
                          <p className="">{i + 1}</p>
                          <p className=""> {e?.evaluator_role} </p>
                          <p className="">{e?.evaluator_name}</p>
                          <p> {e?.evaluator_email}</p>
                          {e?.hasEvaluateParticipant.toLowerCase() !==
                            "in progress" && (
                            <div className="evaluator-status">
                              {/* icon */}
                              <div className="evaluator-status-icon">
                                {e?.hasEvaluateParticipant.toLowerCase() ===
                                "done" ? (
                                  <DoneIcon />
                                ) : (
                                  <ClearIcon className="orange-bg" />
                                )}
                              </div>
                              <p className="evaluator-status-text">
                                {e?.hasEvaluateParticipant.toLowerCase() ===
                                "done"
                                  ? "Complete"
                                  : "Pending"}
                              </p>
                            </div>
                          )}
                          {e?.hasEvaluateParticipant.toLowerCase() ===
                            "in progress" && (
                            <div className="evaluator-status">
                              {/* icon */}
                              <div className="evaluator-status-icon">
                                <ClearIcon className="orange-bg" />
                              </div>
                              <p className="evaluator-status-text orange">
                                In Progress
                              </p>
                            </div>
                          )}
                          <p className="">
                            {" "} 
                            {e?.updatedAt && `${ new Date(e?.updatedAt).toLocaleDateString('en-ng')}`}
                          </p>
                          <div className="evaluator-actions">
                            {/* icon */}
                            <button
                              className="evaluator-edit"
                              onClick={(click) => setEvaluator(e)}
                            >
                              <EditIcon />
                            </button>
                            <button
                              className="evaluator-delete"
                              onClick={(data) =>
                                deleteEvaluator(e?.id, e?.evaluator_name)
                              }
                            >
                              <DeleteIcon />
                            </button>
                            {/* icon */}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EvaluatorTab;
