import React from "react";
import "./Evaluator.css";
function Evaluator(params) {
  const {
    roleSummary = [
      { role: "Self", nominated: 0, response: 0 },
      { role: "Manager", nominated: 0, response: 0 },
      { role: "Direct report", nominated: 0, response: 0 },
      { role: "self", nominated: 0, response: 0 },
    ],
    nominatedFeedback,
    completedFeedback,
    pendingFeedback,
    notStarted,
  } = params;
  return (
    <div className="evaluator">
      <h2 className="mb-2"> Evaluator Feedback Response</h2>
      <div className="evaluator-response">
        <div className="evaluator-box">
          <h3 className=""> {nominatedFeedback}</h3>
          <p className="">Nominated</p>
        </div>
        <div className="evaluator-box">
          <h3 className="">{completedFeedback}</h3>
          <p className="">Completed Feedback</p>
        </div>
        <div className="evaluator-box">
          <h3 className="">{pendingFeedback}</h3>
          <p className="">Feedback in Progress</p>
        </div>
        <div className="evaluator-box">
          <h3 className="">{notStarted}</h3>
          <p className="">Not Started</p>
        </div>
      </div>
      <div className="evaluator-row row-header">
        <p className="">Evaluator Roles</p>
        <p className=""> Nominated</p>
        <p className=""> Response Rate</p>
      </div>

      <div className="evaluator-rows">
        {roleSummary?.map((e) => (
          <div className="evaluator-row">
            <p className="capitalize">{e?.role} </p>
            <p className=""> {e.nominated}</p>
            <p className=""> {e?.response}</p>
            <progress
              className="participant-evaluator-progress"
              value={(e.response / e.nominated) * 100}
              max={100}
              placeholder={35}
            ></progress>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Evaluator;
