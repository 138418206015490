import React from "react";

import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
function Footer() {
  return (
    <div className="landingPage__Footer">
      <div className="footer-left">
        <div className="footer-logo">
          {/* Logo */}
          <img src="/assets/UX/TestAssessify_logo.png" alt="" />
        </div>
        <p className="">© TestAssessify 2022. All rights reserved.</p>
        <p className="">
          Registered office is at 35, Ladipo Labinjo Crescent, Surulere.
        </p>
      </div>
      <div className="footer-center">
        <ul className="footer-links">
          <li className="footer-link">
            <a href="#"> Help Center</a>
          </li>
          <li className="footer-link">
            <a href="#"> Useful Information for Participants</a>
          </li>
          <li className="footer-link">
            <a href="#"> Help Center</a>
          </li>
        </ul>
        <ul className="footer-links">
          <li className="footer-link">
            <Link to="/faq">FAQs</Link>
          </li>
          <li className="footer-link">
            <a href="#"> Contact</a>
          </li>
          {/* <li className="footer-link"> <a href="#"> Help Center</a></li> */}
        </ul>
      </div>
      <div className="footer-right">
        <div className="footer-social">
          {/* image */}
          <a href="#">
            <div className="footer-social-image">
              <FacebookIcon />
            </div>
          </a>
          <a href="#">
            <div className="footer-social-image">
              <TwitterIcon />
            </div>
          </a>
          <a href="#">
            <div className="footer-social-image">
              <InstagramIcon />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
