import React from 'react'
import { updateAdminInput } from '../../../../../../features/create-company/create-company-slice' 
import { useSelector, useDispatch } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Form1 = () => {

  const { formData , errors }  = useSelector( state => state.createCompanyForm )
  const dispatch = useDispatch()
 
  const handleChange = e => {
    dispatch( updateAdminInput({ name : e.target.name , value : e.target.value }) )
  }        

  return (
    <form>
        <h5 className='text-md font-semibold mb-4'> Step 1: Company Name </h5>
        
        <label htmlFor="name" className='text-sm'> Admin Name: </label>
        <input 
              type='text' 
              name='admin_name'
              className='admin-form-input'
              value={ formData.admin.admin_name }
              onChange = { handleChange }            
            />
         <div className='text-red-500 text-sm mt-2'> { errors.admin_name } </div>
         <br />

        <label htmlFor="name" className='text-sm'> Business email with suffix: </label>
        <input 
              type='email' 
              name='admin_email'
              className='admin-form-input'
              value={ formData.admin.admin_email }
              onChange = { handleChange }   
              placeholder='example@business.com'         
            />
            <div className='text-red-500 text-sm mt-2'> { errors.admin_email } </div>
          <br />

         <label htmlFor="name" className='text-sm'> Contact Number: </label>
            <PhoneInput
              country={'ng'}
              disableCountryCode={true}
              value={ formData.admin.admin_phone }
              containerClass='admin-form-input'
              inputStyle={{ border: 'none', paddingTop:0, paddingBottom:0 }}
              buttonStyle = { { height: '40px', marginTop: 9 }}
              regions='africa'
              enableSearch={true}
              onChange={phone => dispatch( updateAdminInput({ name : 'admin_phone' , value : phone }) )}
            />
          <div className='text-red-500 text-sm mt-2'> { errors.admin_phone } </div>
          <br />

        <label htmlFor="name" className='text-sm'> Password: </label>
        <input 
              type='password' 
              name='password'
              className='admin-form-input'
              value={ formData.admin.password }
              onChange = { handleChange }            
            />      
        <div className='text-red-500 text-sm mt-2'> { errors.password } </div>    
      <br />
      <label htmlFor="name" className='text-sm'> Confirm password: </label>
        <input 
              type='password' 
              name='password_confirm'
              className='admin-form-input'
              value={ formData.admin.password_confirm }
              onChange = { handleChange }            
            /> 
        <div className='text-red-500 text-sm mt-2'> { errors.password_confirm } </div>
    </form>
  )
}

export default Form1