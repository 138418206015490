import React from "react";
import styles from "../ReportStyleSheet";
import { Text, View, Image } from "@react-pdf/renderer";

function CompetencySummary(params) {
  const {
    progress = 10,
    summary = `Given below is a summary of your feedback ratings for each of the competencies. The graphs indicate the average ratings for each competency in the review segmented by rater groups.`,
    resultList = [
      {
        title: "Decision Making",
        totalRatingScore: 3.7,
        summary:
          "Demonstrating an ability to make informed decisions after considering all available data and the potential positive and negative outcomes of these decisions",
        ratingList: [
          {
            role: "Self",
            score: 2.4,
          },
          {
            role: "Direct Report",
            score: 2.5,
          },
          {
            role: "Manager",
            score: 4.3,
          },
          {
            role: "Peer",
            score: 4.6,
          },
        ],
      },
      {
        title: "Decision Making",
        totalRatingScore: "3.7",
        summary:
          "Demonstrating an ability to make informed decisions after considering all available data and the potential positive and negative outcomes of these decisions",
        ratingList: [
          {
            role: "Self",
            score: 2.4,
          },
          {
            role: "Direct Report",
            score: 2.5,
          },
          {
            role: "Manager",
            score: 4.3,
          },
          {
            role: "Peer",
            score: 4.6,
          },
        ],
      },
    ],
  } = params;
  return (
    <View style={{ fontSize: 12 }} break>
      <Text style={styles.header}>3.0 COMPETENCY SUMMARY</Text>
      <Text>{summary}</Text>

      {resultList?.map((e) => (
        <View
          style={{
            flexDirection: "row",
            marginBottom: 10,
            // borderStyle: "solid",
            // borderWidth: 1,
          }}
          wrap={false}
        >
          {/* Decision making */}
          <View
            style={{
              maxWidth: "50vw",
              marginTop: 10,
              padding: 10,
            }}
          >
            <Text style={{ marginBottom: 10 }}>
              {" "}
              {e?.category_name?.toUpperCase()}
            </Text>
            <Text style={{ marginBottom: 10 }}> {e?.totalRating} Out of 5</Text>
            {/* progress bar */}
            <View style={[styles.progressBarContainer, { marginBottom: 10 }]}>
              <View
                style={[
                  styles.progressBarBackground,
                  {
                    width: `${e?.totalRating * 20 || 0}%`,
                    backgroundColor: `${
                      e?.totalRating > 2.5 ? "green" : "red"
                    }`,
                  },
                ]}
              />
              {/* <Text style={styles.progressBarText}>{`${progress}%`}</Text> */}
            </View>
            {/* progress bar ends */}

            <Text style={{ minWidth: 260 }}>
              {
                e?.summary
                // "Demonstrating an ability to make informed decisions after considering all available data and the potential positive and negative outcomes of these decisions"
              }
            </Text>
          </View>
          {/* Ratings */}
          <View
            style={{
              maxWidth: "40vw",
              marginTop: 10,
              padding: 10,

              marginLeft: 20,
            }}
          >
            <Text style={{ marginBottom: 10 }}> Ratings</Text>
            {e?.score?.map((e) => (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <Text style={[styles.avatar, { fontSize: "3mm" }]}>
                  {e?.role[0]}
                </Text>
                <Text style={{ marginRight: 5, width: 80 }}> {e?.role}</Text>

                {/* PRogress bar again */}
                <View
                  style={[
                    styles.progressBarContainer,
                    { maxWidth: "20vw", marginLeft: 10, height: 15 },
                  ]}
                >
                  <View
                    style={[
                      styles.progressBarBackground,
                      {
                        width: `${e?.scoreRating * 20 || 0}%`,
                        backgroundColor: `${
                          e?.scoreRating > 2.5 ? "green" : "red"
                        }`,
                      },
                    ]}
                  />
                  {/* <Text style={styles.progressBarText}>{`${progress}%`}</Text> */}
                </View>
                {/* Progress bar ends again */}

                <Text style={{ marginLeft: 5 }}> {e?.scoreRating}</Text>
              </View>
            ))}
          </View>
        </View>
      ))}
    </View>
  );
}

export default CompetencySummary;
