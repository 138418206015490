import React, { useEffect, useState } from "react";
import "./Campaign.css";
import Task from "../components/Task2/Task";
import Button from "../components/Button/Button";
import Evaluator from "../Evaluator/Evaluator";
import CircularProgress from "../components/CircularProgress/CircularProgress";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MenuIcon from "@mui/icons-material/Menu";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Report from "../Report/Report";
import LoadingSpinner from "../../../components/LoadingSpinner";

function Campaign(params) {
  
  const [pageLoading, setPageLoading] = useState(false);
  const [progress, setProgress] = useState(
    localStorage.getItem("progress") ?? 1000
  );
  const [page, setPage] = useState(localStorage.getItem("page"));

  const {
    user = localStorage.getItem("username"),
    deadlineDate = "20",
    subtitle = " Graduate trainee assessment",
  } = params;

  useEffect(() => {
    if (!page) {
      localStorage.setItem("page", 0);
    }
  }, [page]);

  // Evaluator response

  const [completedAssessment, setCompletedAssessment] = useState(
    localStorage.getItem("completedAssessment") ?? false
  );
  const [evaluator, setEvaluator] = useState({});
  const participant_id = localStorage.getItem("participant_id");
  const [projectList, setProjectList] = useState(
    JSON.parse(localStorage.getItem("project_list"))
  );
  const [project_id, setProjectId] = useState(
    // JSON.parse(localStorage.getItem("project_id"))[0]?.id
    JSON.parse(localStorage.getItem("project_list"))[0]?.project_id
  );

  const [loadingEvaluator, setLoadingEvaluator] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    const request = axios
      .post(
        " https://sandboxapi.360degree.testassessify.com/survey/category-question",
        { participant_id, project_id, evaluator_id: participant_id }
      )
      .then((e) => {
        let progress = Math.round(
          ((e?.data?.currentStep - 1) / e?.data?.totalStep) * 100
        );
        localStorage.setItem("progress", progress);
        setProgress(progress);
        setCompletedAssessment(false);
      })
      .catch((e) => {
        localStorage.setItem("progress", 100);
        setProgress(100);
        setCompletedAssessment(true);
      })
      .finally((e) => setPageLoading(false));
  }, [project_id]);

  useEffect(() => {
    if (completedAssessment) {
      setLoadingEvaluator(true);
      const request = axios
        .post(
          " https://sandboxapi.360degree.testassessify.com/project/participant-dashboard",
          { project_id, participant_id }
        )
        .then((e) => {
          setEvaluator(e.data);
          console.log("evaluator request", e.data);
        })
        .finally(() => {
          setLoadingEvaluator(false);
        });
    }
  }, [completedAssessment, project_id]);

  let participantDBQuery = useQuery(
    ["fetch-participant-db", project_id],
    participantDashBoard
  );
  async function participantDashBoard() {
    let res = await axios.post(
      " https://sandboxapi.360degree.testassessify.com/project/participant-dashboard",
      { project_id, participant_id }
    );
    return res.data;
  }

  function changeCampaign(value) {
    localStorage.setItem("project_id", value);
    setProjectId(value);
    console.log({ value, project_id });
  }

  // if (true) {
  //   return;
  // }

  return (
    <div className="campaign">
      {(participantDBQuery?.isLoading || pageLoading) && <LoadingSpinner />}
      <h1 className="campaign__Title">Campaign</h1>
      <div className="campaign__SelectProject mt-4">
        <label htmlFor="selectProject" className="mr-4 ">
          Select Campaign:
        </label>
        <select
          name="selectProject"
          id="selectProject"
          className="admin-select-month"
          defaultValue={localStorage.getItem("project_id") || ""}
          onChange={(e) => changeCampaign(e?.currentTarget?.value)}
        >
          {projectList?.map((project, index) => (
            <option
              key={project?.project_id + index}
              value={project?.project_id}
            >
              {" "}
              {project?.project?.project_name}
            </option>
          ))}
        </select>
      </div>
      <div className="campaign__Content">
        <h2 className="campaign__Header">
          Welcome, <span>{user}</span>
        </h2>

        {participantDBQuery?.data && !completedAssessment && (
          <p className="campaign__Subtitle">
            You have <span>{participantDBQuery.data?.remainingDays} </span>days
            to complete your self assessment
          </p>
        )}

        <div className="open-task">
          <p className="open-task-text">
            {progress != 100 ? "Open" : "Completed"} Task
          </p>
          {/* <hr /> */}
        </div>
        <div className="campaign__Tasks">
          <Link
            to={progress == 100 || progress == 1000 ? null : ".././assessment"}
          >
            <div className="campaign__Task">
              <div className="progress-circle">
                {" "}
                {/* progress circle */}
                <CircularProgress value={Math.round(progress)} />
              </div>
              <div className="progress-task">
                {/* progress Task */}
                <Task
                  // action="Complete your self-assessment for the Graduate Fast Track Feedback Survey"
                  action="Complete your self-assessment"
                  todo=""
                  icon={<MenuIcon />}
                  link=".././assessment"
                  progress={Math.round(progress)}
                  iconLabel={
                    progress !== 1000
                      ? `${progress == 100 ? "Complete" : "Take Assessment"}`
                      : `${"Loading..."}`
                  }
                />
              </div>
            </div>
          </Link>
        </div>

        {
          <div className="campaign__Report">
            <p
              className={`report-status ${
                participantDBQuery?.data?.isReportAvialable ? "gold" : "orange"
              }`}
            >
              {participantDBQuery?.data?.isReportAvialable
                ? "Report Ready"
                : "Report Not Ready"}
            </p>
            <div className="report-body">
              <div
                className={`report-icon ${
                  !participantDBQuery?.data?.isReportAvialable && "disabled"
                }`}
              >
                <InsertDriveFileIcon />
              </div>
              <div className="report-date"></div>
              <button
                className={`report-download ${
                  !participantDBQuery?.data?.isReportAvialable && "disabled"
                }`}
                disabled={!participantDBQuery?.data?.isReportAvialable}
              >
                {/* <button className="report-download " disabled={false}> */}
                {/* Download */}
                <Report
                  participant_id={participant_id}
                  project_id={project_id}
                  className={`${
                    !participantDBQuery?.data?.isReportAvialable
                      ? "disabled"
                      : ""
                  }`}
                >
                  <Button
                    icon={<SystemUpdateAltIcon />}
                    iconLabel="Download"
                    disabled={`${
                      !participantDBQuery?.data?.isReportAvialable
                        ? "disabled"
                        : ""
                    }`}
                  />
                </Report>
              </button>
            </div>
          </div>
        }

        {/* <div className="open-task">
          <p className="open-task-text">
            {progress != 100 ? "Open" : "Completed"} Task
          </p>
        
        </div>
        <div className="campaign__Tasks">
          <Link
            to={progress == 100 || progress == 1000 ? null : ".././assessment"}
          >
            <div className="campaign__Task">
              <div className="progress-circle">
                {" "}
               
                <CircularProgress value={Math.round(progress)} />
              </div>
              <div className="progress-task">
               
                <Task
                  action="Complete your self-assessment for the Graduate Fast Track Feedback Survey"
                  todo=""
                  icon={<MenuIcon />}
                  link=".././assessment"
                  progress={Math.round(progress)}
                  iconLabel={
                    progress !== 1000
                      ? `${progress == 100 ? "Complete" : "Take Assessment"}`
                      : `${"Loading..."}`
                  }
                />
              </div>
            </div>
          </Link>
        </div> */}
        <div className="campaign__Evaluator">
          {completedAssessment &&
            (loadingEvaluator ? (
              "Loading"
            ) : (
              <Evaluator
                roleSummary={evaluator?.roleSummary}
                completedFeedback={evaluator?.completedFeedback}
                nominatedFeedback={evaluator?.nominatedEvaluator}
                pendingFeedback={evaluator?.inProgressFeedback}
                notStarted={evaluator?.notStarted}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Campaign;
