import React from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";

function ProfileCard(props) {
  const { profile } = props;

  function convertToProfileList(obj) {
    if (!obj) {
      return [];
    }
    let profileList = [
      { label: "Name", value: obj.name },
      { label: "Email", value: obj.email },
      { label: "Country", value: obj.country },
      { label: "Company", value: obj?.company },
      { label: "Subsidiary", value: obj.subsidiary },
      { label: "Department", value: obj?.department },
      { label: "Branch", value: obj?.branch },
    ];
    return profileList;
  }

  let profileList = convertToProfileList(profile?.data?.data);
  if (profile.isLoading) {
    return (
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      </Grid>
    );
  }
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Grid>
          {profileList.map((profile, index) => (
            <Grid
              container
              alignItems={"center"}
              key={profile?.value + index}
              mb={2}
            >
              <Box width={"100%"} maxWidth={"150px"}>
                {" "}
                <Typography variant="h6">{profile?.label}</Typography>
              </Box>
              <TextField
                id="read-only-filled-basic"
                variant="filled"
                defaultValue={profile?.value?.toUpperCase()}
                sx={{ textTransform: "capitalize" }}
                InputProps={{
                  readOnly: true,
                }}
              ></TextField>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default ProfileCard;
