import FeedbackTable from './FeedbackTable'

export const reportTemplate = [
    {
       header : 'introduction',
       content : <div>
             <p className="text-sm leading-7">
                  360° appraisals compare an individual's self ratings
                   on a number of behavioural competencies to the ratings 
                   provided by other individuals who regularly interact with 
                   them at work. These "raters" are grouped by the nature of 
                   their relationship with the individual being appraised to be 
                   a manager a peer, a direct report or other key stakeholders 
                   such as clients. 
             </p>
             <img src="/assets/report-sucessfull-program.jpg" alt="report" className="block mx-auto w-[94%] md:w-[80%] h-[260px] md:h-[400px] mt-5" />
              <ul className="mt-5 list-disc text-sm ml-3 md:ml-0">
                 <li> 
                    The purpose of 360 degree feedback: 
                 </li>
                 <li> 
                   	To develop an awareness of one's perceived behavior at the workplace.  
                 </li>
                 <li> 
                  	To identify areas of strengths and weaknesses and plan on using them effectively. 
                 </li>
                 <li> 
                   To improve one's interaction skills and overall performance. 
                 </li>
                 <li> 
                    To incorporate the opinions and perspectives of others and obtain a holistic overview of behavior. 
                 </li>
                 <li> 
                    To pave the way for personal development and growth.
                 </li>                  
              </ul>
              <p className="leading-7 mt-5 text-sm">
                   The information contained in this report provides insight into your strengths
                   and weaknesses within the competencies as well as any differences that might 
                   exist between your perception of your behaviour and that of others. The 
                   information can be used to enhance your self-awareness and better engage
                    in your personal and professional development.
              </p>
       </div>
     },
     {
      header : 'respondent summary',
      content : <div>
            <p className="text-sm">
                 The following is a summary of the group of respondents who 
                 were invited to participate and provide feedback for you
            </p>
            <FeedbackTable />
      </div>
    },
    {
      header : 'competency summary',
      content : <div>
            <p className="text-sm">
                Given below is a summary of your feedback ratings for each of the competencies.
                 The graphs indicate the average ratings for each competency in the review 
                 segmented by rater groups.
            </p>
            <img src="/assets/report-ratings.png" alt="report ratings" className="block mx-auto w-full md:w-[600px] h-[270px] md:h-[470px] mt-5" />
      </div>
    },
    {
      header : 'competency evaluation and inference',
      content : <div>
             <h5 className="text-sm">
               STRENGTHS:  <span className="ml-5 text-gray-800 text-xs">
                  #SELF EVALUATION- HIGH, OTHERS EVALUATION- HIGH
               </span>
             </h5>
            <p className="text-sm">
               Below are the statements where you received the highest ratings 
               and are considered your key strengths.
            </p>
            <img src="/assets/report-strength-ratings.png" alt="report ratings" className="block mx-auto w-full md:w-[450px] h-[260px] md:h-[350px] mt-5" />
            <h5 className="text-sm mt-8">
                 AREAS TO IMPROVE:  <span className="ml-5 text-gray-800 text-xs">
                 #SELF EVALUATION- LOW, OTHERS EVALUATION- LOW
               </span>
             </h5>
            <p className="text-sm">
                Below are the statements where you received the lowest ratings and are 
                considered your areas of improvements.
            </p>
            <img src="/assets/report-area-ratings.png" alt="report ratings" className="block mx-auto w-full md:w-[450px] h-[260px] md:h-[350px] mt-8" />

            <h5 className="text-sm mt-8">
                  HIDDEN STRENGTHS:  <span className="ml-5 text-gray-800 text-xs">
                  #SELF EVALUATION- LOW, OTHERS EVALUATION- HIGH
               </span>
             </h5>
            <p className="text-sm">
                Hidden Strengths are statements where you rated yourself 
                lower compared to the average rating of other respondents.
            </p>
            <img src="/assets/report-hidden-rating.png" alt="report ratings" className="block mx-auto w-full md:w-[450px] h-[260px] md:h-[350px] mt-8" />

            <h5 className="text-sm mt-8">
                   BLIND SPOTS: :  <span className="ml-5 text-gray-800 text-xs">
                   #SELF EVALUATION- HIGH, OTHERS EVALUATION- LOW
               </span>
             </h5>
            <p className="text-sm">
                Blind Spots are statements where you rated yourself higher compared to the average rating of other respondents.
                These may be your potential areas of improvement.
            </p>
            <img src="/assets/report-blind-rating.png" alt="report ratings" className="block mx-auto w-full md:w-[450px] h-[280px] md:h-[350px] mt-8" />

      </div>
    },
    {
      header : 'open ended feedback',
      content : <div>
             <p className="leading-7 mt-5 text-sm">
               This section provides verbatim comments from all respondents. You will gain the most value if you pay attention to the frequently occurring topics and suggestions. 
                Try to view the information objectively and reconcile it with the information in the previous rating sections.
                #ALL NOTABLE QUALITATIEVE COMMENTS ARE LISTED HERE
                #NEGATIVE FEEDBACK QUESTIONS FIRST, THEN THE POSITVE FEEDBACK AFTER
            </p>
            <img src="/assets/report-feedback-1.png" alt="report ratings" className="block mx-auto w-full md:w-[550px] h-[280px] md:h-[370px] mt-8" />
            <img src="/assets/report-feedback.png" alt="report ratings" className="block mx-auto w-full md:w-[550px] h-[280px] md:h-[370px] mt-8" />
      </div>
    },
    {
      header : 'detailed feedback',
      content : <div>
            <p className="text-sm leading-7">
                 The detailed statement-wise rating provides your complete 
                 group-wise breakdown of your feedback on each statement.
            </p>
            <p className="text-sm leading-7 mt-5">
                 #Each category of question is discussed under this section including 
                 each question under each category. The ratings of each participant 
                 are also included here.
            </p>
            <img src="/assets/report-detailed-feedback.png" alt="report ratings" className="block mx-auto w-full md:w-[450px] h-[260px] md:h-[300px] mt-8" />
            <img src="/assets/report-detailed-feedback-2.png" alt="report ratings" className="block mx-auto w-full md:w-[450px] h-[260px] md:h-[300px] mt-8" />
      </div>
    },
  ] ;