import  createProjectFormSlice from './features/create-project/create-project-slice';
import createCompanySlice from './features/create-company/create-company-slice';
import addParticipantsSlice from './features/add-participants/add-participants-slice';
import setupMailSlice from './features/setup-mail/setup-mail-slice';
import AdminLoginSlice from './features/login/AdminLoginSlice';
import viewProjectsSlice from './features/view-projects/view-projects-slice';
import evaluatorForParticipantsSlice from './features/evaluator-for-Participants/evaluatorForParticipantsSlice';
import { configureStore , combineReducers } from '@reduxjs/toolkit'; 
import {  
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
 
const persistConfig = {
    key: 'root',
    version: 1,
    storage,
  }

const reducers = combineReducers( {
        createProjectForm :  createProjectFormSlice,
        createCompanyForm : createCompanySlice,
        addParticipants : addParticipantsSlice,
        setupMail : setupMailSlice,
        adminLogin : AdminLoginSlice,
        evaluatorForParticipant : evaluatorForParticipantsSlice,
        viewProjects : viewProjectsSlice,
} )

const persistedReducers = persistReducer( persistConfig , reducers )

export const store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    devTools : process.env.NODE_ENV !== 'production',
  })
 