import React, { useState, useEffect } from 'react'
import { MultiStepForm, Step } from 'react-multi-form'
import FormWrapper from './components/forms/FormWrapper'
import { Link } from 'react-router-dom'
import { validateEvaluatorForParticipants, validateStep1, validateStep2, validateStep3 } from './components/Validations/validation'
import { useDispatch, useSelector } from 'react-redux'
import { 
    isMappingEvaluatorStep,
          moveToNextStep, moveToPreviousStep, resetProjectSlice, setErrors, updateProjectId, 
       } from '../../../../features/create-project/create-project-slice'
import { 
          setErrors as participantsError, 
          clearErrors as clearParticipantsError,
          setProjectId,
          clearParticipantsSlice
        } from '../../../../features/add-participants/add-participants-slice'
import axios from 'axios'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { clearErrors, clearSetupMailSlice, setErrors as setupMailErrors, setMailProjectId } from '../../../../features/setup-mail/setup-mail-slice'
import ActivateMail from './components/ActivateMail'
import SaveDraft from './components/SaveDraft'
import {
    addUploadedEvaluatorId,
          generateEvaluatorsForm,
           hideEvaluatorForm,
           resetUploadEvaluatorForParticipantsSlice,
            setEvaluatorForParticipantsError 
        } from '../../../../features/evaluator-for-Participants/evaluatorForParticipantsSlice'
import { v4 as uuid } from 'uuid'
import {updateMailTemplateCampaignName} from '../../../../features/setup-mail/setup-mail-slice'
import { Modal, Ripple, initTWE, } from "tw-elements"

const AddProject = () => {

    const dispatch = useDispatch()
    const { createProjectForm, addParticipants, setupMail } = useSelector( state => state)
    const { visible, formData } = useSelector( state => state.evaluatorForParticipant )
    const { project_id } = useSelector((state) => state.addParticipants.formData);
    const { step } = createProjectForm
    const [ addProjectLoading , setAddProjectLoading ] = useState(false)    
    const [ isDeleting, setIsDeleting] = useState(false)
    const [ addEvaluatorForParticipantLoading , setAddEvaluatorForParticipantLoading ] = useState(false)
   
    useEffect(() => {
        initTWE({ Modal, Ripple });
      }, []);

    const handleNextStep = () => {
        if(step < 5 ){                
           switch(step){
              case 1 :     
                        let step1_validator = validateStep1(createProjectForm.formData)
                         if(step1_validator.validated) {                                  
                                    setAddProjectLoading(true)
                                    /*-----------------------------------------------------
                                       let the admin upload evaluators based on the roles selected.
                                    */
                                     let formFields = [] ;
                                      if(createProjectForm.formData.evaluator){
                                          createProjectForm.formData.roles.forEach((role,index) => {                                            
                                            for(let i = 0 ; i < role.max_response; i++){
                                                 formFields.push(                                                      
                                                      { 
                                                        id : uuid() ,
                                                        participantEmail : '',
                                                        evaluatorName : '' , 
                                                        evaluatorEmail : '',
                                                        evaluatorRole :   (role.project_role !== 'self') ? role.project_role : 'manager'
                                                      }
                                                    )                                                  
                                            }
                                        });
                                        dispatch( generateEvaluatorsForm(formFields) )                                        
                                    }
                                    //------------------------------------------------------------//
                                    //create new project or update if project exist
                                    if(createProjectForm.isEditing){                                        
                                        axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/${ createProjectForm.id }`,
                                             { ...createProjectForm.formData, id: createProjectForm.id }
                                        )
                                         .then( response => {
                                             if(response.data.success){            
                                                 const id = response.data.data.id ;     
                                                 dispatch( updateMailTemplateCampaignName(
                                                     createProjectForm.formData.project_name
                                                 ) )
                                                 dispatch( setProjectId( id ) )
                                                 dispatch( setMailProjectId( id ) )
                                                 dispatch( updateProjectId( id  ) )
                                                 dispatch( moveToNextStep() )
                                                 dispatch( hideEvaluatorForm() )
                                                 setAddProjectLoading( false )
                                                 document.querySelector('.admin-panel').scroll(0,0)                                            
                                             }
                                         }) 
                                    }
                                    else{
                                        axios.put(`${ process.env.REACT_APP_BASE_URL }/project`, createProjectForm.formData
                                       )
                                        .then( response => {
                                            if(response.data.success){            
                                                const id = response.data.data.id ;     
                                                dispatch( updateMailTemplateCampaignName(
                                                    createProjectForm.formData.project_name
                                                ) )
                                                dispatch( setProjectId( id ) )
                                                dispatch( setMailProjectId( id ) )
                                                dispatch( updateProjectId( id  ) )
                                                dispatch( hideEvaluatorForm() )
                                                dispatch( moveToNextStep() )
                                                setAddProjectLoading( false )
                                                document.querySelector('.admin-panel').scroll(0,0)                                            
                                            }
                                        }) 
                                    }      
                           }                               
                         else  {
                                 dispatch( setErrors( step1_validator.errors ) )
                                 window.scrollTo({ top : 0 , behavior : 'smooth' })
                               }                              
              break;
              case 2 :                                        
                  let step2_validator = validateStep2( addParticipants.formData)
                  if( step2_validator.validated ){
                       //display a preview modal                        
                       dispatch( clearParticipantsError() )                       
                       if( !visible ){
                          document.querySelector('#preview-modal').click()
                       }
                       //validate evaluator for participants form
                       else{
                           let evaluatorForParticipantValidation = validateEvaluatorForParticipants( 
                                        formData.evaluators, addParticipants.formData.participants
                                 )                           
                           if( evaluatorForParticipantValidation.validated ){    
                               setAddEvaluatorForParticipantLoading( true )
                              //submit evaluator for partcipants                                                         
                              axios.post(`${ process.env.REACT_APP_BASE_URL }/project/add-evaluator`,{
                                project_id : createProjectForm.id,
                                evaluators : formData.evaluators.map( evaluator => {
                                     if(createProjectForm.isEditing){
                                        return {
                                            id: evaluator?.id,
                                            participant_email : evaluator.participantEmail,
                                            evaluator_name : evaluator.evaluatorName,
                                            evaluator_email : evaluator.evaluatorEmail,
                                            evaluator_role : evaluator.evaluatorRole,
                                        }
                                     }
                                     else  return {
                                           participant_email : evaluator.participantEmail,
                                           evaluator_name : evaluator.evaluatorName,
                                           evaluator_email : evaluator.evaluatorEmail,
                                           evaluator_role : evaluator.evaluatorRole,
                                       }
                                  })
                              })
                            .then( response => {
                                if(response.data.success){                                                        
                                    dispatch( moveToNextStep() )    
                                    setAddEvaluatorForParticipantLoading(false)                                     
                                    dispatch( addUploadedEvaluatorId(response?.data?.evaluators) )
                                    document.querySelector('.admin-panel').scroll(0,0)                                       
                                }
                            }).catch( error => {
                                    if( error.response.status === 500 ){
                                    setAddEvaluatorForParticipantLoading(false)
                                    dispatch( 
                                        setEvaluatorForParticipantsError( 
                                                {
                                                evaluator : `Error! All participants email address must be  
                                                                an already existed partcipants. please update
                                                                the partcipants email in the excel template with 
                                                                the existing participants  or
                                                                search for an already existing participant.`
                                                }
                                            )                                        
                                        )
                                    }
                            } )      
                            }
                           else{
                               dispatch( 
                                         setEvaluatorForParticipantsError( 
                                             evaluatorForParticipantValidation.errors
                                          )                                        
                                        )
                           }
                       }
                  }
                  else{
                    console.log('passed = not validated with error');
                    dispatch( participantsError( step2_validator.errors ) )
                  }
              break;
              case 3 :
                    let step3_validator = validateStep3( setupMail.formData )
                    if( step3_validator.validated ){
                        dispatch( clearErrors() )
                        dispatch( moveToNextStep() )       
                        document.querySelector('.admin-panel').scroll(0,0)                 
                    }
                    else{
                        dispatch( setupMailErrors( step3_validator.errors ) )                       
                        document.querySelector('.admin-panel').scrollTo({ top : 400 , behavior : 'smooth' })
                    }
              break;
           }
       }
    }

    const cancelFormSubmission = () => {       
        setIsDeleting(true)
        axios.delete(`${ process.env.REACT_APP_BASE_URL }/project/${ project_id }`)      
        .then( response => {
              if( response.data.success){
                setIsDeleting(false)
                dispatch( resetProjectSlice() )
                dispatch( clearParticipantsSlice() )
                dispatch( clearSetupMailSlice() )
                dispatch(  resetUploadEvaluatorForParticipantsSlice() )                
                document.querySelector('.admin-panel').scrollTo({ top :0 , behavior : 'smooth' })
              }
        })            
    }

 const gotoPreviousStep = () => {
    if( (step === 2)  && createProjectForm.isMappingEvaluatorStep ){
         dispatch(hideEvaluatorForm())
         dispatch( isMappingEvaluatorStep(false))
    }
    else {
        dispatch(moveToPreviousStep())
    }
 }

  return (
    <section className='md:mr-2 h-full'>  
         <h1 className='py-3 text-xl text-white bg-green-small font-semibold pl-3 uppercase'>
             Add New Campaign
         </h1>            
         <div className='mt-8'>
                 <div className='campaign-stepper-wrapper px-8 md:px-12'>
                    {
                        visible ?
                        <MultiStepForm activeStep={ (visible && step === 2 ) ? step + 1 : step } 
                          accentColor = '#d43417'
                       >
                            <Step label= { window.matchMedia('(max-width:500px)').matches ? 'Campaign' : 'Create Campaign' }>                    
                            </Step>
                            <Step label= { 'Add Participants' }> </Step>
                            <Step label= { 'Add Evaluators' }></Step>
                            <Step label='Setup Email'></Step>
                            <Step label='Finalise'></Step>    
                    </MultiStepForm> 
                    :
                    <MultiStepForm activeStep={ step } 
                       accentColor = '#d43417'
                     >
                          <Step label= { window.matchMedia('(max-width:500px)').matches ? 'Campaign' : 'Create Campaign' }>                    
                          </Step>
                          <Step label= { 'Add Participants' }> </Step>
                          <Step label='Setup Email'></Step>
                          <Step label='Finalise'></Step>    
                     </MultiStepForm> 
                    }
                 </div>
              <div>
                   <FormWrapper />
              </div>
         </div>
          <div className={`pb-8 mt-3 ml-8 md:flex ${step === 1 ? 'justify-end':'justify-between'}`}>                 
                { 
                    (step > 1 && step < 5 ) &&
                         <div>   
                             { step === 4 && <button 
                                   onClick={gotoPreviousStep}                                    
                                  className='bg-gray-200 bi bi-arrow-left py-2 ml-4 text-sm px-8 mt-8 rounded-full'
                             >
                                &nbsp; Back
                             </button> }                          
                            <button 
                                   data-twe-toggle='modal'
                                   id='cancel-form-submission'                                   
                                   data-twe-target='#cancelFormSubmission'
                                  className='bg-red-500 text-white py-2 ml-2 text-sm px-8 mt-8 rounded-full'
                                  data-twe-ripple-init
                                  data-twe-ripple-color="light"
                             >
                                Cancel
                             </button>  
                            <div
                                data-twe-modal-init
                                className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                                id="#cancelFormSubmission"
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div
                                    data-twe-modal-dialog-ref
                                    className="pointer-events-none overflow-x-hidden relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[1200px]">
                                    <div
                                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark">
                                    <div
                                        className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 p-4 dark:border-white/10">
                                        <h5 className='text-xl bi bi-people font-medium leading-normal text-gray-800' id='exampleModalLgLabel'>
                                            &nbsp;Participants Summary
                                        </h5>
                                        <button
                                        type="button"
                                        className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                                        data-twe-modal-dismiss
                                        aria-label="Close">
                                        <span className="[&>svg]:h-6 [&>svg]:w-6">
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </span>
                                        </button>
                                    </div>
                                    <div className="relative text-center flex-auto p-4" data-twe-modal-body-ref>
                                    <i className='mt-16 bi bi-exclamation-triangle text-center text-[4.5rem] text-red-500'></i>
                                           <h3 className='text-3xl -mt-2 text-center'> Warning </h3>
                                           <p className='text-sm mt-2'>  
                                              Please note that you cannot undo this action. If you proceed with the form cancellation
                                              you will roll back to the earlier step.
                                           </p>
                                           <div className='mt-8 flex justify-end mb-3'>                                                  
                                                <button className='py-2 text-sm px-8  bg-gray-200 rounded-lg' data-bs-dismiss='modal' aria-label='Close'>
                                                    Cancel
                                                </button>
                                                <Link  to={ '/admin/add-new-campaign'}
                                                        onClick={ cancelFormSubmission }
                                                        className='py-2 text-sm ml-3 px-8 text-white bg-red-500 rounded-lg' 
                                                        data-bs-dismiss='modal' aria-label='Close'
                                                 >
                                                    Yes i understand
                                                </Link>
                                          </div>  
                                        
                                </div>
                             </div>
                            </div>
                         </div>
                    </div>                      
                }      
                {    (step < 4) &&
                      <div className='flex'>
                           { step > 1 && <button 
                                   onClick={gotoPreviousStep}                                    
                                  className='bg-gray-200 bi bi-arrow-left py-2 ml-4 mr-8 text-sm px-8 mt-8 rounded-full'
                             >
                                &nbsp; Prev
                             </button> }
                          <button style={{background: 'rgba(102, 129, 2,0.7)'}} className='admin-form-btn' onClick={ handleNextStep }>
                             Next <span className='bi bi-arrow-right'>  </span>
                          </button>
                      </div>
                }
                {
                    (step === 4 ) && <div className='flex justify-end pr-8'>
                        <ActivateMail />
                        <SaveDraft />
                    </div>
                }              
          </div>
          {  addProjectLoading && <LoadingSpinner />  }
          {  addEvaluatorForParticipantLoading && <LoadingSpinner /> }
          {  isDeleting && <LoadingSpinner /> }
    </section>
  )
}

export default AddProject