import React from 'react'

const Spinners = ({className, style = {} }) => {
  return (
    <div className={ className }>    
        <div style={ style } className="spinner-grow inline-block w-5 h-5 bg-current rounded-full opacity-0 text-white" role="status">
           <span  className="visually-hidden">Loading...</span>
        </div>
        <div style={ style } className="spinner-grow inline-block w-5 h-5 bg-current rounded-full opacity-0 text-white" role="status">
           <span className="visually-hidden">Loading...</span>
        </div>
        <div style={ style } className="spinner-grow inline-block w-5 h-5 bg-current rounded-full opacity-0 text-white" role="status">
           <span className="visually-hidden">Loading...</span>
        </div>        
  </div>
  )
}

export default Spinners