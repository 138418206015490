import React, { useState } from 'react'
import DateTimePicker from 'react-datetime-picker'
import { useDispatch, useSelector } from 'react-redux'
import { updateInput } from '../../../../../../../features/setup-mail/setup-mail-slice';
import TestMail from './TestMail';
import TextEditorOptionDropdown from './TextEditorOptionDropdown';
import TextEditorWrapper from './TextEditorWrapper';

 const SetupEmailForm = () => {
  
  const [ activeTemplate , setActiveTemplate ] = useState('evaluator')
  const dispatch = useDispatch()
  const {formData, errors } = useSelector( state => state.setupMail)
  const strToDate = str => new Date( Date.parse( str ) ) ;

  const handleInputChange = (inputName,inputValue) => {         
       dispatch( updateInput( { name : inputName, value : inputValue } ) )
  }   

  return (
     <div className='pt-5 md:pt-8'>
          <div className='md:flex justify-between px-2 md:px-0'>                 
                <div className='md:w-[69%]'>
                   <TextEditorWrapper activeTemplate={ activeTemplate } />
                   <TestMail />
                </div> 
                <div className='md:w-[30%]'>
                    <ul>
                       <li className='text-[12px] font-medium bg-gray-100 py-2 px-3 items-center flex justify-between uppercase'>
                          <span className='flex items-center'> <i className='bi bi-gear text-lg mr-2'></i> Setup Emails </span>
                          <i 
                              className='bi bi-three-dots-vertical text-xl'                             
                               
                            ></i>
                            
                       </li>
                       <li className='text-[13px] font-medium bg-gray-100 py-2 mt-2 px-3 items-center flex justify-between'>
                       <div class='form-check flex items-center'>
                          <input class='form-check-input appearance-none w-[30px] h-[30px] border border-green-small rounded-sm bg-white checked:bg-green-small checked:border-green-small focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' 
                                  type='checkbox' value={ formData.on_invitation } id='c-1'
                                   name='on_invitation' checked={ formData.on_invitation }
                                   onClick = { (e) => handleInputChange(e.target.name,e.target.value) }
                          />
                          <label class='form-check-label inline-block text-gray-800' for='c-1'>
                             Invitation Email
                          </label>
                        </div>
                          <i className='bi bi-chevron-right text-xl'></i>
                       </li> 
                    </ul>
                    <TextEditorOptionDropdown setActiveTemplate = { setActiveTemplate } />
                    
                    <div className='border border-gray-200 mt-4 py-3 px-4 rounded-lg'>
                          <label htmlFor='name' className='text-sm'> From: </label>
                          <input 
                                type='text' readOnly = { true }
                                name='sender_email'
                                className='admin-form-input-sm w-full'
                                placeholder='sender email address...'        
                                value={ formData.sender_email }   
                                onChange = { (e) => handleInputChange(e.target.name,e.target.value) } 
                            />
                            <div className='text-xs text-red-500 pl-2 mt-2'> { errors.sender_email } </div>
                            <br />
                            <label htmlFor='name' className='text-sm'> Reply to: </label>
                            <input 
                                type='text' readOnly = { true }
                                name='email_reply_to'
                                className='admin-form-input-sm w-full'
                                placeholder='e.g mail.lorem'      
                                value={ formData.email_reply_to }   
                                onChange = { (e) => handleInputChange(e.target.name,e.target.value) }   
                            />
                            <div className='text-xs text-red-500 pl-2 mt-2'> { errors.reply_to } </div>
                            <br />
                            <label htmlFor='name' className='text-sm'> Mail subject: </label>
                            <input 
                                type='text' 
                                name='email_subject'
                                className='admin-form-input-sm w-full'
                                placeholder='subject of the mail...' 
                                value={ formData.email_subject }   
                                onChange = { (e) => handleInputChange(e.target.name,e.target.value) }     
                            />
                            <div className='text-xs text-red-500 pl-2 mt-2'> { errors.email_subject } </div>                            
                            <div className='border border-green-small my-4 h-[2px] w-full'></div>
                            <span className='text-sm'> Schedule </span>
                            <div>
                            <select value={ formData.email_schedule_type } name='email_schedule_type'
                                    onChange = { (e) => handleInputChange(e.target.name,e.target.value) }     
                                    class='form-select appearance-none
                                          block
                                          w-full
                                          px-3
                                          py-2
                                          text-sm
                                          font-normal
                                          text-gray-700
                                          bg-white bg-clip-padding bg-no-repeat
                                          border border-solid border-gray-300
                                          rounded
                                          transition
                                          ease-in-out
                                          my-4
                                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none' aria-label='Default select example'>                                             
                                            <option value='fixed'>Fixed</option>
                                            <option value='specific'>Specific</option>                                             
                              </select>
                            </div>
                            <div className='flex items-center mt-3'>
                                <span className='text-xs pr-2'>Send</span>
                                 <div>
                                    <input 
                                        type='number' 
                                        name='email_schedule_day'
                                        className='admin-form-input-sm text-xs w-[57px]'                           
                                        min={1}
                                        max = { 30 }    
                                        placeholder='1' 
                                        value={ formData.email_schedule_day }
                                        onChange = { (e) => handleInputChange(e.target.name, parseInt( e.target.value )) }
                                    />
                                 </div>
                                <span className='text-xs pl-2'>days before survey ends.</span>
                            </div>
                            <div className='flex items-center mt-3'>
                                <span className='text-xs pr-2'>Time</span>
                                 <div>
                                    <input 
                                        type='time' 
                                        name='email_schedule_time'
                                        className='admin-form-input-sm text-xs w-[125px]'                           
                                        min={1}
                                        max = { 24 }    
                                        placeholder='1'    
                                        value={ formData.email_schedule_time }   
                                        onChange = { (e) => handleInputChange(e.target.name,e.target.value ) }                      
                                    />
                                 </div>                                                                 
                            </div>
                            <p className='mt-5 text-sm'> Or select a specific date and time </p>
                            <div className='w-full relative mt-4 pb-3'>                    
                               <div className='w-full'>                                
                                  <DateTimePicker                                      
                                    name='email_schedule_fixed'
                                    onChange = { (e) =>  handleInputChange('email_schedule_fixed',e.toString()) }
                                    value = { strToDate( formData.email_schedule_fixed ) }
                                    className = 'date-picker'
                                    calendarIcon = {
                                            <span   className=' bi bi-calendar text-red-500'></span>
                                        }
                                    format = 'dd-MM-y h:mm:ss a'     
                                    dayPlaceholder = 'dd'                                                                                
                                    monthPlaceholder = 'mm'
                                    yearPlaceholder = 'yyyy'
                                    hourPlaceholder = 'h'
                                    minutePlaceholder = 'm'
                                    secondPlaceholder = 's'                                                                                                                          
                                  />                                                                         
                                </div>                          
                             </div> 
                        </div>
                </div>                
          </div>
          <div className='flex justify-between'>
               
          </div>
     </div>
   )
 }
 
 export default SetupEmailForm