import React, { useContext } from "react";
import { Menu, MenuItem } from "@mui/material";
import FilterCard from "./FilterCard";
import { ReportContext } from "context/ReportDashboardContext";

function FilterMenu(props) {
  const { open, handleClose, anchorEl } = props;

  const { initialValue, setInitialValue } = useContext(ReportContext);

  return (
    <Menu
      id="basic-menu"
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      // sx={{ minWidth: "600px", maxWidth: "600px", backgroundColor: "red" }}
    >
      <MenuItem fullWidth sx={{ minWidth: "600px" }}>
        <FilterCard
          handleClose={handleClose}
          initialFilter={initialValue}
          setInitialFilter={setInitialValue}
        />
      </MenuItem>
    </Menu>
  );
}

export default FilterMenu;
