import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateProject } from '../../../../../features/view-projects/view-projects-slice'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import swal from 'sweetalert'
import axios from 'axios'

const ActivateCampaign = ({project}) => {

    const dispatch = useDispatch()
    const [ loading , setLoading ] = React.useState(false)
    const { company_id  }  = useSelector( state => state.viewProjects )
  
    const activateCampaign = () => {
        setLoading(true)
        axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/${ project.id }`, { 
          status : 'active' ,
          isComplete : true,
        })      
        .then( response => {
              if( response.data.success){
                 axios.get(`${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }`)
                  .then(
                    res => {
                          if( res.data.success ){
                              dispatch( updateProject( res.data ) )                
                              setLoading(false)
                              swal('Success!', 'Campaign status updated successfully.' , 'success')
                          }
                     }
                  )     
              }
         })
         //tobe deleted if an update is made in the api
         .catch( error => {
            if(error.response.status === 500 ){
                axios.get(`${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }`)
                .then(
                  res => {
                        if( res.data.success ){
                            dispatch( updateProject( res.data ) )                
                            setLoading(false)
                            swal('Success!', 'Campaign status updated successfully.' , 'success')
                        }
                   }
                )    
            }
        })   
    }

  return (
    <> { loading && <LoadingSpinner /> }
       <button onClick={ activateCampaign } className='md:block mr-16 sm:mr-0 bi bi-check-circle text-[13px] text-white py-1 text-center mx-auto w-full rounded-lg bg-green-small'>
          &nbsp; Activate
      </button>
    </>
  )
}

export default ActivateCampaign