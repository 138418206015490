import React from "react";
import "./Task.css";
import { Link } from "react-router-dom";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import { Typography, Button, Link } from "@mui/material";
// import Grid2 from "@mui/material/Unstable_Grid2";
// import { Box } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

// const theme = createTheme({
//   components: {
//     // Name of the component ⚛️
//     MuiCardContent: {
//       styleOverrides: {
//         root: {
//           padding: 0,
//           paddingBottom: 0,
//           "&:last-child": {
//             paddingBottom: 0,
//           },
//         },
//       },
//     },
// MuiGrid2: {
//   styleOverrides: {
//     root: {
//       padding: 0,
//     },
//   },
// },
//   },
// });

function Task({
  sn = 1,
  action = "Nominate 12 evaluators for the graduate fast track feedback survey",
  todo = "Nominate 12 evaluators for the graduate fast track",
  icon = <PersonAddIcon />,
  iconLabel = "Nominate",
  link = "./assessment",
  progress,
}) {
  return (
    <div className="task">
      <div className="task__Card ">
        <div className="task__Box">
          <p className="task__Sn h-[90%] w-10 md:w-full ">{sn}</p>
        </div>
        <div className="task__Content">
          <p className="task__Action text-[11px] mb-2 text-left md:text-sm">
            {action}
          </p>
          <p className="task__Todo text-[11px] text-left md:text-sm">{todo}</p>
        </div>

        <div
          className={`task__Link flex orange-bg ${
            (progress == 100 || progress === 1000) && "disabled gold-bg  "
          } `}
        >
          <Link to={progress == 100 || progress === 1000 ? null : link}>
            <div
              className={` task-link sm:h-[90%] orange-bg ${
                (progress == 100 || progress === 1000) && "disabled gold-bg"
              }`}
            >
              <div className="task__LinkIcon text-md md:text-[30px] ">
                {icon}
              </div>
              <p className="task__LinkLabel text-[12px] md:text-sm md:text-center ">
                {iconLabel}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Task;

// <div>
//   <ThemeProvider theme={theme}>
//     <Card variant="outlined" className="p-2 pt-4">
//       <Grid2 container>
//         <Grid2 xs={2} sm={5}>
//           <CardContent className="h-[90%]">
//             <Box
//               sx={{
//                 backgroundColor: "red",
//                 textAlign: "center",
//                 height: "100%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <Typography sx={{ color: "white" }}>{sn}</Typography>
//             </Box>
//           </CardContent>
//         </Grid2>
//         <Grid2 xs={7} sm={3}>
//           <CardContent>
//             <Typography className="text-xs mx-1 mb-4 align-center">
//               {action}
//             </Typography>
//             <Typography className="text-xs">{todo}</Typography>
//           </CardContent>
//         </Grid2>
//         <Grid2 xs={3} sm={1}>
//           <CardContent>
//             <Link>
//               <Button variant="contained">
//                 <Grid2
//                   container
//                   className="flex align-center justify-center"
//                 >
//                   <Grid2>
//                     <PersonAddIcon />
//                   </Grid2>
//                   <Grid2>
//                     <Typography className="text-xs">{iconLabel}</Typography>
//                   </Grid2>
//                 </Grid2>
//               </Button>
//             </Link>
//           </CardContent>
//         </Grid2>
//       </Grid2>
//     </Card>
//   </ThemeProvider>
// </div>
