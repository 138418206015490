import React, { useEffect, useState } from "react";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Header from "../../../components/Header/Header";
import Modal from "./Modal";
import "./Assessment.css";
import axios from "axios";
import Forms from "./Form/Form";

function Assessment(params) {
  const {
    deadline = "27/01/2023",
    user = localStorage.getItem("username"),
    instructions = [
      // "You are required to respond to ALL the questions in the survey and leave questions you have no answers for blank.",
      // "The survey measures your perception of your leadership traits. This information is vital for your leadership development. Please ensure your responses reflect your leadership traits.",
      // "The grading scale ranges from “never” to “always”, where “never” is the lowest score and “always” is the highest score. If you don’t have an answer to a question, you leave it blank.",
      // "You are required to respond to all free text survey questions below. If you have no comments, please enter 'N/A', but do not leave the box blank.",
      // "For support, please get in touch with 360@testassessify.com.",
      //   `The  purpose  of  this  survey  is  to  gather  feedback  on your  performance
      // and behaviour patterns and to help you identify your strengths and areas
      // for improvement. Your feedback will be treated with confidentiality and
      // anonymity. The feedback received will be utilized to set goals and track
      // your progress in your professional development. `,
      //   `Please, carefully read all questions and respond accurately and honestly
      //   as possible. If there are any questions that you are not in a position to
      //   answer appropriately, it is best to leave them blank.  If you encounter any
      //   technical  difficulties or  have  any  questions, please  contact  our  support
      //   team at 360@TestAssessify.com.`,
      //   `The  survey  will  be  open  for  feedback  collection  until  ${deadline}  and
      //   should take about 15 minutes to complete. Please keep in mind that the
      //   questions  are  structured  in  a  5-point  Likert  scale  and  open-ended
      //   format. Please use the Likert scale to evaluate the questions and provide
      //   specific examples in the open-ended questions to help us better
      //   understand the areas that need improvement.`,
      //   `You have the option to pause and resume the feedback survey if you are
      //   unable to finish it all at once. This enables you to take your time and give
      //   insightful feedback that is accurate.`,

      `The purpose of this survey is to gather feedback on your performance and behaviour patterns and to help you identify your strengths and areas for improvement. `,
      `Please, carefully read all questions and respond accurately and honestly as possible. If there are any questions that you are not in a position to answer appropriately, it is best to leave them blank.  If you encounter any technical difficulties or have any questions, please contact our support team at 360@TestAssessify.com.`,
    ],
  } = params;
  const dummyData = [
    {
      category: "leadership",
      questions: [
        { id: 1, qusetion: "H", type: "radio" },
        { id: 6, qusetion: "H", type: "radio" },
        { id: 7, qusetion: "H", type: "radio" },
        { id: 2, qusetion: "aT", type: "text" },
        { id: 3, qusetion: "Ta", type: "text" },
        { id: 4, qusetion: "Ta", type: "text" },
      ],
    },
  ];
  // const [assessments, setAssessments] = use.State([]);
  const [assessments, setAssessments] = useState({});
  const [page, setPage] = useState(localStorage.getItem("page") ?? 0);
  const [currentStep, setCurrentStep] = useState();
  const [currentProgress, setCurrentProgress] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(
    localStorage.getItem("progress") ?? 0
  );
  const [disableNext, setDisableNext] = useState(false);
  const [disableBack, setDisableBack] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [open, setOpen] = useState(false);

  const assessmentSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("You have to fill this field"),
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("You have to fill this field"),
    questions: Yup.array().of(
      Yup.object().shape({
        // answer: Yup.string().min(2, "Too Short").required(),
        answer: Yup.lazy((value) => {
          console.log(value, "val");
          return Yup.mixed().notRequired();
        }),
      })
    ),

    email: Yup.lazy((value) => {
      if (value !== undefined) {
        return Yup.string()
          .min(2, "Too Short")
          .required("You have to fill this field");
      }
      return Yup.mixed().notRequired();
    }),
  });

  const participant_id = localStorage.getItem("participant_id");
  const evaluator_id = localStorage.getItem("evaluator_id");
  const project_id = localStorage.getItem("project_id");

  useEffect(() => {
    // if (currentStep >1){
    //   setIsLoading(true)
    // }
    const request = axios
      .post(
        `
      https://sandboxapi.360degree.testassessify.com/survey/category-question`,
        {
          project_id,
          participant_id,
          evaluator_id: participant_id,
        }
      )
      .then((e) => {
        setAssessments(e.data);
        setCurrentStep(e.data?.currentStep);
        setCurrentProgress(
          ((e.data?.currentStep - 1) / e.data?.totalStep) * 100
        );
        setIsLoading(false);
      });
  }, [currentStep]);

  useEffect(() => {
    console.log(assessments);
  }, [assessments]);

  const submitFunc = () => {
    if (page === assessments.length - 1) {
      localStorage.setItem("progress", 100);
      setProgress(100);
      setOpen(true);
    }
  };
  useEffect(() => {
    if (page < 1) {
      setDisableBack(true);

      // setPage(0);
      // localStorage.setItem("page", 0);
    } else {
      setDisableBack(false);
    }
    if (page === assessments.length - 1) {
      setDisableNext(true);
      setDisableSubmit(false);
      //   console.log(page, assessments.length);
    } else {
      setDisableNext(false);
      //   console.log(page, assessments.length);
      setDisableSubmit(true);
    }

    if (assessments.length) {
      //   console.log(assessments[page]?.questions[page]?.qusetion);
      setProgress((page / assessments.length) * 100);
      localStorage.setItem("progress", (page / assessments.length) * 100);
    }
    if (page < 0) {
      localStorage.setItem("page", 0);
      setPage(0);
    }
  }, [page]);

  return (
    <div className="assessment">
      <Header title="Campaign" />
      <h2 className="assessment__Title text-white">Self Assessment</h2>
      <div className="assessment__Body">
        <div className="assessment-logo">
          {/* <img src="/assets/preview-evaluator-logo-2.png" alt="Lorem Logo" /> */}
          {/* <img src="/assets/Access-bank-PLC.png" alt="Lorem Logo" /> */}
          <div className="progressbar ml-6">
            <label for="progress">
              Progress: {Math.round(currentProgress)}%
            </label>
            <progress
              className="assessment-progressbar"
              id="progress"
              value={Math.round(currentProgress)}
              max="100"
            ></progress>
            {/* progressbar */}
            <div className="progress-details">
              {/* clock */}
              <p> {/* <span>Survey ends at </span> {deadline} */}</p>
            </div>
          </div>
        </div>

        <div className="assessment__Form">
          <div className="assessment-instruction">
            <h3 className="instruction-title">
              Hello <span>{user}</span> and welcome  to the 360-degree feedback
              process.
            </h3>
            {instructions.map((instruction, index) => (
              <p className="" key={index}>
                {instruction}
              </p>
            ))}
          </div>

          <div className="score-guide">
            <div className="score score-title">
              <p>Score</p>
              <p>1</p>
              <p>2</p>
              <p>3</p>
              <p>4</p>
              <p>5</p>
            </div>
            <div className="score">
              <p>Frequency</p>
              <p>Almost Never</p>
              <p>Not Very Often</p>
              <p>Some of the time</p>
              <p>Most of the time</p>
              <p>Nearly always</p>
            </div>
            <div className="score">
              <p>Leadership</p>
              <p>Clear Weakness</p>
              <p>Weak</p>
              <p>Good</p>
              <p>Very Good</p>
              <p>Clear Strength</p>
            </div>
            <div className="score">
              <p>Communication</p>
              <p>1-20%</p>
              <p>21-40%</p>
              <p>41-60%</p>
              <p>61-80%</p>
              <p>81-100%</p>
            </div>
          </div>

          <Forms
            buttonLabel="submit"
            formKey={assessments?.currentStep}
            title={`${assessments?.questions?.category}`}
            questionsList={assessments?.questions?.question}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            totalStep={assessments?.totalStep}
            setOpen={setOpen}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>
        <Modal open={open} setOpen={setOpen} />
      </div>
    </div>
  );
}

export default Assessment;
