import React from "react";
import "./FAQ.css";
import Accordion from "./component/Accordion/Accordion";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

function FAQ() {
  const username = localStorage.getItem("username");
  const faqList = [
    {
      title: "Q: What is a 360-degree feedback process?",
      summary:
        "A 360-degree feedback process collects comments on an individual's performance from a variety of sources, including peers, managers, subordinates, and even customers. It enables a more thorough and balanced assessment of a person's strengths and areas for development.",
    },
    {
      title: `Q: Why is 360-degree feedback being implemented in our organization?`,
      summary: `Our Organization is introducing 360-degree feedback to boost overall performance, promote a culture of feedback, and assist staff members in developing both personally and professionally. We can acquire a more comprehensive knowledge of a person's effectiveness and discover possibilities for growth and development by collecting feedback from a variety of sources.`,
    },
    {
      title: `Q: Who will be participating in the 360 degree feedback process?`,
      summary: `A: Our organization's entire workforce will take part in the 360-degree feedback process. From entry-level managers to senior leadership, the approach is intended to be inclusive and provide feedback for all levels of employees.`,
    },
    {
      title: `Q: How will the feedback be collected?`,
      summary: `A: An internet survey platform will be utilized to get the responses. `,
    },
    {
      title: `Q: How will the feedback be used?`,
      summary: `A: The feedback will be used to boost performance, recognize areas for growth, foster an inclusive and positive work environment, and meet the overall goals and objectives of the organization.`,
    },
    {
      title: `Q: How will the feedback be used to improve performance?`,
      summary: `A: The feedback will be used to identify strengths and areas for improvements. A plan of action will be prepared by the individual being evaluated and their development coach to address the areas that need improvement and play to their strengths. Follow-up and coaching may also be provided to assist the individual in carrying out the action plan.`,
    },
    {
      title: `Q: What are the roles in a 360 feedback process?`,
      summary: `A: There are three key responsibilities in a 360 feedback process: evaluator, participant, and growth coach. The evaluator is responsible for providing constructive feedback on the performance of the managers. The individual whose performance is being evaluated is referred to as the participant. The development coach assists the participant in interpreting and implementing the feedback received.`,
    },
    {
      title: `Q: What will the evaluator receive when they are assigned to provide feedback in a 360 feedback process?`,
      summary: `A: A: The evaluator will receive an onboarding email with information about the manager they are evaluating, as well as a link to the survey. The email will include instructions for completing the survey and providing feedback.`,
    },
    {
      title: `Q: What will the participant receive when they are participating in a 360 feedback process??`,
      summary: `A: The participant will receive an onboarding email that includes information about the process and login credentials and instructions on how to access their dashboard. The dashboard will allow the participant to take a self-assessment and download their report when ready. `,
    },
    {
      title: `Q: When will the report of the 360 feedback be available for download?`,
      summary: `A: The 360-degree feedback report will be available for download once the required number of evaluators have successfully provided feedback for that participant, and the process timeline has matured. `,
    },
    {
      title: `Q: How do I contact support if I experience other issues?`,
      summary: `A: Contact us: 360@testassessify.com `,
    },
  ];
  return (
    <div className="faq">
      <div className="faq-navbar">
        <div className="landingPage__Header">
          <div className="header-left">
            <div className="header-logo">
              {/* Img */}
              <img src="/assets/lorem.png" alt="lorem excell" />
              {/* <p>Lorem Excellentiam</p> */}
            </div>
          </div>
          <div className="header-right">
            <ul className="header-links">
              {!username && (
                <Link to={"/login"}>
                  <li className="header-link">Login</li>
                </Link>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="faq-body">
        <Accordion
          resultList={faqList}
          title="Frequently Asked Questions (FAQ)"
        />
      </div>
      <div className="faq-footer mt-6">
        <Footer />
      </div>
    </div>
  );
}

export default FAQ;
