import React from 'react'
import CreateCompanyForm from './CreateCompanyForm'
import AddCompanyDetailsForm from './AddCompanyDetailsForm'
import CreateAdminForm from './CreateAdminForm'
import { useSelector } from 'react-redux'

const FormWrapper = () => {

   const { step } = useSelector( state => state.createCompanyForm )

   switch(step){
         case 1 :
            return (
               <CreateCompanyForm />
            )
         break;
      case 2 :
            return (
               <AddCompanyDetailsForm />
            )
      break;
      case 3 :
            return (
               <CreateAdminForm />
            )
      default :
        return <> </>
    }

}

export default FormWrapper