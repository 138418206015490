import axios from "axios";
import "./Form.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { object, string, array, ValidationError } from "yup";
import _ from "lodash";
import { TextField } from "@mui/material";

function Forms(params) {
  const {
    title,
    questionsList,
    buttonLabel,
    formKey,
    setCurrentStep,
    currentStep,
    totalStep,
    setOpen,
    isLoading,
    setIsLoading,
    hideLegend,
  } = params;
  const project_id = localStorage.getItem("project_id");
  const participant_id = localStorage.getItem("participant_id");

  const validationSchema = object({
    questions: array(
      object({
        answer: string(),
      })
    )
      .min(1)
      .test((e) => {
        if (
          questionsList.length * 0.3 >=
          e.filter((e) => e?.answer !== undefined)?.length
        ) {
          return new ValidationError(
            "Please provide more answer to proceed",
            undefined,
            "questions"
          );
        }
        console.log(
          "yee",
          e.filter((e) => e?.answer !== undefined)
        );
        return true;
      }),
  });

  const scrollers = document.querySelectorAll('*')

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          questions: [{ answer: "" }],
        }}
        key={formKey}
        validationSchema={validationSchema}
        onSubmit={(values, { isSubmitting, setSubmitting }) => {
          setSubmitting(true);
          setIsLoading(true);

          let questionsPayload = [];
          for (let i = 0; i < values.questions.length; i++) {
            if (values?.questions[i]?.answer) {
              questionsPayload.push({
                id: questionsList[i].id,
                answer: values?.questions[i]?.answer,
              });
            }
          }
          let postPayload = axios
            .post(
              " https://sandboxapi.360degree.testassessify.com/survey/question/submit",
              {
                project_id,
                participant_id,
                evaluator_id: participant_id,
                evaluator_role: "Self",
                category_name: title,
                current_step: currentStep,
                questions: questionsPayload,
                domain: title,
              }
            )
            .then((e) => {
                
              if (totalStep === currentStep) {
                localStorage.setItem("completedAssessment", true);
                localStorage.setItem("progress", 100);
                setOpen(true);
                return null;
              }
              setCurrentStep((e) => currentStep + 1);
              setSubmitting(false);
              scrollers.forEach( element => {
                element.scroll({top: 0, behavior: 'smooth'})
              } )
            });
          // .finally((e) => setSubmitting(false));
        }}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <div className="assessment__Questions">
              <div className="question sticky-top top-20 question-title">
                <h2 className="assessment-title">
                  {formKey}{" "}
                  {currentStep !== totalStep ? `Rate yourself as a ` : ""}{" "}
                  {title}
                </h2>
                {currentStep !== totalStep && (
                  <fieldset
                    id="title"
                    className="assessment-radio assessment-header-label"
                  >
                    <div className="opt-label text-sm">Never</div>
                    <div className="opt-label text-sm">Rarely</div>
                    <div className="opt-label text-sm">Sometimes</div>
                    <div className="opt-label text-sm">Often</div>
                    <div className="opt-label text-sm">Always</div>
                  </fieldset>
                )}
              </div>

              <div className="questions">
                {_.orderBy(questionsList, ["quesstion_type"], ["desc"])?.map(
                  (e, i) => (
                    <div className="question" key={e?.id}>
                      <p className="assessment-question">
                        {i + 1}. {e?.qusetion}
                      </p>
                      {e?.quesstion_type.toLowerCase() === "radio" && (
                        <>
                          <fieldset
                            id={e?.id}
                            className="assessment-radio"
                            name={`questions.${i}.answer`}
                          >
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="never"
                            />
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="rarely"
                            />
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="sometimes"
                            />
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="often"
                            />
                            <Field
                              type="radio"
                              name={`questions.${i}.answer`}
                              value="always"
                            />
                          </fieldset>
                        </>
                      )}

                      {e?.quesstion_type.toLowerCase() === "input" && (
                        <>
                          {/* <Field
                            name={`questions.${i}.answer`}
                            placeholder={`Enter Answer`}
                            className="assessment-text"
                            required
                          /> */}
                          <TextField
                            fullWidth
                            id="outlined-multiline-static"
                            label="Enter your Answer"
                            multiline
                            rows={3}
                            className="assessment-text"
                            onChange={(e) =>
                              setFieldValue(
                                `questions.${i}.answer`,
                                e.target.value
                              )
                            }
                          />

                          <ErrorMessage
                            name={`questions.${i}.answer}`}
                            component="div"
                            className="error-message"
                          />
                        </>
                      )}
                    </div>
                  )
                )}
                {/* </FieldArray> */}
              </div>
            </div>
            <div className="assessment-paginate">
              <div className="assessment-submit-error">
                <button
                  className="assessment-submit"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                  // onClick={submitFunc}
                >
                  {!isSubmitting &&
                    !isLoading &&
                    (totalStep === currentStep ? "Submit" : "Next")}
                  {(isSubmitting || isLoading) && "Submitting"}
                </button>
                {/* <Modal open={open} setOpen={setOpen} /> */}
              </div>

              <ErrorMessage
                name="questions"
                className="assessment-error-message"
              />
              {/* )} */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Forms;
