import LoadingSpinner from "../../../components/LoadingSpinner";
import React, { useState } from "react";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

function ChangePassword(props) {
  const { userEmail, userType, url } = props;
  const navigate = useNavigate();
  const [loginFailed, setLoginFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function changePasswordRequest(data) {
    let res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/user/password-update`,
      data
    );
    return res.data;
  }

  const changePasswordQuery = useMutation(changePasswordRequest);
  return (
    <div>
      <h2 className="change-password-title">
        {" "}
        You have to change password from the default password
      </h2>
      <div className="">
        <Formik
          initialValues={{
            username: "",
            password: "",
            confirmPassword: "",
            email: userEmail,
            userType: userType,
          }}
          //   validationSchema={SignupSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (values.password === values.confirmPassword) {
              setLoginFailed(false);
              changePasswordQuery.mutate(
                {
                  email: values.email,
                  password: values.password,
                  userType: values.userType,
                },
                {
                  onSuccess: () => navigate(url),
                  onError: () => {
                    setLoginFailed(true);
                    setErrorMessage("Sorry An Error Occured");
                  },
                }
              );
            } else {
              setLoginFailed(true);
              setErrorMessage("Password do not match");
            }
            setSubmitting(false);
          }}
        >
          {({ errors, touched, isSubmitting, setSubmitting }) => (
            <Form className="login-formik">
              {/* <label htmlFor="username">Username</label>
            <Field
              id="username"
              name="username"
              placeholder="Enter your Username"
            />
            {errors.username && touched.username ? (
              <div className="error-message">* {errors.username}</div>
            ) : null} */}

              <label htmlFor="password">New Password</label>
              <Field
                id="password"
                type="password"
                name="password"
                placeholder="Enter new Password"
              />
              {errors.password && touched.password ? (
                <div className="error-message">* {errors.password}</div>
              ) : null}
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <Field
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                placeholder="Confirm new Password"
              />
              {errors.password && touched.password ? (
                <div className="error-message">* {errors.password}</div>
              ) : null}

              <button type="submit" className="login-submit">
                Change Password
              </button>
              {loginFailed && (
                <div className="error-message text-center">{errorMessage}</div>
              )}

              {isSubmitting && <LoadingSpinner />}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;
