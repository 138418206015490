import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import Evaluators from '../Evaluators'
import axios from 'axios'
import swal from 'sweetalert'

const EditEvaluatorModal = ({target}) => {

   const { formData , id }  = useSelector( state => state.createProjectForm )
   const dispatch = useDispatch()
   const [ loading , setLoading ] = React.useState( false ) 
    
   const updateEvaluatorRoles = () => {
       const roles = formData.roles.map( role => {            
             return {...role, id} ;
       } )
       setLoading( true )
       document.querySelector('#close-evaluator-modal').click()
       axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/role/update`, { roles })
       .then( response => {
               if(response.data.success){
                   swal('Success!', 'Update successful.' , 'success');                                             
                   setLoading(false)
               }
       } ) 
   }

  return (
   <>  { loading && <LoadingSpinner /> }
    <div className='modal fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto' id={ target } tabIndex='-1' aria-labelledby='exampleModalLgLabel' aria-modal='true' role='dialog'>
       <div className='modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered  relative w-auto pointer-events-none'>
          <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
            <div className='modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
            <h5 className='text-xl bi bi-pencil font-medium leading-normal text-gray-800' id='exampleModalLgLabel'>
                &nbsp;  <span className='text-[16px]'> Edit Evaluators </span>
            </h5>
            <button type='button' id='close-evaluator-modal'
                className='btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body relative -mt-4 sm:mt-0 p-4 pt-1 sm:pt-4 px-1 md:px-8'>
                <Evaluators className={'w-full md:w-[95%]'}/>   
                <div className='flex justify-end -mt-5'>
                      <button className='admin-form-btn' onClick={ updateEvaluatorRoles }>
                         Update <span className='bi bi-arrow-right'>  </span>
                      </button>
                </div>
            </div>
         </div>
       </div>
    </div>
   </>
  )
}

export default EditEvaluatorModal