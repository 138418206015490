
export const email_subject = `Take your first step to your 360 feedback experience`

const link = 'http://360degree.testassessify.com/client-admin-login'

export const email_template = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />    
    <meta name="viewport" content="width=device-width, initial-scale=1" />    
    <title>360 Degree Feedback</title>
  </head>
  <body>      
    <div>
      <img src="{logo}" alt="company logo" />
      <h4 style="margin-top:5px; font-size:30px"> Dear  <span style="color:green"> {adminName} </span> </h4>
      <p style="margin-top:5px; font-size:20px">
           Welcome to our 360-degree feedback process. We are excited to 
           have you on board as an administrator for the 360-degree 
           feedback process and look forward to working with you
      </p>
      <p style="margin-top:5px; font-size:20px">
          You will play an essential role in the success of the feedback process. 
          You will oversee the contact data collection and ensure that everything 
          runs smoothly.  
      </p>
      <p style="margin-top:5px; font-size:20px">
         Please, follow the link to access your dashboard using the credentials below
      </p>
      <p style="margin-top:5px; font-size:20px">
         Username: {username}
      </p>
      <p style="margin-top:5px; font-size:20px">
         Password: {password}
      </p>
      <p style="margin-top:5px; font-size:20px">
          <a href="${link}" target="_blank" style="border-radius: 8px; text-decoration: none; padding: 5px 10px; margin-left: 10px; color: #fff; background:#00aa00;"> View Dashboard </a>                    
      </p>
      <p style="margin-top:5px; font-size:20px">
          OR click this link : &nbsp; ${link}
      </p>
      <p style="margin-top:5px; font-size:20px">
           Your dashboard is simple and easy to navigate.
            If you have any questions or need assistance,
             please refer to the support documents, which Include FAQs,
           user manual, etc., or contact the customer support team at 360@testassessify.com
      </p>
      <p style="margin-top:5px; font-size:20px">
          Best regards
      </p>
      <p style="margin-top:5px; font-size:20px">
         Zainab Ahmed
      </p>
    </div>    
    <footer style="background-color:#ccc; text-align: center; padding: 30px 5px; font-size:26px">
       For Support &nbsp; Contact:  <a href="mailto:360@testassessify.com"> 
           360@testassessify.com
       </a>
    </footer>
  </body>
</html>

`
