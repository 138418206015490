import React from "react";
import MyDocument from "../../components/ReportPDF/ReportPDF";

// import { PDFViewer } from "@react-pdf/renderer";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
const data = {
  success: true,
  report: {
    participant: {
      id: "0c43b2d3-97f1-464d-9404-45f3c32eee75",
      project_id: "b8421c9c-6a3d-4a56-8288-ac1666cb4b7f",
      name: "Eniola Soniyi",
      email: "samadex5050@gmail.com",
      dev_coach_name: "Dayo Amusu",
      dev_coach_email: "dayo.amusu@loremexcellentiam.com",
      password: "sammy",
      token: null,
      createdAt: "2022-12-13T07:17:21.000Z",
      updatedAt: "2022-12-13T07:17:21.000Z",
    },
    respondentSummary: [
      {
        relationship: "Development",
        nominated: 3,
        completed: 3,
        parcentage: 100,
      },
      {
        relationship: "Engineering",
        nominated: 5,
        completed: 2,
        parcentage: 40,
      },
    ],
    competencySummary: [
      {
        totalRating: 3.1,
        category_name: "communication",
        score: [
          {
            role: "Development",
            scoreRating: 3.3,
          },
          {
            role: "Engineering",
            scoreRating: 3.8,
          },
          {
            role: "Peer",
            scoreRating: 2.6,
          },
          {
            role: "Self",
            scoreRating: 2.6,
          },
        ],
      },
      {
        totalRating: 3.5,
        category_name: "Customer service skills",
        score: [
          {
            role: "Development",
            scoreRating: 2.6,
          },
          {
            role: "Self",
            scoreRating: 4.5,
          },
        ],
      },
      // {
      //   totalRating: 0,
      //   category_name: "Delivering results",
      //   score: [],
      // },
      // {
      //   totalRating: 0,
      //   category_name: "Developing others",
      //   score: [],
      // },
      // {
      //   totalRating: 0,
      //   category_name: "Developing self",
      //   score: [],
      // },
      // {
      //   totalRating: 0,
      //   category_name: "Interpersonal skills and character",
      //   score: [],
      // },
      // {
      //   totalRating: 0,
      //   category_name: "Leadership",
      //   score: [],
      // },
      // {
      //   totalRating: 0,
      //   category_name: "Problem solving and decision making",
      //   score: [],
      // },
    ],
    competencyEvaluatorStrength: [
      {
        rating: 3.5,
        category_name: "Customer service skills",
      },
      {
        rating: 3.1,
        category_name: "communication",
      },
      {
        rating: 1.4,
        category_name: "Delivering results",
      },
      {
        rating: 1.8,
        category_name: "Developing others",
      },
    ],
    competencyEvaluatorimproveOn: [
      {
        rating: 2.2,
        category_name: "Developing self",
      },
      {
        rating: 1.2,
        category_name: "Interpersonal skills and character",
      },
      {
        rating: 1.8,
        category_name: "Leadership",
      },
    ],
    hiddenStrength: [
      {
        category_name: "communication",
        yourRating: 1.6,
        othersRating: 3.3,
      },
      {
        category_name: "communication",
        yourRating: 2.6,
        othersRating: 2.1,
      },
      {
        category_name: "communication",
        yourRating: 3.6,
        othersRating: 4.1,
      },
    ],
    blindSpot: [
      {
        category_name: "Customer service skills",
        yourRating: 4.5,
        othersRating: 3.5,
      },
      {
        category_name: "communication",
        yourRating: 2.6,
        othersRating: 2.1,
      },
      {
        category_name: "communication",
        yourRating: 3.6,
        othersRating: 4.1,
      },
      {
        category_name: "communication",
        yourRating: 3.6,
        othersRating: 4.1,
      },
      {
        category_name: "communication",
        yourRating: 3.6,
        othersRating: 4.1,
      },
    ],
    surveyResponse: [
      {
        question_response: "sometimes",
        evaluator_role: "Peer",
        survey_question: {
          qusetion:
            "Encourages team members to build relationships and connection within and outside the team",
          category: "Developing others",
        },
      },
    ],

    openFeedback: {
      positive: [
        {
          feedback: "Layo is good at Locs.",
        },
        {
          feedback: "Eniola loves partying",
        },
      ],
      negative: [
        {
          feedback: "Layo Should stop disturbing People.",
        },
        {
          feedback: "Eniola Should test more often",
        },
      ],
    },

    openFeedback2: [
      {
        title:
          "What would you want to do differently or stop doing? / What would you want this person to do differently or stop doing?",
        summaryList: [
          {
            feedback: "Layo Should stop disturbing People.",
          },
          {
            feedback: "Eniola Should test more often",
          },
        ],
      },
      {
        title:
          "What would you want to continue doing? / What would you want this person to continue doing?",
        summaryList: [
          {
            feedback: "Layo is good at Locs.",
          },
          {
            feedback: "Eniola loves partying",
          },
        ],
      },
    ],

    detailedFeedback: [
      {
        category_name: "communication",
        score: 3.5,
        question: [
          {
            title: "States own views clearly, concisely and confidently",
            raters: [
              {
                rating: 2.2,
                role: "Self",
              },
              {
                rating: 2.2,
                role: "Others",
              },
              {
                rating: 2.2,
                role: "Manager",
              },
              {
                rating: 2.2,
                role: "Peer",
              },
            ],
          },
          {
            title: "States own views clearly, concisely and confidently",
            raters: [
              {
                rating: 2.2,
                role: "Self",
              },
              {
                rating: 2.2,
                role: "Others",
              },
              {
                rating: 2.2,
                role: "Manager",
              },
              {
                rating: 2.2,
                role: "Peer",
              },
            ],
          },
        ],
        raters: [
          {
            rating: 2.2,
            raters: "Peer",
            question: "States own views clearly, concisely and confidently",
          },
          {
            rating: 5,
            raters: "Self",
            question:
              "Asks questions to find out others' real views and checks understanding",
          },
        ],
      },
      // {
      //   category_name: "communication",
      //   question: [
      //     {
      //       title: "States own views clearly, concisely and confidently",
      //       raters: [
      //         {
      //           rating: 2.2,
      //           raters: "Self",
      //         },
      //         {
      //           rating: 2.2,
      //           raters: "Others",
      //         },
      //         {
      //           rating: 2.2,
      //           raters: "Manager",
      //         },
      //         {
      //           rating: 2.2,
      //           raters: "Peer",
      //         },
      //       ],
      //     },
      //     {
      //       title: "States own views clearly, concisely and confidently",
      //       raters: [
      //         {
      //           rating: 2.2,
      //           raters: "Self",
      //         },
      //         {
      //           rating: 2.2,
      //           raters: "Others",
      //         },
      //         {
      //           rating: 2.2,
      //           raters: "Manager",
      //         },
      //         {
      //           rating: 2.2,
      //           raters: "Peer",
      //         },
      //       ],
      //     },
      //   ],
      //   raters: [
      //     {
      //       rating: 2.2,
      //       raters: "Peer",
      //       question: "States own views clearly, concisely and confidently",
      //     },
      //   ],
      // },
    ],
  },
};
function Report() {
  return (
    <div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <MyDocument data={data?.report} />
      </PDFViewer>

      <PDFDownloadLink document={<MyDocument />} fileName="test.pdf">
        {({ loading }) => (loading ? "loading" : "Download Now")}
      </PDFDownloadLink>
    </div>
  );
}

export default Report;
