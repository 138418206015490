import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import { setErrors, updateInput} from '../../../../../../features/add-participants/add-participants-slice'
import { validateStep2 } from '../Validations/validation'
import axios from 'axios'
import swal from 'sweetalert'

const EditPartcipantsNameModal = ({target}) => {
 
    const dispatch = useDispatch()
    const { formData, errors } = useSelector( state => state.addParticipants );     
    const [ loading , setLoading ] = useState( false )              
      
     const handleInputChange = (id,field,value) => {
            dispatch( updateInput( { id, field, value } ) )
        }

    const updateParticipants = () => {
        const { validated , errors }  = validateStep2(formData)
        if(validated){
            setLoading(true)
            document.querySelector('#close-participants-modal').click()
            axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/participant/update`, formData)
            .then( response => {
                    if(response.data.success){
                        swal('Success!', 'Update successful.' , 'success');                                             
                        setLoading(false)
                    }
            } ) 
        }
        else dispatch(  setErrors(errors) )
    }

  return (
      <>  { loading && <LoadingSpinner /> }
      <div className='modal fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto' id={ target } tabIndex='-1' aria-labelledby='exampleModalLgLabel' aria-modal='true' role='dialog'>
          <div className='modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable  relative w-auto pointer-events-none'>
            <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
                <div className='modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
                <h5 className='text-xl bi bi-pencil font-medium leading-normal text-gray-800' id='exampleModalLgLabel'>
                    &nbsp;  <span className='text-[16px]'> Edit Partcipants </span>
                </h5>
                <button type='button' id='close-participants-modal'
                    className='btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                    data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body relative p-4 md:px-8'>                                                        
                <h5 className='text-md md:ml-2 bi bi-people md:mt-5'> 
                    &nbsp; Edit Participants & Development Coach 
                </h5>
                 <div className='flex flex-col '>
                    <div className='overflow-x-auto sm:-mx-8 lg:-mx-8'>
                        <div className='py-2 inline-block min-w-full px-5'>
                        <div className='overflow-hidden'>
                            <table className='min-w-full text-center -ml-[10px] md:ml-0'>
                               <thead>
                                    <tr>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-3 py-2'>
                                            S/N
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-16 md:px-3 py-2'>
                                             Name
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                            Email Address
                                        </th>                                         
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                             Development Coach Name
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                           Development Coach Email
                                        </th>                                         
                                    </tr>
                            </thead>
                            <tbody>                            
                                {
                                    formData.participants.map(
                                        (participant,id) => {
                                            return (
                                                <tr key={id}>
                                                    <td className='px-3 py-2 whitespace-nowrap text-[13px] font-medium text-gray-900'>
                                                        { id + 1}.  
                                                    </td>
                                                    <td className='text-[13px] text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                                        <input 
                                                             type="text" name="name"                                                              
                                                              value={ participant.name }
                                                              onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }
                                                             id="" className='admin-form-input'
                                                              style={{ paddingTop : 7 , paddingBottom : 7}} 
                                                        />
                                                    </td>
                                                    <td className='text-[13px] text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                                        <input 
                                                            type="text" name="email" id="" 
                                                             value={ participant.email }                                                             
                                                             onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }
                                                            className='admin-form-input' 
                                                            style={{ paddingTop : 7 , paddingBottom : 7}}
                                                         />
                                                    </td>      
                                                    <td className='text-[13px] flex items-center text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                                        <input 
                                                             value={ participant.dev_coach_name }
                                                             type="text" name="dev_coach_name" id="" 
                                                             className='admin-form-input'                                                              
                                                             onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }
                                                             style={{ paddingTop : 7 , paddingBottom : 7}} 
                                                         />                                                         
                                                    </td>                                                  
                                                    <td className='text-[13px] text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                                        <input 
                                                             value={ participant.dev_coach_email }
                                                             type="text" name="dev_coach_email" id="" 
                                                             className='admin-form-input'                                                              
                                                             onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }
                                                             style={{ paddingTop : 7 , paddingBottom : 7}} 
                                                        />
                                                    </td>                                                                                                     
                                                  </tr>   
                                                )
                                              }
                                           )
                                        }
                                    </tbody>
                                </table>
                              </div>
                           </div>
                         </div>
                    </div>
                     { errors.participant &&
                        <div className='border-l mt-2 border-l-2 border-red-500 text-red-500 text-[13px] text-center py-3 w-[400px] mx-auto bg-red-100'> 
                            {errors.participant} 
                        </div>
                      }                    
                <div className='flex justify-end'>
                      <button onClick={ updateParticipants } className='admin-form-btn'>
                         Update <span className='bi bi-arrow-right'>  </span>
                      </button>
                </div>
            </div>
         </div>
       </div>
    </div>
   </>
  )
}

export default EditPartcipantsNameModal