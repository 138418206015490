import React, { useState } from "react";
import "./Modal.css";
import { useQueryClient } from "react-query";
import Alert from "@mui/material/Alert";
import BasicModal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, FieldArray, Formik, Form, ErrorMessage } from "formik";
import { object, string, array } from "yup";
import axios from "axios";
function Modal(params) {
  const {
    formName = "Add Participant",
    open = true,
    setOpen,
    refetch,
    projectId,
    participantData = [
      { name: "", email: "", dev_coach: "", dev_coach_email: "", id: "" },
    ],
    setParticipantData,
  } = params;
  const [isParticipantAdded, setIsParticipantAdded] = useState(false);
  const [error, setError] = useState(false);
  const yupValidation = object({
    // project: string().min(1).required(),
    participant: array()
      // object({
      //   name: string().required(),
      //   email: string().email().required(),
      //   dev_coach: string().required(),
      //   dev_coach_email: string().email().required(),
      // })
      .min(1, "Add at least one participant")
      .max(5)
      .required(),
  });
  const initialValues = {
    project: projectId,
    participant: participantData,
  };
  const handleSubmit = (values, setSubmitting, resetForm) => {
    let newValues = [...values?.participant]?.map((e) => {
      e.dev_coach_name = e?.dev_coach;
      return e;
    });

    let newPayload = {
      // project_id: values.project,
      participants: newValues,
    };
    // alert(JSON.stringify(newPayload));
    console.log(newPayload);
    const request = axios
      .patch(
        "https://sandboxapi.360degree.testassessify.com/project/participant/update",
        newPayload
      )
      .then((e) => {
        // resetForm();
        setIsParticipantAdded(true);
        setTimeout((e) => setIsParticipantAdded(false), 5500);

        refetch();
      })
      .catch((e) => {
        setError(true);
        setTimeout((e) => setError(false), 2500);
      })
      .finally((e) => {
        setSubmitting(false);
        // setOpen(false);
      });
  };
  return (
    <div className="modal .admin-dashboard-modal">
      <BasicModal
        open={open}
        handleClose={(e) => setOpen(false)}
        className="modal__BasicModal"
      >
        <div className="modal__Modal">
          {isParticipantAdded && (
            <Alert
              severity="success"
              color="info"
              onClose={() => {
                setIsParticipantAdded(false);
              }}
            >
              Participant Updated Successfully
            </Alert>
          )}
          {error && (
            <Alert
              severity="error"
              onClose={() => {
                setError(false);
              }}
            >
              Failed to add Participant
            </Alert>
          )}
          {!error && !isParticipantAdded && (
            <button className="modal__Close" onClick={(e) => setOpen(false)}>
              x
            </button>
          )}
          <div className="modal__Content">
            <h2>{formName}</h2>
            <div className="modal__Form">
              <Formik
                className="modal-formik"
                initialValues={initialValues}
                validationSchema={yupValidation}
                onSubmit={(values, { setSubmitting, resetForm }) =>
                  handleSubmit(values, setSubmitting, resetForm)
                }
              >
                {({ values, errors, isSubmitting }) => (
                  <Form className="modal-form">
                    {/* <Field
                      name="project"
                      type="text"
                      placeholder="project-id"
                      className="modal-field-text"
                      required
                    /> */}
                    {/* <ErrorMessage name="project" /> */}
                    <FieldArray name="participant">
                      {({ push, remove, insert }) => (
                        <div className="participant-field-array">
                          {values?.participant?.map((_, i) => (
                            <div className="modal-field" key={i}>
                              <Field
                                type="text"
                                name={`participant.${i}.name`}
                                placeholder="Participant Name"
                                className="modal-field-text"
                                required
                              />
                              <Field
                                type="password"
                                name={`participant.${i}.password`}
                                placeholder="Participant Password"
                                className="modal-field-text"
                                required
                              />

                              <Field
                                type="email"
                                name={`participant.${i}.email`}
                                placeholder="Participant Email"
                                className="modal-field-text"
                                required
                              />
                              <Field
                                type="text"
                                name={`participant.${i}.dev_coach`}
                                placeholder="Dev coach Name"
                                className="modal-field-text"
                                required
                              />
                              <Field
                                type="email"
                                name={`participant.${i}.dev_coach_email`}
                                placeholder="Dev coach Email"
                                className="modal-field-text"
                                required
                              />

                              {/* <button
                                className="modal-remove-field"
                                type="button"
                                onClick={(e) => remove(i)}
                              >
                                <DeleteIcon />
                              </button> */}
                            </div>
                          ))}

                          {/* <button
                            type="button"
                            onClick={(e) => push({ name: "" })}
                            className="modal-add-new"
                            disabled={isSubmitting}
                          >
                            Add new
                          </button> */}
                        </div>
                      )}
                    </FieldArray>
                    <button
                      type="submit"
                      className="modal-submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting" : "Submit"}
                    </button>
                    <ErrorMessage
                      name="participant"
                      className="error-message"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </BasicModal>
    </div>
  );
}

export default Modal;
