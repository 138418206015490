import React from "react";
import { Autocomplete, TextField } from "@mui/material";
function SearchSelect(props) {
  const { options, optionLabel, setValue, value, label, key } = props;
  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={options}
      label={optionLabel}
      getOptionLabel={(option) => option[optionLabel]}
      onChange={(event, newValue) => {
        setValue(newValue[value]);
        //   console.log({ value });
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={key}>
            {option[optionLabel]}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={label}
        />
      )}
    />
  );
}

export default SearchSelect;
