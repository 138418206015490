import React from 'react'
import AddProjectForm from './AddProjectForm'
import { useSelector } from 'react-redux'
import AddParticipantsForm from './AddParticipantsForm'
import SetupEmailForm from './set-up-mail-form/SetupEmailForm'
import Preview from './Preview'
import EvaluatorForParticipantsForm from './EvaluatorForParticipantsForm'

const FormWrapper = () => {
   
    const { step } = useSelector( state => state.createProjectForm )
    const { visible } = useSelector( state => state.evaluatorForParticipant )
        
    switch(step){
       case 1 :
         return ( <AddProjectForm /> )
       case 2 : 
          return( (visible) ? <EvaluatorForParticipantsForm />  : <AddParticipantsForm /> )
       break;
       case  3 :
          return ( <SetupEmailForm /> )
       case 4  :
           return( <Preview /> )
       break;
       default :
           return <> </> ;
    }     
}

export default FormWrapper