import axios from 'axios'
import React from 'react'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import swal from 'sweetalert'

const DeleteCompany = ({company,target,setCompaniesList,companiesList}) => {
  const [ loading , setLoading ] = React.useState(false)

  const deleteCompany = () => {
      setLoading(true)
      axios.delete(`${ process.env.REACT_APP_BASE_URL }/company/${ company.id }`)      
      .then( response => {
            if( response.data.success){
                axios.get(`${ process.env.REACT_APP_BASE_URL }/company/all`)
                .then(
                res => {
                        if( res.data.success ){
                            setCompaniesList(res.data.data)                    
                            setLoading(false)
                            swal('Success!', 'Company is deleted successfully.' , 'success')
                        }
                  }
                )      
            }
       })     
  }

  return (
   <>     { loading && <LoadingSpinner /> }
    <div className='modal fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto' id={ target } tabIndex='-1' aria-labelledby='exampleModalLgLabel' aria-modal='true' role='dialog'>
    <div className='modal-dialog  modal-dialog-centered  relative w-auto pointer-events-none'>
       <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>         
         <div className='modal-body relative p-4 px-8 text-center'>
             <div className='pt-3'>
                <i className='mt-16 bi bi-exclamation-triangle text-center text-[4.5rem] text-red-500'></i>
                <h3 className='text-3xl  text-center mt-2'> Warning </h3>
                <div className='text-sm mt-4 whitespace-normal'>  
                    Did you really want to delete a company <strong> { company.company_name }</strong>.
                     Please note that you cannot undo this action.                 
                </div>
                <div className='mt-8 flex justify-end mb-3'>                                                  
                    <button className='py-2 text-sm px-8  bg-gray-200 rounded-lg' data-bs-dismiss='modal' aria-label='Close'>
                        Cancel
                    </button>
                    <button
                            className='py-2 text-sm ml-3 px-8 text-white bg-red-500 rounded-lg' 
                            data-bs-dismiss='modal' aria-label='Close'
                            onClick={ deleteCompany }
                        >
                        Proceed & Delete
                    </button>
                </div>  
             </div>       
         </div>
        </div>
      </div>
    </div>
 </>
  )
}

export default DeleteCompany