import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../../admin/layouts/Navbar";
import LoadingSpinner from "../../../components/LoadingSpinner";
import "./Login.css";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Modals from "../../../components/Modals/Modals";
import ChangePassword from "../../login/ChangePassword/ChangePassword";

function Login(params) {
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [user, setUser] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const { type = "participant" } = params;
  const loginFunc = (username, password, setSubmitting) => {
    login(username, password, setSubmitting);
  };

  const login = (email, password, setSubmitting) => {
    let res = axios
      .post(
        "https://sandboxapi.360degree.testassessify.com/project/development-coach-login",
        { evaluator_email: email, password }
      )
      .then((e) => {
        setUser(e.data);
        localStorage.setItem("username", e?.data?.data?.user?.evaluator_name);
        localStorage.setItem("project_id", e?.data?.data?.user?.project_id);
        localStorage.setItem("coach_id", e?.data?.data?.user?.id);
        if (e?.data?.data?.user?.first_time_login) {
          setFirstTimeLogin(true);
        } else {
          navigate("/coach/dashboard");
          // navigate("/participant/campaign");
        }
      })
      .catch((e) => {
        setLoginFailed(true);
      })
      .finally((e) => setSubmitting(false));
  };

  // useEffect(() => {
  //   console.log(user);
  // }, [user]);

  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("You have to fill this field"),
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("You have to fill this field"),
  });
  return (
    <div className="login">
      {/* <Navbar /> */}
      <div className="login__Body">
        <div className="login__Form ">
          <div className="login__Left">
            <h2 className="sign-in">Sign In</h2>
            <div className="login-form">
              <Formik
                initialValues={{
                  username: "",
                  password: "",
                  email: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  const { username, password } = values;
                  setLoginFailed(false);
                  // if (username == "admin" && password == "admin") {
                  loginFunc(username, password, setSubmitting);
                  setUserEmail(username);
                  // } else {
                  //   setLoginFailed(true);
                  // }
                }}
              >
                {({ errors, touched, isSubmitting, setSubmitting }) => (
                  <Form className="login-formik">
                    <label htmlFor="username">Username</label>
                    <Field
                      id="username"
                      name="username"
                      placeholder="Enter your Username"
                    />
                    {errors.username && touched.username ? (
                      <div className="error-message">* {errors.username}</div>
                    ) : null}

                    <label htmlFor="password">Password</label>
                    <Field
                      id="password"
                      type="password"
                      name="password"
                      placeholder="Enter your Password"
                    />
                    {errors.password && touched.password ? (
                      <div className="error-message">* {errors.password}</div>
                    ) : null}

                    <Link
                      to="/auth/forgot-password"
                      className="text-sm text-blue-500 mt-3 mb-2"
                    >
                      Forgot password?
                    </Link>
                    <button type="submit" className="login-submit">
                      Login
                    </button>
                    {loginFailed && (
                      <div className="error-message text-center">
                        Wrong Details
                      </div>
                    )}

                    {isSubmitting && <LoadingSpinner />}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="login__Right">
            <img
              src={
                loginFailed ? `/assets/login-failed.png` : `/assets/login.png`
              }
              alt="login girl"
            />
          </div>
        </div>
      </div>
      {firstTimeLogin && (
        <Modals open={firstTimeLogin} setOpen={setFirstTimeLogin}>
          <ChangePassword
            userType="Dev Coach"
            userEmail={userEmail}
            url="/coach/dashboard"
          />
        </Modals>
      )}
    </div>
  );
}

export default Login;
