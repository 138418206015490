import React from "react";
import Sidebar from "./Sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAdmin } from "../../../features/login/AdminLoginSlice";
import Avatar from "@mui/material/Avatar";

const Navbar = (params) => {
  const {
    isAdmin = true,
    isLoggedIn = true,
    links = [
      {
        title: "Dashboard",
        icon: "bi-speedometer2",
        path: "dashboard",
        link: "/admin/dashboard",
      },
      {
        title: "Campaign",
        icon: "bi-bag-plus",
        path: "/admin/create-campaign",
        link: "campaign",
      },
      {
        title: "Survey",
        icon: "bi-pencil-square",
        path: "survey",
        link: "/admin/survey",
      },
      {
        title: "Report",
        icon: "bi-journals",
        path: "report",
        link: "/admin/report",
      },
    ],
  } = params;
  //the isAuth prop is a boolean which specifies either someone is logged in or not.
  //also the userType check which user is logged in e.g participant or dev coach or admin etc
  const { adminType } = useSelector((state) => state.adminLogin);
  const { user = "Admin", isAuth, userType } = params;
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const logoutFunc = () => {
    //check if logged user is admin
    if (userType === "admin") {
       dispatch(logoutAdmin());
       window.localStorage.clear();
       window.sessionStorage.clear()
      if (adminType === "client admin") {
        navigate("/client-admin-login");
        sessionStorage.setItem('login-redirect', "/client-admin-login")
      }
      else {
        navigate("/site-admin-login");
        sessionStorage.setItem('login-redirect', "/client-admin-login")
      } 
    } else {
      localStorage.removeItem("username");
      localStorage.removeItem("user");
      localStorage.clear();
      sessionStorage.clear();

      if (location.pathname.includes("coach")) {
        navigate("/coach/login");
      } else {
        navigate("/");
      }
    }
  };

  return (
    <nav className="sticky-top bg-white">
      <div className="flex justify-between items-center h-[60px] shadow-md px-2">
        {/* <img src="/assets/logo.png" alt="logo" className="w-[70px] h-[60px]" /> */}
        <img
          src="/assets/Access-bank-PLC.png"
          alt="logo"
          className="w-[140px] md:w-[150px] h-[60px]"
        />
        <div className="flex items-center ml-3">
          {/* <img
            src="/assets/avatar.jpg"
            alt="avatar.jpg"
            className="  md:h-[35px] md:w-[35px] rounded-full"
          /> */}
          {isLoggedIn && (
            <img
              src="/assets/UX/lady 2.png"
              alt="avatar.jpg"
              className="  h-[35px] w-[35px] rounded-full"
            />
          )}
          <button className="text-[16px] mr-3 hidden sm:inline-block">
            &nbsp; {user} <span className="bi bi-chevron-down"></span>
          </button>
          {!location.pathname.includes("evaluator") &&
            ((user && isAuth) || username) && (
              <button
                className="text-[16px] text-red-500 mr-3 hidden sm:inline-block logout"
                onClick={logoutFunc}
              >
                &nbsp; Logout
              </button>
            )}
          {/*display sidebar for mobile devices */}
          {!location.pathname.includes("evaluator") &&
            ((user && isAuth) || username) && (
              <button
                className="sm:hidden ml-8 bi bi-justify text-gray-800 text-4xl"
                data-bs-toggle="offcanvas"
                data-bs-target="#sidebar"
                aria-controls="sidebar"
              ></button>
            )}
          <div>
            <div
              className="offcanvas offcanvas-start fixed bottom-0 
             flex flex-col max-w-full bg-white invisible 
             bg-clip-padding shadow-sm outline-none transition
             duration-300 ease-in-out text-gray-700 top-0 left-0
             border-none w-96"
              tabIndex="-1"
              id="sidebar"
            >
              <div className="mobile-sidebar-header flex justify-between">
                <button
                  className="text-[14px] text-red-500 mr-3 py-2 px-4 border border-red-500 rounded-lg"
                  onClick={logoutFunc}
                >
                  &nbsp; Logout
                </button>
                <button
                  className="mobile-sidebar-close-btn"
                  data-bs-dismiss="offcanvas"
                ></button>
              </div>
              <div className="offcanvas-body flex-grow p-0 overflow-y-auto">
                <Sidebar links={links} isAdmin={isAdmin} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
