import React from "react";
import Navbar from "../layouts/Navbar";
import axios from "axios";
import validator from "validator";
import { useDispatch } from "react-redux";
import {
  authenticateAdmin,
  hasCompany,
} from "../../../features/login/AdminLoginSlice";
import { useNavigate } from "react-router-dom";

const SiteAdminLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState([]);
  const [adminForm, setAdminForm] = React.useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setAdminForm({ ...adminForm, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const ignoreWhiteSpace = { ignore_whitespace: true };
    let validated = false;

    if (validator.isEmpty(adminForm.email, ignoreWhiteSpace)) {
      setError({ email: "Error! admin email can't be empty" });
    } else if (!validator.isEmail(adminForm.email)) {
      setError({ email: "Error! invalid admin email address" });
    } else if (
      validator.isEmpty(adminForm.password.toString(), ignoreWhiteSpace)
    ) {
      setError({ password: "Error! password field can't be empty" });
    } else validated = true;

    return { validated };
  };

  const login = () => {
    const _validator = validate();
    if (_validator.validated) {
      setError([]);
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/company/login`, adminForm)
        .then((response) => {
          
          if (response.data.success) {
            dispatch(authenticateAdmin(response.data.data));
            localStorage.setItem(
              "companyID",
              response.data?.data?.user?.company_id
            );
            
            //check if company exist
            axios
              .get(`${process.env.REACT_APP_BASE_URL}/company/all`)
              .then((response) => {
                if (response.data.success && response.data.data.length) {
                  dispatch(hasCompany());
                  setLoading(false);
                  navigate("/admin/create-company");
                } else {
                  setLoading(false);
                  navigate("/admin/view-company");
                }
              });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setLoading(false);
            setError({ unknown: err.response.data.error });
          }
        });
    }
  };

  return (
    <>
      <Navbar />
      <section className='mt-16 w-[90%] xl:w-[70%] mx-auto block border border-gray-200'>
        <div className='grid md:grid-cols-2 p-0 m-0'>
             <div className='p-4 md:p-12'>
                    <img src='/assets/avatar.jpg' alt='avatar' className='block mx-auto w-[70px] h-[70px] rounded-full' />
                   <h1 className='text-[1.4rem]  mb-4 mt-2 text-center'>Site Admin Sign In </h1>
                   <label htmlFor='' className='text-sm text-gray-800'> Email Address: </label>
                     <input 
                          type='email' className='admin-form-input' 
                          placeholder='email address...' name='email'
                          value={ adminForm.email } onChange = { handleChange }
                      />
                      <p className='text-sm text-red-500 mt-3'> { error.email } </p>
                   <br />
                   <label htmlFor='' className='text-sm text-gray-800'> Password: </label>
                   <input 
                        type='password' className='admin-form-input'
                         value={ adminForm.password } onChange={ handleChange }
                         placeholder='password...' name='password'
                      />
                    <p className='text-sm text-red-500 mt-3'> { error.password } { error.unknown } </p>                    
                    {
                       !loading ?
                         <button  onClick={ login } className='mt-4 bg-green-small text-center block mx-auto w-[280px] py-3 text-white rounded-lg text-sm'>
                           Login
                         </button>
                         :
                         <button className='mt-4 bg-green-small text-center block mx-auto w-[280px] py-3 text-white rounded-lg text-sm opacity-50'>
                           Loading...
                        </button>
                    }
             </div>
             <img src='/assets/login.png' alt='avatar' className='m-hidden w-full h-full p-0 m-0' />
        </div> 
      </section>         
    </>
  );
};

export default SiteAdminLogin;
