import styles from "../ReportStyleSheet";
import { Text, View, Image, Svg, Path } from "@react-pdf/renderer";
import ProgressBar from "./ProgressBar";

function CompetencyEvaluation({
  resultList,
  title = " 4.0 Competency Evaluation & Inferences",
  subtitle = "4.1 STRENGTH",
  summary = "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam sapiente officiis odit, nam nobis sunt cum. Hic nesciunt voluptatibus labore.",
  self = "High",
  others = "High",
  hasExtra = false,
  yourRating = 3.5,
  othersRating = 1.8,
}) {
  return (
    <View style={{ fontSize: 12 }} break>
      <Text style={[styles.header, { marginBottom: 15 }]}>{title}</Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Text style={{ color: "blue", fontSize: 14 }}>{subtitle} </Text>
        {/* <Text style={{ fontSize: 12 }}>
          #Self-Evaluation : {self}, Other Evaluation : {others}{" "}
        </Text> */}
      </View>
      <Text style={{ marginBottom: 30 }}>{summary}</Text>
      {resultList?.map((e) => (
        <View
          wrap={false}
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 40,
            },
          ]}
        >
          {e?.rating && (
            <Text
              style={[
                styles.avatar,
                {
                  marginRight: 5,
                  fontSize: 12,
                  padding: 6,
                  backgroundColor: "green",
                },
              ]}
            >
              {e?.rating}
            </Text>
          )}
          <View>
            {/* <Text style={{ fontSize: 12 }}>
              {e?.summary || "Gbolahan needs to fix this also"}
            </Text> */}
            <Text style={{ fontSize: 13, fontStyle: "italic" }}>
              {e?.category_name}
            </Text>
            {hasExtra && (
              <View
                style={[
                  {
                    flexDirection: "row",
                    marginTop: 5,
                    justifyContent: "space-between",
                    minWidth: "90vw",
                    fontSize: 13,
                  },
                ]}
              >
                <View style={[styles.ratings, { marginRight: 20 }]}>
                  <Text style={{ marginRight: 10, fontSize: 12 }}>
                    Your Rating: {e?.yourRating}
                  </Text>
                  {/* Progress Bar */}
                  <ProgressBar rating={e?.yourRating} height={20} width={150} />
                </View>

                <View style={[styles.ratings, { marginRight: 20 }]}>
                  <Text style={{ marginRight: 10, fontSize: 12 }}>
                    Other Rating: {e?.othersRating}
                  </Text>
                  {/* Progress Bar */}
                  <ProgressBar
                    rating={e?.othersRating}
                    height={20}
                    width={150}
                  />
                </View>
              </View>
            )}
          </View>
        </View>
      ))}
    </View>
  );
}

export default CompetencyEvaluation;
