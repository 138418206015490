import React from "react"
import { Draggable } from "react-drag-reorder";
import { v4 as uuid } from 'uuid'
import { reportTemplate } from "./components/report-template";

const Report = () => {  

  const toggleVisibilityIcon = id => {
        const iconClassName = document.querySelector(`#${id}`)
        if( iconClassName.classList.contains('bi-chevron-down') ){
            iconClassName.classList.remove('bi-chevron-down')
            iconClassName.classList.add('bi-chevron-up')
        }
        else{
          iconClassName.classList.remove('bi-chevron-up')
          iconClassName.classList.add('bi-chevron-down')
        }
  }

  return (
    <section className='md:mr-2 h-full'>
    <h1 className='py-1 text-xl text-white bg-green-small font-semibold pl-3 uppercase'>Report </h1>
    <div className='mt-8 px-2 pb-8'>             
             <div className="md:flex justify-between">
                 <p className="text-sm pb-3 md:pb-0"> Click on a section to expand. Drag and Drop section to reorder. </p>
                 <a href="/assets/documents/360-FEEDBACK-REPORT-SAMPLE.pdf" download className="bg-green-small text-white bi bi-download py-[8px] flex items-center px-5  rounded-md text-sm">
                    &nbsp; Download Report
                 </a>
             </div>
             
             <div class="accordion accordion-flush" id="accordionFlushExample">
               <Draggable>
                 { reportTemplate.map( content => {
                     const id = uuid()                     
                     return(
                      <section>
                       <div key={id} class="accordion-item w-full  rounded-lg  border-0 rounded-none">              
                        <h2 class="flex justify-between px-3 py-3 text-sm uppercase items-center mt-3 bg-gray-100 mb-0" id="flush-headingOne">                           
                          <div className="flex items-center">
                            <span className="bi bi-arrow-down-up mr-3"> </span>
                            <span> {content.header} </span>
                        </div>
                        <div className="flex items-center md:pr-4">
                            <span className="bi bi-trash text-gray-500"> </span>
                            <span className="bi bi-pencil text-gray-500 ml-4"> </span>
                            <span className="ml-4 bi bi-chevron-down p-2 hover:cursor-pointer"
                                   data-bs-toggle="collapse"
                                   data-bs-target={"#flush-collapseOne" + id }
                                   onClick = { () => toggleVisibilityIcon('chevron' + id) }
                                   id = { 'chevron' + id }
                                    aria-expanded="false" aria-controls={"flush-collapseOne" + id }
                            ></span>
                        </div>
                        </h2>
        
                        <div id={"flush-collapseOne" + id } class="accordion-collapse border-0 collapse"
                          aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body py-4 px-2 md:px-5"> 
                              { content.content }                               
                          </div>
                        </div>
                       </div>
                     </section>
                     )
                 })}
                </Draggable>
           </div>
    </div>
   </section>
  )
}  
export default Report

 