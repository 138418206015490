import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateInput } from '../../../../../../features/create-company/create-company-slice'

const Form1 = () => {

  const { formData , errors }  = useSelector( state => state.createCompanyForm )
  const dispatch = useDispatch()
 
  const handleChange = e => {
    dispatch( updateInput({ name : e.target.name , value : e.target.value }) )
  }

  return (
    <form>
        <h5 className='text-md font-semibold mb-4'> Step 1: Company Name </h5>
        
        <label htmlFor='name' className='text-sm'> Company Name : </label>
        <input 
              type='text' 
              name='company_name'
              className='admin-form-input'
              value={ formData.company_name }
              onChange = { handleChange }            
            />
          <div className='text-red-500 text-sm mt-2'> { errors.company_name } </div>
         <br />
        <label htmlFor='name' className='text-sm'> Type of industry : </label>
        
        <select class='industry-type-input form-select form-select-md'
                 name='industry_type' onChange={ handleChange }
                value={ formData.industry_type } aria-label='.form-select-sm example'
        >
            <option value=''> Select industry type </option>
            <option value='Manufacturing'>Manufacturing</option>
            <option value='Technology'>Technology</option>
            <option value='Media'>Media</option>
            <option value='Telecommunication'>Telecommunication</option>
            <option value='Healthcare & Pharmaceuticals'> Healthcare & Pharmaceuticals </option>
            <option value='Power & Utilities'>Power & Utilities</option>
            <option value='Financial Services'>Financial Services</option>
            <option value='Agriculture'>Agriculture</option>
            <option value='Oil & Gas'>Oil & Gas</option>
            <option value='Professional Services'>Professional Services</option>
            <option value='Transportation'>Transportation</option>                        
       </select>
      <div className='text-red-500 text-sm mt-2'> { errors.industry_type } </div>
    </form>
  )
}

export default Form1