import React from 'react'
import DevCoachTextEditor from './DevCoachTextEditor'
import TextEditor from './EvaluatorTextEditor'
import ParticipantsTextEditor from './ParticipantsTextEditor'

const TextEditorWrapper = ({activeTemplate}) => {
   switch(activeTemplate){
      case 'evaluator' :
        return <TextEditor />
       break
       case 'development coach' :
         return <DevCoachTextEditor />
        break
        case 'participants' :
          return <ParticipantsTextEditor />
        break
        default :
          return <TextEditor />
   }
}

export default TextEditorWrapper