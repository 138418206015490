import React, { useState } from "react";
import "./Modal.css";
import { useQuery } from "react-query";
import Alert from "@mui/material/Alert";
import BasicModal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, FieldArray, Formik, Form, ErrorMessage } from "formik";
import { object, string, array } from "yup";
import axios from "axios";
import SearchSelect from "./SearchSelect";
import { Autocomplete, TextField } from "@mui/material";
function Modal(params) {
  const {
    formName = "Add Participant",
    open = true,
    setOpen,
    refetch,
    projectId,
    updateParticipant,
    participantData = [
      {
        name: "",
        email: "",
        dev_coach: "",
        dev_coach_email: "",
        country: "",
        company: "",
        subsidiary: "",
        department: "",
        branch: "",
      },
    ],
  } = params;
  const [isParticipantAdded, setIsParticipantAdded] = useState(false);
  const [error, setError] = useState(false);
  const yupValidation = object({
    project: string().min(1).required(),
    participant: array()
      // object({
      //   name: string().required(),
      //   email: string().email().required(),
      //   dev_coach: string().required(),
      //   dev_coach_email: string().email().required(),
      // })
      .min(1, "Add at least one participant")
      .max(5)
      .required(),
  });
  const initialValues = {
    project: projectId,
    participant: participantData,
  };
  const handleSubmit = (values, setSubmitting, resetForm) => {
    let newValues = [...values?.participant]?.map((e) => {
      e.dev_coach_name = e?.dev_coach;
      return e;
    });

    let newPayload = {
      project_id: values.project,
      participants: newValues,
    };

    const request = axios
      .post(
        "https://sandboxapi.360degree.testassessify.com/project/participant/",
        newPayload
      )
      .then((e) => {
        console.log("Data", e?.data);
        resetForm();
        setIsParticipantAdded(true);
        setTimeout((e) => setIsParticipantAdded(false), 5500);
        refetch();
        updateParticipant();
      })
      .catch((e) => {
        setError(true);
        setTimeout((e) => setError(false), 2500);
      })
      .finally((e) => {
        setSubmitting(false);
      });
  };

  async function getter(url) {
    let res = await axios.get(url);
    return res.data;
  }
  const [country, setCountry] = useState([]);
  const [company, setCompany] = useState([]);
  const [subsidiary, setSubsidiary] = useState([]);
  const [department, setDepartment] = useState([]);
  const [branch, setBranch] = useState([]);

  const countryQuery = useQuery(
    ["fetch-country"],
    () => getter(`${process.env.REACT_APP_BASE_URL}/company/country`),
    {
      onSuccess: (data) => {
        let newData = [...data.countries];
        newData.map((data) => ({ label: data, value: data }));
        setCountry(newData);
      },
    }
  );

  const subsidiaryQuery = useQuery(
    ["fetch-subsidiary"],
    () => getter(`${process.env.REACT_APP_BASE_URL}/company/subsidiary`),
    {
      onSuccess: (data) => {
        let newData = [...data.subsidiary];
        newData.map((data) => ({ label: data, value: data }));
        setSubsidiary(newData);
      },
    }
  );
  const departmentQuery = useQuery(
    ["fetch-department"],
    () => getter(`${process.env.REACT_APP_BASE_URL}/company/departments`),
    {
      onSuccess: (data) => {
        let newData = [...data.departments];
        newData.map((data) => ({ label: data, value: data }));
        setDepartment(newData);
      },
    }
  );
  const branchQuery = useQuery(
    ["fetch-branches"],
    () => getter(`${process.env.REACT_APP_BASE_URL}/company/branches`),
    {
      onSuccess: (data) => {
        let newData = [...data.branches];
        newData.map((data) => ({
          label: data?.branch_name,
          value: data?.branch_name,
        }));
        setBranch(newData);
      },
    }
  );
  return (
    <div className="modal .admin-dashboard-modal">
      <BasicModal
        open={open}
        handleClose={(e) => setOpen(false)}
        className="modal__BasicModal"
      >
        <div className="modal__Modal">
          {isParticipantAdded && (
            <Alert
              severity="success"
              color="info"
              onClose={() => {
                setIsParticipantAdded(false);
              }}
            >
              Participant Added Successfully
            </Alert>
          )}
          {error && (
            <Alert
              severity="error"
              onClose={() => {
                setError(false);
              }}
            >
              Failed to add Participant
            </Alert>
          )}
          {!error && !isParticipantAdded && (
            <button className="modal__Close" onClick={(e) => setOpen(false)}>
              x
            </button>
          )}
          <div className="modal__Content">
            <h2>{formName}</h2>
            <div className="modal__Form">
              <Formik
                className="modal-formik"
                initialValues={initialValues}
                validationSchema={yupValidation}
                onSubmit={(values, { setSubmitting, resetForm }) =>
                  handleSubmit(values, setSubmitting, resetForm)
                }
              >
                {({ values, errors, isSubmitting, setFieldValue }) => (
                  <Form className="modal-form">
                    {/* <Field
                      name="project"
                      type="text"
                      placeholder="project-id"
                      className="modal-field-text"
                      required
                    /> */}
                    {/* <ErrorMessage name="project" /> */}
                    <FieldArray name="participant">
                      {({ push, remove, insert }) => (
                        <div className="participant-field-array">
                          {values?.participant?.map((_, i) => (
                            <div className="modal-field" key={i}>
                              <Field
                                type="text"
                                name={`participant.${i}.name`}
                                placeholder="Participant Name"
                                className="modal-field-text"
                                required
                              />

                              <Field
                                type="email"
                                name={`participant.${i}.email`}
                                placeholder="Participant Email"
                                className="modal-field-text"
                                required
                              />
                              {/* <Field
                                type="text"
                                name={`participant.${i}.country`}
                                placeholder="Country"
                                className="modal-field-text"
                                // required
                              /> */}

                              <Autocomplete
                                className="modal-field-text"
                                id="tags-standard"
                                options={country}
                                // label={optionLabel}
                                // getOptionLabel={(option) => option[optionLabel]}
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    `participant.${i}.country`,
                                    newValue
                                  );
                                }}
                                // renderOption={(props, option) => {
                                //   return (
                                //     <li {...props} key={key}>
                                //       {option[optionLabel]}
                                //     </li>
                                //   );
                                // }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Select Country"
                                    placeholder="Select Country"
                                  />
                                )}
                              />
                              <Autocomplete
                                className="modal-field-text"
                                id="tags-standard"
                                options={subsidiary}
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    `participant.${i}.subsidiary`,
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Select Subsidiary"
                                    placeholder="Select Subsidiary"
                                  />
                                )}
                              />
                              <Autocomplete
                                className="modal-field-text"
                                id="tags-standard"
                                options={department}
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    `participant.${i}.department`,
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Select Department"
                                    placeholder="Select Department"
                                  />
                                )}
                              />
                              <Autocomplete
                                id="tags-standard"
                                options={branch}
                                getOptionLabel={(option) => option.branch_name}
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    `participant.${i}.branch`,
                                    newValue?.branch_name
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Branch"
                                    placeholder="Select Branch"
                                  />
                                )}
                              />
                              {/* <Field
                                type="text"
                                name={`participant.${i}.company`}
                                placeholder="Company"
                                className="modal-field-text"
                                // required
                              /> */}
                              {/* <Field
                                type="text"
                                name={`participant.${i}.subsidiary`}
                                placeholder="Subsidiary"
                                className="modal-field-text"
                                // required
                              /> */}
                              {/* <Field
                                type="text"
                                name={`participant.${i}.department`}
                                placeholder="Department"
                                className="modal-field-text"
                                // required
                              /> */}
                              {/* <Field
                                type="text"
                                name={`participant.${i}.branch`}
                                placeholder="Branch"
                                className="modal-field-text"
                                // required
                              /> */}
                              <Field
                                type="text"
                                name={`participant.${i}.dev_coach`}
                                placeholder="Dev coach Name"
                                className="modal-field-text"
                                required
                              />
                              <Field
                                type="email"
                                name={`participant.${i}.dev_coach_email`}
                                placeholder="Dev coach Email"
                                className="modal-field-text"
                                required
                              />

                              <button
                                className="modal-remove-field"
                                type="button"
                                onClick={(e) => remove(i)}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          ))}

                          <button
                            type="button"
                            onClick={(e) => push({ name: "" })}
                            className="modal-add-new"
                            disabled={isSubmitting}
                          >
                            Add new
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <button
                      type="submit"
                      className="modal-submit"
                      disabled={isSubmitting}
                    >
                      {" "}
                      Submit
                    </button>
                    <ErrorMessage
                      name="participant"
                      className="error-message"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </BasicModal>
    </div>
  );
}

export default Modal;
