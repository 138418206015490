import React from "react";
import "@fontsource-variable/open-sans";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Panel from "../component/shared/Panel";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans Variable', sans-serif",
  },
});
function TabLinks(props) {
  const { value, handleChange, tabHeaders, tabOptions } = props;

  return (
    <ThemeProvider theme={theme}>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            TabIndicatorProps={{ hidden: true }}
            fontWeight="700"
            sx={{
              fontWeight: "700",
              "& .Mui-selected": {
                color: "#707070 !important",
              },
            }}
          >
            {tabHeaders?.map((tab, index) => (
              <Tab
                label={tab?.label}
                value={tab?.value}
                key={index}
                sx={{
                  color: "#1E210F",
                  fontWeight: "700",
                  fontSize: "1rem",
                  textTransform: "capitalize",
                }}
              />
            ))}
            {/* <Tab
              label="Competency"
              value="2"
              sx={{ color: "#1E210F", fontWeight: "700" }}
            />
            <Tab
              label="Competency"
              value="3"
              sx={{ color: "#1E210F", fontWeight: "700" }}
            /> */}
          </TabList>
        </Box>
        <Box>
          {tabOptions?.map((tab, index) => (
            <TabPanel value={tab?.value}>
              {/* <Panel
                key={index}
                title={tab?.panel?.title}
                subtitle={tab?.panel?.subtitle}
              /> */}
              {tab?.children}
            </TabPanel>
          ))}
          {/* <TabPanel value="2" sx={{ textTransform: "capitalize !important" }}>
            Competency
          </TabPanel> */}
        </Box>
      </TabContext>
    </ThemeProvider>
  );
}

export default TabLinks;
