import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = ({ label = 'Back to homepage', path = '/' , height = '100%' }) => {
  return (
    <div className='flex items-center justify-center p-2' style={{ height }}>
          <div className='w-auto mx-auto h-auto'>
               <h5 className='text-xl md:text-2xl text-center text-red-500 mb-3'> Opps! </h5>
               <img src='/assets/pagenotfound.png' alt="page not found" className='block mx-auto w-[200px] h-[200px]' />
               <p className='mt-3 text-md md:text-lg text-center'> The page you are looking for does not exits. </p>
               <Link 
                    to={path}
                    className='bg-green-small mt-4 mx-auto text-center text-white w-[250px] py-[10px] flex justify-center items-center px-5  rounded-md text-sm'
                >
                   <span className='bi bi-arrow-left mr-2'></span> { label } 
               </Link>
          </div>
    </div>
  )
}

export default PageNotFound