import React, { useContext, useState } from "react";
import { Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Panel from "../shared/Panel";
import { useGetMainReport } from "hooks/api/report-dashboard";
import { useEffect, useMemo } from "react";
import { ReportContext } from "context/ReportDashboardContext";
import useConvertToMainReportPayload from "hooks/helpers/useConvertToMainReportPayload";
import convertToDomainDetails from "hooks/helpers/convertToDomainDetails";

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
});
function Overall() {
  const { initialValue, setInitialValue } = useContext(ReportContext);
  // console.log({ initialValue });
  const mainReport = useGetMainReport(
    "/report/main",
    useConvertToMainReportPayload(initialValue)
  );
  const [domainSummary, setDomainSummary] = useState([]);
  const [domainDetail, setDomainDetail] = useState(
    convertToDomainDetails(mainReport?.data?.domain)
  );
  // console.log({ domainDetail });
  useEffect(() => {
    setDomainSummary(
      mainReport?.data?.domain?.map((data) => ({
        title: data?.domain,
        score: data?.score,
      }))
    );
    setDomainDetail(convertToDomainDetails(mainReport?.data?.domain));
  }, [mainReport?.data]);

  // const cardList = [
  //   {
  //     title: "Overall",
  //     score: "1.7",
  //   },
  //   {
  //     title: "Overall",
  //     score: "1.7",
  //   },
  //   {
  //     title: "Overall",
  //     score: "1.7",
  //   },
  //   {
  //     title: "Overall",
  //     score: "1.7",
  //   },
  // ];
  // const scoreList = {
  //   left: [
  //     {
  //       title: "Lorem Ipsum",
  //       summary: "Lorem Ipsum category",
  //       imageList: [
  //         { score: "50%", src: "/assets/france.png" },
  //         { score: "30%", src: "/assets/ghana.png" },
  //         { score: "20%", src: "/assets/senegal.png" },
  //         { score: "85.5%", src: "/assets/9ija.png" },
  //       ],
  //     },
  //     {
  //       title: "Lorem Ipsum",
  //       summary: "Lorem Ipsum category",
  //       imageList: [
  //         { score: "50%", src: "/assets/9ija.png" },
  //         { score: "65.5%", src: "/assets/9ija.png" },
  //       ],
  //     },
  //   ],
  // };
  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters>
        <Panel
          key="1"
          title="Domain"
          subtitle="Subtitle for Domain Overall"
          // cardList={cardList}
          cardList={domainSummary}
          // scoreList={scoreList}
          scoreList={domainDetail}
        />
      </Container>
    </ThemeProvider>
  );
}

export default React.memo(Overall);
