import validator from "validator";

 /***************************************************
   functions to validate each form step
*****************************************************/
const ignoreWhiteSpace = { ignore_whitespace : true } ;

export const validateStep1 = ( formData ) => {

    let validated = false
    let errors = {}     
    const start_date = formData.start_date === null ? '' : formData.start_date.toString()
    const end_date = formData.end_date === null ? '' : formData.end_date.toString()

    if(validator.isEmpty(formData.project_name , ignoreWhiteSpace)){
        errors.project_name = "project name can't be empty" ;          
    } 
     
    else if(validator.isEmpty(formData.company_id , ignoreWhiteSpace)){
      errors.company_id = "please select a company" ;          
    } 
     else if( validator.isEmpty(formData.project_description, ignoreWhiteSpace) ){
        errors.project_description = "project description can't be empty" ;        
     }

     else if( validator.isEmpty(start_date, ignoreWhiteSpace)){
                  errors.start_date_and_time = "start date and time can't be empty" ;        
          }
    
    else if( validator.isEmpty(end_date, ignoreWhiteSpace)){
              errors.end_date_and_time = "end date and time can't be empty" ;        
      }    
     else validated = true;

     return { validated , errors };
} 

export const validateStep2 = ( formData ) => {

    let validated = true;
    let errors = {}      
    let emails = formData.participants.map( participant => participant.email )
    let isDuplicateEmail = emails.length !== new Set( emails ).size

    formData.participants.forEach( (participant,id) => {        
         if(  validator.isEmpty(participant.name, ignoreWhiteSpace)
              ||  validator.isEmpty(participant.email, ignoreWhiteSpace) 
              ||  validator.isEmpty(participant.dev_coach_name, ignoreWhiteSpace) 
              ||  validator.isEmpty(participant.dev_coach_email, ignoreWhiteSpace)          
              ||  validator.isEmpty(participant.country, ignoreWhiteSpace)
              ||  validator.isEmpty(participant.department, ignoreWhiteSpace)
              ||  validator.isEmpty(participant.subsidiary, ignoreWhiteSpace)   
              ||  validator.isEmpty(participant.branch, ignoreWhiteSpace)   
            )
         {              
             errors.participant = `each field in every row is required found empty field  at row  ${id + 1} `
             validated = false ; 
         }  
          //validation for bulk upload for participants dropdown fields
         else if( 
                 !formData.countries.map(c => c.toLowerCase()).
                  includes(participant.country.toLowerCase()) 
              ) {
            errors.participant = `Selected country name "${participant.country}" at row  ${ id + 1 } is not an authorized country`
            validated = false ; 
         }  
         else if( 
               !formData.departments.map(c => c.toLowerCase()).
               includes(participant.department.toLowerCase()) 
            ) {
               errors.participant = `Selected department "${participant.department}" at row  ${ id + 1 } is invalid`
               validated = false ; 
            }  
            else if( 
               !formData.branches.map(c => c.toLowerCase()).
               includes(participant.branch.toLowerCase()) 
            ) {
               errors.participant = `Selected branch "${participant.branch}" at row  ${ id + 1 } is invalid`
               validated = false ; 
            } 
            else if( 
               !formData.subsidiaries.map(c => c.toLowerCase()).
               includes(participant.subsidiary.toLowerCase()) 
            ) {
               errors.participant = `Selected subsidiary "${participant.subsidiary}" at row  ${ id + 1 } is invalid`
               validated = false ; 
            }             
         else if( !validator.isEmail(participant.email) ) {
            errors.participant = `invalid email address at row ${ id + 1 }`
            validated = false ; 
         }                  
         
         else if( !validator.isEmail(participant.dev_coach_email) ) {
            errors.participant = `invalid development coach email address at row ${ id + 1 }`
            validated = false ; 
         }                                                             
    });    

     return { validated , errors };
} 

export const validateEvaluatorForParticipants = (evaluators, uploadedParticipants ) => {

   let validated = true;
   let errors = {}      
   let emails = evaluators.map( evaluator => evaluator.participantEmail )
   let isDuplicateEmail = emails.length !== new Set( emails ).size

   evaluators.forEach( (evaluator,id) => {        
        if(  validator.isEmpty(evaluator.participantEmail, ignoreWhiteSpace)
              ||  validator.isEmpty(evaluator.evaluatorName, ignoreWhiteSpace) 
             ||  validator.isEmpty(evaluator.evaluatorEmail, ignoreWhiteSpace)                              
           )
        {              
            errors.evaluator = `each field in every row is required found empty field  at row  ${id + 1} `
            validated = false ; 
        }    
        else if( !validator.isEmail(evaluator.participantEmail) ) {
           errors.evaluator = `invalid participant email address at row ${ id + 1 }`
           validated = false ; 
        }                  
         else if( 
            !uploadedParticipants.map(c => c.email.toLowerCase()).
            includes(evaluator.participantEmail.toLowerCase()) 
         ) {
            errors.evaluator = `Error! participant email address '${evaluator.participantEmail}' at row ${ id + 1 } does not exist.`
           validated = false ; 
        } 
        else if( !validator.isEmail(evaluator.evaluatorEmail) ) {
           errors.evaluator = `invalid evaluator email address at row ${ id + 1 }`
           validated = false ; 
        }         
        
        else if( evaluator.evaluatorRole !== 'self' && evaluator.evaluatorRole !== 'manager' &&
                evaluator.evaluatorRole !== 'peer' && evaluator.evaluatorRole !== 'direct report'
             ){
                errors.evaluator = `invalid evaluator role at row ${ id + 1 }. valid roles are
                                    manager, peer, direct report, self but ${evaluator.evaluatorRole} given`
                validated = false ;
             }
   });    

    return { validated , errors };
}

export const validateStep3 = ( formData ) => {

   let validated = false
   let errors = {}         

   if(validator.isEmpty(formData.sender_email , ignoreWhiteSpace)){
       errors.sender_email = "sender email address can't be empty" ;          
   } 
    else if( !validator.isEmail(formData.sender_email) ){
       errors.sender_email = "Invalid email address" ;        
    }

    else if( validator.isEmpty(formData.email_reply_to, ignoreWhiteSpace)){
                 errors.reply_to = "reply to email can't be empty" ;        
         }
   
   else if( validator.isEmpty(formData.email_subject, ignoreWhiteSpace)){
             errors.email_subject = "email subject can't be empty" ;        
     }    
    else validated = true;

    return { validated , errors };
} 