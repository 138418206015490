import { useQuery } from "react-query";
import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

async function getter(url) {
  let res = await instance.get(url);
  return res.data;
}

export const useFetchCountries = () => {
  const queryCountry = useQuery(["fetch-country"], () =>
    getter(`/company/country`)
  );
  return queryCountry;
};
export const useFetchSubsidiary = () => {
  const querySubsidiary = useQuery(["fetch-subsidiary"], () => getter(``));
  return querySubsidiary;
};
export const useFetchBranch = () => {
  const queryBranch = useQuery(["fetch-Branch"], () =>
    getter(`/company/branches`)
  );
  return queryBranch;
};

export const useFetchDepartment = () => {
  const queryDepartment = useQuery(["fetch-Department"], () =>
    getter(`/company/department`)
  );
  return queryDepartment;
};

//   export const useFetch=()=>{
//     const query = useQuery(["fetch-"], () =>
//     getter(``)
//   );
//   return query
//   }
