import React, { useEffect } from "react";
export default function convertToDomainDetails(
  payload,
  filterState = "country"
) {
  console.log({ payload });
  //payload is an array
  let left = [];
  let right = [];

  if (payload?.constructor !== Array) {
    return { left, right };
  }
  for (let i = 0; i < payload.length; i++) {
    if (i % 2 === 0) {
      left.push({
        title: payload[i].domain,
        summary: "Lorem Ipsum category",

        imageList: [
          ...payload[i].country.map((data) => ({
            score: data.total_obtained_score + "%",
            src: "/assets/france.png",
          })),
          //   { score: "50%", src: "/assets/france.png" },
          //   { score: "30%", src: "/assets/ghana.png" },
          //   { score: "20%", src: "/assets/senegal.png" },
          //   { score: "85.5%", src: "/assets/9ija.png" },
        ],
      });
    } else {
      right.push({
        title: payload[i].domain,
        summary: "Lorem Ipsum category",

        imageList: [
          ...payload[i].country.map((data) => ({
            score: data.total_obtained_score + "%",
            src: "/assets/france.png",
          })),
          //   { score: "50%", src: "/assets/france.png" },
          //   { score: "30%", src: "/assets/ghana.png" },
          //   { score: "20%", src: "/assets/senegal.png" },
          //   { score: "85.5%", src: "/assets/9ija.png" },
        ],
      });
    }
  }
  console.log({ emi: { left, right } });
  return { left, right };
}
