import React, { useState } from "react";
import "./Participant.css";
import Navbar from "../admin/layouts/Navbar";
import Campaign from "./Campaign/Campaign";

import { Route, Routes, Navigate } from "react-router-dom";
// import Sidebar from "../admin/layouts/Sidebar";
import Feedback from "./Feedback/Feedback";
import Sidebar from "../../components/SideBar/SideBar";
import Assessment from "./Assessment/Assessment";
import Profile from "./Profile/Profile";
function Participant() {
  const [user, setUser] = useState(localStorage.getItem("username"));
  const [progress, setProgress] = useState(
    localStorage.getItem("progress") || 0
  );
  const sidebarLinks = [
    {
      link: "/participant/campaign",
      label: "Campaign",
      icon: "bi-building",
    },
    {
      link: "/participant/profile",
      label: "Profile",
      icon: "bi-building",
    },
  ];
  const links = [
    {
      title: "Campaign",
      icon: "bi-building",
      path: "campaign",
      link: "/participant/campaign",
    },
    {
      title: "Profile",
      icon: "bi-building",
      path: "profile",
      link: "/participant/profile",
    },
  ];
  return (
    <div className="participant">
      <Navbar user={user} isAdmin={false} links={links} />
      <section className="md:flex justify-between mt-3">
        <Sidebar
          links={sidebarLinks}
          className="participant__Sidebar w-[20%]  bg-gray-200"
        />
        <div className="w-full md:w-[79%] participant__Content">
          <Routes>
            <Route path="/campaign" element={<Campaign />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/assessment" element={<Assessment />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </div>
      </section>
    </div>
  );
}

export default Participant;
