import React from 'react'
import axios from 'axios'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import swal from 'sweetalert'

const EditComapany = ({company,target,setCompaniesList,companiesList}) => {
  
    const [ loading , setLoading ] = React.useState(false)
    const [ companyInfo , setCompanyInfo ] = React.useState({
        company_name:  company.company_name,
        industry_type: company.industry_type,         
        phone_number: company.phone_number,
        address: company.address
    })
    const [ error , setError ] = React.useState([])

    const handlenput = (e) => {
        e.preventDefault()
        setCompanyInfo({...companyInfo , [e.target.name] : e.target.value})
    }

    const validator = () => {

        let validated = false;

        if( companyInfo.company_name === '' ){
            setError({ company_name : 'Company name cannot be empty' })
        }
        else if( companyInfo.industry_type === '' ){
            setError({ industry_type : 'Industry type cannot be empty' })
        }
        else if( companyInfo.phone_number.toString() === '' ){
            setError({ phone_number : 'Contact number cannot be empty' })
        }
        else if( companyInfo.address === '' ){
            setError({ address : 'Company address cannot be empty' })
        }
        else validated = true

        return {validated};
    }

    const editCompany = () => {

        let _validator = validator();

       if(_validator.validated){
        
            setError([])
            document.querySelector('#close-edit-company-modal' + company.id).click()
            setLoading(true)

            axios.patch(`${ process.env.REACT_APP_BASE_URL }/company/${ company.id }`, companyInfo )      
            .then( response => {
              if( response.data.success){
                    //get companies list after update
                    axios.get(`${ process.env.REACT_APP_BASE_URL }/company/all`)
                    .then(
                    res => {
                            if( res.data.success ){
                                setCompaniesList(res.data.data)                    
                                setLoading(false)
                                swal('Success!', 'Company updated successfully.' , 'success')
                            }
                    }
                    )                  
              }
         })  
       }   
    }

  return (
    <>  { loading && <LoadingSpinner /> }
    <div className='modal fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto' id={ target } tabIndex='-1' aria-labelledby='exampleModalLgLabel' aria-modal='true' role='dialog'>
       <div className='modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered  relative w-auto pointer-events-none'>
          <div className='modal-content -mt-[100px] sm:mt-0 border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
            <div className='modal-header bg-green-small text-white flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
            <h5 className='text-xl bi bi-pencil font-medium leading-normal' id='exampleModalLgLabel'>
                &nbsp;  <span className='text-[16px]'> Edit Company Details </span>
            </h5>
            <button type='button' id={'close-edit-company-modal' + company.id }
                className='text-white text-2xl bi bi-x-lg'
                data-bs-dismiss='modal' aria-label='Close'                
                ></button>
            </div>
            <div className='modal-body relative p-4 px-8'>
                <div className='flex items-center md:w-[90%] xl:w-[80%] mt-5'>
                     <span className='mr-5'> Company Name: </span>
                     <input 
                          type='text' className='admin-form-input' 
                          name='company_name'
                          value={ 
                                 companyInfo.company_name
                             } 
                          onChange = { handlenput }
                     />
                </div>
                <p className='text-[13px] mt-3 text-center text-red-500'> { error.company_name } </p>
                <div className='flex items-center md:w-[90%] xl:w-[80%] mt-5'>
                     <span className='mr-5'> Contact Number: </span>
                     <input 
                          type='number' className='admin-form-input' 
                          name='phone_number'                           
                          value={ 
                               companyInfo.phone_number 
                          } 
                          onChange = { handlenput }
                     />
                </div>
                <p className='text-[13px] mt-3 text-center text-red-500'> { error.phone_number } </p>
                <div className='flex items-center md:w-[90%] xl:w-[80%] mt-5'>
                     <span className='mr-5'> Industry Type: </span>
                     <select class='industry-type-input form-select form-select-md'
                             name='industry_type'                               
                             value={ 
                                companyInfo.industry_type  
                             } 
                             onChange = { handlenput }
                             aria-label='.form-select-sm example'
                       >
                            <option value=''> Select industry type </option>
                            <option value='Manufacturing'>Manufacturing</option>
                            <option value='Technology'>Technology</option>
                            <option value='Media'>Media</option>
                            <option value='Telecommunication'>Telecommunication</option>
                            <option value='Healthcare & Pharmaceuticals'> Healthcare & Pharmaceuticals </option>
                            <option value='Power & Utilities'>Power & Utilities</option>
                            <option value='Financial Services'>Financial Services</option>
                            <option value='Agriculture'>Agriculture</option>
                            <option value='Oil & Gas'>Oil & Gas</option>
                            <option value='Professional Services'>Professional Services</option>
                            <option value='Transportation'>Transportation</option>                        
                      </select>
                </div>
                <p className='text-[13px] text-center mt-3 text-red-500'> { error.industry_type } </p>
                <div className='flex items-center md:w-[90%] xl:w-[80%] mt-5'>
                     <span className='mr-5'> Company Address: </span>
                     <textarea
                          className='admin-form-input h-[140px]' 
                          name='address'                          
                          value={ 
                            companyInfo.address  
                          } 
                          onChange = { handlenput }
                     />                     
                </div>
                <p className='text-[13px] text-center mt-3 text-red-500'> { error.address } </p>
                <div className='flex justify-end mt-5'>
                      <button className='text-white px-16 py-2 rounded-lg text-sm bg-green-small' onClick={ editCompany }>
                         Update <span className='bi bi-arrow-right'>  </span>
                      </button>
                </div>
            </div>
         </div>
       </div>
    </div>
   </>
  )
}

export default EditComapany