import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ExistingCompanies from './components/ExistingCompanies'

const CreateProject = () => {

    const { hasCompany,adminType } = useSelector( state => state.adminLogin )    

    return (
        <section className='md:mr-2 h-full'>
            <h1 className='py-3 text-xl text-white bg-green-small font-semibold pl-3 uppercase'> 
                Setup A New Campaign
             </h1>
            <div className={ (adminType !== "client admin" && hasCompany ) ? 'mt-[4rem] md:mt-[6rem]' : 'mt-[7rem] md:mt-[12rem]' }>
                  <div className='block md:w-[50%] mx-auto text-center'>
                       <span className='bi bi-building text-8xl text-gray-600'></span>
                       <h5 className='text-xl mt-2 font-semibold'> Create Campaign </h5>
                       <p className='text-md mt-2 mb-5 px-3'>
                         Set up a new campaign and add participants to get started.
                       </p>
                       <Link className='bg-red-500 py-2 px-8 text-white rounded-full bi bi-plus'
                            to={'/admin/add-new-campaign'}
                        >
                            Create Campaign
                       </Link>
                  </div>
            </div>
            {    (adminType !== "client admin" && hasCompany ) &&
                  <>
                     <h5 className='text-center text-xl mt-12'> OR </h5>
                    <p className='block md:w-[50%] mx-auto text-center mt-1 px-2'>
                        View campaign for an existing company.
                    </p>
                    <ExistingCompanies />
                  </>
           }
        </section>
      )
}

export default CreateProject