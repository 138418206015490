import React, { useState } from 'react'
import axios from 'axios'
import Spinners from '../Spinners'
import { useSelector, useDispatch } from 'react-redux'
import { updateInput , updateAdminLogo } from '../../../../../../features/create-company/create-company-slice'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Form1 = () => {

  const [ loading, setLoading ] = useState(false)
  const [ isValidImage, setIsValidImage ] = useState( { status : true , message : '' } )

  const { formData , errors }  = useSelector( state => state.createCompanyForm )
  const dispatch = useDispatch()

 
  const handleChange = e => {
    dispatch( updateInput({ name : e.target.name , value : e.target.value }) )
  }

 const uploadLogo = event => {

      let company_logo = event.target.files[0]
       const reader = new FileReader();

      //------------ Event handler for when file reading is complete ---------------//
      reader.onload = (e) => {

        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;

          // ------------- Check the dimensions of the image -----------------------//
          if (width > 200 || height > 200) {
             setIsValidImage({ 
                status : false, 
                message :  `Error! Image dimension should not be greater than 200X200`
             })
          }
        else if( !company_logo.type.toString().toLocaleLowerCase().endsWith('png') &&
          !company_logo.type.toString().toLocaleLowerCase().endsWith('jpg') &&
          !company_logo.type.toString().toLocaleLowerCase().endsWith('jpeg')
        ){            
            setIsValidImage({ status : false, message : 'Invalid image file.' })
         }
       else if( (company_logo.size / 1024 ) > 500 ){
         setIsValidImage({ status : false, message : 'company logo must not be greater than 500kb' })
       }       
       else{
        setIsValidImage({ status : true, message : '' })
          setLoading(true)
          let _formData = new FormData();       
          _formData.append('file',company_logo)
          //send request to upload image
          axios.post( `${process.env.REACT_APP_BASE_URL}/company/upload-logo`,_formData )
          .then( response => {
              if(response.data.success){
                  dispatch( updateInput({ name : 'company_logo' , value : response.data.filePath }) )
                  dispatch( updateAdminLogo(response.data.filePath ) )
              }
              setLoading(false)
          } ) 
      } 
        };
        
        // Set the source of the image to the file
        img.src = e.target.result;
      };
      // Read the file as a data URL
      reader.readAsDataURL(company_logo);                  
  }

  return (
    <form>
        <h5 className='text-md font-semibold mb-4'> Step 2: Company Details </h5>
        
        <label htmlFor='name' className='text-sm'> Address: </label>
        <input 
              type='text' 
              name='address'
              className='admin-form-input'
              value={ formData.address }
              onChange = { handleChange }            
            />
          <div className='text-red-500 text-sm mt-2'> { errors.address } </div>
         <br />

        <label htmlFor='name' className='text-sm'> Business email suffix: </label>
        <input 
              type='text' 
              name='email_suffix'
              className='admin-form-input'
              value={ formData.email_suffix }
              onChange = { handleChange }  
              placeholder='E.g business.com'          
            />
          <div className='text-red-500 text-sm mt-2'> { errors.email_suffix } </div>
         <br />

        <label htmlFor='name' className='text-sm'> Business Number: </label>
            <PhoneInput
              country={'ng'}
              disableCountryCode={true}
              value={formData.phone_number}
              containerClass='admin-form-input'
              inputStyle={{ border: 'none', paddingTop:0, paddingBottom:0 }}
              buttonStyle = { { height: '40px', marginTop: 9 }}
              regions='africa'
              enableSearch={true}
              onChange={phone => dispatch( updateInput({ name : 'phone_number' , value : phone }) )}
            />
         <div className='text-red-500 text-sm mt-2'> { errors.phone_number } </div>
          <br />
         <label htmlFor='name' className='text-sm'> Generic password: </label>
         <p className='text-xs text-gray-700 pb-3'>
            Users will be prompted to change their password at first login.
         </p>
        <input 
              type='password'  
              name='default_password'
              className='admin-form-input'
              value={ formData.default_password }
              onChange = { handleChange }      
              min = { 0 }                   
            />
         <div className='text-red-500 text-sm mt-2'> { errors.default_password } </div>

        <div className='mt-5 text-sm'>             
            <span className='mr-2'> Note:</span>
            <i className='text-gray-700 text-xs'>Company logo must be jpg or png only,
               max upload size is 500kb with dimension 200X200 or less.
            </i>
            <div className='mt-5 flex justify-between items-center'>
               {
                  //display a preview when user uplaod a logo
                  !formData.company_logo ? 
                  <span className='image-icon-wrapper'>  
                      <i className='bi bi-image text-gray-400 text-5xl'></i>
                  </span>
                  :
                  <img 
                     src={formData.company_logo} 
                     alt="company logo" 
                     className=' w-[100px] h-[90px]'
                   />
                }
                <div>
                    {
                      !loading ?
                       <>
                          <input 
                                 type='file' onChange={ uploadLogo }
                                  name='file' className='hidden' id='company_logo' 
                           />
                           <label htmlFor='company_logo' className='upload-btn mr-3'>
                               <i className='bi bi-upload text-md mr-2 '></i> Upload
                           </label>
                       </>
                       :
                        <Spinners className={ 'flex justify-center items-center space-x-2 bg-green-500 py-2 rounded-full px-8' } />
                    }
                </div>
            </div>
            <div className='text-red-500 text-sm mt-2'> 
                  { formData.company_logo ? '' : errors.company_logo } 
                  { !isValidImage.status && isValidImage.message }
            </div>
        </div>
    </form>
  )
}

export default Form1