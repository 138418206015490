import React, { useState } from "react";
import {
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import TabLinks from "./component/TabLinks";
import Header from "./component/Header";
import Competency from "./component/Competency/Competency";
import Strength from "./component/Strength/Strength";
import { tabHeaders } from "../../../../data/reportDummyData";
import Overall from "./component/Overall/Overall";
import KeyQuestions from "./component/KeyQuestions/KeyQuestions";
import { ReportContext } from "context/ReportDashboardContext";

const tabOptions = [
  {
    value: "1",
    children: <Overall />,
  },
  {
    value: "2",
    children: <Competency />,
  },
  {
    value: "3",
    children: <KeyQuestions />,
  },
  {
    value: "4",
    children: <Strength />,
  },
];

function ReportDashboard() {
  const [initialValue, setInitialValue] = useState({
    country: [],
    subsidiary: [],
    state: [],
    branch: [],
    domain: [],
    projectId: "",
    filterState: "country",
  });
  const [activeTab, setActiveTab] = useState(tabHeaders[0]?.value || "");
  const changeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [filterState, setFilterState] = useState("country");
  const handleFilterState = (event, newEvent) => {
    setFilterState(newEvent);
    setInitialValue((val) => ({ ...val, filterState: newEvent }));
  };
  return (
    <ReportContext.Provider value={{ initialValue, setInitialValue }}>
      <Container>
        <Header />
        <ToggleButtonGroup
          value={filterState}
          exclusive
          onChange={handleFilterState}
          aria-label="text alignment"
        >
          <ToggleButton value="country" aria-label="left aligned">
            <Typography>Country</Typography>
          </ToggleButton>
          <ToggleButton value="subsidiary" aria-label="left aligned">
            <Typography>Subsidiary</Typography>
          </ToggleButton>
          <ToggleButton value="state" aria-label="left aligned">
            <Typography>State</Typography>
          </ToggleButton>
          <ToggleButton value="branch" aria-label="left aligned">
            <Typography>Branch</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        <TabLinks
          value={activeTab}
          handleChange={changeTab}
          tabHeaders={tabHeaders}
          tabOptions={tabOptions}
          filterState={filterState}
        />
      </Container>
    </ReportContext.Provider>
  );
}
export default ReportDashboard;
