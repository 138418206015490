
export const formDataRoles = roles => {
    return [
            ...roles.map( role => {
            return {
                        project_role : role.role.project_role,
                        min_response : role.role.min_response,
                        max_response : role.role.max_response
                }
          } )
     ]
}