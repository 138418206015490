import React from 'react'

const FeedbackTable = () => {
  return (
    <>
         <div className='flex flex-col'>
                    <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
                        <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
                        <div className='overflow-hidden'>
                            <table className='min-w-full text-center'>
                               <thead>
                                    <tr>                                         
                                        <th scope='col' className='text-[13px] text-left font-medium text-gray-900 px-3 py-2'>
                                             RELATIONSHIP
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-3 py-2'>
                                            NOMINATED
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-3 py-2'>
                                             COMPLETED
                                        </th>                                        
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-3 py-2'>
                                             RESPONSE RATE
                                        </th>                                          
                                    </tr>
                            </thead>
                            <tbody>                                                           
                                <tr  className=''>                                     
                                    <td className='text-sm text-gray-700 font-light px-3 py-2 whitespace-nowrap'>
                                         <div className='flex items-center ml-5'>
                                             <span className='flex items-center justify-center w-[35px] h-[35px] text-white rounded-full bg-yellow-500'>
                                                S
                                             </span>
                                             <span className='text-sm ml-2'> Self </span>
                                         </div>
                                    </td>
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                         1
                                    </td>
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                         3
                                    </td>                                                    
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                        100%
                                    </td>                                                    
                                </tr>                                               

                                <tr  className=''>                                     
                                    <td className='text-sm text-gray-700 font-light px-3 py-2 whitespace-nowrap'>
                                         <div className='flex items-center ml-5'>
                                             <span className='flex items-center justify-center w-[35px] h-[35px] text-white rounded-full bg-green-small'>
                                                M
                                             </span>
                                             <span className='text-sm ml-2'> Manager </span>
                                         </div>
                                    </td>
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                         6
                                    </td>
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                         1
                                    </td>                                                    
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                        100%
                                    </td>                                                    
                                </tr>        

                                <tr  className=''>                                     
                                    <td className='text-sm text-gray-700 font-light px-3 py-2 whitespace-nowrap'>
                                         <div className='flex items-center ml-5'>
                                             <span className='flex items-center justify-center w-[35px] h-[35px] text-white rounded-full bg-gray-800'>
                                                P
                                             </span>
                                             <span className='text-sm ml-2'> Peers </span>
                                         </div>
                                    </td>
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                         4
                                    </td>
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                         7
                                    </td>                                                    
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                        100%
                                    </td>                                                    
                                </tr>        

                                <tr  className=''>                                     
                                    <td className='text-sm text-gray-700 font-light px-3 py-2 whitespace-nowrap'>
                                         <div className='flex items-center ml-5'>
                                             <span className='flex items-center justify-center w-[35px] h-[35px] text-white rounded-full bg-red-500'>
                                                D
                                             </span>
                                             <span className='text-sm ml-2'> Direct Repoer </span>
                                         </div>
                                    </td>
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                         4
                                    </td>
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                         5
                                    </td>                                                    
                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                        100%
                                    </td>                                                    
                                </tr>        

                             </tbody>
                           </table>
                        </div>
                      </div>
                 </div>
          </div>
    </>
  )
}

export default FeedbackTable