import React from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import { MultiStepForm, Step } from 'react-multi-form'
import DeleteCampaign from './DeleteCampaign'
import ActivateCampaign from './ActivateCampaign'
import { useNavigate } from 'react-router-dom'
import ActionDropdown from './ActionDropdown'
import EditCampaign from './EditCampaign'
import {
  Modal,
  Ripple,
  initTWE,
} from "tw-elements";

const Campaigns = ({campaigns}) => {

  const navigate = useNavigate()
  const navigateTodashboard = (campaign) => navigate(`/admin/dashboard?campaign_name=${campaign.project.project_name}&id=${campaign.project.id}`)
 
 React.useEffect( () => {
  initTWE({ Modal, Ripple });
  }, [] )

  return (
    <>
      { campaigns.map( campaign => {
          if(campaign.project.status === 'draft'){
              return (
                <div key={ campaign.project.id } className='md:flex justify-between items-center py-2 px-1 text-[13px] mt-5 border border-gray-200 rounded-lg'>
                   <span className='ps-1' onClick={() => navigateTodashboard(campaign)} className='hover:cursor-pointer'> { campaign.project.project_name } </span>
                   <div className='md:w-[70%] xl:w-[75%] md:flex justify-between items-center'>
                      <div className='w-[86%] ml-8 md:ml-0 md:w-[80%] mt-4 tracking-stepper'>
                          <MultiStepForm activeStep={ 4 } accentColor = '#668102b3'>
                            <Step label='Campaign'>                
                            </Step>
                            <Step label='Add Participants'>                  
                            </Step>
                            <Step label='Setup Email'>                    
                            </Step>
                            <Step label='Finalise'>                    
                            </Step>
                          </MultiStepForm> 
                      </div>
                      <div className='pr-1 pl-12 sm:pl-0'>                                                    
                          <ActionDropdown>
                             <li className='my-2 px-2'>
                                <ActivateCampaign project = { campaign.project }  />
                             </li>                     
                             <li className='my-2 px-2'>
                             {/*<button 
                                className='md:block bi bi-pencil text-[13px] py-1 text-center mx-auto w-full rounded-lg bg-gray-200 mt-2'
                                data-bs-toggle='modal'
                                id={`delete-campaign-${ campaign.project.id }` }                               
                                data-bs-target={'#edit-campaign' + campaign.project.id } 
                               >
                                 &nbsp; Edit
                             </button> */}                          
                             </li>
                             <li className='my-2 px-2'>
                              <button
                                type="button"
                                className='md:block bi bi-trash text-[13px] text-white py-1 text-center mx-auto w-full rounded-lg bg-red-500 mt-2'
                                data-twe-toggle="modal"
                                data-twe-target={'#delete-campaign' + campaign.project.id } 
                                id={`delete-campaign-${ campaign.project.id }` }
                                data-twe-ripple-init
                                data-twe-ripple-color="light">
                                  &nbsp; Delete
                              </button>                           
                             </li>                              
                          </ActionDropdown>
                          <DeleteCampaign 
                                 target={ 'delete-campaign' + campaign.project.id }
                                  project = { campaign.project }  
                          />
                          <EditCampaign
                            target={ 'edit-campaign' + campaign.project.id }
                            project = { campaign.project } 
                           />
                      </div>
                  </div>
              </div>
            )
         }
         else{
             return(
              <React.Fragment key={ campaign.project.id }>
                <div  className='m-hidden flex justify-between items-center text-[13px] mt-5 border border-gray-200 py-4 px-1'>
                    <div>
                        <span className='block' onClick={() => navigateTodashboard(campaign)} className='hover:cursor-pointer'>{ campaign.project.project_name }</span>
                        <span className='text-gray-700 pt-2 block'> 
                           { new Date(campaign.project.start_date).toLocaleDateString('EN-gb') }
                         </span>
                    </div>
                   <div className='flex justify-between w-[75%] items-center'>
                        <span className='md:ml-4'>
                            { campaign?.participantNumber }
                        </span>
                        <span className='md:ml-12'>
                             {  
                               new Date().getTime() > new Date(campaign.project.end_date).getTime() ?
                                'Closed' : 'Open'
                             }
                        </span>
                        <span>
                        <ProgressBar
                            completed={ campaign.responseRate === null ? 0 : parseInt(campaign.responseRate) }
                            maxCompleted = { 100 }
                            bgColor = '#668102b3'
                            baseBgColor='#ddd'
                            height='20px'
                            width='130px'
                            animateOnRender={ true }                           
                        />
                        </span>
                        <div>                         
                        <span className='text-gray-700 pt-2 text-[12px] flex items-center'>
                             <span className='mr-2'>
                                 { new Date(campaign.project.end_date).toLocaleDateString('EN-gb') } 
                              </span>                           
                            <ActionDropdown>                 
                             <li className='my-2 px-2'>
                             {/*<button 
                                className='md:block bi bi-pencil text-[13px] py-1 text-center mx-auto w-full rounded-lg bg-gray-200 mt-2'
                                data-bs-toggle='modal'
                                id={`delete-campaign-${ campaign.project.id }` }                               
                                data-bs-target={'#edit-campaign' + campaign.project.id } 
                               >
                                 &nbsp; Edit
                            </button>  */}                           
                             </li>
                             <li className='my-2 px-2'>
                             <button
                                type="button"
                                className='md:block bi bi-trash text-[13px] text-white py-1 text-center mx-auto w-full rounded-lg bg-red-500 mt-2'
                                data-twe-toggle="modal"
                                data-twe-target={'#delete-campaign' + campaign.project.id } 
                                id={`delete-campaign-${ campaign.project.id }` }
                                data-twe-ripple-init
                                data-twe-ripple-color="light">
                                  &nbsp; Delete
                              </button>                             
                             </li>                              
                          </ActionDropdown>
                             {
                               window.matchMedia('(min-width:510px)').matches &&
                               <DeleteCampaign 
                                 target={ 'delete-campaign' + campaign.project.id }
                                 project = { campaign.project }  
                               />
                             }
                           <EditCampaign
                            target={ 'edit-campaign' + campaign.project.id }
                            project = { campaign.project } 
                           />
                        </span>
                    </div>
                </div>
              </div>
              <ul className='sm:hidden text-sm mt-5 border border-gray-200 py-4 px-1'>
              <li className='py-2 flex justify-end flex-wrap'>                      
                     <span>  
                     <button
                                type="button"
                                className='md:block bi bi-trash text-[13px] text-white py-1 text-center mx-auto w-full rounded-lg bg-red-500 mt-2'
                                data-twe-toggle="modal"
                                data-twe-target={'#delete-campaign' + campaign.project.id } 
                                id={`delete-campaign-${ campaign.project.id }` }
                                data-twe-ripple-init
                                data-twe-ripple-color="light">
                              </button> 
                            {
                               window.matchMedia('(max-width:500px)').matches &&
                               <DeleteCampaign 
                                 target={ 'delete-campaign' + campaign.project.id }
                                 project = { campaign.project }  
                               />
                             }                           
                     </span>                    
                 </li> 
                 <li className='py-2 flex justify-between flex-wrap'>
                     <span> Campaign name: </span> 
                     <span onClick={() => navigateTodashboard(campaign)} className='hover:cursor-pointer'>  { campaign.project.project_name } </span>                    
                 </li> 
                 <li className='py-2 flex justify-between flex-wrap'>
                     <span>  Start date: </span> 
                     <span>  { new Date(campaign.project.start_date).toLocaleDateString('EN-gb') } </span>                    
                 </li> 
                 <li className='py-2 flex justify-between flex-wrap'>
                     <span> Closed date: </span> 
                     <span>  { new Date(campaign.project.end_date).toLocaleDateString('EN-gb') } </span>                    
                 </li> 
                 <li className='py-2 flex justify-between flex-wrap'>
                     <span> Participants: </span> 
                     <span>  {  campaign?.participantNumber } </span>                    
                 </li> 
                 <li className='py-2 flex justify-between flex-wrap'>
                     <span>  status: </span> 
                     <span>  {  
                               new Date().getTime() > new Date(campaign.project.end_date).getTime() ?
                                'Closed' : 'Open'
                             }
                       </span>                    
                 </li> 
                 <li className='py-2 flex justify-between flex-wrap'>
                     <span>  Response rate: </span>                      
                     <span>
                        <ProgressBar
                            completed={ campaign.responseRate === null ? 0 : parseInt(campaign.responseRate) }
                            maxCompleted = { 100 }
                            bgColor = '#668102b3'
                            baseBgColor='#ddd'
                            height='20px'
                            width='130px'
                            animateOnRender={ true }                           
                        />
                    </span>                    
                 </li> 
              </ul>
             </React.Fragment>
            )
         }
      })}
    </>
  )
}

export default Campaigns