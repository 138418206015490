import React, { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { Box, Grid, Button, Autocomplete, TextField } from "@mui/material";
import { Field, FieldArray, Formik, Form, ErrorMessage } from "formik";
import { subsidiaryOptions } from "data/reportDummyData";
import { v4 as uuidv4 } from "uuid";
import style from "./ProfileForm.module.css";
import {
  useFetchCountries,
  useFetchBranch,
  useFetchSubsidiary,
} from "hooks/api/fetch";
function ProfileForm(props) {
  const { profile, handleClose } = props;
  const profileData = profile?.data?.data;

  const countryList = useFetchCountries();
  const branchList = useFetchBranch();
  const subsidiaryList = useFetchSubsidiary();
  // console.log({
  //   tttt: subsidiaryOptions?.find(
  //     (x) => x?.name?.toUpperCase() === profileData?.subsidiary?.toUpperCase()
  //   ),
  //   cccc: profileData?.subsidiary?.toUpperCase(),
  // });
  const fields = [
    // { name: "name" },
    {
      name: "country",
      type: "select",
      data: countryList?.data?.countries || [],
      defaultValue: countryList?.data?.countries?.find(
        (x) => x?.name?.toUpperCase() === profileData?.country?.toUpperCase()
      ),
    },
    // { name: "company", type: "text" },
    {
      name: "subsidiary",
      type: "select",
      data: subsidiaryOptions,
      defaultValue: subsidiaryOptions?.find(
        (x) => x?.name?.toUpperCase() === profileData?.subsidiary?.toUpperCase()
      ),
    },
    {
      name: "branch",
      type: "select",
      data:
        branchList?.data?.branches?.map((data) => {
          data.name = data.branch_name;
          return data;
        }) || [],
      defaultValue: branchList?.data?.branches
        ?.map((x) => {
          if (
            x.branch_name?.toUpperCase() === profileData.branch?.toUpperCase()
          ) {
            x.name = x.branch_name;
          }
          return x;
        })
        ?.find(
          (x) => x?.name?.toUpperCase() === profileData?.branch?.toUpperCase()
        ),
    },
    { name: "department", type: "text" },
  ];

  async function updateProfile(data) {
    let res = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/project/participant/update/${data.id}`,
      data.data
    );
  }
  const mutateProfile = useMutation(updateProfile);

  if (profile.isLoading || countryList?.isLoading || branchList?.isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Formik
        initialValues={{
          country: profileData?.country || "",
          company: profileData?.company || "",
          subsidiary: profileData?.subsidiary || "",
          department: profileData?.department || "",
          branch: profileData?.branch || "",
        }}
        // validationSchema={yupValidation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          mutateProfile.mutate(
            { data: values, id: localStorage.getItem("participant_id") },
            {
              onSuccess: () => {
                alert("Profile Updated Successfully");
                profile.refetch();
                handleClose();
              },
              onError: () => alert("An Error occured"),
              onSettled: () => {
                setSubmitting(false);
              },
            }
          );
        }}
      >
        {({ values, errors, setFieldValue, isSubmitting }) => (
          <Form className="modal-form">
            {fields.map((field, index) => (
              <Box>
                <label>{field.name} :</label>
                {field.type === "text" ? (
                  <Field
                    name={field.name}
                    type="text"
                    placeholder={field.name}
                    className={style.field}
                    required
                  />
                ) : (
                  <Autocomplete
                    className={style.field}
                    options={[{ name: "Select", value: "" }, ...field.data]}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setFieldValue(field.name, newValue?.name);
                      // console.log({ newValue });
                    }}
                    getOptionDisabled={(option) => option.name === "Select"}
                    defaultValue={
                      field?.defaultValue || { name: "Select", value: "" }
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={uuidv4()}>
                          {option.name}
                          {/* {console.log(option)} */}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        variant="standard"
                        // label={label}
                        // placeholder={label}
                      />
                    )}
                  />
                )}
              </Box>
            ))}
            {/* <ErrorMessage name="project" /> */}
            <Box
              sx={{
                width: "max-content",
                display: "flex",
                margin: "0 auto",
                justifyContent: "space-evenly",
              }}
            >
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={handleClose}
                  mr={2}
                  variant="contained"
                  size="small"
                  color="error"
                >
                  Cancel
                </Button>
              </Box>
              <Button
                type="submit"
                className="modal-submit"
                disabled={isSubmitting}
                size="small"
                // justifyContent={"center"}
                color="success"
                variant="contained"
              >
                {" "}
                {isSubmitting ? "Submitting" : "Submit"}
              </Button>
            </Box>
            <ErrorMessage name="participant" className="error-message" />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProfileForm;
