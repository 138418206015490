import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  flex: {
    flexDirection: "row",
    // flexWrap: "wrap",
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    paddingBottom: 65,
    height: "100vh",
  },
  header: {
    // backgroundColor: "#E4E4E4",
    color: "blue",
    marginBottom: 10,
    fontSize: 16,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  dash: {
    display: "block",
  },
  TOC: {
    display: "grid",
  },
  list: {
    marginLeft: 20,
    marginBottom: 10,
  },
  progressBarContainer: {
    flexDirection: "row",
    height: 15,
    width: "100%",
    maxWidth: "30vw",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 20,
  },
  progressBarBackground: {
    height: "100%",
    backgroundColor: "green",
    borderRadius: 20,
  },
  progressBarText: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    textAlign: "center",
    color: "white",
  },
  avatar: {
    backgroundColor: "orange",
    color: "white",
    borderRadius: "99%",
    padding: 3,
    paddingLeft: 6,
    paddingRight: 6,
    textAlign: "center",
  },
  feedback: {},
  chat: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e3e3e3",
    justifyContent: "center",
    fontSize: 12,
    padding: 10,
    borderRadius: 5,
  },
  detailTable: {
    flexDirection: "row",
    marginBottom: 20,
    backgroundColor: "#e4e4e4",
    padding: 10,
  },
  ratings: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default styles;
