import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./layouts/Navbar";
import Sidebar from "./layouts/Sidebar";
import AddNewCompany from "./pages/add-new-company/AddNewCompany";
import AddProject from "./pages/add-project/AddProject";
import CreateCompany from "./pages/create-company/CreateCompany";
import CreateProject from "./pages/create-project/CreateProject";
import Dashboard from "./pages/dashboard/Dashboard";
import ReportDashboard from "./pages/report-dashboard/ReportDashboard";
import ViewCompany from "./pages/view-company/ViewCompany";
import ViewProjects from "./pages/view-projects/ViewProjects";
import Survey from "./pages/survey/Survey";
import Report from "./pages/reports/Report";
import PageNotFound from "../../components/PageNotFound";

const Admin = () => {
  const isMobileDevice = window.matchMedia("(max-width:500px)").matches;
  const { hasCompany, adminName, isAuth, adminType } = useSelector(
    (state) => state.adminLogin
  );
  const { hasCampaign } = useSelector((state) => state.viewProjects);

  const sidebarLinks = [
    {
      title: "Dashboard",
      icon: "bi-speedometer2",
      path: "dashboard",
      link: "/admin/dashboard",
    },
    {
      title: "Report Dashboard",
      icon: "bi-speedometer2",
      path: "rep-dash",
      link: "/admin/rep-dash",
    },
    {
      title: "Campaign",
      icon: "bi-bag-plus",
      path: "campaign",
      link:
        adminType !== "client admin"
          ? "/admin/create-campaign"
          : "/admin/view-campaigns",
    },
    {
      title: "Survey",
      icon: "bi-pencil-square",
      path: "survey",
      link: "/admin/survey",
    },
    {
      title: "Report",
      icon: "bi-journals",
      path: "report",
      link: "/admin/report",
    },
  ];
  return (
    <div>
      <Navbar
        user={adminName}
        userType="admin"
        isAuth={isAuth}
        links={sidebarLinks}
      />
      <section className="md:flex justify-between mt-3">
        {!isMobileDevice && (
          <Sidebar
            className="w-[18%] bg-gray-200 md-sidebar"
            links={sidebarLinks}
          />
        )}
        <div className="w-full md:w-[81%] overflow-y-auto md-container admin-panel">
          <Routes>
            <Route
              path="/"
              element={
                isAuth ? (
                  hasCompany ? (
                    <Navigate to={"/admin/view-company"} />
                  ) : (
                    <Navigate to={"/admin/create-company"} />
                  )
                ) : (
                  <Navigate to={"/site-admin-login"} />
                )
              }
            />
            {/* prevent client admin from accessing company module */}
            <Route
              path="/create-company"
              element={
                adminType !== "client admin" ? (
                  hasCompany ? (
                    <Navigate to={"/admin/view-company"} />
                  ) : (
                    <CreateCompany />
                  )
                ) : (
                  <Navigate to={"/client-admin-login"} />
                )
              }
            />
            <Route
              path="/add-new-company"
              element={
                adminType !== "client admin" ? (
                  <AddNewCompany />
                ) : (
                  <Navigate to={"/client-admin-login"} />
                )
              }
            />
            <Route
              path="/view-company"
              element={
                adminType !== "client admin" ? (
                  <ViewCompany />
                ) : (
                  <Navigate to={"/client-admin-login"} />
                )
              }
            />

            <Route
              path="/create-campaign"
              element={
                hasCampaign ? (
                  <Navigate to={"/admin/view-campaigns"} />
                ) : (
                  <CreateProject />
                )
              }
            />
            <Route path="/add-new-campaign" element={<AddProject />} />
            <Route path="/view-campaigns" element={<ViewProjects />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/rep-dash" element={<ReportDashboard />} />
            <Route path="/report" element={<Report />} />
            <Route
              path="*"
              element={
                <PageNotFound
                  path="/admin/dashboard"
                  label="Back to dashboard"
                />
              }
            />
          </Routes>
        </div>
      </section>
    </div>
  );
};

export default Admin;
