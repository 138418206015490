export default function convertToStrengthDetails(payload) {
  console.log({ payload });
  //payload is an array
  let left = [];
  let right = [];

  if (payload?.constructor !== Array) {
    return { left, right };
  }
  for (let i = 0; i < payload.length; i++) {
    if (payload[i]?.strength?.countryStrength?.length > 0) {
      left.push({
        title: payload[i]?.competency,
        summary: "Lorem Ipsum category",

        imageList: [
          ...payload[i].strength?.countryStrength?.map((data) => ({
            score: data.total_obtained_score + "%",
            src: "/assets/france.png",
          })),
          //   { score: "50%", src: "/assets/france.png" },
          //   { score: "30%", src: "/assets/ghana.png" },
          //   { score: "20%", src: "/assets/senegal.png" },
          //   { score: "85.5%", src: "/assets/9ija.png" },
        ],
      });
    }
    if (payload[i]?.weakness?.countryWeakness?.length > 0) {
      right.push({
        title: payload[i]?.competency,
        summary: "Lorem Ipsum category",

        imageList: [
          ...payload[i]?.weakness?.countryWeakness?.map((data) => ({
            score: data.total_obtained_score + "%",
            src: "/assets/france.png",
          })),
          //   { score: "50%", src: "/assets/france.png" },
          //   { score: "30%", src: "/assets/ghana.png" },
          //   { score: "20%", src: "/assets/senegal.png" },
          //   { score: "85.5%", src: "/assets/9ija.png" },
        ],
      });
    }
    // }
    // else {
    //   right.push({
    //     title: payload[i].domain,
    //     summary: "Lorem Ipsum category",

    //     imageList: [
    //       ...payload[i].country.map((data) => ({
    //         score: data.total_obtained_score + "%",
    //         src: "/assets/france.png",
    //       })),
    //       //   { score: "50%", src: "/assets/france.png" },
    //       //   { score: "30%", src: "/assets/ghana.png" },
    //       //   { score: "20%", src: "/assets/senegal.png" },
    //       //   { score: "85.5%", src: "/assets/9ija.png" },
    //     ],
    //   });
    // }
  }
  console.log({ emi: { left, right } });
  return { left, right };
}
