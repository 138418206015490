import React from "react";
import "./Button.css";
function Button(params) {
  const { iconLabel, icon, disabled } = params;
  return (
    <div className={`button__Link flex ${disabled}`}>
      {/* <a href="/" className=" sm:h-[90%] "> */}
      <div className="text-md  ">{icon}</div>
      <p className="button__LinkLabel text-[12px] md:text-sm md:text-center ">
        {iconLabel}
      </p>
      {/* </a> */}
    </div>
  );
}

export default Button;
