import React, {useEffect} from 'react'
import {
  Dropdown,
  Ripple,
  initTWE,
} from "tw-elements";

const ActionDropdown = ({ children }) => {
 
  useEffect( () => {
    initTWE({ Dropdown, Ripple });
   }, [] )

  return (
    <>  
       <div class="relative" data-twe-dropdown-ref>
        <button
         className="flex items-center rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-gray-900 shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
         type="button"
         id="dropdownMenuButton1"
         data-twe-dropdown-toggle-ref
         aria-expanded="false"
         data-twe-ripple-init
         data-twe-ripple-color="light">
            <span className='bi bi-chevron-down'></span>
        </button>
      <ul
         className="absolute z-[1000] py-3 w-[130px] h-[100px] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark"
         aria-labelledby="dropdownMenuButton1"
         data-twe-dropdown-menu-ref>
               {children}
            </ul>
        </div>
    </>
  )
}

export default ActionDropdown

 