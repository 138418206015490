import { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Header from "../../../components/Header/Header";

import LoadingSpinner from "../../../components/LoadingSpinner";
import Modal from "./Modal";
import "./Assessment.css";
import axios from "axios";
import Forms from "./Form/Form";
import ThankYou from "../ThankYou/ThankYou";

function Assessment(params) {
  const topPageRef = useRef(null);
  const participant_name = localStorage.getItem("participant_name");
  const {
    deadline = `27/03/2023`,
    setCompletedSurvey,
    isLoadingPage,
    setIsLoadingPage,
    user = localStorage.getItem("username"),
    instructions = [
      `The purpose of this survey is to gather feedback on ${participant_name} performance and behaviour patterns and to help them identify their strengths and areas for improvement.`,
      `Please, carefully read all questions and respond accurately and honestly as possible. If there are any questions that you are not in a position to answer appropriately, it is best to leave them blank.  If you encounter any technical difficulties or have any questions, please contact our support team at 360@TestAssessify.com.`,
    ],
  } = params;
  const dummyData = [
    {
      category: "leadership",
      questions: [
        { id: 1, qusetion: "H", type: "radio" },
        { id: 6, qusetion: "H", type: "radio" },
        { id: 7, qusetion: "H", type: "radio" },
        { id: 2, qusetion: "aT", type: "text" },
        { id: 3, qusetion: "Ta", type: "text" },
        { id: 4, qusetion: "Ta", type: "text" },
      ],
    },
  ];
  const [assessments, setAssessments] = useState({});
  const [page, setPage] = useState(localStorage.getItem("page") ?? 0);
  const [currentStep, setCurrentStep] = useState();
  const [currentProgress, setCurrentProgress] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(
    localStorage.getItem("progress") ?? 0
  );
  const [disableNext, setDisableNext] = useState(false);
  const [disableBack, setDisableBack] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [open, setOpen] = useState(false);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [evaluator, setEvaluator] = useState({});

  const assessmentSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("You have to fill this field"),
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("You have to fill this field"),
    questions: Yup.array().of(
      Yup.object().shape({
        // answer: Yup.string().min(2, "Too Short").required(),
        answer: Yup.lazy((value) => {
          console.log(value, "val");
          return Yup.mixed().notRequired();
        }),
      })
    ),

    email: Yup.lazy((value) => {
      if (value !== undefined) {
        return Yup.string()
          .min(2, "Too Short")
          .required("You have to fill this field");
      }
      return Yup.mixed().notRequired();
    }),
  });

  const participant_id = localStorage.getItem("participant_id");
  const project_id = localStorage.getItem("project_id");
  const evaluator_id = localStorage.getItem("evaluator_id");

  useEffect(() => {
    const request = axios
      .post(
        `
      https://sandboxapi.360degree.testassessify.com/survey/category-question`,
        {
          project_id,
          participant_id,
          evaluator_id,
        }
      )
      .then((e) => {
        setAssessments(e.data);
        setCurrentStep(e.data?.currentStep);
        setCurrentProgress(
          ((e.data?.currentStep - 1) / e.data?.totalStep) * 100
        );
      })
      .catch((e) => setCompletedSurvey(true))
      .finally((e) => {
        setIsLoading(false);
        setIsLoadingPage(false);
      });
  }, [currentStep]);

  const submitFunc = () => {
    if (page === assessments.length - 1) {
      localStorage.setItem("progress", 100);
      setProgress(100);
      setOpen(true);
    }
  };
  
  useEffect(() => {
    if (page < 1) {
      setDisableBack(true);

      // setPage(0);
      // localStorage.setItem("page", 0);
    } else {
      setDisableBack(false);
    }
    if (page === assessments.length - 1) {
      setDisableNext(true);
      setDisableSubmit(false);
      //   console.log(page, assessments.length);
    } else {
      setDisableNext(false);
      //   console.log(page, assessments.length);
      setDisableSubmit(true);
    }

    if (assessments.length) {
      //   console.log(assessments[page]?.questions[page]?.qusetion);
      setProgress((page / assessments.length) * 100);
      localStorage.setItem("progress", (page / assessments.length) * 100);
    }
    if (page < 0) {
      localStorage.setItem("page", 0);
      setPage(0);
    }
  }, [page]);

  function handleFocus(ref) {
    if (ref.current) {
      console.log({ Femmmmi: ref.current });
      ref.current.focus();
    }
  }

  if (isLoadingPage) {
    return <LoadingSpinner />;
  }
  if (openThankYou) {
    return <ThankYou participant={participant_name} />;
  }
  return (
    <div className="assessment md:px-8">
      <Header title="Campaign" />
      <h2 className="assessment__Title text-white mt-5">Evaluator Assessment</h2>
      <div className="block">
        <div className="md:flex justify-between">
          {/* <img src="/assets/preview-evaluator-logo-2.png" alt="Lorem Logo" /> */}
          <img 
             src="/assets/Access-bank-PLC.png" alt="Lorem Logo" 
             className="w-[260px] md:w-[160px] h-[60px] pl-5 md:pl-0"
          />
          <div className="progressbar pl-8 mt-3 md:pl-0">
            <label for="progress">
              Progress: {Math.round(currentProgress)}%
            </label>
            <progress
              className="assessment-progressbar"
              id="progress"
              value={Math.round(currentProgress)}
              max="100"
            ></progress>
            {/* progressbar */}
            <div className="progress-details">
              {/* clock */}
              <p> {/* <span>Survey ends at </span> {deadline} */}</p>
            </div>
          </div>
        </div>

        <div className="assessment__Form">
          <div className="assessment-instruction">
            <h3 className="instruction-title">
              Hello <span>{user}</span>, and welcome to the 360-degree feedback
              survey.
            </h3>
            {instructions.map((instruction, index) => (
              <p className="" key={index}>
                {instruction}
              </p>
            ))}
          </div>

          <div className="score-guide">
            <div className="score score-title">
              <p>Score</p>
              <p>1</p>
              <p>2</p>
              <p>3</p>
              <p>4</p>
              <p>5</p>
            </div>
            <div className="score">
              <p>Frequency</p>
              <p>Almost Never</p>
              <p>Not Very Often</p>
              <p>Some of the time</p>
              <p>Most of the time</p>
              <p>Nearly always</p>
            </div>
            <div className="score">
              <p>Leadership</p>
              <p>Clear Weakness</p>
              <p>Weak</p>
              <p>Good</p>
              <p>Very Good</p>
              <p>Clear Strength</p>
            </div>
            <div className="score">
              <p>Communication</p>
              <p>1-20%</p>
              <p>21-40%</p>
              <p>41-60%</p>
              <p>61-80%</p>
              <p>81-100%</p>
            </div>
          </div>

          <Forms
            buttonLabel="submit"
            data={assessments}
            formKey={assessments?.currentStep}
            title={assessments?.questions?.category}
            questionsList={assessments?.questions?.question}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            totalStep={assessments?.totalStep}
            setOpen={setOpen}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            topPageRef={topPageRef}
            handleFocus={handleFocus}
          />
        </div>
        <Modal
          open={open}
          setOpen={setOpen}
          openThankYou={openThankYou}
          setOpenThankYou={setOpenThankYou}
        />
      </div>
    </div>
  );
}

export default Assessment;
