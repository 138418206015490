import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import "./Dashboard.css";
import Tab from "./Tab/Tab";
import Header from "../../../../components/Header/Header";
import CircularProgress from "../../../../components/CircularProgress/CircularProgress";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Modal from "./component/Modal/Modal";
import MapEvaluator from "./component/MapEvaluator/MapEvaluator";
import { useSearchParams } from "react-router-dom";

function Dashboard() {
  const [open, setOpen] = useState(false);
  const [openEvaluator, setOpenEvaluator] = useState(false);
  const [openCoach, setOpenCoach] = useState(false);
  const [evaluatorAdded, setEvaluatorAdded] = useState(false);
  const [error, setError] = useState(false);
  const companyID = localStorage.getItem("companyID");
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id')

  const fetchData = async (projectId) => {
    let res = await axios.get(
      `https://sandboxapi.360degree.testassessify.com/project/admin-dashboard/${projectId}`
    );
    return await res.data;
  };
  const fetchParticipant = async (projectId) => {
    let res = await axios.get(
      `https://sandboxapi.360degree.testassessify.com/project/participant/all/${projectId}`
    );
    return await res.data;
  };
  const mapEvaluatorFunc = async (data, resetForm) => {
    let res = axios
      .post(
        "https://sandboxapi.360degree.testassessify.com/project/participant/evaluator",
        data
      )
      .then((e) => {
        setEvaluatorAdded(true);
        refetch();
        resetForm();
        setTimeout(() => setEvaluatorAdded(false), 2000);
      })
      .catch((e) => alert(JSON.stringify(e.data)));
  };
  const deleteEvaluatorFunc = async (id, data) => {
    let deleteFile = window.confirm("Are your sure you want to delete?");
    if (deleteFile) {
      let res = await axios
        .delete(
          `https://sandboxapi.360degree.testassessify.com/project/evaluator/${id}`
        )
        .then((e) => {
          refetch().then((e) =>
            alert(`${data.toUpperCase()} Deleted Successfully`)
          );
        })
        .catch((e) => {
          alert("An error occured");
          setError(true);
          setTimeout(() => setError(false), 2500);
        });
      // alert(id);
    }
  };

  const fetchCampaign = async () => {
    let res = await axios.get(
      `https://sandboxapi.360degree.testassessify.com/project/all/${companyID}`
    );

    setProjectId(await res?.data?.campaigns[0]?.project?.id);
    return await res.data;
  };

  const handleChangeCampaign = (e) => {
    setProjectId(e);
    refetch();
    console.log(e, "femmmmi");
  };

  const [projectId, setProjectId] = useState();
  // "40b14649-45cc-44bc-af74-d1f8501e38ac"

  const { data, refetch } = useQuery(
    ["admin-dashboard", projectId],
    () => {
      console.log(projectId, "pjID");
      return fetchData(projectId);
    },
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    }
  );
  const campaignData = useQuery("fetch-campaign", fetchCampaign);

  const participantData = useQuery(["fetch-participant", projectId], () =>
    fetchParticipant(projectId)
  );

  const campaignRef = useRef();

  useEffect(() => {
    if (campaignRef) {
      console.log(campaignRef.current.value, "this is ref");
    }
  }, [campaignRef]);
  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard__Title">
        <Header title=" Dashboard" />
      </div> {console.log(id)}
      <div className="admin-dashboard__Options">
        <div className="admin-options-left">
          <select
            name="select-month"
            id="select-month"
            className="admin-select-month"
            ref={campaignRef}
          >
            {campaignData?.data?.campaigns
              // ?.filter((e) => e?.status === "active")
              ?.map((e) => (
                <option 
                     key={e?.project?.id} 
                     value={e?.project?.id} 
                     selected = { (id !== null && e?.project?.id === id ) ? true : false }
                >
                  {" "}
                  {e?.project?.project_name}
                </option>
              ))}
          </select>
          <button
            className="admin-option-button"
            onClick={(e) => handleChangeCampaign(campaignRef.current.value)}
          >
            {" "}
            Select
          </button>
        </div>
        <div className="admin-options-right">
          <div className="admin-option-create">
            {/* Add Icon */}
            <PersonAddIcon />
          </div>
          <button
            className="admin-option-box"
            onClick={(e) => {
              setOpen(true);
            }}
          >
            Participant
          </button>
          <button
            className="admin-option-box"
            onClick={(e) => {
              setOpenEvaluator(true);
            }}
          >
            Evaluator
          </button>
          <button
            className="admin-option-box"
            onClick={(e) => {
              setOpenCoach(true);
            }}
            disabled
          >
            Development Coach
          </button>
          <Modal
            open={open}
            setOpen={setOpen}
            refetch={refetch}
            projectId={projectId}
            updateParticipant={participantData.refetch}
          />
          <MapEvaluator
            open={openEvaluator}
            setOpen={setOpenEvaluator}
            submitFunc={mapEvaluatorFunc}
            participant={participantData.data?.data}
            isSuccessful={evaluatorAdded}
            setSuccess={setEvaluatorAdded}
            isError={error}
            setError={setError}
            label="Evaluator"
            project_id={projectId}
          />
          <MapEvaluator
            open={openCoach}
            setOpen={setOpenCoach}
            title="Map Development Coach"
            participant={participantData.data?.data}
            label="Development Coach"
          />
        </div>
      </div>

      <div className="admin-dashboard__Statistics">
        <div className="stat-participant">
          <h3>Total Number of Invited Participants</h3>
          <h2> {data?.participants.length || 0}</h2>
          <div className="stat-participant-flex">
            <div className="stat-participant-box">
              <h6 className="gold">Complete</h6>
              <h5>{data?.participantReportCompleted || 0}</h5>
            </div>
            <div className="stat-participant-box">
              <h6 className="yellow">In Progress</h6>
              <h5>{data?.participantReportInProgress || 0}</h5>
            </div>
            <div className="stat-participant-box">
              <h6 className="red">Not Started</h6>
              <h5>{data?.participantReportNotStarted || 0}</h5>
            </div>
          </div>
        </div>

        <div className="admin-stat-progress">
          <div className="stat-progress-box">
            <h6>Total Responses</h6>
            <CircularProgress
              value={
                Math.floor(
                  (data?.surveyReponse.response / data?.surveyReponse.total) *
                    100
                ) || 0
              }
            />
            <p className="">
              <span>{data?.surveyReponse.response || 0}</span> out of{" "}
              {data?.surveyReponse.total || 0}
            </p>
          </div>
          <div className="stat-progress-box">
            <h6>Report Ready</h6>
            <CircularProgress
              value={
                Math.floor(
                  (data?.reportReady.response / data?.reportReady.total) * 100
                ) || 0
              }
            />
            <p className="">
              <span>{data?.reportReady.response || 0}</span> out of{" "}
              {data?.reportReady.total || 0}
            </p>
          </div>
          <div className="stat-progress-box">
            <h6>Self Assessment</h6>
            <CircularProgress
              value={
                Math.floor(
                  (data?.selfAssessment.response / data?.selfAssessment.total) *
                    100
                ) || 0
              }
            />
            <p className="">
              <span>{data?.selfAssessment.response || 0}</span> out of{" "}
              {data?.selfAssessment.total || 0}
            </p>
          </div>
          <div className="stat-progress-box">
            <h6>Manager</h6>
            <CircularProgress
              value={
                Math.floor(
                  (data?.manager.response / data?.manager.total) * 100
                ) || 0
              }
            />
            <p className="">
              <span>{data?.manager.response || 0}</span> out of{" "}
              {data?.manager.total || 0}
            </p>
          </div>
          <div className="stat-progress-box">
            <h6>Peer</h6>
            <CircularProgress
              value={
                Math.floor((data?.peer.response / data?.peer.total) * 100) || 0
              }
            />
            <p className="">
              <span>{data?.peer.response || 0}</span> out of{" "}
              {data?.peer.total || 0}
            </p>
          </div>
          <div className="stat-progress-box">
            <h6>Direct Report</h6>
            <CircularProgress
              value={
                Math.floor(
                  (data?.directReport.response / data?.directReport.total) * 100
                ) || 0
              }
            />
            <p className="">
              <span>{data?.directReport.response || 0}</span> out of{" "}
              {data?.directReport.total || 0}
            </p>
          </div>
        </div>
      </div>

      <div className="admin-dashboard__Table">
        <div className="admin-tab">
          {/* React Tab */}
          {data?.participants.length && (
            <Tab
              data={data?.participants}
              evaluatorData={data?.evaluators}
              deleteEvaluator={deleteEvaluatorFunc}
              refetch={refetch}
            />
          )}
        </div>
        <div className="admin-paginate">{/* react paginate */}</div>
      </div>
    </div>
  );
}

export default Dashboard;
