
export const mailtemplateHeader = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />    
    <meta name="viewport" content="width=device-width, initial-scale=1" />                  
  </head>
  <body>
`

export const mailtemplateFooter = `
    </body>
 </html>
`