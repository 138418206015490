import React from 'react'
import axios from 'axios'
import { useSelector,useDispatch } from 'react-redux'
import { updateProject } from '../../../../../features/view-projects/view-projects-slice'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import swal from 'sweetalert'
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker'
 

const DeleteCampaign = ({ project,target }) => {
    
    const [ loading , setLoading ] = React.useState(false)
    const { company_id }  = useSelector( state => state.viewProjects ) 
    const dispatch = useDispatch()
    const [campaign, setCampaign] = React.useState({
        name: project.project_name,
        closingDate: project.end_date,
    }) 
    const [error, setError] = React.useState('')

    const handleInput = (name, value) => {      
        setCampaign({ ...campaign, [name]: value })      
   }

    const editCampaign = () => {
        if(campaign.name == '') {
            setError(`Error name field can't be empty`)
            swal('Error!',`Error name field can't be empty` , 'error')
        }
        else if(campaign.closingDate.toString() === ''){
            setError(`Error campaing closing date can't be empty`)
            swal('Error!',`Error campaing closing date can't be empty`, 'error')
        }
        else{
            document.querySelector('.close-campaign-delete-modal').click()
            setLoading(true)
            axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/${ project.id }`,{
                end_date: campaign.closingDate,
                project_name: campaign.name
            })
           .then( response => {
                if(response.data.success){                                                                                     
                    axios.get(`${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }`)
                    .then(
                    res => {
                            if( res.data.success ){                                                                               
                                dispatch( updateProject( res.data ) )
                                setLoading(false)  
                                setError('')
                                swal('Success!', 'Campaign updated successfully.' , 'success')
                                const pages = document.querySelectorAll('ul.pagination a') ;
                                pages[1].click()        
                            }
                        })
                }                   
          })  
        }
    }
  
  return (
    <>    { loading && <LoadingSpinner /> }
     <div className='modal fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto' id={ target } tabIndex='-1' aria-labelledby='exampleModalLgLabel' aria-modal='true' role='dialog'>
      <div className='modal-dialog modal-lg modal-dialog-centered  relative w-auto pointer-events-none'>
       <div className='modal-content  border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>         
       <div className='modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
                <h5 className='text-xl bi bi-pencil font-medium leading-normal text-gray-800' id='exampleModalLgLabel'>
                    &nbsp; Edit Campaign
                </h5>
                <button type='button' id='close-participants-modal'
                    className='btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                    data-bs-dismiss='modal' aria-label='Close'></button>
        </div>
         <div className='modal-body relative p-4 px-8 text-center'>
             <div className='pt-3'>
                 <div>
                     <div className='flex items-center'>
                         <span className='mr-3'>Campaign Name: </span>
                           <div className='w-[270px]'>
                                <input
                                    className='admin-form-input'
                                    value={campaign.name}
                                    onChange={ (e) => handleInput('name', e.target.value)}
                                />
                           </div>
                     </div>
                     <div className='flex items-center mt-5'>
                         <span className='mr-3'>Campaign Closing Date: </span>
                         <DateTimePicker 
                                onChange = { (value) => handleInput('closingDate',value) }
                                value = { new Date(campaign.closingDate) }
                                name='end_date'
                                className = 'date-picker'
                                calendarIcon = {
                                        <span   className=' bi bi-calendar text-red-500'></span>
                                    }
                                format = 'dd-MM-y h:mm:ss a'     
                                dayPlaceholder = 'dd'                                                                                
                                monthPlaceholder = 'mm'
                                yearPlaceholder = 'yyyy'
                                hourPlaceholder = 'h'
                                minutePlaceholder = 'm'
                                secondPlaceholder = 's'                                                                                                                        
                      />
                     </div>
                 </div>
                 {  error && <p className='text-md pl-3 my-3 text-red-500'>{error}</p>}
                <div className='mt-8 flex justify-end mb-3'>                                                  
                    <button className='py-2 text-sm px-8  close-campaign-delete-modal bg-gray-200 rounded-lg' data-bs-dismiss='modal' aria-label='Close'>
                        Cancel
                    </button>
                    <button
                            className='py-2 text-sm ml-3 px-8 text-white bg-green-small rounded-lg' 
                            data-bs-dismiss='modal' aria-label='Close'
                            onClick={ editCampaign }
                        >
                        Edit
                    </button>
                </div>  
             </div>       
         </div>
        </div>
      </div>
     </div>
   </>
  )
}

export default DeleteCampaign