import React, { useState } from "react";
import { MultiStepForm, Step } from "react-multi-form";
import FormWrapper from "./components/forms/FormWrapper";
import { Link } from "react-router-dom";
import {
  validateStep1,
  validateStep2,
  validateStep3,
} from "./components/validation/formValidation";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import {
  moveToNextStep,
  setErrors,
  moveToPreviousStep,
  clearErrors,
  serverError,
  clearCreateCompanySlice,
} from "../../../../features/create-company/create-company-slice";

const AddNewCompany = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { formData, step } = useSelector((state) => state.createCompanyForm);
  const dispatch = useDispatch();

  const handleNextStep = () => {
    if (step < 4) {
      if (step === 1) {
        const { validated, errors } = validateStep1(formData);
        if (validated) dispatch(moveToNextStep());
        else dispatch(setErrors(errors));
      }

      if (step === 2) {
        const { validated, errors } = validateStep2(formData);
        if (validated) dispatch(moveToNextStep());
        else dispatch(setErrors(errors));
      }
    }
  };

  const handlePrevStep = () => {
    dispatch(moveToPreviousStep());
    dispatch(clearErrors());
  };

  const handleSubmit = () => {
    const { validated, errors } = validateStep3(formData);
    if (!validated) dispatch(setErrors(errors));
    // submit form data after validation
    else {
      setLoading(true);
      dispatch(clearErrors());
      //sent company details to the backend
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/company`, formData)
        .then((response) => {
          if (response.data.success) {
            setSuccess(true);
            setLoading(false);
            dispatch(clearCreateCompanySlice());
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            swal("Oops!", error.response.data.error, "error");
            dispatch(serverError());
            setLoading(false);
          }
        });
    }
  };

  return (
    <section className="md:mr-2 h-full">
      <h1 className="py-3 mt-3 text-xl text-white bg-green-small font-semibold pl-3 uppercase">
        Add New Company{" "}
      </h1>
      <div className="mt-[3rem] mx-auto w-[96%] md:w-[44%] p-8 rounded-md border border-gray-200">
        <MultiStepForm activeStep={step} accentColor="#d43417">
          <Step label="Step 1"></Step>
          <Step label="Step 2"></Step>
          <Step label="Step 3"></Step>
        </MultiStepForm>

        <div className="form-wrapper">
          {
            //hide multistep form once  registration is successful
            success ? (
              <div className="mt-12 text-center">
                <i className="text-6xl text-green-500 bi bi-check2-circle"></i>
                <h2 className="mt-2 font-semibold text-xl text-center">
                  {" "}
                  Success{" "}
                </h2>
                <p className="mt-5 text-sm mb-8 text-gray-800">
                  Company created successfully an email with credentials is sent
                  to company admin
                </p>
                <Link
                  className="bg-green-500 text-white font-semibold py-2 text-md px-8 rounded-lg"
                  to={"/admin/view-company"}
                >
                  Ok
                </Link>
              </div>
            ) : (
              <FormWrapper />
            )
          }
          {!success && (
            <>
              {step === 3 ? (
                <button className="admin-form-btn" onClick={handleSubmit}>
                  Submit <span className="bi bi-arrow-right"> </span>
                </button>
              ) : (
                <button onClick={handleNextStep} className="admin-form-btn">
                  Next <span className="bi bi-arrow-right"> </span>
                </button>
              )}
            </>
          )}
          {!success && (
            <>
              {step > 1 ? (
                <button
                  onClick={handlePrevStep}
                  className="bg-gray-200 py-2 ml-4 text-sm px-8 mt-8 rounded-full"
                >
                  Back
                </button>
              ) : (
                <Link
                  className="bg-gray-200 py-2 text-sm ml-4 mt-8 px-8 rounded-full"
                  to={"/admin/create-company"}
                >
                  Cancel
                </Link>
              )}
            </>
          )}
          {loading && <LoadingSpinner />}
        </div>
      </div>
    </section>
  );
};

export default AddNewCompany;
