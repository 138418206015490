

export const defautMailTemplate = `<p>
   <img width='200' alt='company logo' title='logo' height='200' src="{logo}"/></p>
   <p></p>
   <h1>Dear <span style="color: rgb(178, 178, 0);">{evaluator}</span>  </h1>        
   <p> Welcome to our 360-degree feedback process. </p>
    <p>
         You will play an essential role in the success of the feedback process
          by providing comprehensive feedback on  <b> {participant} </b> performance.
           This will be immensely helpful in finding areas for improvement and
            consolidating on strength to drive continual progress within our organization. 
    </p>   
    <p>
       Please, click on the button below to access the survey.
    </p>          
     <br>                 
     <p class="ql-align-center">
      <a href="{link}" target="_blank" style="background-color: rgb(102, 129, 2); color: rgb(255, 255, 255); padding: 12px 36px; border-radius: 10px; text-decoration: none;"> Provide Feedback</a>
       </p> 
       <br>  
       <p> 
          or click this link: <a href="{link}" target="_blank"> {link} </a>
       </p>     
        <p>
          If you have any questions or need assistance, please refer to the FAQs, or contact the customer support team at  <a href="mailto:360@testassessify.com">360@testassessify.com.</a>
        </p>                           
        <p> Thank you for participating in the 360-degree feedback process.</p>        
        <p>Warm Regards,</p><p>Zainab Ahmed</p><p>Customer Service Team</p>
        <p><br></p><p class="ql-align-center"><br></p>
       <p class="ql-align-center" style="background: rgb(230, 230, 233);
        padding: 20px 10px; margin-top: 5px;">For&nbsp;<span style="color: rgb(178, 178, 0);">
        Support </span>&nbsp;Contact:  360@testassessify.com</p>
    `;

    export const devCoachDefaulTempalte = `<p>
    <img width='200' alt='company logo' height='200' src="{logo}"/></p><p></p>
    <h1>Dear <span style="color: rgb(178, 178, 0);">{development coach}</span></h1>
    <p>
        Welcome to our 360-degree feedback process for <b>{cp_name}</b> campaign. We are excited to 
        have you on board as a development coach for the 360-degree feedback 
        process, and we look forward to working with you.
     </p>
       <p> 
          Your role is essential in this process, and your participation is much appreciated. 
          Please, follow the link to access your dashboard using the credentials below.       
       </p>            
              
       <p>
           Link: <a href="{link}" target="_blank"> {link} </a>
        </p>      
            <p> Username: [dayo.amusu@9ijakids.com]  </p>
         <p>
             Password: [9ijakids]
         </p>          
         <p>
          Please, carefully read all questions and respond accurately
            and honestly as possible. If there are any questions that you are not in a position to answer appropriately, it is best to leave them blank. If you encounter any technical 
           difficulties or have any questions, please contact our support team at  <a href="mailto:360@testassessify.com">
           360@testassessify.com
        </a>.
         </p>         
                            
         <p>Warm Regards,</p><p>Zainab Ahmed</p><p>Customer Service Team</p>
         <p><br></p><p class="ql-align-center"><br></p>
        <p class="ql-align-center" style="background: rgb(230, 230, 233);
         padding: 20px 10px; margin-top: 5px;">For&nbsp;<span style="color: rgb(178, 178, 0);">
         Support </span>&nbsp;Contact:  360@testassessify.com</p></div>
     `;

     export const participantsDefaulTempalte = `<p>
       <img width='200' alt='company logo' height='200' src="{logo}" />
       </p>
         <p></p>
       <h1>Dear <span style="color: rgb(178, 178, 0);">{participant}</span></h1>
    <p>
       We're excited to welcome you to the 360-degree feedback process,
       a valuable tool for your professional growth and development as a manager.
     </p>               
      <p>
          The primary aim of this survey  (<b>{cp_name}</b>) is to collect comprehensive feedback on leadership skills so,
          we can help you uncover your strengths and pinpoint areas where there is room for growth
      </p> 
      <p>
         Please, click on the button below to access your dashboard using the credentials.
         You are required to choose a new password after the first login.
      </p>      
       <p>
           Email : {participant email}
        </p>         
        <p>
            Password: {participant password}
        </p>
        <br>
        <p class="ql-align-center">
         <a href="{link}" target="_blank" style="background-color: rgb(102, 129, 2); color: rgb(255, 255, 255); padding: 12px 36px; border-radius: 10px; text-decoration: none;"> View Dashboard </a>
       </p>    <br>   
       <p> 
           or click this link: <a href="{link}" target="_blank"> {link} </a>
       </p>        
       <p>
           Please, carefully read all questions and respond accurately
            and honestly as possible. If there are any questions that you are not in a position to answer appropriately, it is best to leave them blank. If you encounter any technical 
            difficulties or have any questions, please contact our support team at  <a href="mailto:360@testassessify.com">
            360@testassessify.com
            </a>.
      </p>                                  
        <p>For any enquiries please feel free to contact us.</p><p><br>
        </p><p>Warm Regards,</p><p>Zainab Ahmed</p><p>Customer Service Team</p>
         <p><br></p><p class="ql-align-center"><br></p>
        <p class="ql-align-center" style="background: rgb(230, 230, 233);
         padding: 20px 10px; margin-top: 5px;">For&nbsp;<span style="color: rgb(178, 178, 0);">
         Support </span>&nbsp;Contact:   <a href="mailto:360@testassessify.com">
              360@testassessify.com
           </a>  </p></div>
     `;