import { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { updateInput } from '../../../../../../features/create-project/create-project-slice';
import Evaluators from '../Evaluators';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { updateViewProjectCompanyId } from '../../../../../../features/view-projects/view-projects-slice';
import { updateMailTemplateLogo } from '../../../../../../features/setup-mail/setup-mail-slice';

const AddProjectForm = () => {
    
 const { formData , errors  }  = useSelector( state => state.createProjectForm ) 
 const { adminType } = useSelector((state) => state.adminLogin);
 const company_admin_email =  localStorage.getItem('client_admin_email')

 const dispatch = useDispatch()
 const [ companiesList , setCompaniesList ] = useState([])

 const strToDate = str => new Date( Date.parse( str ) ) ;

 const handleInput = (e) => {
      if(e.target.name === 'evaluator' || e.target.name === 'development_coach'){
          const selected =   Boolean(parseInt(e.target.value))
         dispatch( updateInput({ name : e.target.name , value : selected }) )          
      }
      else {
          dispatch( updateInput({ name : e.target.name , value : e.target.value }) )
      }
 }

  const handleCompanyIdInput = (value) => {
    dispatch( updateInput({ name:'company_id', value }) )     
  }

  const handleDateInput = (name,value) => {
    dispatch( updateInput({ name, value : value.toString() }) )
   }

  useEffect( () => {     
    axios.get(`${ process.env.REACT_APP_BASE_URL }/company/all`)
    .then(
       response => {
            if( response.data.success ){
              if(adminType === 'client admin'){
                  setCompaniesList(
                    [response?.data?.data?.find(
                      company => company.company_admin?.admin_email.toLowerCase() === company_admin_email.toLowerCase()
                   )]
                  )                                                                    
                }
               else setCompaniesList(response?.data?.data)                                                    
            }
       }
    )
  }, [])

 return (
   <form>   
      <div className='px-2 md:px-12 pt-3 md:pt-5'>         
         <div className='md:flex'> 
             <div className='w-full md:w-[120px] text-sm mt-3'> Campaign Name: </div>
              <div className='w-full md:w-[300px]'>
                  <input 
                       type='text' className='admin-form-input'
                       placeholder='campaign name...'
                       style={{ padding:'10px' }}
                       onChange = {  handleInput }
                       name='project_name'
                       value = { formData.project_name }
                  />
                   <div className='mt-1 text-red-500 text-[13px]'> 
                      { errors.project_name  } 
                   </div>
              </div>
              <div className='w-full md:w-[120px] text-sm md:ml-12 mt-5 sm:mt-3'> Select Company: </div>
              <div className='w-full md:w-[300px]'>                  
                  { <select class='industry-type-input form-select form-select-md'
                          name='company_id'  style={{ padding:'10px' }} 
                          onChange = { (e) => {                                       
                                      const id =  e.target.value                                      
                                        handleCompanyIdInput( id )
                                        dispatch( updateViewProjectCompanyId( id ) )   
                                        dispatch(
                                        updateMailTemplateLogo(
                                           companiesList.find(
                                              company => company.company.id === id
                                           ).company.company_logo
                                        )
                                      )                                                              
                                } } 
                         value={ formData.company_id } 
                         aria-label='.form-select-sm example'
                     >
                        <option value=''> Select </option>
                         { companiesList?.length && companiesList?.map( (company_list,id) => {                               
                              return (
                                       <option value={ company_list?.company?.id } key={ company_list?.company?.id }> 
                                           { company_list?.company?.company_name }  
                                        </option>
                                    )
                         })}                  
                 </select>}
                   <div className='mt-1 text-red-500 text-[13px]'> 
                      { errors.company_id  } 
                   </div>
              </div>
        </div>        

        <div className='md:flex items-center mt-8'> 
             <div className='w-full md:w-[120px] text-sm'> Description: </div>
              <div style={{  width : window.matchMedia('(max-width:500px)').matches ? '100%' : 'calc(96% - 120px)' }}>
                  <textarea
                       type='text' className='admin-form-input h-[100px]'                       
                       style={{ padding:'10px' }}
                       placeholder='campaign description...'
                       onChange = {  handleInput }
                       value = { formData.project_description }
                       name='project_description'
                  />
              </div>
        </div>
        <div className='mt-1 text-red-500 text-[13px] ml-[125px]'> 
            { errors.project_description  } 
        </div>

         <div className='md:flex justify-between'>
              <div className='flex items-center justify-between mt-8'> 
                  <div className='w-[100px] xl:w-[120px] text-sm mb-1 sm:mb-0'> Start Date:  </div>
                    <div className='w-full md:w-[300px] relative'>                                             
                         <div className='w-full'>                                                                                                                        
                            <DateTimePicker 
                              onChange = { (e) =>  handleDateInput('start_date',e) }
                              value = { strToDate(formData.start_date) }
                              name='start_date'
                              className = 'date-picker'
                              calendarIcon = {
                                     <span   className=' bi bi-calendar text-red-500'></span>
                                 }
                              format = 'dd-MM-y h:mm:ss a'        
                              dayPlaceholder = 'dd'                                                                                
                              monthPlaceholder = 'mm'
                              yearPlaceholder = 'yyyy'
                              hourPlaceholder = 'h'
                              minutePlaceholder = 'm'
                              minDate = { new Date() }
                              secondPlaceholder = 's'                              
                            />    
                        </div>
                      <div className='mt-1 text-red-500 text-[13px]'> { errors.start_date_and_time } </div>
                  </div>
              </div>

              <div className='flex items-center justify-between mt-5'> 
                  <div className='md:w-[80px] xl:w-[100px] md:ml-3 xl:ml-0 text-sm'> End Date:  </div>
                    <div className='w-[300px] relative'>                    
                         <div className='w-full'>                                
                            <DateTimePicker 
                                onChange = { (e) =>  handleDateInput('end_date',e) }
                                value = { strToDate(formData.end_date) }
                                name='end_date'
                                className = 'date-picker'
                                calendarIcon = {
                                        <span   className=' bi bi-calendar text-red-500'></span>
                                    }
                                format = 'dd-MM-y h:mm:ss a'     
                                dayPlaceholder = 'dd'                                                                                
                                monthPlaceholder = 'mm'
                                yearPlaceholder = 'yyyy'
                                hourPlaceholder = 'h'
                                minutePlaceholder = 'm'
                                secondPlaceholder = 's'            
                                minDate = { strToDate(formData.start_date) }                                                                           
                                />                                                                         
                         </div>
                         <div className='text-red-500 text-[13px] mt-1'> { errors.end_date_and_time } </div>
                  </div>
              </div>
         </div>        
      </div>
       <div className='bg-gray-100 py-3 text-sm mt-5 pl-3'>
           <span className='bi bi-gear mr-1'></span>  Settings
        </div>
         <Evaluators className={'w-full md:w-[80%]'} />
        {/*<h6 className='mt-8 text-md pl-5 font-semibold'>Survey </h6>
        <p className='py-2 ml-5 border-b  text-green-700 border-gray-300 text-sm w-[80%]'> Survey for each roles </p>
         
                                 <PreviewSurvey handleInput={ handleInput } />*/}

        <h6 className='mt-8 text-md pl-5 font-semibold'>Evaluators </h6>
        <p className='py-2 ml-5 border-b  text-green-700 border-gray-300 text-sm w-[80%]'> Upload Evaluators </p>        
        <div className='mt-5 px-5 md:flex'>             
                 <div className='form-check form-check-inline'>                      
                      <input 
                            className='admin-form-check'  
                            type='radio' name='evaluator' 
                            id='inlineRadio1' value={ 1 }                            
                            onChange = { handleInput }   
                            defaultChecked = { true }                         
                       />
                       <label className='form-check-label text-sm ml-3 inline-block text-gray-800' htmlFor='inlineRadio1'>
                          I will select evaluators
                       </label>
                  </div>
                  <div className='form-check form-check-inline mt-3 md:mt-0 md:ml-[5rem]'>                      
                      <input 
                            className='admin-form-check' 
                            type='radio' name='evaluator' 
                            id='inlineRadio1' value= { 0 }                            
                            onChange = { handleInput }                             
                       />
                       <label className='form-check-label text-sm ml-3 inline-block text-gray-800' htmlFor='inlineRadio1'>
                          Let participants choose their  evaluators
                       </label>
                  </div>
        </div>

        <h6 className='mt-8 text-md pl-5 font-semibold'>Development Coach </h6>
        <p className='py-2 ml-5 border-b  text-green-700 border-gray-300 text-sm w-[80%]'> Upload Development Coach </p>        
        <div className='mt-5 px-5 md:flex'>             
                 <div className='form-check form-check-inline'>                      
                      <input 
                            className='admin-form-check' 
                            type='radio' name='development_coach'                             
                            id='inlineRadio1' value= { 1 }
                            defaultChecked = { true }
                            onChange = { handleInput }
                       />
                       <label className='form-check-label text-sm ml-3 inline-block text-gray-800' for='inlineRadio1'>
                          I will select development coach
                       </label>
                  </div>
                  <div className='form-check flex form-check-inline mt-3 md:mt-0 md:ml-[5rem]'>                      
                      <input 
                            className='admin-form-check' 
                            type='radio' name='development_coach' 
                            id='inlineRadio1' value={ 0 }                             
                            onChange = { handleInput }
                       />
                       <label className='form-check-label text-sm md:ml-3 mr-8 inline-block text-gray-800' for='inlineRadio1'>
                          Let partcipants choose their  development coach
                       </label>
                  </div>
        </div>
   </form>
  )
}

export default AddProjectForm

 
   
    
  