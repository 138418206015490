import axios from 'axios'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { updateParticipants } from '../../../../../features/add-participants/add-participants-slice'
import { isMappingEvaluatorStep, moveToNextStep } from '../../../../../features/create-project/create-project-slice'
import { uploadEvaluatorForParticipants } from '../../../../../features/evaluator-for-Participants/evaluatorForParticipantsSlice'
import { Modal, Ripple, initTWE, } from "tw-elements"

const PreviewParticipantsModal = () => {

  const dispatch = useDispatch()
  const { formData } = useSelector( state => state.addParticipants )
  const { formData: cpFormData, isEditing }  = useSelector( state => state.createProjectForm )
  const {evaluator} = cpFormData
  const { company_id  }  = useSelector( state => state.viewProjects ) 
  const [ loading, setLoading ] = useState( false )

  useEffect(() => {
    initTWE({ Modal, Ripple });
  }, []);
     
  const submitParticipants = () => {
       document.querySelector('#close-participants-modal').click()                
       setLoading(true)

        axios.post(`${ process.env.REACT_APP_BASE_URL }/project/participant`,
            {
                   project_id: formData.project_id, company_id , 
                   participants : [ ...formData.participants.map( participant => {
                        if(isEditing){
                            return {
                                id: participant.id,  name : participant.name , email : participant.email , 
                                dev_coach_name : participant.dev_coach_name, 
                                dev_coach_email : participant.dev_coach_email,
                                country:  participant.country, department : participant.department, 
                                subsidiary: participant.subsidiary, branch: participant.branch,  
                            }
                        }                        
                        else {
                              return {
                                    name : participant.name , email : participant.email , 
                                    dev_coach_name : participant.dev_coach_name, 
                                    dev_coach_email : participant.dev_coach_email,
                                    country:  participant.country, department : participant.department, 
                                    subsidiary: participant.subsidiary, branch: participant.branch,  
                              }
                          }
            } )] }
          )
        .then( response => {
                if(response.data.success){
                    setLoading(false)
                   if(response?.data?.data?.length) {
                        dispatch( updateParticipants( response.data.data.map( participant => {                        
                            return{
                                id : participant.id 
                            }
                        })))     
                    }                                    
                    //move to next step iff evaluator === false
                    if( !evaluator ){
                        dispatch( moveToNextStep() ) 
                        document.querySelector('.admin-panel').scrollTo({ top : 0, behavior : 'smooth' })
                    }   
                    else {
                          dispatch( uploadEvaluatorForParticipants() )
                          dispatch( isMappingEvaluatorStep() )
                          document.querySelector('.admin-panel').scrollTo({ top : 0, behavior : 'smooth' })
                        }               
                }
        } )        
  }

  return (
     <>
         {  loading && <LoadingSpinner /> }
         <button  
            id='preview-modal'    
            className='hidden'
            data-twe-target='#exampleModal'
            data-twe-toggle="modal"
            data-twe-ripple-init
            data-twe-ripple-color="light"
        > </button> 
        <div
        data-twe-modal-init
        className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div
            data-twe-modal-dialog-ref
            className="pointer-events-none overflow-x-hidden relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[1200px]">
            <div
            className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark">
            <div
                className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 p-4 dark:border-white/10">
                <h5 className='text-xl bi bi-people font-medium leading-normal text-gray-800' id='exampleModalLgLabel'>
                    &nbsp;Participants Summary
                </h5>
                <button
                type="button"
                className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                data-twe-modal-dismiss
                aria-label="Close">
                <span className="[&>svg]:h-6 [&>svg]:w-6">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </span>
                </button>
            </div>
            <div className="relative flex-auto p-4" data-twe-modal-body-ref>
                <p className='text-md text-red-500'>
                      The following are list of participants who are invited to 
                      participate in this project
                 </p>
                 <div className='flex flex-col'>
                    <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
                        <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
                        <div className='overflow-hidden'>
                            <table className='min-w-full text-center mt-3'>
                               <thead>
                                    <tr className='bg-gray-100'>
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-4'>
                                             S/N
                                        </th>
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-4'>
                                             Name
                                        </th>
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-4'>
                                            Email Address
                                        </th>
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-4'>
                                             Development Coach Name
                                        </th>                                        
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-4'>
                                             Development Coach Email
                                        </th>                                          
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-4'>
                                              Country
                                        </th> 
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-4'>
                                             Department
                                        </th> 
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-4'>
                                              Subsidiary
                                        </th> 
                                        <th scope='col' className='text-sm font-medium text-gray-900 px-3 py-4'>
                                              Branch
                                        </th> 
                                    </tr>
                            </thead>
                            <tbody>                            
                                {
                                    formData.participants.map(
                                        (participant,id) => {
                                            return (
                                                <tr key={id} className='border-b border-l border-r border-gray-200'>
                                                    <td className='px-3 text-gray-700 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                                                        { id + 1}.  
                                                    </td>
                                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                                       { participant.name }
                                                    </td>
                                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                                        { participant.email }
                                                    </td>
                                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                                        { participant.dev_coach_name }
                                                    </td>                                                    
                                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                                        { participant.dev_coach_email }
                                                    </td>                                                    
                                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                                        { participant.country }
                                                    </td> 
                                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                                        { participant.department }
                                                    </td> 
                                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                                        { participant.subsidiary }
                                                    </td> 
                                                    <td className='text-sm text-gray-700 font-light px-3 py-4 whitespace-nowrap'>
                                                        { participant.branch }
                                                    </td> 
                                              </tr>   
                                            )
                                        }
                                    )
                                }
                             </tbody>
                           </table>
                        </div>
                       </div>
                     </div>
                   </div>
           </div>
            <div
                className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 p-4 dark:border-white/10">
                <button
                type="button"
                id='close-participants-modal'
                className="inline-block rounded mr-5 bg-gray-200 text-gray-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-200 focus:bg-primary-accent-200 focus:outline-none focus:ring-0 active:bg-primary-accent-200 dark:bg-primary-300 dark:hover:bg-primary-400 dark:focus:bg-primary-400 dark:active:bg-primary-400"
                data-twe-modal-dismiss
                data-twe-ripple-init
                data-twe-ripple-color="light"
                >
                   Cancel
                </button>
                <button
                type="button"
                className="ms-1 inline-block rounded bg-red-500 px-16 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                data-twe-ripple-init
                data-twe-ripple-color="light"
                onClick={ submitParticipants }
                >
                      OK
                </button>
            </div>
            </div>
        </div>
        </div>
     </>
  )
}

export default PreviewParticipantsModal