import React from 'react'
import DeleteCompany from './DeleteCompany'
import EditComapany from './EditComapany'

const CompanyList = ({ companiesList , setCompaniesList }) => {
  return (
    <div className='mt-5'>
        <div class="flex flex-col">
        <div class="overflow-x-auto md:overflow-x-hidden sm:-mx-6 lg:-mx-8">
            <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div class="overflow-x-auto">
                <table class="min-w-full">
                   <thead class="border-b border-b-2 border-red-400 bg-gray-50">
                    <tr>
                        <th scope="col" class="text-sm font-medium text-red-500 px-4 py-3 text-left">                            
                            Action
                        </th>
                        <th scope="col" class="text-xs text-red-500 font-medium px-4 py-3 text-left">
                            COMPANY NAME
                        </th>
                        <th scope="col" class="text-xs font-medium text-red-500 px-4 py-3 text-left">
                            EMAIL SUFFIX
                        </th>
                        <th scope="col" class="text-xs font-medium text-red-500 px-4 py-3 text-left">
                            ADMIN NAME
                        </th>
                        <th scope="col" class="text-xs font-medium text-red-500 px-4 py-3 text-left">
                            ADMIN EMAIL
                        </th>
                        <th scope="col" class="text-xs font-medium text-red-500 px-4 py-3 text-left">
                            USERS
                        </th>
                        <th scope="col" class="text-xs font-medium text-red-500 px-4 py-3 text-left">
                            CAMPAIGN
                        </th>
                        <th scope="col" class="text-xs font-medium text-red-500 px-4 py-3 text-left">
                            CREATED DATE
                        </th>
                     </tr>
                   </thead>
                   <tbody className='overflow-x-auto'>
                         { 
                            companiesList.map( (companyList,id ) => {
                                return(
                                    <tr class="border-b" key={ companyList.company.id }>
                                        <td class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                            <span 
                                                  className='bi bi-pencil px-1 mr-2 text-blue-500 hover:cursor-pointer'
                                                  data-bs-toggle='modal'
                                                  id={`edit-company-${ companyList.company.company_name  }` }                               
                                                  data-bs-target={'#edit-company' + companyList.company.id } 
                                             ></span>
                                              <EditComapany 
                                                 company={  companyList.company }
                                                 setCompaniesList = { setCompaniesList }
                                                 target = {'edit-company' + companyList.company.id} 
                                                 companiesList = { companiesList }
                                              />
                                            <span 
                                                  className='bi bi-trash text-red-500 px-1 hover:cursor-pointer'
                                                  data-bs-toggle='modal'
                                                  id={`delete-company-${ companyList.company.company_name  }` }                               
                                                  data-bs-target={'#delete-company' + companyList.company.id } 
                                             ></span>
                                             <DeleteCompany 
                                               company={  companyList.company }
                                               setCompaniesList = { setCompaniesList }
                                               target = {'delete-company' + companyList.company.id } 
                                               companiesList = { companiesList }
                                             />
                                        </td>
                                        <td class="company-list-tr">
                                            { companyList.company.company_name }
                                        </td>
                                        <td class="company-list-tr">
                                            { companyList.company.email_suffix }
                                        </td>
                                        <td class="company-list-tr capitalize">
                                            {   
                                               (companyList.company_admin !== null ) ?
                                                companyList.company_admin.admin_name : 'ahmed'
                                            }
                                        </td>
                                        <td class="company-list-tr">
                                           {   
                                               (companyList.company_admin !== null ) ?
                                                companyList.company_admin.admin_email : 'saniahmed813@9ijakids.com'
                                            }
                                        </td>
                                        <td class="company-list-tr">
                                         {companyList.totalParticipant}
                                        </td>
                                        <td class="company-list-tr">
                                            {companyList.totalCampaign}
                                        </td>
                                        <td class="company-list-tr">
                                            { new Date(companyList.company.createdAt).toLocaleDateString('EN-gb') }
                                        </td>
                                   </tr> 
                                )
                             } ) 
                         }
                   </tbody>
                 </table>
               </div>
             </div>
           </div>
        </div>
    </div>
  )
}

export default CompanyList