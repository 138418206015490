import React from "react";
import "./LandingPage.css";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
function LandingPage(params) {
  const {
    // title = `Develop & Nurture the leadership qualities of your workforce`,
    title = `Unlock Your Team's True Potential with our 360 Degree Feedback`,
    // subtitle = `How do you get the balance right between criticism and
    // encouragement?`,
    subtitle = `Gain a competitive edge by understanding your teams' strengths and areas of improvement with our 360-degree feedback`,
    subtitle2 = `The ability to give honest and effective feedback is an
    unsung skill in the workplace.`,
    partnerContent = "Join",
    service = `What is the 360-degree feedback process?    `,
    serviceSummary = `A 360-degree feedback process collects comments on an individual's performance from a variety of sources. It enables a more thorough and balanced assessment of a person's strengths and areas for development.
    `,
    experience = `Establish performance management and promote team engagement
    `,
    experienceSummary = `The process improves performance management by providing a more complete picture and tracking the progress made by the team. Team engagement is also increased by providing the team with a voice in their development and a sense of empowerment through the 360 feedback process.
    `,
    extraTitle = "User-friendly and Accessible",
    extraSummary = `Our 360 degree feedback tool is designed to be user-friendly and accessible to all. Managers and supervisors can easily set up evaluations, collect feedback, and generate reports thanks to a user-friendly interface and intuitive navigation.    `,
    footerSummary = "Lorem excellentium",
  } = params;
  return (
    <div className="landingPage">
      <div className="landingPage__Header">
        <div className="header-left">
          <div className="header-logo">
            {/* Img */}
            <img src="/assets/lorem.png" alt="lorem excell" />
            {/* <p>Lorem Excellentiam</p> */}
          </div>
        </div>
        <div className="header-right">
          <ul className="header-links">
            <Link to={"/login"}>
              <li className="header-link mr-2">Login</li>
            </Link>
          </ul>
        </div>
      </div>
      <div className="landingPage__Hero">
        <div className="hero-left">
          <div className="hero-content">
            <h2 className=""> {title}</h2>
            <h2 className="hero-right-align">{/* - Ken Blanchard */}</h2>
            <p className=""> {subtitle}</p>
            {/* <p className="">{subtitle2}</p> */}
            <div className="link">
              <Link to="/login">
                <div className="hero-link">Login</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="hero-right">
          <div className="hero-image">
            {/* hero-image */}
            <img src="/assets/UX/header1.png" alt="" />
          </div>
        </div>
      </div>

      <div className="landingPage__Partners">
        <div className="partners-content">
          <h3 className="">{partnerContent}</h3>
          <div className="partner-image-list">
            {["", "", "", ""].map((e) => (
              <div className="partner-logo">{/* image */}</div>
            ))}
          </div>
        </div>
      </div>

      <div className="landingPage__Services">
        <div className="services-left">
          <div className="service-image">
            {/* img */}
            <img src="/assets/services2.JPG" alt="services" />
          </div>
        </div>
        <div className="services-right">
          <h3 className=""> {service}</h3>
          <p>{serviceSummary}</p>
          {/* <ul>
            <li>Select the best Candidate</li>
            <li>Seemless</li>
            <li>Grade and rank</li>
          </ul> */}
        </div>
      </div>

      <div className="landingPage__Benefit">
        <h3 className=""> Why You Should Use Us?</h3>
        <div className="benefit-cards">
          {[
            {
              img: "/assets/UX/businesswoman-consulting-legal-experts-1.png",
              title: "Track leadership development ",
              summary: `Through constructive feedback, goal planning, and progress monitoring, the process aids in the development of leadership qualities.
              `,
            },
            {
              img: "/assets/UX/businesswoman-consulting-legal-experts-2.png",
              title: "Bolstering team dynamics",
              summary:
                "The process encourages open communication and awareness among team members, which helps to improve team dynamics.               ",
            },
            {
              img: "/assets/UX/businesswoman-consulting-legal-experts.png",
              title: "Self-awareness and reflection.",
              summary:
                "Through the provision of different perspectives on one’s conduct, the process encourages self-awareness and reflection.           ",
            },
          ].map((e) => (
            <div className="benefit-card">
              <div className="benefit-card-image">
                {/* img */}
                <img src={e.img} alt="" />
              </div>
              <h3 className="benefit-card-title">{e.title}</h3>
              <p className="benefit-card-summary">{e.summary}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="landingPage__Experience">
        <div className="experience-left">
          <h2 className="experience-title">{experience}</h2>
          <p className="experience-summary">{experienceSummary}</p>
        </div>
        <div className="experience-right">
          <div className="experience-image">
            {/* image */}
            <img src="/assets/UX/lady 2.png" alt="" />
          </div>
        </div>
      </div>

      <div className="landingPage__Extra">
        <div className="extra-left">
          <div className="extra-image">
            {/* image */}
            <img src="/assets/UX/top talent.JPG" alt="" />
          </div>
        </div>
        <div className="extra-right">
          <h2 className="extra-title"> {extraTitle}</h2>
          {/* <h2 className="extra-right-align">- Ken Blanchard</h2> */}
          <p className="extra-summary">{extraSummary}</p>
          <div className="link">
            <Link to="/login">
              <div className="hero-link">Login</div>
            </Link>
          </div>
        </div>
      </div>

      <div className="landingPage__Footer">
        <div className="footer-left">
          <div className="footer-logo">
            {/* Logo */}
            <img src="/assets/UX/TestAssessify_logo.png" alt="" />
          </div>
          <p className="">© TestAssessify 2022. All rights reserved.</p>
          <p className="">
            Registered office is at 35, Ladipo Labinjo Crescent, Surulere.
          </p>
        </div>
        <div className="footer-center">
          <ul className="footer-links">
            <li className="footer-link">
              <a href="#"> Help Center</a>
            </li>
            <li className="footer-link">
              <a href="#"> Useful Information for Participants</a>
            </li>
            <li className="footer-link">
              <a href="#"> Help Center</a>
            </li>
          </ul>
          <ul className="footer-links">
            <li className="footer-link">
              <Link to="/faq">FAQs</Link>
              {/* <a href="/faq"> FAQs</a> */}
            </li>
            <li className="footer-link">
              <a href="#"> Contact</a>
            </li>
            {/* <li className="footer-link"> <a href="#"> Help Center</a></li> */}
          </ul>
        </div>
        <div className="footer-right">
          <div className="footer-social">
            {/* image */}
            <a href="#">
              <div className="footer-social-image">
                <FacebookIcon />
              </div>
            </a>
            <a href="#">
              <div className="footer-social-image">
                <TwitterIcon />
              </div>
            </a>
            <a href="#">
              <div className="footer-social-image">
                <InstagramIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
