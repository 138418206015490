import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import { updateInput, setErrors, clearErrors } from '../../../../../../features/create-project/create-project-slice'
import swal from 'sweetalert'
import axios from 'axios'

const EditCampaignNameModal = ({target}) => {

   const { formData , errors }  = useSelector( state => state.createProjectForm )
   const { project_id } = useSelector( state => state.addParticipants.formData );

   const dispatch = useDispatch()
   const [ campaignName , setCampaignName ] = React.useState({ project_name : formData.project_name })
   const [ loading, setLoading ]  = React.useState(false)

   const handleInput = (e) => {      
        setCampaignName({ ...campaignName, project_name : e.target.value })      
   }

   const updateCampaignName = () => {
        
       if(campaignName.project_name !== ''){
          dispatch( updateInput({ name : 'project_name' , value : campaignName.project_name }) )
          dispatch( clearErrors() )
          setLoading(true)
          //close modal
          document.querySelector('#close-project-name-modal-window').click()
          //update the campaign name
          axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/${ project_id }`,{...formData, project_name: campaignName.project_name})
           .then( response => {
                if(response.data.success){                                            
                    swal('Success!', 'Campaign Name is updated successfully.' , 'success')
                    setLoading(false)                    
              }
          })   
       }
       else dispatch( setErrors( { project_name : 'Error! project name cannot be empty' } ))
   }

  return (
   <>   { loading && <LoadingSpinner /> }
    <div className='modal fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto' id={ target } tabIndex='-1' aria-labelledby='exampleModalLgLabel' aria-modal='true' role='dialog'>
       <div className='modal-dialog  modal-dialog-centered  relative w-auto pointer-events-none'>
          <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
            <div className='modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
            <h5 className='text-xl bi bi-pencil font-medium leading-normal text-gray-800' id='exampleModalLgLabel'>
                &nbsp;  <span className='text-[16px]'> Edit Campaign Name </span>
            </h5>
            <button type='button' id='close-project-name-modal-window'
                className='btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body relative p-4 px-8'>
            <div className='md:flex '> 
             <div className='w-[120px] text-sm md:mt-4'> Campaign Name: </div>
               <div className='md:w-[300px] mt-2 sm:mt-0'>
                    <input 
                       type='text' className='admin-form-input'
                       placeholder='project name...'
                       style={{ padding:'10px' }}
                       onChange = {  handleInput }
                       name='project_name'
                       value = { campaignName.project_name }
                     />
                     { errors.project_name &&
                    <div className='border-l border-l-2 border-red-500 text-red-500 text-[13px] text-center mt-4 py-2  bg-red-100'> 
                        {errors.project_name} 
                    </div>
                  }
                   </div>
               </div>                  

                <div className='flex justify-end mt-1'>
                      <button className='admin-form-btn'  onClick={ updateCampaignName }>
                         Update <span className='bi bi-arrow-right'>  </span>
                      </button>
                </div>
            </div>
         </div>
       </div>
    </div>
   </>
  )
}

export default EditCampaignNameModal