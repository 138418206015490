import React from 'react'
import axios from 'axios'
import LoadingSpinner from '../../../../../components/LoadingSpinner'

const EmptyCompany = ({ setCompaniesList }) => {

  const [ loading , setLoading ] = React.useState(false)
  const getCompanies = () => {
    setLoading(true)
    axios.get(`${ process.env.REACT_APP_BASE_URL }/company/all`)
    .then(
       response => {
            if( response.data.success ){
                setCompaniesList(response.data.data)                    
                setLoading(false)
            }
       }
    ).catch( error => {
      if( error.response.status === 404 ){
        setCompaniesList([])                    
        setLoading(false)
      }
     } )
  }

  return (
    <div className='flex items-center mt-[8rem] justify-center'> { loading && <LoadingSpinner /> }
        <div className='text-center'>
            <span className='bi bi-folder2-open text-[6rem] text-gray-700'></span>
            <h5 className='text-sm text-center'> Sorry! No any company found. </h5>
            <button onClick={ getCompanies } className='mt-5 text-sm py-2 px-12 bi bi-arrow-left text-white bg-green-small rounded-lg'>
                &nbsp;Back
            </button>
        </div>
    </div>
  )
}

export default EmptyCompany