import { useQuery } from "react-query";
import axios from "axios";
const token = "";
const getter = async (url) => {
  const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
export const useFetchCampaigns = () => {
  const campaignApi = async () =>
    await getter(`project/all/${localStorage.getItem("companyID")}`);

  const queryCampaign = useQuery("fetch-campaign", () => campaignApi(), {
    refetchOnWindowFocus: false,
  });
  return queryCampaign;
};
