import React from 'react'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import axios from 'axios'
import { updateProject } from '../../../../../features/view-projects/view-projects-slice'
import { useDispatch, useSelector } from 'react-redux'

const NoCampaign = () => {

  const [ loading, setLoading ] = React.useState(false)
  const dispatch = useDispatch()
  const { company_id  }  = useSelector( state => state.viewProjects )

  const getCampaigns = () => {
      setLoading(true)
      axios.get(`${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }`)
      .then(
      res => {
             if( res.data.success ){                                   
                 dispatch( updateProject( res.data ) )
                 setLoading(false)
             }
         })  
  }

  return (
    <div className='flex items-center mt-[3rem] justify-center'> { loading && <LoadingSpinner /> }
        <div className='text-center'>
            <span className='bi bi-folder2-open text-[6rem] text-gray-700'></span>
            <h5 className='text-sm text-center'> Sorry! No any campaign found for this search. </h5>
            <button onClick={ getCampaigns} className='mt-5 text-sm py-2 px-12 bi bi-arrow-left text-white bg-green-small rounded-lg'>
                &nbsp;Back
            </button>
        </div>
    </div>
  )
}

export default NoCampaign