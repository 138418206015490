import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditCampaignDeadlineModal from '../modals/EditCampaignDeadlineModal'
import EditCampaignNameModal from '../modals/EditCampaignNameModal'
import { updateDevCoach, updateEvaluator } from '../../../../../../features/create-project/create-project-slice'
import EditPartcipantsNameModal from '../modals/EditParticipantsModal'
import { updateInput } from '../../../../../../features/setup-mail/setup-mail-slice'
import EditEvaluatorModal from '../modals/EditEvaluatorModals'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import axios from 'axios'
import swal from 'sweetalert'
import EditCampaignStartDate from '../modals/EditCampaignStartDate'

const Preview = () => {
     
  const [ loading, setLoading ]  = React.useState(false)
  const dispatch = useDispatch()
  const { project_id, participants } = useSelector( state => state.addParticipants.formData );
  const { setupMail, createProjectForm } = useSelector( state => state)

  const updateCampaign = () => {
      setLoading( true )
      axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/${ project_id }`, createProjectForm.formData)
           .then( response => {
                if(response.data.success){                                            
                    swal('Success!', 'Campaign is updated successfully.' , 'success')
                    setLoading(false)                    
              }
          })  
  }

  const HandleUpdateEvaluator = () => {
      dispatch( updateEvaluator()) 
      updateCampaign()
    }
  const HandleUpdateDevCoach = () => {
         dispatch( updateDevCoach() )
         updateCampaign()
    }
  
  const handleInputChange = (inputName,inputValue) => {         
    dispatch( updateInput( { name : inputName, value : inputValue } ) )
   }  

  return (
    <div className='px-2 md:px-8 mt-5 md:mt-16'> { loading && <LoadingSpinner /> }
         <h5 className='text-md text-gray-800'> Preview your campaign settings </h5>
         <div className='px-5 border border-gray-200 rounded-lg mt-5'>
            <p className='text-sm mt-5'> Campaign Details </p>
             <div className='grid grid-cols-1 md:grid-cols-2 gap-5 pt-4 pb-8'>
                   <div>
                        <p className='text-sm pb-2'> Name: </p>
                        <div className='py-2 px-3 border border-gray-200 flex justify-between items-center rounded-lg'>
                              <span className='text-sm'> { createProjectForm.formData.project_name } </span>
                              <i className='bi bi-pencil hover:cursor-pointer text-lg text-green-small'                                 
                                 data-bs-toggle='modal'
                                 id='edit-campaign-modal'                                
                                 data-bs-target='#edit-campaign-name'
                              ></i>
                              <EditCampaignNameModal target={ 'edit-campaign-name' }/>
                        </div>
                   </div>
                   <div>
                        <p className='text-sm pb-2'> Campaign starts on: </p>
                        <div className='py-2 px-3 border border-gray-200 flex justify-between items-center rounded-lg'>
                              <span className='text-sm'> 
                                   {  new Date(createProjectForm.formData.start_date).toLocaleDateString('en-GB')  }
                               </span>
                              <i className='bi bi-pencil text-lg text-green-small hover:cursor-pointer'
                                 data-bs-toggle='modal'
                                 id='edit-campaign-modal'                                
                                 data-bs-target='#edit-campaign-start-date'
                              ></i>
                              <EditCampaignStartDate target={'edit-campaign-start-date'} />
                        </div>
                   </div>
                   <div>
                        <p className='text-sm pb-2'> Campaign closes on: </p>
                        <div className='py-2 px-3 border border-gray-200 flex justify-between items-center rounded-lg'>
                              <span className='text-sm'> 
                                   {  new Date(createProjectForm.formData.end_date).toLocaleDateString('en-GB')  }
                               </span>
                              <i className='bi bi-pencil text-lg text-green-small hover:cursor-pointer'
                                 data-bs-toggle='modal'
                                 id='edit-campaign-modal'                                
                                 data-bs-target='#edit-campaign-deadline'
                              ></i>
                              <EditCampaignDeadlineModal target={'edit-campaign-deadline'} />
                        </div>
                   </div>
             </div>             
         </div>
         <div className='md:flex justify-between py-4'>
                   <div className='border border-gray-200 rounded-lg p-4 md:w-[43%]'>
                        <p className='text-sm flex justify-between'> 
                            <span>participants</span> 
                           <i className='bi bi-pencil text-lg text-green-small'
                               data-bs-toggle='modal'
                               id='edit-participants-modal'                                
                               data-bs-target='#edit-participants'
                           ></i>
                           <EditPartcipantsNameModal target={'edit-participants'} />
                        </p>
                        <div className='py-2 px-3 flex items-center mt-16'>
                               <h1 className='text-5xl font-semibold'> 
                                 { participants.length  < 10 ? '00' + participants.length :
                                   participants?.length
                                 }
                                </h1>
                               <div>
                                 <div class='form-check flex items-center ml-8'>
                                    <input class='form-check-input appearance-none w-[20px] h-[20px] border border-green-small rounded-sm bg-white checked:bg-green-small checked:border-green-small focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' 
                                            type='checkbox'
                                            checked = { createProjectForm.formData.evaluator }
                                             onClick = { HandleUpdateEvaluator }
                                    />                                
                                    <label class='form-check-label inline-block text-sm text-gray-800' for='c-1'>
                                        Upload Evaluators
                                    </label>
                                 </div>
                                 <div class='form-check flex items-center ml-8 mt-2'>
                                    <input class='form-check-input appearance-none w-[20px] h-[20px] border border-green-small rounded-sm bg-white checked:bg-green-small checked:border-green-small focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' 
                                            type='checkbox'
                                            checked = {  createProjectForm.formData.development_coach }
                                            onClick = { HandleUpdateDevCoach }
                                    />                                
                                    <label class='form-check-label text-sm inline-block text-gray-800' for='c-1'>
                                       Upload Development Coach
                                    </label>
                                 </div>
                               </div>
                        </div>
                   </div>
                   <div className='mt-4 sm:mt-0 md:w-[55%]'>                         
                         <div className='border border-gray-200 rounded-lg p-2'>
                            <p className='text-sm'> 
                                <span>Evaluators</span>                             
                            </p>
                            <p className='text-[13px] font-medium mt-2 flex justify-between'> 
                                Minimum number of responses for report availability
                            <i className='bi bi-pencil text-lg text-green-small'
                               data-bs-toggle='modal'
                               id='edit-evaluators-modal'                                
                               data-bs-target='#edit-evaluators'
                            ></i>
                            </p>
                            <div className='pb-2 px-3  flex justify-between items-center'>
                                {
                                    createProjectForm.formData.roles.map( role => {
                                        return(
                                            <div className='text-center mt-2'>
                                                <span className='text-sm capitalize'> 
                                                { role.project_role }
                                                </span>
                                                <h5 className='text-2xl text-center mt-2'> 
                                                { role.min_response }
                                                </h5>
                                            </div>
                                        )
                                    } )
                                }   
                            </div>
                         </div>
                         <div className='border border-gray-200 rounded-lg p-2 mt-3'>
                            <p className='text-[13px] font-medium mt-2 flex justify-between'> 
                                Maximum number of responses for report availability
                                <i className='bi bi-pencil text-lg text-green-small'
                                    data-bs-toggle='modal'
                                    id='edit-evaluators-modal'                                
                                    data-bs-target='#edit-evaluators'
                                ></i>
                            </p>
                            <div className='pb-2 px-3  flex justify-between items-center'>
                                {
                                    createProjectForm.formData.roles.map( role => {
                                        return(
                                            <div className='text-center mt-2'>
                                                <span className='text-sm capitalize'> 
                                                { role.project_role }
                                                </span>
                                                <h5 className='text-2xl text-center mt-2'> 
                                                { role.max_response }
                                                </h5>
                                            </div>
                                        )
                                    } )
                                }   
                                <EditEvaluatorModal target={ 'edit-evaluators' } />
                            </div>
                        </div>                         
                   </div>
             </div>                  
    </div>
  )
}

export default Preview