import React from "react";
import "./Evaluator.css";
import Assessment from "./Assessment/Assessment";

import Navbar from "../admin/layouts/Navbar";
import {
  Route,
  Routes,
  Navigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import Sidebar from "../../components/SideBar/SideBar";

import LoadingSpinner from "../../components/LoadingSpinner";
import { useState } from "react";
import ThankYou from "./ThankYou/ThankYou";

function Evaluator(params) {
  const location = useLocation();
  // const [searchParams, setSearchParams] = useSearchParams(location.search);
  // const [searchParams, setSearchParams] = new URLSearchParams(
  //   decodeURIComponent(location.search)
  // );
  const [completedSurvey, setCompletedSurvey] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  localStorage.clear();
  const searchParams = new URLSearchParams(decodeURIComponent(location.search));

  const user = searchParams.get("evaluator_name");
  const project_id = searchParams.get("project_id");
  const participant_id = searchParams.get("participant_id");
  const participant_name = searchParams.get("participant_name");
  const evaluator_id = searchParams.get("evaluator_id");
  const evaluator_role = searchParams.get("evaluator_role");

  localStorage.setItem("username", user);
  localStorage.setItem("project_id", project_id);
  localStorage.setItem("participant_id", participant_id);
  localStorage.setItem("participant_name", participant_name);
  localStorage.setItem("evaluator_id", evaluator_id);
  localStorage.setItem("evaluator_role", evaluator_role);

  console.log(searchParams.get("project_id"));
  const sidebarLinks = [];

  if (completedSurvey) {
    return <ThankYou participant={participant_name} />;
  }
  return (
    <div className="evaluator">
      <Navbar
        user={user}
        links={sidebarLinks}
        isLoggedIn={false}
        isAdmin={false}
      />
      <section className="md:flex justify-between mt-3">
        {/* <Sidebar
          links={sidebarLinks}
          className="participant__Sidebar w-[20%]  bg-gray-200"
        /> */}
        {/* <div className="w-full md:w-[79%] participant__Content"> */}
        <Routes>
          <Route
            path="/assessment"
            element={
              <Assessment
                setCompletedSurvey={setCompletedSurvey}
                setIsLoadingPage={setIsLoading}
                isLoadingPage={isLoading}
              />
            }
          />
        </Routes>
        {/* </div> */}
      </section>
    </div>
  );
}

export default Evaluator;
