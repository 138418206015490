import axios from 'axios';
import React, { useState } from 'react'
import LoadingSpinner from '../../../../../components/LoadingSpinner';

const SearchCompany = ({ setCompaniesList }) => {

  const [ search , setSearch ] = useState('') ;
  const [ loading, setLoading ] = useState(false)

  const handleSearchInput = e => {
     setSearch(e.target.value)
  }

  const clearSearch = () => {
        setSearch('')
        let url = `${ process.env.REACT_APP_BASE_URL }/company/all`
        setLoading(true)
        axios.get(url)
        .then(
            response => {                    
                    setCompaniesList(response.data.data)     
                    setLoading( false )  
            }
        )   
  }

  const searchCompany = e => {
        e.preventDefault()
        let url
        if( search ) url = `${ process.env.REACT_APP_BASE_URL }/company/search?searchTerm=${ search }`
        else  url = `${ process.env.REACT_APP_BASE_URL }/company/all`
        setLoading(true)
        axios.get(url)
        .then(
            response => {                    
                    setCompaniesList(response.data.data)     
                    setLoading( false )  
            }
        )    
  }

  return (
    <form className="search-form"> {  loading && <LoadingSpinner /> }
        <div className="flex  w-[450px] items-center">
            <div className="w-[300px] xl:w-96 ml-8 sm:ml-0">
                <div className="input-group relative flex flex-wrap items-stretch w-full">
                        <input
                                type="text" 
                                className="search-input"
                                name="search"
                                placeholder="Search Company..."
                                aria-label="Search" 
                                value={ search }
                                onChange = { handleSearchInput }
                                aria-describedby="button-addon2"                                
                        />
                        <button  onClick={ searchCompany } className="search-btn text-center" >
                           <i className='bi bi-search text-2xl'></i>
                        </button>
                        { search !== '' ?
                          <button  onClick={ clearSearch } className="search-btn text-center" >
                            <i className='bi bi-x text-2xl text-red-500'></i>
                        </button> : null }
                </div>
        </div>
    </div>
    </form>          
  )
}

export default SearchCompany