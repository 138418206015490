import React, { useContext, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { ReportContext } from "context/ReportDashboardContext";
import { useGetReport } from "hooks/api/report-dashboard";
import useConvertToMainReportPayload from "hooks/helpers/useConvertToMainReportPayload";
import convertToDomainDetails from "hooks/helpers/convertToDomainDetails";
import Panel from "../shared/Panel";

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
});
function KeyQuestions() {
  const { initialValue, setInitialValue } = useContext(ReportContext);
  const [domainSummary, setDomainSummary] = useState([]);
  const [domainDetail, setDomainDetail] = useState([]);
  const keyQuestionsReport = useGetReport(
    "/report/key-question",
    useConvertToMainReportPayload(initialValue),
    "key-questions-report",
    {
      onSuccess: (data) => {
        setDomainSummary(
          data?.domain?.map((data) => ({
            title: data?.domain,
            score: data?.score,
          }))
        );
        setDomainDetail(convertToDomainDetails(data?.domain));
      },
    }
  );

  const [scoreList, setScoreList] = useState([]);
  console.log({ domainSummary, domainDetail });

  useEffect(() => {
    console.log({ kqr: keyQuestionsReport?.data });
    let scores = {
      left: [],
      right: [],
    };
    let kqr = keyQuestionsReport?.data?.competency;
    for (let i = 0; i < keyQuestionsReport?.data?.competency?.length; i++) {
      let score = {};
      score.title = "Question";
      score.summary = kqr[i]?.question;
      score.imageList = kqr[i]?.country?.map((count) => {
        count.score = count?.total_obtained_score + "%";
        return count;
      });
      if (i % 2 === 0) {
        scores.left.push(score);
      } else {
        scores.right.push(score);
      }
    }
    setScoreList((e) => scores);
    return () => setScoreList([]);
  }, [keyQuestionsReport?.data]);

  // const cardList = [
  //   {
  //     title: "Overall",
  //     score: "1.7",
  //   },
  //   {
  //     title: "Overall",
  //     score: "1.7",
  //   },
  //   {
  //     title: "Overall",
  //     score: "1.7",
  //   },
  //   {
  //     title: "Overall",
  //     score: "1.7",
  //   },
  // ];
  // const scoreLists = {
  //   left: [
  //     {
  //       title: "Lorem Ipsum",
  //       summary: "Lorem Ipsum category",
  //       imageList: [
  //         { score: "50%", src: "/assets/france.png" },
  //         { score: "30%", src: "/assets/ghana.png" },
  //         { score: "20%", src: "/assets/senegal.png" },
  //         { score: "85.5%", src: "/assets/9ija.png" },
  //       ],
  //     },
  //     {
  //       title: "Lorem Ipsum",
  //       summary: "Lorem Ipsum category",
  //       imageList: [
  //         { score: "50%", src: "/assets/9ija.png" },
  //         { score: "65.5%", src: "/assets/9ija.png" },
  //       ],
  //     },
  //   ],
  // };
  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters>
        <Panel
          key="1"
          title="Key Questions"
          subtitle="Key Questions Subtitle"
          cardList={[]}
          scoreList={scoreList}
          showDomainCard={false}
        />
      </Container>
    </ThemeProvider>
  );
}

export default KeyQuestions;
