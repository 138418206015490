import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import styles from "../ReportStyleSheet";
import { yellow } from "@mui/material/colors";
function TableOfContent(params) {
  const {
    pageNumber,
    TCList = [
      { name: "Introduction", page: 1, index: "1.0" },
      { name: "Respondent Summary", page: 2, index: "2.0" },
      { name: "Competency Summary", page: 3, index: "3.0" },
      {
        name: "Competency Evaluation & Inferences",
        page: 4,
        index: "4.0",
        sublist: [
          { name: "Strength", page: 4, index: "4.1" },
          { name: "AREAS TO IMPROVE", page: "5", index: "4.2" },
          { name: "HIDDEN STRENGTH", page: "6", index: "4.3" },
          { name: "BLIND SPOTS", page: "7", index: "4.4" },
        ],
      },

      { name: "OPEN ENDED FEEDBACK", page: 8, index: "5.0" },
      { name: "DETAILED FEEDBACK", page: 10, index: "6.0" },
    ],
  } = params;
  return (
    <div>
      <View>
        {/* <View style={{ flexDirection: "row", display: "flex" }} wrap={false}>
          <Text style={{ width: "10%" }}>Helo</Text>
          <Text
            style={{
              width: "80%",
              overflow: "hidden",
              flexDirection: "row",
              // maxHeight: 20,
            }}
            // wrap={false}
            debug={true}
          >
            <Text>
              {`Hellom .................................................................................................................po
          `}
            </Text>
          </Text>
          <Text style={{ width: "10%" }}>123</Text>
        </View> */}

        <Text style={styles.header}> Table of Content</Text>

        {TCList.map((e) => (
          <View>
            <View
              style={[
                {
                  flexDirection: "row",
                  display: "flex",
                  marginBottom: 10,
                  fontSize: 14,
                },
              ]}
            >
              <Text style={[{ width: 30 }]}>{e?.index}</Text>
              <View
                style={[
                  styles.flex,
                  {
                    width: "100%",
                    maxWidth: "calc(100% - 40px)",
                    overflow: "hidden",
                  },
                ]}
              >
                <Text style={{}}>{` ${e?.name} `}</Text>
                <Text wrap={false} style={[styles.flex]}>
                  ................................................................................................................................................................
                </Text>
              </View>
              <Text style={[{ marginLeft: 20, width: 10 }]}>{e?.page}</Text>
            </View>

            {e?.sublist &&
              e.sublist.map((e) => (
                <View
                  style={[
                    {
                      flexDirection: "row",
                      display: "flex",
                      marginBottom: 10,
                      marginLeft: 15,
                      fontSize: 14,
                    },
                  ]}
                >
                  <Text style={[{ width: 30 }]}>{e?.index}</Text>
                  <View
                    style={[
                      styles.flex,
                      {
                        // width: "100%",
                        // maxWidth: "calc(100% - 40px)",
                        width: "calc(100% - 40px)",
                        overflow: "hidden",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        zIndex: 10,
                        paddingRight: 10,
                        // width: "100%",
                        flexGrow: 2,
                      }}
                      wrap={false}
                    >
                      {e?.name}
                    </Text>

                    <Text wrap={false} style={[{}]}>
                      ............................................................................................................................................................................................................................
                    </Text>
                  </View>
                  <Text style={[{ marginLeft: 20, width: 10 }]}>{e?.page}</Text>
                </View>
              ))}
          </View>
        ))}
      </View>
    </div>
  );
}

export default TableOfContent;
