import { createSlice } from "@reduxjs/toolkit";
import { roles } from "./roles";
import { formDataRoles } from "./form-data-roles";

const initialState =  {
    step : 1,
    isMappingEvaluatorStep: false,
    isEditing: false,
    errors : [],   
    id : "",      
    roles,
     formData : {
        project_name : '',
        project_description : '',
        company_id: "",             
        start_date : new Date().toString(),        
        end_date :  new Date().toString() ,  
        evaluator : true,
        survey_type : 'self',
        development_coach : true,
        status : 'draft',
        roles : formDataRoles(roles),
     }
   }
    export const createProject = createSlice({
       name : 'create project',
       initialState,
       reducers : {
         resetProjectSlice : (state) => {
             state.formData = initialState.formData;
             state.roles = initialState.roles;
             state.isMappingEvaluatorStep = initialState.isMappingEvaluatorStep
             state.isEditing = initialState.isEditing
             state.errors = [] ;
             state.id = ''
             state.step = 1 ;
         },
         toggleRole : (state, { payload }) => {
             state.roles[payload].status =  !state.roles[payload].status   

             let selectedRoles = state.roles.filter( role => role.status === true )
             
             state.formData.roles =   formDataRoles(selectedRoles)    
         },
         isMappingEvaluatorStep : (state, { payload = true}) => {
             state.isMappingEvaluatorStep = payload
             state.isEditing = true
         },
         moveToNextStep : (state) => {
            state.step = state.step + 1 ;
            window.scroll(0,0)
         },
         moveToPreviousStep : (state) => {
            state.step = state.step - 1 ;
            state.isEditing = true
            window.scroll(0,0)
         },
         resetStep : state => {
             state.step = 1 ;
         },
         setErrors : (state,{ payload }) => {
            state.errors = payload
         },       
         saveDraft : state => {
               state.formData.status = 'draft';
         },
         activateCampaign : state => {
            state.formData.status = 'active';
         },
         clearErrors : (state) => {
             state.errors = [] ;
         },
         updateInput : (state, { payload }) => {              
              state.formData = {...state.formData, [payload.name] : payload.value }               
         }, 
         incrementRoleMinValue : (state, { payload }) => {
                if( 
                     state.roles[payload].role.min_response < 10 &&
                     state.roles[payload].role.min_response < state.roles[payload].role.max_response 
                 ){
                    state.roles[payload].role.min_response += 1 ;
                    if(state.roles[payload].status){
                         state.formData.roles[payload].min_response += 1 ;
                    }
                }
        } ,
        decrementRoleMinValue :  (state, { payload }) => {
            if( state.roles[payload].role.min_response > 1 ){
                state.roles[payload].role.min_response -= 1 ;
                if(state.roles[payload].status){
                    state.formData.roles[payload].min_response -= 1 ;
               }
            }
         } ,     
         incrementRoleMaxValue : (state, { payload }) => {
            if( state.roles[payload].role.max_response < 10 ){
                state.roles[payload].role.max_response += 1 ;
                if(state.roles[payload].status){
                    state.formData.roles[payload].max_response += 1 ;
               }
            }
            } ,
            decrementRoleMaxValue :  (state, { payload }) => {
                if( state.roles[payload].role.max_response > 1 ){
                    state.roles[payload].role.max_response -= 1 ;
                    //auto reduce the value of the min response tobe === max
                    if(state.roles[payload].role.min_response > state.roles[payload].role.max_response){
                        state.roles[payload].role.min_response = state.roles[payload].role.max_response
                    }
                    if(state.roles[payload].status){
                        state.formData.roles[payload].max_response -= 1 ;
                   }
                }
            } ,    
            updateEvaluator : state => {
                state.formData.evaluator = !state.formData.evaluator
            },      
            updateDevCoach : state => {
                state.formData.development_coach = !state.formData.development_coach
            }, 
            updateCompanyId : ( state , { payload } ) => {
                state.formData.company_id = payload ;
            },
            updateProjectId : ( state , { payload }) => {
                 state.id = payload ;
            }
    }
})

export const { 
                 toggleRole, 
                 moveToNextStep,
                 setErrors,
                 moveToPreviousStep,
                 clearErrors,
                 updateInput, 
                 incrementRoleMinValue ,
                 decrementRoleMinValue ,
                 incrementRoleMaxValue,
                 decrementRoleMaxValue ,    
                 resetStep,
                 updateDevCoach,
                 updateEvaluator,  
                 activateCampaign,
                 saveDraft,
                 updateCompanyId,
                 updateProjectId,     
                 resetProjectSlice,    
                 isMappingEvaluatorStep     
               } = createProject.actions
               
export default createProject.reducer

