import React from 'react'

const LoadingSpinner = () => {
  return (
    <div className='loading-spinner h-[100vh] w-[100vw]'> 
         <div className='flex items-center justify-center h-full w-full p-0 m-0'>
         <div>
            <div
              className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status">
              <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
              >Loading...</span>
            </div>
         </div>
        </div>
    </div>
  )
}

export default LoadingSpinner