import React from "react";
import style from "./Accordion.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Accordions(params) {
  const {
    title = "FAQ",
    resultList = [
      { title: "what the fuck", summary: "lorem1" },
      { title: "what the fuck", summary: "lorem11" },
      { title: "what the fuck", summary: "lorem111" },
    ],
  } = params;
  return (
    <div>
      <h2 className={style.title}>{title}</h2>
      <div className={style.accordions}>
        {resultList.map((e, i) => (
          <Accordion key={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "700", fontSize: "1.2rem" }}>
                {e?.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{e?.summary}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default Accordions;
