import React, { useContext, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { ReportContext } from "context/ReportDashboardContext";
import { useGetReport } from "hooks/api/report-dashboard";
import useConvertToMainReportPayload from "hooks/helpers/useConvertToMainReportPayload";
import convertToStrengthDetails from "hooks/helpers/convertToStrengthDetails";
import Panel from "../shared/Panel";

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
});
function Strength() {
  const { initialValue, setInitialValue } = useContext(ReportContext);

  const [domainSummary, setDomainSummary] = useState([]);
  const [domainDetail, setDomainDetail] = useState([]);
  const keyQuestionsReport = useGetReport(
    "/report/strength-weakness",
    useConvertToMainReportPayload(initialValue),
    "strength-weakness-report",
    {
      onSuccess: (data) => {
        setDomainSummary(
          data?.competency?.map((data) => ({
            title: data?.domain,
            score: data?.score,
          }))
        );
        setDomainDetail(convertToStrengthDetails(data?.competency));
      },
    }
  );

  const cardList = [
    {
      title: "Overall",
      score: "1.7",
    },
    {
      title: "Overall",
      score: "1.7",
    },
    {
      title: "Overall",
      score: "1.7",
    },
    {
      title: "Overall",
      score: "1.7",
    },
  ];
  const scoreList = {
    left: [
      {
        title: "Lorem Ipsum",
        summary: "Lorem Ipsum category",
        imageList: [
          { score: "50%", src: "/assets/france.png" },
          { score: "30%", src: "/assets/ghana.png" },
          { score: "20%", src: "/assets/senegal.png" },
          { score: "85.5%", src: "/assets/9ija.png" },
        ],
      },
      {
        title: "Lorem Ipsum",
        summary: "Lorem Ipsum category",
        imageList: [
          { score: "50%", src: "/assets/9ija.png" },
          { score: "65.5%", src: "/assets/9ija.png" },
        ],
      },
    ],
  };
  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters>
        <Panel
          key="1"
          title="Strength and Weakness"
          subtitle="Strength and Weakness subtitle"
          cardList={[]}
          // scoreList={scoreList}
          scoreList={domainDetail}
        />
      </Container>
    </ThemeProvider>
  );
}

export default Strength;
