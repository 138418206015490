import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateInput, setErrors, clearErrors } from '../../../../../../features/create-project/create-project-slice'
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker'
import axios from 'axios'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import swal from 'sweetalert'

const EditCampaignDeadlineModal = ({target}) => {

   const { formData , errors }  = useSelector( state => state.createProjectForm )
   const { project_id } = useSelector( state => state.addParticipants.formData );
    
   const dispatch = useDispatch()
   const [ campaignName , setCampaignName ] = React.useState({ end_date : new Date(formData.end_date) })
   const [ loading, setLoading ]  = React.useState(false)

   const handleInput = (value) => {               
        setCampaignName({ ...campaignName, end_date : value })      
   }

   const updateCampaignName = () => {
        
       if(campaignName.end_date.toString() !== ''){
          setLoading(true)
          //close modal
          document.querySelector('#close-deadline-modal-window-e').click()
          //update the campaign name
          axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/${ project_id }`,{ end_date: campaignName.end_date })
           .then( response => {
                if(response.data.success){                              
                    dispatch( updateInput({ name : 'end_date' , value : campaignName.end_date.toString() }) )
                    dispatch( clearErrors() )              
                    swal('Success!', 'Campaign deathline is updated successfully.' , 'success')
                    setLoading(false)                    
              }
          })   
       }
       else dispatch( setErrors( { end_date : 'Error! campaign closing date cannot be empty' } ))
   }

  return (
   <>  {  loading && <LoadingSpinner /> }
    <div className='modal c-deathline-p-modal fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto' id={ target } tabIndex='-1' aria-labelledby='exampleModalLgLabel' aria-modal='true' role='dialog'>
       <div className='modal-dialog  modal-dialog-centered  relative w-auto pointer-events-none'>
          <div className='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
            <div className='modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
            <h5 className='text-xl bi bi-pencil font-medium leading-normal text-gray-800' id='exampleModalLgLabel'>
                &nbsp;  <span className='text-[16px]'> Edit Campaign Deadline </span>
            </h5>
            <button type='button' id='close-deadline-modal-window-e'
                className='btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body relative p-4 px-5'>
            <div className='md:flex '> 
             <div className='md:w-[150px] text-sm md:mt-4'> Campaign closes on: </div>
               <div className='w-full md:w-[300px] mt-2 md:mt-0'>                     
                     <DateTimePicker 
                                onChange = { (value) => handleInput(value) }
                                value = { new Date(campaignName.end_date) }
                                name='end_date'
                                className = 'date-picker'
                                calendarIcon = {
                                        <span   className=' bi bi-calendar text-red-500'></span>
                                    }
                                format = 'dd-MM-y h:mm:ss a'     
                                dayPlaceholder = 'dd'                                                                                
                                monthPlaceholder = 'mm'
                                yearPlaceholder = 'yyyy'
                                hourPlaceholder = 'h'
                                minutePlaceholder = 'm'
                                secondPlaceholder = 's'     
                                minDate = { new Date( Date.parse( formData.start_date ) ) }                                                                                                                   
                      />         
                       { errors.end_date &&
                        <div className='border-l border-l-2 border-red-500 text-red-500 text-[13px] text-center mt-4 py-2  bg-red-100'> 
                            {errors.end_date} 
                        </div>
                       }
                   </div>
               </div>                  

                <div className='flex justify-end mt-1'>
                      <button className='admin-form-btn'  onClick={ updateCampaignName }>
                         Update <span className='bi bi-arrow-right'>  </span>
                      </button>
                </div>
            </div>
         </div>
       </div>
    </div>
   </>
  )
}

export default EditCampaignDeadlineModal