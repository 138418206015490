
export const roles = [
    {
        status : true ,  
        id : 0,   
        role : {
             project_role : 'self',      
             min_response : 1 ,
             max_response : 1 ,
          }
    },
    {
        status : true ,  
        id : 1,   
        role : {
             project_role : 'manager',      
             min_response : 1 ,
             max_response : 2 ,
          }
    },
    {
        status : true ,  
        id : 2,   
        role : {
             project_role : 'peer',      
             min_response : 1 ,
             max_response : 3 ,
          }
    },
    {
        status : true ,  
        id : 3,   
        role : {
             project_role : 'direct report',      
             min_response : 1 ,
             max_response : 4 ,
          }
    }     
]
