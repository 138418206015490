import axios from "axios";
import { useQuery } from "react-query";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
async function getter(url) {
  let { data } = await request.get(url);
  return data;
}
async function getReport(url, body) {
  let { data } = await request.post(url, body);
  return data;
}
export function useGetReport(url, filter, key, options = {}) {
  const query = useQuery([key, filter], () => getReport(url, filter), options);
  return query;
}

async function getMainReport(url, body) {
  let { data } = await request.post(url, body);
  return data;
}
export function useGetMainReport(url, filter) {
  const query = useQuery(["get-MainReport", filter], () =>
    getMainReport(url, filter)
  );
  return query;
}
