import React, { useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import TableOfContent from "./Components/TableOfContent";
import Introduction from "./Components/Introduction";
import ReportSummary from "./Components/ReportSummary";
import CompetencySummary from "./Components/CompetencySummary";
import CompetencyEvaluation from "./Components/CompetencyEvaluation";
import OpenFeedback from "./Components/OpenFeedback";
import DetailedFeedback from "./Components/DetailedFeedback";
import Cover from "./Components/Cover";
// import Layout from "./Components/Layout";

// Create Document Component

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const MyDocument = ({ data, project }) => {
  const [pageNumber, setPageNumber] = useState({
    introduction: 15,
    respondent: 2,
    competence: 3,
    evaluation: 4,
    strength: 5,
    improve: 6,
    blind: 7,
    openEnded: 8,
    detailed: 9,
  });
  return (
    <Document>
      <Page style={styles.body}>
        <View
          style={[
            {
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-end",
            },
            styles.header,
          ]}
          fixed
        >
          <Image src="/assets/logo.png" style={{ height: 40 }} />
        </View>
        <Cover
          name={data?.participant?.name}
          campaignName={project?.project_name}
          date={data?.participant?.createdAt.split("T")[0]}
        />
        <TableOfContent pageNumber={pageNumber} />
        <Introduction
          setPageNumber={setPageNumber}
          summary={`360° appraisals compare an individual's self ratings on a number of behavioural competencies to the ratings provided by other individuals who regularly interact with them at work. These "raters" are grouped by the nature of their relationship with the individual being appraised to be a manager a peer, a direct report or other key stakeholders such as clients.`}
          list={[
            `The purpose of 360 degree feedback: `,
            `To enable one to communicate with staff, peers, managers, customers etc. in a transparent manner. `,
            `To develop an awareness of one's perceived behavior at the workplace. `,
            `To identify areas of strengths and weaknesses and plan on using them effectively. `,
            `To improve one's interaction skills and overall performance. `,
            `To incorporate the opinions and perspectives of others and obtain a holistic overview of behavior. `,
            `To pave the way for personal development and growth. `,
          ]}
          summary2={`The information contained in this report provides insight into your strengths and weaknesses within the competencies as well as any differences that might exist between your perception of your behaviour and that of others. The information can be used to enhance your self-awareness and better engage in your personal and professional development.`}
        />
        <ReportSummary
          summary={`The following is a summary of the group of respondents who were invited to participate and provide feedback for you`}
          responseList={data?.respondentSummary}
        />
        <CompetencySummary resultList={data?.competencySummary} />
        <CompetencyEvaluation
          resultList={data?.competencyEvaluatorStrength}
          summary="Below are the statements where you received the highest ratings and are considered your key strengths."
        />

        <CompetencyEvaluation
          subtitle=" 4.2 AREAS TO IMPROVE "
          self="Low"
          others="Low"
          resultList={data?.competencyEvaluatorimproveOn}
          summary="Below are the statements where you received the lowest ratings and are considered your areas of improvements."
        />
        <CompetencyEvaluation
          subtitle=" 4.3 HIDDEN STRENGTHS "
          self="Low"
          others="High"
          resultList={data?.hiddenStrength}
          hasExtra={true}
          summary="Hidden Strengths are statements where you rated yourself lower compared to the average rating of other respondents."
        />
        <CompetencyEvaluation
          subtitle=" 4.4 BLIND SPOTS "
          self="High"
          others="Low"
          resultList={data?.blindSpot}
          hasExtra={true}
          summary="Blind Spots are statements where you rated yourself higher compared to the average rating of other respondents.
        These may be your potential areas of improvement.
        "
        />

        <OpenFeedback
          summary="This section provides verbatim comments from all respondents. You will gain the most value if you pay attention to the frequently occurring topics and suggestions. 
Try to view the information objectively and reconcile it with the information in the previous rating sections.
"
          feedbackList={data?.openFeedback}
        />
        <DetailedFeedback detailedList={data?.detailedFeedback} />

        {/* <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      /> */}

        <View
          style={[
            {
              position: "absolute",
              width: "100%",
              bottom: 0,
              fontSize: 13,
            },
            styles.pageNumber,
          ]}
          fixed
        >
          <Text
            style={{ textAlign: "right" }}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
          {/* {page} | Page</Text> */}
          <Text>
            Powered by <Text style={{ color: "green" }}>Test</Text>
            <Text style={{ color: "yellow" }}>Assessify</Text>{" "}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
