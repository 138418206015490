import React, { useEffect, useState } from 'react'
import { updateViewProjectCompanyId } from '../../../../../features/view-projects/view-projects-slice'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import {
  Dropdown,
  Ripple,
  initTWE,
} from "tw-elements";

const ExistingCompanies = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ companiesList , setCompaniesList ] = useState([])
    
    useEffect( () => {
      axios.get(`${ process.env.REACT_APP_BASE_URL }/company/all`)
      .then(
         response => {
              if( response.data.success ){
                  setCompaniesList(response.data.data)                                                    
              }
         }
      )
    }, [] )

    useEffect( () => {
      initTWE({ Dropdown, Ripple });
     }, [] )

    const filterCampaigns = companyId => {
          dispatch( updateViewProjectCompanyId( companyId ) )
          navigate('/admin/view-campaigns')
      }

  return (
    <div>
      <div class="relative" data-twe-dropdown-ref>
        <button
          className='dropdown-toggle mt-2  bg-green-small text-white px-8 py-2.5 font-medium text-sm  leading-tight rounded-full flex items-center whitespace-nowrap'
          type="button"
         id="dropdownMenuButton1"
         data-twe-dropdown-toggle-ref
         aria-expanded="false"
         data-twe-ripple-init
         data-twe-ripple-color="light">
            <span className='bi bi-funnel mr-2 text-[18px]'></span>Select Company <span className='bi bi-chevron-down ml-2 text-md'></span>                
         <span class="ms-2 w-2 [&>svg]:h-5 [&>svg]:w-5">
            <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
               fill-rule="evenodd"
               d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
               clip-rule="evenodd" />
            </svg>
         </span>
      </button>
      <ul
         className="absolute z-[1000] py-3 w-[230px] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark"
         aria-labelledby="dropdownMenuButton1"
         data-twe-dropdown-menu-ref>
              { companiesList.length ? companiesList.map( (company_list) => {
                    const { company } = company_list ;
                    return (
                        <li key={company.id}>
                            <a
                             className=' dropdown-item text-sm py-1 px-4 capitalize font-normal flex items-center
                                         w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'
                              href='#'
                              onClick={ (event) => {
                                 event.preventDefault()                              
                                 filterCampaigns( company.id )
                              }}
                            >
                              <img 
                                  src={ company.company_logo } 
                                  className='w-[26px] h-[26px] rounded-full mr-2' 
                                  alt="company logo" 
                               />
                              { company.company_name }
                            </a>
                         </li>
                      )
                 }) : <p className='p-5'> Loading... </p> }    
             </ul>
        </div>
       
    </div>
  )
}

export default ExistingCompanies