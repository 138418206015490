import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Box, Button, Stack } from "@mui/material";
import ProfileCard from "./ProfileCard/ProfileCard";
import Modal from "../../../components/Modal/Modal";
import ProfileForm from "./Forms/ProfileForm";
import Header from "../../../components/Header/Header";
function Profile() {
  const [participantID, setParticipantID] = useState(
    localStorage.getItem("participant_id")
  );
  const fetchProfile = useQuery(["fetch-profile", participantID], () =>
    getProfile(participantID)
  );

  async function getProfile(participantID) {
    let res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/project/participant/get/${participantID}`
    );
    return res.data;
  }

  const [modalState, setModalState] = useState(false);
  return (
    <div>
      <Box>
        <Header title="Profile" />
        <Box sx={{ padding: "20px" }}>
          <Stack>
            <ProfileCard profile={fetchProfile} />
          </Stack>

          <Box>
            <Button onClick={() => setModalState(true)}>Edit Profile</Button>
          </Box>
          <Stack>
            <Modal open={modalState} setOpen={setModalState}>
              <ProfileForm
                profile={fetchProfile}
                handleClose={() => setModalState(false)}
              />
            </Modal>
          </Stack>
        </Box>
      </Box>
    </div>
  );
}

export default Profile;
