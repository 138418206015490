import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function CircularProgress(params) {
  const {
    value,
    width,
    height,
    color = "#6da945",
    textColor = "#6da945",
  } = params;
  return (
    <div style={{ width: width, height: height }}>
      {
        <CircularProgressbar
          value={value}
          text={`${value}%`}
          styles={buildStyles({
            textColor: textColor,
            pathColor: color,
          })}
        />
      }
    </div>
  );
}

export default CircularProgress;
