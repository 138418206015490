import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {QueryClientProvider, QueryClient} from "react-query"
import {ReactQueryDevtools} from 'react-query/devtools'
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
})
let persistor = persistStore(store)
root.render (
    <React.Fragment>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null}
                    persistor={persistor}>
                    <BrowserRouter>
                        <App/> {
                        < ReactQueryDevtools />
                    } </BrowserRouter>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    </React.Fragment>
);
