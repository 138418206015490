import validator from "validator";

/***************************************************
   functions to validate each form step
*****************************************************/
const ignoreWhiteSpace = { ignore_whitespace : true } ;

export const validateStep1 = ( formData ) => {

    let validated = false
    let errors = {}     

    if(validator.isEmpty(formData.company_name , ignoreWhiteSpace) && validator.isEmpty(formData.industry_type,ignoreWhiteSpace)){
        errors.company_name = "company name can't be empty" ;  
        errors.industry_type = "industry type can't be empty"  
    }
     else if( validator.isEmpty(formData.company_name, ignoreWhiteSpace) ){
        errors.company_name = "company name can't be empty" ;        
     }

     else if( validator.isEmpty(formData.industry_type , ignoreWhiteSpace) ){
       errors.industry_type = "industry type can't be empty"        
     }
     else validated = true;

     return { validated , errors };
}

export const validateStep2 = (formData) => {

    let validated = false;
    let errors = {} ;
 
    if( validator.isEmpty(formData.address, ignoreWhiteSpace)){
        errors.address = "company address can't be empty"       
    }     

     else if( validator.isEmpty(formData.email_suffix, ignoreWhiteSpace) ){
        errors.email_suffix = "business email suffix can't be empty";        
    }    

    /* else if(  String(formData.email_suffix).endsWith('@gmail.com') ||
              String(formData.email_suffix).endsWith('@yahoo.com') ||
              validator.contains(formData.email_suffix,'@gmail') ||
              validator.contains(formData.email_suffix,'@yahoo') ||
              !validator.contains(formData.email_suffix,'.')
    ){
        errors.email_suffix = "invalid business email suffix"       
     } */

    else if(validator.isEmpty(formData.phone_number , ignoreWhiteSpace )){
        errors.phone_number = "business phone number can't be empty";        
    }

    else if(formData.phone_number.toString().length > 14){
        errors.phone_number = "Error! business phone number is too long";        
    }
    else if(formData.phone_number.toString().length < 5 ){
        errors.phone_number = "Error! business phone number is too short.";        
    }
    else if(validator.isEmpty(formData.default_password , ignoreWhiteSpace )){
        errors.default_password = "Generic password can't be empty";        
    }  
    else if( formData.default_password.toString().length < 8 ){
        errors.default_password = "Generic password can't be less than 8 characters";        
    }  

    else if(validator.isEmpty(formData.company_logo , ignoreWhiteSpace )){
       errors.company_logo = "company logo is required. Please upload a company logo."
    }
        
    else validated = true;
    return { validated , errors };
}

export const validateStep3 = (formData) => {

    let validated = false;
    let errors = {} ;
    const { admin_name  , admin_email , admin_phone , password , password_confirm } = formData.admin
    
    if( validator.isEmpty(admin_name, ignoreWhiteSpace)){
        errors.admin_name = "Admin name can't be empty."       
    } 

    else if( validator.isEmpty(admin_email , ignoreWhiteSpace)){
        errors.admin_email = "Admin business email can't be empty."       
    } 

  /*  else if( !validator.isEmail(admin_email) || validator.contains(admin_email,'gmail') ||
             validator.contains(admin_email,'yahoo')
            ){
               errors.admin_email = "Invalid business email address."
             }
   */

    else if( formData.email_suffix !== admin_email.slice( admin_email.indexOf('@') + 1 , admin_email.length ) ){
        errors.admin_email = "Business email suffix and admin email suffix doesn't match."      
    }
             
    else if(validator.isEmpty(admin_phone, ignoreWhiteSpace)){
        errors.admin_phone = " Contact number can't be empty."      
    } 

    else if(admin_phone.toString().length > 14){
        errors.admin_phone = "Error! contact number is too long";        
    }
    else if(admin_phone.toString().length < 5){
        errors.admin_phone = "Error! contact number is too short";        
    }
    else if(validator.isEmpty(password, ignoreWhiteSpace)){
        errors.password = "password can't be empty."       
    }
    
    else if(password.length < 8 ){
        errors.password = "password can't be less than 8 characters"       
    }

    else if(password.length > 20 ){
        errors.password = "password must be between 8 to 20 characters"       
    }

    else if(validator.isEmpty(password_confirm, ignoreWhiteSpace)){
        errors.password_confirm = "password must be confirmed."       
    } 

    else if(password !== password_confirm){
        errors.password_confirm = "password mismatch. Please re-enter password."       
    }

    else validated = true;

    return { validated , errors };
}