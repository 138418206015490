import React, { useState,useEffect } from 'react'
import axios from 'axios'
import SurveyQuestions from './components/SurveyQuestions'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { useSelector } from 'react-redux'

const Survey = () => {

    const [ loading , setLoading ] = useState(false)
    const [ surveyQuestions , setSurveyQuestions ] = useState([])
    const [ totalSurveyQuestions, setTotalSurveyQuestions] = useState(0)
    const { adminName } = useSelector( state => state.adminLogin )

    useEffect(() => {
        setLoading(true)
        axios.post(`${ process.env.REACT_APP_BASE_URL }/survey/question`)
        .then(
          res => {
             if( res.data.success ){
                setSurveyQuestions(res.data.questions)
                let total = 0 ;
                res.data.questions.forEach( survey => {
                    total += survey.questions.length;                 
                });
                setTotalSurveyQuestions( total )
                setLoading(false)
             }
          }
        )
    }, [])

  return (
      <section className='md:mr-2 h-full'> { loading && <LoadingSpinner /> }
            <h1 className='py-3 text-xl text-white bg-green-small font-semibold pl-3 uppercase'> 
                Preview Survey
             </h1>
             <React.Fragment>
                <div className='p-4 px-2 md:px-5 mt-5'>
                      <img src="/assets/preview-evaluator-logo.PNG" alt="survey logo" className='w-[100px] h-[90px]' />
                     <h6 className='text-xl mt-3 text-gray-800'>
                         Hello <span className='text-green-small font-medium m'> 
                            { adminName }
                         </span>,  and welcome to the 360-degree feedback process.
                     </h6>
                     <p className='text-[14px] mt-3 md:mt-[25px] text-gray-700 xl:w-[90%]'>
                        The purpose of this survey is to gather feedback on your performance 
                        and behaviour patterns and to help you identify your strengths and 
                        areas for improvement.                         
                     </p>
                     <p className='text-[14px] mt-3 md:mt-[25px] text-gray-700 xl:w-[90%]'>
                          Please, carefully read all questions and respond accurately and honestly as
                           possible. If there are any questions that you are not in a position to answer
                            appropriately, it is best to leave them blank.  If you encounter any technical
                             difficulties or have any questions, please contact our support team at 
                          <a href='mailto:360@testassessify.com' className='text-blue-500 ml-2'> 
                              360@testassessify.com
                          </a> 
                     </p>                                                                                    
                        { surveyQuestions.length && 
                             <SurveyQuestions surveyQuestions={ surveyQuestions } 
                             /> 
                        }                                                 
             </div>    
         </React.Fragment>
    </section>
  )
}

export default Survey