import React, { useEffect } from "react";
import {
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Circle,
  Svg,
} from "@react-pdf/renderer";
import styles from "../ReportStyleSheet";

function Introduction(params) {
  const {
    summary = "",
    setPageNumber,
    list = ["purpose of 360", "Why 360", "Electricity to roast corn"],
    summary2 = "A town hall different from bala blu, blue blu bulaba. broom broom broom brooooooooom. Bala blu blue blu bulab.",
  } = params;
  function Page() {
    // alert("ouch");
    setPageNumber({ introduction: 4 });
  }
  // useEffect(() => {
  //   Page();
  // }, []);
  return (
    <div>
      <View style={{ fontSize: 12 }} break>
        <Text style={styles.header}> 1.0 Introduction</Text>
        <Text style={{ fontSize: 12, lineHeight: 1.5 }}>{summary}</Text>
        <Image
          // src={"https://react-pdf.org/images/logo.png"}
          src={"/assets/townhall.png"}
          style={{ height: "40vh", marginTop: 15, marginBottom: 30 }}
        />
        {list.map((e) => (
          <View style={[styles.list, { flexDirection: "row" }]}>
            <Text>• </Text>
            <Text>{e}</Text>
          </View>
        ))}
        <Text style={[{ marginTop: 15, lineHeight: 1.5 }]}>{summary2}</Text>
      </View>
    </div>
  );
}

export default Introduction;
