import React, { useState } from 'react'
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import axios from 'axios';
import { useSelector , useDispatch } from 'react-redux';
import { updateProject } from '../../../../../features/view-projects/view-projects-slice';
import {
  Ripple,
  Input,
  initTWE,
} from "tw-elements";

const SearchCampaign = ({setTotalCampaign}) => {

    const dispatch = useDispatch()
    const { company_id  }  = useSelector( state => state.viewProjects ) 
    const [ search , setSearch ] = useState('') ;
    const [ loading, setLoading ] = useState(false)

    React.useEffect( () => {
      initTWE({ Ripple, Input });
    }, [] )

    const handleSearchInput = e =>  setSearch(e.target.value)
  
    const searchCampaign = e => {

          e.preventDefault()
          let url
          if( search ) url = `${ process.env.REACT_APP_BASE_URL }/project/search/${ company_id }?searchTerm=${ search }`
          else  url = `${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }`
          setLoading(true)

          axios.get(url)
          .then(
              response => {                    
                      dispatch( updateProject(response.data) )
                      setTotalCampaign(response.data.campaigns.length)
                      setLoading( false )  
                      const pages = document.querySelectorAll('ul.pagination a') ;
                      pages[1].click()
              }
          )    
    }

  return (
    <>  { loading && <LoadingSpinner /> }
         
    <div
        className="relative flex"
        data-twe-input-wrapper-init
        data-twe-input-group-ref
     >
          <input
            type="search"
            className="peer block min-h-[auto] w-full rounded border-0 bg-white px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-green-small data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-green-small [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
            placeholder="Search Campaign"
            aria-label="Search"
            id="exampleFormControlInput"
            aria-describedby="basic-addon1" 
            onChange={ handleSearchInput }
            />
          <label
            for="exampleFormControlInput"
            className="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-green-small peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-green-small"
            >Search
          </label>
          <button
            className="relative z-[2] -ms-0.5 flex items-center rounded-e bg-green-small px-5  text-xs font-medium uppercase leading-normal text-white shadow-green-matext-green-small-3 transition duration-150 ease-in-out hover:bg-green-matext-green-small-accent-300 hover:shadow-green-matext-green-small-2 focus:bg-green-matext-green-small-accent-300 focus:shadow-green-matext-green-small-2 focus:outline-none focus:ring-0 active:bg-green-matext-green-small-600 active:shadow-green-matext-green-small-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
            type="button"
            id="button-addon1"
            data-twe-ripple-init
            data-twe-ripple-color="light"
            onClick={ searchCampaign }
            >
            <span className="[&>svg]:h-5 [&>svg]:w-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
              </svg>
            </span>
          </button>
       </div>
    </>
  )
}

export default SearchCampaign