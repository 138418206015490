import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import DomainCard from "./DomainCard";
import ScoreCard from "./ScoreCard";
function Panel(props) {
  const {
    key,
    title,
    subtitle,
    showDomainCard = true,
    cardList = [],
    // scoreList = {
    //   left: [
    //     {
    //       title: "Lorem Ipsum",
    //       summary: "Lorem Ipsum category",
    //       imageList: [
    //         { score: "50%", src: "/assets/9ija.png" },
    //         { score: "65.5%", src: "/assets/9ija.png" },
    //       ],
    //     },
    //     {
    //       title: "Lorem Ipsum",
    //       summary: "Lorem Ipsum category",
    //       imageList: [
    //         { score: "50%", src: "/assets/9ija.png" },
    //         { score: "65.5%", src: "/assets/9ija.png" },
    //       ],
    //     },
    //   ],
    // },
    scoreList,
  } = props;
  return (
    <Container key={key} disableGutters>
      <Typography variant="h5" sx={{ fontWeight: "700" }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ marginTop: "10px" }}>
        {subtitle}
      </Typography>

      <Stack
        // spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        sx={{ marginTop: "20px", marginBottom: "20px" }}
      >
        {showDomainCard &&
          cardList?.map((card, index) => (
            <Box sx={{ marginBottom: "20px", marginRight: "20px" }}>
              <DomainCard
                title={card?.title}
                score={card?.score}
                large={index === 10 ? true : false}
              />
            </Box>
          ))}
      </Stack>

      <Divider sx={{ border: "4px solid #D9D9D9" }} />
      <Grid mt={2} container spacing={2}>
        <Grid md={6} item>
          {scoreList?.left?.map((score, index) => (
            <Box key={index} mb={2}>
              <ScoreCard
                title={score?.title}
                summary={score?.summary}
                imageList={score?.imageList}
              />
            </Box>
          ))}
        </Grid>
        <Grid md={6} item>
          {scoreList?.right?.map((score, index) => (
            <Box key={index} mb={2}>
              <ScoreCard
                title={score?.title}
                summary={score?.summary}
                imageList={score?.imageList}
                sliderColor="#EA7844"
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Panel;
