import React from "react";
import "./ThankYou.css";
function ThankYou(params) {
  const {
    title,
    participant = "",
    summary = `Thanks for providing Development feedback to help ${participant} understand their strengths and prioritise any areas to improve.`,
  } = params;
  return (
    <div className="thankyou">
      <div className="thankyou__Content">
        <div className="thankyou__Left">
          <h2>Thanks for Completing the survey!</h2>
          <p>{summary}</p>
        </div>
        <div className="thankyou__Right">
          <div className="thankyou__image"></div>
          <img src="/assets/login.png" alt="girl " />
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
