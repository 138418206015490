import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from 'uuid'

export const evaluatorForParticipants = createSlice({
    name : 'evaluator for participants' ,
    initialState : {
        visible : false,
        errors : [],
        maxEvaluatorRoles : 0 ,
        formData : {
         evaluators : []
        }
    },
    reducers : {
         resetUploadEvaluatorForParticipantsSlice : state => {
            state.visible = false;
            state.maxEvaluatorRoles = 0 
            state.formData = { evaluators : [] }
            state.errors = []            
         },
         uploadEvaluatorForParticipants : (state) => {
             state.visible = true ;
         },
         addUploadedEvaluatorId: (state,{ payload = [] }) => {
            state.formData.evaluators = state.formData.evaluators.map( (evaluator,index) => {
                return {...evaluator, id: payload[index]?.id }
            })
         },
         hideEvaluatorForm: (state) => {
           state.visible = false
         },
         generateEvaluatorsForm : ( state, {payload}) => {
            state.formData.evaluators = [...payload] 
            state.maxEvaluatorRoles = [...payload].length
         },
         updateInput : (state,{payload}) =>{
            state.formData.evaluators[payload.id] = {
                                                 ...state.formData.evaluators[payload.id],
                                                 [payload.field] : payload.value
                                              }              
       },  
       setEvaluatorForParticipantsError : (state,{ payload }) => {
        state.errors = payload
     },
     deleteEvaluator : (state,{payload}) => {
        state.formData.evaluators = state.formData.evaluators.filter( evaluator => {
            return evaluator.id !== payload;
        } )
    },
    mapEvaluatorToParticipant : (state,{payload}) => {
        state.formData.evaluators[payload.id] = {
            ...state.formData.evaluators[payload.id],
            participantEmail : payload.participantEmail
         }
    },
    addMoreEvaluator : (state,{payload}) => {
        state.formData.evaluators.push({
            id : payload,
            evaluatorEmail : '',
            evaluatorName : '',
            evaluatorRole : 'self',
            participantEmail : ''                      
         })
     },
     readExcelFile : (state,{payload}) => {
        const data = payload.map(( data, id ) => {
            return { 
                     id : uuid(), 
                     evaluatorEmail : data['EVALUATOR EMAIL'],
                     evaluatorName : data['EVALUATOR NAME'],
                     evaluatorRole : data['EVALUATOR ROLE'],
                     participantEmail : data['PARTICIPANT EMAIL']                                               
                    }
         } )

        state.formData.evaluators = [...data] ;
     }
    }
})

export const { 
                  mapEvaluatorToParticipant,
                  uploadEvaluatorForParticipants,
                  generateEvaluatorsForm,
                  updateInput,
                  deleteEvaluator,
                  hideEvaluatorForm,
                  addMoreEvaluator,
                  readExcelFile,
                  addUploadedEvaluatorId,
                  resetUploadEvaluatorForParticipantsSlice,
                  setEvaluatorForParticipantsError,
             } = evaluatorForParticipants.actions
               
export default evaluatorForParticipants.reducer

