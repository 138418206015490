import { createSlice } from "@reduxjs/toolkit";
import { defautMailTemplate, devCoachDefaulTempalte, participantsDefaulTempalte } from "./mail-template";

const initialState = {
    errors : [],   
    test_email : '',
    formData : {  
        project_id:"",
        email_template: defautMailTemplate,
        devcoach_template : devCoachDefaulTempalte,
        participant_template : participantsDefaulTempalte,
        email_subject:"Take the First Step to Your 360 feedback experience",
        sender_email : "360@testassessify.com",
        on_invitation:true,
        on_reminder:true,
        on_adhoc_reminder:false,
        on_share_report:false,
        email_schedule_type:"fixed",
        email_schedule_fixed: new Date().toString(),
        email_schedule_day:7,
        email_schedule_time:"12:00",
        email_reply_to : "360@testassessify.com",
    }
}

export const setupMail = createSlice({
    name : 'setup mail' ,
    initialState,

    reducers : {
        clearSetupMailSlice : (state) => {
           state.formData = initialState.formData
           state.errors = [] ;
           state.test_email = ''
        },
        updateInput : (state, { payload }) => {    
            if(
                 payload.name === 'on_invitation' || payload.name === 'on_reminder' 
                 || payload.name === 'on_adhoc_reminder' || payload.name === 'on_share_report'
                ){
                    state.formData = {...state.formData, [payload.name] : !state.formData[payload.name]}
                }                
              else  state.formData = {...state.formData, [payload.name] : payload.value }               
       },
       
       updateTestMailInput : (state, { payload }) => { 
            state.test_email = payload ;
       },
       updateMailTemplateLogo :  (state, { payload }) => { 
        state.formData.email_template =  state.formData.email_template.replace('{logo}', payload) ;         
        state.formData.devcoach_template =  state.formData.devcoach_template.replace('{logo}', payload) ; 
        state.formData.participant_template =  state.formData.participant_template.replace('{logo}', payload) ; 
       },
       updateMailTemplateCampaignName :  (state, { payload }) => { 
        state.formData.devcoach_template =  state.formData.devcoach_template.replace('{cp_name}', payload) ; 
        state.formData.participant_template =  state.formData.participant_template.replace('{cp_name}', payload) ; 
       },
       setErrors : ( state, {payload} ) => {
          state.errors = payload;          
       },
       clearErrors : (state) => {
            state.errors = [];
       },
       setMailProjectId : (state,{payload}) => {
           state.formData.project_id = payload;
       },        
    }
})

export const { 
                updateInput, updateTestMailInput, clearSetupMailSlice,  updateMailTemplateCampaignName,
                clearErrors, setErrors, setMailProjectId, updateMailTemplateLogo,
              } = setupMail.actions
               
export default setupMail.reducer

