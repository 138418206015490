import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAccordion from "./FilterAccordion";
import FilterAutoComplete from "./FilterAutoComplete";
import { ReportContext } from "context/ReportDashboardContext";
import useObjectToArray from "hooks/helpers/useObjectToArray";
function FilterCard({ subFilter, initialFilter, setInitialFilter }) {
  const { initialValue, setInitialValue } = useContext(ReportContext);
  const [countryFilter, setCountryFilter] = useState({
    nigeria: false,
    ghana: false,
  });
  const [countryOptions, setCountryOptions] = useState([
    {
      name: "corporation",
      label: "Access Corporation",
      value: false,
      imageUrl: "/assets/access.png",
    },
  ]);
  const initialCountryValue = useObjectToArray({ ...initialFilter.country });
  const [selectedCountry, setSelectedCountry] = useState([
    ...initialCountryValue,
  ]);
  const [selectedSubsidiary, setSelectedSubsidiary] = useState([]);
  // const [subsidiaryFilter, setSubsidiaryFilter] = useState(subFilter);
  const [subsidiaryFilter, setSubsidiaryFilter] = useState({
    corporation: false,
    bank: false,
    payment: false,
    lending: false,
    pension: false,
    insurance: false,
  });

  const subsidiaryOptions = [
    {
      name: "corporation",
      label: "Access Corporation",
      value: false,
      imageUrl: "/assets/access.png",
    },
    {
      name: "bank",
      label: "Access Bank",
      value: false,
      imageUrl: "/assets/access.png",
    },
    {
      name: "payment",
      label: "Access Payments",
      value: false,
      imageUrl: "/assets/access.png",
    },
    {
      name: "lendings",
      label: "Access Lendings",
      value: false,
      imageUrl: "/assets/access.png",
    },
    {
      name: "pension",
      label: "Access Pensions",
      value: false,
      imageUrl: "/assets/access.png",
    },
    {
      name: "insurance",
      label: "Access Insurance",
      value: false,
      imageUrl: "/assets/access.png",
    },
  ];
  //   const stateOptions = [
  //     {
  //       title: "Accra",
  //       value: "Accra",
  //     },
  //     {
  //       title: "Lagos",
  //       value: "Lagos",
  //     },
  //     {
  //       title: "Ibadan",
  //       value: "Ibadan",
  //     },
  //   ];
  // const [stateValue, setStateValue] = useState(stateInitialFilter);
  const [stateValue, setStateValue] = useState([...initialFilter.state]);
  const [branchValue, setBranchValue] = useState([...initialFilter.branch]);
  const [domainValue, setDomainValue] = useState([...initialFilter.domain]);

  const handleChange = (event) => {
    console.log({ yyy: event, initialFilter });

    setInitialFilter((initial) => ({
      ...initial,
      country: {
        ...initialFilter.country,
        [event.target.name]: event.target.checked,
      },
    }));

    setCountryFilter({
      ...countryFilter,
      [event.target.name]: event.target.checked,
    });

    if (event.target.checked) {
      setSelectedCountry((country) => [...country, event.target.name]);
    } else {
      setSelectedCountry((country) =>
        country.filter((x) => x != event.target.name)
      );
    }

    console.log({ [event.target.name]: event.target.checked }, countryFilter);
  };
  const handleSubsidiaryChanges = (event) => {
    setInitialFilter((initial) => ({
      ...initial,
      subsidiary: {
        ...initialFilter.subsidiary,
        [event.target.name]: event.target.checked,
      },
    }));
    setSubsidiaryFilter({
      ...subsidiaryFilter,
      [event.target.name]: event.target.checked,
    });
    console.log(
      { [event.target.name]: event.target.checked },
      subsidiaryFilter
    );
    if (event.target.checked) {
      setSelectedSubsidiary((subsidiary) => [...subsidiary, event.target.name]);
    } else {
      setSelectedSubsidiary((subsidiary) =>
        subsidiary.filter((x) => x != event.target.name)
      );
    }
  };

  async function getter(url) {
    let res = await axios.get(url);
    return res.data;
  }

  async function getStates(country) {
    let stateJoin = [
      ...new Set(
        country
        // .map((country) => country?.state_name)
      ),
    ].join(",");
    console.log({ stateJoin: stateJoin });
    let res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/company/states?countries=${stateJoin}`
    );
    return res.data;
  }
  const queryDomain = useQuery(["fetch-domains"], () =>
    getter(`${process.env.REACT_APP_BASE_URL}/company/domain`)
  );
  const queryStates = useQuery(
    ["fetch-states", selectedCountry],
    () => getStates(selectedCountry),
    {
      //   enabled: !!selectedCountry,
    }
  );

  const queryCountry = useQuery("fetch Country", () =>
    getter(`${process.env.REACT_APP_BASE_URL}/company/country`)
  );

  async function getBranches(states) {
    console.log({ states });
    let stateJoin = [...states].map((state) => state?.state_name).join(",");
    let res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/company/branches?branch_state=${stateJoin}`
    );
    return res.data;
  }
  const queryBranches = useQuery(["fetch-branches", stateValue], () =>
    getBranches(stateValue)
  );

  useEffect(() => {
    if (queryCountry.data) {
      let newCountryFilter = {};
      queryCountry.data?.countries?.map((data) => {
        newCountryFilter[data] = false;
      });
      setCountryFilter(newCountryFilter);
      setCountryOptions(
        queryCountry.data?.countries?.map((data) => ({
          name: data?.name,
          label: data?.name,
          value: false,
          imageUrl: data?.flag || "/assets/access.png",
        }))
      );
    }
  }, [queryCountry.data]);

  useEffect(() => {
    // setInitialValue({
    //   domains: domainValue?.map((data) => data?.value),
    //   countries: selectedCountry,
    //   subsidiaries: selectedSubsidiary,
    //   states: stateValue,
    //   branches: branchValue,
    // });

    setInitialValue((initial) => ({
      ...initial,
      state: stateValue,
      branch: branchValue,
      domain: domainValue,
    }));

    // return () => setInitialValue({});
  }, [domainValue, stateValue, branchValue]);
  return (
    <Paper fullWidth elevation={0} sx={{ width: "100%" }}>
      {/* <Stack></Stack> */}

      <Stack fullWidth>
        <Accordion
          elevation={0}
          disableGutters
          disableFocusRipple
          disableRipple
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body1" fontWeight={700}>
              Filter by Country
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <Grid container spacing={2} fullWidth>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableFocusRipple
                        disableRipple
                        checked={countryFilter.nig}
                        onChange={handleChange}
                        name="nigeria"
                      />
                    }
                    label={
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: "2" }}
                      >
                        <Avatar
                          src="/assets/9ija.png"
                          height="10px"
                          width="10px"
                        />
                        <Typography variant="body2"> NIG</Typography>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableFocusRipple
                        disableRipple
                        checked={countryFilter.gha}
                        onChange={handleChange}
                        name="ghana"
                      />
                    }
                    label={
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: "2" }}
                      >
                        <Avatar
                          src="/assets/ghana.png"
                          height="10"
                          width="10"
                        />
                        <Typography variant="body2"> Ghana</Typography>
                      </Box>
                    }
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </Stack>
      <Divider />

      <Divider />
      <FilterAccordion
        title="Filter by Country"
        options={countryOptions}
        // values={countryFilter}
        values={initialFilter.country}
        handleChange={handleChange}
        // handleChange={handleSubsidiaryChanges}
      />
      <Divider />
      <FilterAccordion
        title="Filter by Subsidiary"
        options={subsidiaryOptions}
        // values={subsidiaryFilter}
        values={initialFilter.subsidiary}
        handleChange={handleSubsidiaryChanges}
      />
      <Divider />
      <FilterAutoComplete
        title="Filter by States"
        value={stateValue}
        setValue={setStateValue}
        // options={stateOptions}
        optionLabel={"state_name"}
        options={queryStates?.data?.states}
        label="Choose State(s)"
      />
      <Divider />
      <FilterAutoComplete
        title="Filter by Branch"
        value={branchValue}
        setValue={setBranchValue}
        // options={stateOptions}
        optionLabel={"branch_name"}
        options={queryBranches?.data?.branches}
        label="Choose Branch(es)"
      />
      <Divider />
      <FilterAutoComplete
        title="Filter by Domain"
        value={domainValue}
        setValue={setDomainValue}
        // options={stateOptions}
        optionLabel={"domain_name"}
        options={queryDomain?.data?.map((data) => ({
          domain_name: data,
          value: data,
        }))}
        label="Choose Domain(s)"
      />
    </Paper>
  );
}

export default React.memo(FilterCard);
