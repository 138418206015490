import { createSlice } from "@reduxjs/toolkit";
 
const initialState =  {
        company_id: "",    
        project : [],    
        hasCampaign : false,     
     }
   
    export const viewProjects = createSlice({
       name : 'view project' ,
       initialState,
       reducers : {
           updateViewProjectCompanyId : (state,{payload}) => {
               state.company_id = payload
           },
           updateProject : (state, { payload }) => {
              state.project = payload ;
              if(payload.campaigns.length){
                 state.hasCampaign = true;
                 state.project.campaigns = state.project.campaigns.sort( (a,b) => {                                
                  return new Date(b.project.createdAt).getTime() - new Date(a.project.createdAt).getTime()
                 })
              }
           }
       }
})

export const { updateViewProjectCompanyId, updateProject } = viewProjects.actions
               
export default viewProjects.reducer

