import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux'
import { updateInput } from '../../../../../../../features/setup-mail/setup-mail-slice';

const ParticipantsTextEditor = () => {

  const dispatch = useDispatch()
  const { formData } = useSelector( state => state.setupMail)

  const applyCustomStyle = () => {
          //apply style to feedback btn and footer
          const links = document.querySelectorAll('.ql-editor a');
          const footer = document.querySelector('.ql-editor p:last-child')
          const paragraphs = document.querySelectorAll('.ql-editor p')
          const headers = document.querySelectorAll('h1')
                    
          footer.style.background = '#e6e6e9'
          footer.style.padding = '20px 10px'
          footer.style.marginTop = '5px'    
          footer.className = 'ql-align-center'                

          links.forEach( link => {
              if(link.innerHTML.toLocaleLowerCase().includes('feedback') || 
                 link.innerHTML.toLocaleLowerCase().includes('dashboard') ||
                 link.innerHTML.toLocaleLowerCase().includes('view')
                ){
                  link.style.background= 'rgba(102, 129, 2,0.7)'
                  link.style.padding = '12px 36px';
                  link.style.color = '#fff'
                  link.style.borderRadius = '10px'
                  link.style.textDecoration = 'none' 
                  link.parentNode.className = 'ql-align-center'                                 
              }
          })
          
          paragraphs.forEach( p => {
             p.style.fontWeight = '400'
             p.style.fontSize = '15px'
             p.style.paddingTop = '5px'
          })

          headers.forEach( h => {
            h.style.fontWeight = '400'
         })

  }

  React.useEffect( () => {
      applyCustomStyle()        
  },[])

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3 , 4 , 5 , 6 , false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      [ {'align' : []} ],
      [ {'color' : [] } ,  {  'background' : [] } ],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image','align','color','background' 
  ]

  return (
    <ReactQuill 
            theme="snow"
            value={ formData.participant_template } 
            onChange={ (value) =>{ 
                dispatch( updateInput({ name : 'participant_template', value }) )
                applyCustomStyle()
            }} 
            formats = { formats }
            modules = { modules }
    />
  )
}

export default ParticipantsTextEditor