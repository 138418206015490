import React from 'react'
import { Link } from 'react-router-dom'

const CreateCompany = () => {
  return (
    <section className='md:mr-2 h-full'>
        <h1 className='py-1 text-xl text-white bg-green-small font-semibold pl-3 uppercase'>Company </h1>
        <div className='mt-[8rem] md:mt-[12rem]'>
              <div className='block md:w-[50%] mx-auto text-center'>
                   <span className='bi bi-building text-8xl text-gray-600'></span>
                   <h5 className='text-xl mt-2 font-semibold'> Site Admin </h5>
                   <p className='text-md mt-2 mb-5'>
                     Add a new company to get started.
                   </p>
                   <Link className='bg-red-500 py-2 px-8 text-white rounded-full bi bi-plus'
                        to={'/admin/add-new-company'}
                    >
                       Add New Company
                   </Link>
              </div>
        </div>
    </section>
  )
}

export default CreateCompany