import React from 'react'

 const SurveyQuestions = ({ surveyQuestions }) => {

        return (
           <div>
                {
                   surveyQuestions.map( (surveyQuestion,id) => {
                        return(
                             <React.Fragment key={id}>
                                 <h6 className='text-md mt-5 md:mt-12 md:flex justify-between'>
                                       <span className='text-green-small capitalize text-lg'>{surveyQuestion.category}</span>
                                       <div className='text-sm md:w-[390px] mt-3 sm:mt-0 flex justify-between'>
                                           <span>Never</span>
                                           <span>Rarely</span>
                                           <span>Sometimes</span>
                                           <span>Often</span>
                                           <span>Always</span>
                                       </div>
                                 </h6>
                <div className='bg-gray-100 py-4 mt-2 px-1 md:px-3'>                                                           
                   {                      
                      [...surveyQuestion.questions].sort( (a,b) => {
                        let  input =  a.quesstion_type.toLocaleLowerCase()
                        let  radio =  b.quesstion_type.toLocaleLowerCase()
                         if( input > radio ) return -1
                         if( input < radio ) return 1
                          return 0
                      }).map(
                          (survey,id) => {
                                           if(survey.quesstion_type==='Radio'){
                                             return(
                                                <div key={ survey.id } className='md:flex justify-between items-center'>
                                                   <p className='text-sm text-gray-800 md:w-[56%]'>  { survey.qusetion }. </p>
                                                     <div className='text-sm w-full md:w-[380px] mt-3 md:mt-0 flex justify-between mb-8 bg-white px-2 py-3'>                                     
                                                             <input 
                                                                  className='admin-form-check-lg'  
                                                                  type='radio' name={'survey-preview' + id } 
                                                                  id='inlineRadio1' value={ 1 }
                                                                  defaultChecked = { false } 
                                                                  data-bs-toggle='tooltip' 
                                                                  data-bs-placement='bottom'
                                                                  title='Never'                          
                                                                  />                                    
                                                               <input 
                                                                  className='admin-form-check-lg'  
                                                                  type='radio' name={'survey-preview' + id } 
                                                                  id='inlineRadio1' value={ 1 }   
                                                                  data-bs-toggle='tooltip' 
                                                                  data-bs-placement='bottom'
                                                                  title='Rarely'                                                              
                                                                  />                                    
                                                               <input 
                                                                  className='admin-form-check-lg'  
                                                                  type='radio' name={'survey-preview' + id } 
                                                                  id='inlineRadio1' value={ 1 }   
                                                                  data-bs-toggle='tooltip' 
                                                                  data-bs-placement='bottom'
                                                                  title='Sometimes'                                                              
                                                                  />                                    
                                                               <input 
                                                                  className='admin-form-check-lg'  
                                                                  type='radio' name={'survey-preview' + id} 
                                                                  id='inlineRadio1' value={ 1 } 
                                                                  data-bs-toggle='tooltip' 
                                                                  data-bs-placement='bottom'
                                                                  title='Often'                                                                
                                                                  />                                    
                                                               <input 
                                                                  className='admin-form-check-lg'  
                                                                  type='radio' name={'survey-preview' + id } 
                                                                  id='inlineRadio1' value={ 1 } 
                                                                  data-bs-toggle='tooltip' 
                                                                  data-bs-placement='bottom'
                                                                  title='Always'                                                                
                                                                  />                                     
                                                            </div>
                                                          </div>
                                                           )
                                           }
                                           else {
                                             return(
                                                <div key={ survey.id } className='md:flex justify-between items-center'>
                                                   <p className='text-sm text-gray-800 w-full md:w-[56%]'>  { survey.qusetion }. </p>
                                                     <div className='text-sm mt-3 md:mt-0 w-full md:w-[380px] flex justify-between mb-8 bg-white px-2 py-3'>                                     
                                                             <input 
                                                                  type={'text'}
                                                                  className='admin-form-input'                                                                                              
                                                                  />                                                                                                                                       
                                                            </div>
                                                          </div>
                                                 )
                                              }                                           
                                           }
                                         )
                                      }
                                </div>
                             </React.Fragment>
                        )
                   })
                }
          </div>
        )
}

export default SurveyQuestions