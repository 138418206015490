import React, { useState, useRef, useContext } from "react";
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Select,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterMenu from "./Filter/Menu/FilterMenu";
import { ReportContext } from "context/ReportDashboardContext";
import { useFetchCampaigns } from "hooks/admin/fetch";
function Header() {
  const { initialValue, setInitialValue } = useContext(ReportContext);

  const fetchCampaign = useFetchCampaigns();
  const filterButtonRef = useRef(null);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState("");
  function openFilterMenuFunc(event) {
    setAnchorEl(event.target);
    setOpenFilterMenu(true);
  }
  function handleCloseFilterMenu() {
    setOpenFilterMenu(false);
  }

  function selectProject(event) {
    setInitialValue((val) => ({
      ...val,
      projectId: event.target.value,
    }));
  }
  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        mb={4}
      >
        <Stack direction="row">
          <Box>
            <Button
              variant="outlined"
              color="success"
              size="large"
              startIcon={
                <FilterAltIcon fontSize="small" sx={{ color: "#94A74D" }} />
              }
              ref={filterButtonRef}
              onClick={openFilterMenuFunc}
            >
              <Typography variant="body2" color="success">
                Filter
              </Typography>
            </Button>
          </Box>
        </Stack>
        <Box sx={{ minWidth: "150px" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Project
            </InputLabel>
            <Select
              fullWidth
              // placeholder="Select Project"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={initialValue?.projectId}
              label="Select Project"
              onChange={selectProject}
            >
              <MenuItem value="" disabled>
                Select Project
              </MenuItem>
              {fetchCampaign?.data?.campaigns?.map((option, index) => (
                <MenuItem
                  value={option?.project?.id}
                  key={option?.project?.id || index}
                >
                  {option?.project?.project_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="success"
            size="large"
            endIcon={<KeyboardArrowDownIcon fontSize="small" />}
          >
            <Typography variant="body2">Download Report As</Typography>
          </Button>
        </Box>
      </Stack>
      <FilterMenu
        open={openFilterMenu}
        handleClose={handleCloseFilterMenu}
        anchorEl={anchorEl}
      />
    </Box>
  );
}

export default Header;
