import styles from "../ReportStyleSheet";
import { Text, View, Image, Svg, Path } from "@react-pdf/renderer";
function OpenFeedback({
  summary = "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus commodi delectus, temporibus ut fugit est dolore sunt iste? Nulla labore eos itaque neque necessitatibus corporis fuga, nisi ad optio maxime quis modi. In facilis, accusantium cum fugit voluptas quam quos consequuntur ipsam deserunt aperiam culpa commodi repellat soluta assumenda repellendus?",
  summary2 = "#ALL NOTABLE QUALITATIEVE COMMENTS ARE LISTED HERE",
  summary3 = "#NEGATIVE FEEDBACK QUESTIONS FIRST, THEN THE POSITVE FEEDBACK AFTER",
  feedbackList = [
    {
      title:
        "What would you want to do differently or stop doing? / What would you want this person to do differently or stop doing?",
      summaryList: [{ feedback: "stopp" }, { feedback: "clann" }],
    },
    {
      title:
        "What would you continue to do? / What would you want this person to do differently or stop doing?",
      summaryList: [{ feedback: "stopp" }],
    },
  ],
}) {
  return (
    <View style={{ fontSize: 12 }} break>
      <Text style={[styles.header]}> 5.0 OPEN ENDED FEEDBACK</Text>
      <Text style={{ marginBottom: 10, fontSize: 12, lineHeight: 1.5 }}>
        {summary}
      </Text>
      {/* <Text style={{ marginBottom: 10, fontSize: 12, lineHeight: 1.5 }}>
        {summary2}
      </Text>
      <Text style={{ marginBottom: 10, fontSize: 12, lineHeight: 1.5 }}>
        {summary3}
      </Text> */}

      {feedbackList.map((e) => (
        <View
          wrap={false}
          style={[styles.feedback, { paddingLeft: 10, marginTop: 20 }]}
        >
          <Text style={{ fontSize: 12, marginBottom: 15 }}>
            {e?.title} {`(${e?.category_name})`}
          </Text>

          {e?.summaryList?.map((e) => (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Image
                src="/assets/avatar.jpg"
                style={{ height: 30, borderRadius: "99%" }}
              />
              <Text style={[styles.chat, { marginLeft: 10 }]}>
                {e?.question_response}
              </Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}

export default OpenFeedback;
