export const tabHeaders = [
  {
    label: "Overall",
    value: "1",
  },
  {
    label: "Competency",
    value: "2",
  },
  {
    label: "Key Questions",
    value: "3",
  },
  {
    label: "Strength & Weakness",
    value: "4",
  },
];

export const tabOptions = [
  {
    value: "1",
    panel: {
      title: "Lorem",
      subtitle: "Ipsum overdo",
    },
  },
];
export const subsidiaryOptions = [
  {
    name: "corporation",
    label: "Access Corporation",
    value: false,
    imageUrl: "/assets/access.png",
  },
  {
    name: "bank",
    label: "Access Bank",
    value: false,
    imageUrl: "/assets/access.png",
  },
  {
    name: "payment",
    label: "Access Payments",
    value: false,
    imageUrl: "/assets/access.png",
  },
  {
    name: "lendings",
    label: "Access Lendings",
    value: false,
    imageUrl: "/assets/access.png",
  },
  {
    name: "pension",
    label: "Access Pensions",
    value: false,
    imageUrl: "/assets/access.png",
  },
  {
    name: "insurance",
    label: "Access Insurance",
    value: false,
    imageUrl: "/assets/access.png",
  },
];
