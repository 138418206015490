import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { resetProjectSlice, saveDraft } from '../../../../../features/create-project/create-project-slice'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import axios from 'axios'
import { clearParticipantsSlice } from '../../../../../features/add-participants/add-participants-slice'
import { clearSetupMailSlice } from '../../../../../features/setup-mail/setup-mail-slice'
import { resetUploadEvaluatorForParticipantsSlice } from '../../../../../features/evaluator-for-Participants/evaluatorForParticipantsSlice'
import { mailtemplateFooter, mailtemplateHeader } from './forms/set-up-mail-form/mail-template-header-footer'

const SaveDraft = () => {

  const { formData } = useSelector( state => state.setupMail )
  const { createProjectForm }  = useSelector( state => state )
   const { project_id } = useSelector( state => state.addParticipants.formData );

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ loading, setLoading ]  = React.useState(false)

  const sendMail = () => {
    setLoading(true)
    dispatch( saveDraft() )
    
    axios.post(`${ process.env.REACT_APP_BASE_URL }/project/email-setup`,
              {
                 ...formData, 
                 email_template : mailtemplateHeader +  formData.email_template + mailtemplateFooter,
                 devcoach_template : mailtemplateHeader +  formData.devcoach_template + mailtemplateFooter,
                 participant_template : mailtemplateHeader +  formData.participant_template + mailtemplateFooter
              })
    .then( res => {      
         if(res.data.success){
          
          axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/${ project_id }`,{              
              isComplete : true,
            })
            .then( response => {
                if(response.data.success){   
                   //set the campaign status to active                                                                                  
                    setLoading(false)
                    dispatch( resetProjectSlice() )
                    dispatch( clearParticipantsSlice() )
                    dispatch( clearSetupMailSlice() )
                    dispatch( resetUploadEvaluatorForParticipantsSlice() )
                    navigate('/admin/view-campaigns')
                }
            })   
         }
    } )
  }

  return (
    <>
        <button      
            onClick={ sendMail }                             
            className='bg-blue-500 text-white py-2 ml-4 text-sm px-8 mt-8 rounded-full'
         >
            Save Draft
        </button> 
        {  loading && <LoadingSpinner />  }
    </>
  )
}

export default SaveDraft