import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "tw-elements";
import "bootstrap-icons/font/bootstrap-icons.css";
import ProtectedRoute from "./ProtectedRoute";
import Admin from "./pages/admin/Admin";
import Participant from "./pages/participant/Participant";
import Coach from "./pages/coach/Coach";
import LoginCoach from "./pages/coach/login-coach/Login";
import Login from "./pages/login/Login";
import LandingPage from "./pages/landingPage/LandingPage";
import ClientAdminLogin from "./pages/admin/auth/ClientAdminLogin";
import Evaluator from "./pages/evaluator/Evaluator";
import SiteAdminLogin from "./pages/admin/auth/SiteAdminLogin";
import { useSelector } from "react-redux";
import Report from "./pages/report/Report";
import ForgotPasswordEmail from "./pages/admin/auth/ForgotPasswordEmail";
import ResetPassword from "./pages/admin/auth/ResetPassword";
import FAQ from "./pages/faq/FAQ";
import PageNotFound from "./components/PageNotFound";
import swal from 'sweetalert'
// import Participant from "./pages/participant/Campaign/Campaign";

function App() {
  
  const location = useLocation();
  const { isAuth } = useSelector((state) => state.adminLogin);

  useEffect(() => {

    window.scroll(0, 0)            
    if(location.pathname.includes('/admin/')){
       document.body.style.overflow = 'hidden'        
     }
    else document.body.style.overflow = 'auto'
    /*if( 
         location.pathname.includes('/site-admin-login') ||
          location.pathname.includes('/client-admin-login')
      ){
         if(isAuth){
           swal('Error!', 'You need to logout first.' , 'error')

         }
      }*/
    
  } , [location.pathname]);

  return (
    <Routes>
      <Route
        path="/admin/*"
        element={ isAuth ? <Admin /> : <Navigate to={"/site-admin-login"} />}
      />
      <Route exact path="/" element={<LandingPage />} />
      <Route
        path="/participant/*"
        element={
          <ProtectedRoute>
            <Participant />
          </ProtectedRoute>
        }
      />
      <Route path="/login/*" element={<Login />} />
      <Route
            path="/client-admin-login" 
            element={ isAuth ? <Navigate to = {'/admin/dashboard'} /> :  <ClientAdminLogin /> } 
      />
      <Route 
              path="/site-admin-login" 
              element={ isAuth ? <Navigate to = {'/admin/view-company'} /> :  <SiteAdminLogin />} 
      />
      <Route path="/auth/reset-password" element={ <ResetPassword /> } />
      <Route path="/auth/forgot-password" element={ <ForgotPasswordEmail /> } />
      <Route
        path="/coach/*"
        element={
          <ProtectedRoute redirectTo="/coach/login">
            <Coach />
          </ProtectedRoute>
        }
      />
      <Route exact path="/coach/login" element={<LoginCoach />} />
      <Route path="/evaluator/*" element={<Evaluator />} />
      <Route path="/report/*" element={<Report />} />
      <Route path="/faq/*" element={<FAQ />} />
      <Route path="*" element={ <PageNotFound height="100vh" />} />
    </Routes>
  );
}

export default App;
