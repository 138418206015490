export default function convertToDomainDetails(payload) {
  console.log({ payload });
  //payload is an array
  let left = [];
  let right = [];

  if (payload?.constructor !== Array) {
    return { left, right };
  }
  for (let i = 0; i < payload.length; i++) {
    if (i % 2 === 0) {
      left.push({
        title: payload[i]?.competency_name,
        summary: "Lorem Ipsum category",

        imageList: [
          ...payload[i]?.country?.map((data) => ({
            score: data?.total_obtained_score + "%" || "0%",
            src: "/assets/france.png",
          })),
          //   { score: "85.5%", src: "/assets/9ija.png" },
        ],
      });
    } else {
      right.push({
        title: payload[i]?.competency_name,
        summary: "Lorem Ipsum category",

        imageList: [
          ...payload[i]?.country?.map((data) => ({
            score: data?.total_obtained_score + "%" || "0%",
            src: "/assets/france.png",
          })),
        ],
      });
    }
  }
  console.log({ emi: { left, right } });
  return { left, right };
}
