import React, { useEffect, useState } from 'react'
import axios from 'axios'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { useDispatch, useSelector } from 'react-redux'
import { updateProject, updateViewProjectCompanyId } from '../../../../../features/view-projects/view-projects-slice'
import {
   Dropdown,
   Ripple,
   initTWE,
 } from "tw-elements";

const FilterCampaignByCompany = ({ setTotalCampaign }) => {
   
  const [activeCompany, setActiveCompany ] = useState('Filter By Company')
  const [ companiesList , setCompaniesList ] = useState([])
  const [ loading, setLoading ] = useState(false)

  const { company_id }  = useSelector( state => state.viewProjects ) 
 
  const dispatch = useDispatch()

  useEffect( () => {
    axios.get(`${ process.env.REACT_APP_BASE_URL }/company/all`)
    .then(
       response => {
            if( response.data.success ){
                setCompaniesList(response.data.data)                                                    
            }
       }
    )
  }, [] )

  useEffect( () => {
   initTWE({ Dropdown, Ripple });
  }, [] )

  const filterCampaigns = (companyId, isFilter) => {
    setLoading(true)
 
    let url = `${ process.env.REACT_APP_BASE_URL }/project/all/${ companyId }?access=true`
    if (isFilter === null ){
       url = `${ process.env.REACT_APP_BASE_URL }/project/all/${ companyId }`
    }

    axios.get(url)
    .then(
    res => {
            if( res.data.success ){      
                setTotalCampaign( res.data.campaigns.length )                                                  
                dispatch( updateProject( res.data ) )
                setLoading(false)  
                const pages = document.querySelectorAll('ul.pagination a') ;
                pages[1].click()        
            }
        })
  }
 
  return (
    <>  { loading && <LoadingSpinner /> }
 
      <div class="relative" data-twe-dropdown-ref>
        <button
         className="flex items-center rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-gray-900 shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
         type="button"
         id="dropdownMenuButton1"
         data-twe-dropdown-toggle-ref
         aria-expanded="false"
         data-twe-ripple-init
         data-twe-ripple-color="light">
            <span className='bi bi-funnel mr-2 text-[18px]'></span> { activeCompany } <span className='bi bi-chevron-down ml-2 text-md'></span>                
         <span class="ms-2 w-2 [&>svg]:h-5 [&>svg]:w-5">
            <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
               fill-rule="evenodd"
               d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
               clip-rule="evenodd" />
            </svg>
         </span>
      </button>
      <ul
         className="absolute z-[1000] py-3 w-[230px] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark"
         aria-labelledby="dropdownMenuButton1"
         data-twe-dropdown-menu-ref>
         <li>
         
            <a
               className='text-sm py-1 px-4 capitalize font-normal flex items-center
                           w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'
               href='#'
               data-twe-dropdown-item-ref
               onClick={ (event) => {
                  event.preventDefault()                                
                  filterCampaigns(company_id, null)
                  window.sessionStorage.setItem('in-filter-mode',0)
               }}
               >
                  Get All Companies                             
               </a>
         </li>
    { companiesList.length ? companiesList.map( (company_list) => {
                    const { company } = company_list ;
                    return (
                        <li key={company.id}>
                            <a
                             href='#'
                             className="flex items-center w-full whitespace-nowrap bg-white px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-zinc-200/60 focus:bg-zinc-200/60 focus:outline-none active:bg-zinc-200/60 active:no-underline dark:bg-surface-dark dark:text-white dark:hover:bg-neutral-800/25 dark:focus:bg-neutral-800/25 dark:active:bg-neutral-800/25"
                             data-twe-dropdown-item-ref
                             onClick={ (event) => {
                                event.preventDefault()
                                setActiveCompany( company.company_name )
                                filterCampaigns( company.id )
                                dispatch( updateViewProjectCompanyId( company.id ) )                              
                                window.sessionStorage.setItem('in-filter-mode',1)
                             }}
                            >
                              <img 
                                  src={ company.company_logo } 
                                  className='w-[26px] h-[26px] rounded-full mr-2' 
                                  alt="company logo" 
                               />
                              { company.company_name }
                            </a>
                         </li>
                      )
                 }) : <p className='p-5'> Loading... </p> }    
             </ul>
        </div>
    </>
  )
}

export default FilterCampaignByCompany

 