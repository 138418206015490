import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Dashboard.css";
import Header from "../../../components/Header/Header";
import CircularProgress from "../../participant/components/CircularProgress/CircularProgress";

import TablePagination from "@mui/material/TablePagination";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

function Dashboard(params) {
  const {
    user = "Tiwa Savage",
    deadline = "25",
    todo = "3 reports",
    participants = 6,
    completed = 1,
    pending = 2,
    undone = 3,
  } = params;
  let multiple = [
    { title: "Self Assessment", value: 40 },
    { title: "Evaluator Response", value: 80 },
  ];

  const [dashboard, setDashboard] = useState({});
  const [reportReady, setReportReady] = useState([]);
  const [reportPending, setReportPending] = useState([]);
  const [sortedReportPending, setSortedReportPending] = useState([]);
  const [selfAssessmentCompleted, setSelfAssessmentCompleted] = useState([]);
  const [evaluatorResponse, setEvaluatorResponse] = useState();
  const coach_id = localStorage.getItem("coach_id");
  const project_id = localStorage.getItem("project_id");
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    const request = axios
      .post(
        " https://sandboxapi.360degree.testassessify.com/project/coach-dashboard",
        { project_id, coach_id }
      )
      .then((e) => {
        // console.log(e.data?.allReport);
        setDashboard(e.data);
        setReportReady(e.data?.allReport.filter((e) => e.readyStatus === true));
        setReportPending(
          e.data?.allReport.filter((e) => e.readyStatus === false)
        );

        setSortedReportPending(
          e.data?.allReport.filter((e) => e.readyStatus === false)
        );

        setShowData(true);
        console.log(
          [...e?.data.allReport].reduce(
            (a, b) => a + b?.evaluatorResponse.percentage,
            0
          )
        );

        setSelfAssessmentCompleted(
          e.data?.allReport.filter((e) => e.selfAssessment === true)
        );

        setEvaluatorResponse(
          [...e?.data.allReport].reduce(
            (a, b) => a + b?.evaluatorResponse.percentage,
            0
          )
        );
        // setEvaluatorResponse(
        //   [...e?.data.allReport].reduce(
        //     (a, b) =>
        //       a.evaluatorResponse.percentage + b.evaluatorResponse.percentage
        //   ),
        //   0
        // );
      });
  }, []);

  const [pendingPage, setPendingPage] = useState(0);
  const [pendingRowPage, setPendingRowPage] = useState(10);
  const handlePendingChange = (event, value) => {
    setPendingPage(value);
  };
  const handlePendingRowChange = (event) => {
    setPendingRowPage(parseInt(event.target.value, 10));
    setPendingPage(0);
  };

  const [filterValue, setFilterValue] = useState("");
  const handleFilter = (report, opt) => {
    console.log(opt, "oooop");
    let defaultReport = [...report];
    if (opt == "done") {
      console.log(report);
      setSortedReportPending((e) =>
        [...report].sort(
          (a, b) => a.evaluatorResponse.count - b.evaluatorResponse.count
        )
      );
    } else {
      console.log("initial", defaultReport);
      setSortedReportPending((e) => defaultReport);
    }
  };
  const orderPending = (report, value) => {
    let def = [...report];
    if (value === "desc") {
      setSortedReportPending((e) => [...e].reverse());
    } else {
      // setSortedReportPending((e) => def);
    }
  };

  return (
    <div className="dashboard">
      <Header title="Dashboard" />
      <h2 className="dashboard__Title">
        Welcome, <span>{user}</span>
      </h2>
      <div className="dashboard__Content">
        {/*<div className="dashboard__Notification">
          <div className="notify-icon">
            <MarkUnreadChatAltIcon />
          </div>
          <p className="notify-text">
            <span>{deadline}</span> days. You have {todo} to complete
          </p>
        </div>*/}

        <div className="dashboard__Statistics">
          <div className="stats-left">
            <div className="neumorph dashboard-participants">
              <h2>Participants</h2>
              <h2>{dashboard?.allReport?.length}</h2>
            </div>

            <div className="dashboard-reports">
              <div className="report neumorph report-completed">
                <h4 className="bold">Reports Ready</h4>
                <h4>{reportReady?.length}</h4>
              </div>
              {/* <div className="report neumorph report-pending">
                <h4 className="bold ">Reports pending</h4>
                <h4>{pending}</h4>
              </div> */}
              <div className="report neumorph report-undone">
                <h4 className="bold">Reports Not Ready </h4>
                <h4>{reportPending?.length}</h4>
              </div>
            </div>
          </div>
          {/* {multiple.map((e) => ( */}
          <div className="stats-right">
            <div className="stats-task">
              <p className="task-title">Self Assessment</p>
              {/* Circolar progress */}
              <CircularProgress
                value={Math.round(
                  (Number(selfAssessmentCompleted?.length) /
                    dashboard?.allReport?.length) *
                    100
                )}
              />
            </div>
          </div>
          <div className="stats-right">
            <div className="stats-task">
              <p className="task-title">Evaluator Response</p>
              {/* Circolar progress */}
              <CircularProgress
                value={Math.floor(
                  evaluatorResponse / dashboard?.allReport?.length
                )}
              />
            </div>
          </div>
          {/* ))} */}
        </div>

        <div className="dashboard__Reports">
          <div className="dashboard__Report">
            <Accordion className="dashboard-accordion">
              <AccordionSummary className="report-accordion-summary">
                <div className="neumorph report-header">
                  {/* icon */}
                  <div className="report-icon-arrow">
                    <ArrowDropDownIcon />
                  </div>
                  <h3 className="report-title">Reports Ready</h3>
                </div>
              </AccordionSummary>
              <AccordionDetails className="report-accordion-details">
                <div className="report-bodyy">
                  <div className="report-name ">
                    <h5 className="bold">Participant Name</h5>
                    <p>Completed on 23/12/2022</p>
                  </div>
                  <div className="report-downloadd">
                    <div className="report-iconn">
                      <SystemUpdateAltIcon />
                    </div>
                    <button>Download Report</button>
                  </div>
                  <div className="report-state">
                    <p className="bold">Total</p>
                    <p className="report-date">3</p>
                  </div>
                  <div className="report-status">
                    <p className="bold">Pending</p>
                    <p className="status-date">1</p>
                  </div>
                  <div className="report-action">
                    <p className="bold">Done</p>
                    <p className="report-date">2</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className="dashboard__Reports">
          <div className="dashboard__Report">
            <Accordion className="dashboard-accordion">
              <AccordionSummary>
                <div className="neumorph report-header">
                  {/* icon */}
                  <div className="report-icon-arrow">
                    <ArrowDropDownIcon />
                  </div>

                  <h3 className="report-title">Reports Not Ready</h3>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="report-bodies">
                  {sortedReportPending
                    .slice(
                      pendingPage * pendingRowPage,
                      (pendingPage + 1) * pendingRowPage
                    )
                    .map((e, i) => (
                      <div className="report-bodyy" key={e?.id}>
                        <div className="report-name ">
                          <h5 className="bold">{e?.participantName}</h5>
                          <p>Deadline:- 23/12/2022</p>
                        </div>
                        <div className="report-downloadd report-not-ready">
                          <div className="report-iconn">
                            <SystemUpdateAltIcon className="disabled-color" />
                          </div>
                          <button className="report-not-ready disabled-color">
                            Download Report
                          </button>
                        </div>
                        <div className="report-status">
                          <p className="bold">Total</p>
                          <p className="status-date">
                            {e?.evaluatorResponse?.count + e?.remainResponse}
                          </p>
                        </div>
                        <div className="report-action">
                          <p className="bold">Pending</p>
                          <p className="report-date">{e?.remainResponse}</p>
                        </div>
                        <div className="report-state">
                          <p className="bold">Done</p>
                          <p className="report-date">
                            {e?.evaluatorResponse?.count}
                          </p>
                        </div>
                      </div>
                    ))}
                  <div className="report-pagination">
                    {/* <select
                      name="filter-pending"
                      id="filter-pending"
                      onChange={(e) =>
                        handleFilter(reportPending, e.currentTarget.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="done">done</option>
                    </select>

                    <select
                      name="order-pending"
                      id="order-pending"
                      onClick={(e) =>
                        orderPending(sortedReportPending, e.currentTarget.value)
                      }
                    >
                      <option value="asc">Asc</option>
                      <option value="desc">Desc</option>
                    </select> */}
                    <TablePagination
                      component="div"
                      count={reportPending.length}
                      page={pendingPage}
                      rowsPerPage={pendingRowPage}
                      onRowsPerPageChange={handlePendingRowChange}
                      onPageChange={handlePendingChange}
                      labelRowsPerPage=""
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
