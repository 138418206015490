import React from "react";
import { Paper, Typography } from "@mui/material";
function DomainCard(props) {
  const { title, score, large } = props;
  return (
    <Paper
      sx={{
        minWidth: "200px",
        maxWidth: "350px",
        padding: "20px",
        backgroundColor: "#EAEDDB",

        transform: `${large ? "scale(1.1, 1.1)" : "scale(1,1)"}`,
      }}
    >
      <Typography variant="h6" align="center" sx={{ color: "#A0A2A4" }}>
        {title}
      </Typography>
      <Typography variant="h3" align="center" mt={2} sx={{ color: "#1E210F" }}>
        {" "}
        {score}{" "}
      </Typography>
    </Paper>
  );
}

export default DomainCard;
