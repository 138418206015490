import React from 'react'
import Navbar from '../layouts/Navbar'
import axios from 'axios'
import validator from 'validator'
 

const ForgotPasswordEmail = () => {
     
  const [ loading, setLoading ]  = React.useState(false)
  const [ error, setError ]  = React.useState([])
  const [ adminForm, setAdminForm ]  = React.useState({ email : '', })
  const [ isSent, setIsSent ] = React.useState(false)

  const handleChange = e => {
     e.preventDefault()
     setIsSent( false )
     setAdminForm({...adminForm, [e.target.name] : e.target.value })
  }

  const validate = () => {
      const ignoreWhiteSpace = { ignore_whitespace : true } ;
      let validated = false;

      if( validator.isEmpty(adminForm.email, ignoreWhiteSpace) ){
         setError({ email : "Error! admin email can't be empty" })      
     }
     else if( !validator.isEmail(adminForm.email) ){
        setError({ email : "Error! invalid admin email address" })      
     }      
     else validated = true

     return { validated }
  }

  const sentMail = () => {
      const _validator = validate()                  
      if( _validator.validated ){  
          setError([])   
          setLoading( true )   
          setIsSent(false)  
         //send a request to reset password 
         const sentMail = async () => {
           try{
                 const response = await axios.post(`${ process.env.REACT_APP_BASE_URL }/user/password-reset`,
                                                   { email : adminForm.email }
                                               )
                  if( response.data.success ){
                      setLoading( false )
                      setIsSent( true )
                  }
                  else{
                      setLoading( false )
                      setError({ email : "Error! unknown error occured." }) 
                  }
           } catch( error ){
               if( error.response.status === 400 ){
                  setLoading( false )
                  setError({ email : "Error! invalid or non existing email address." })
               }
           }
        }        
        sentMail() 
      }
  }

  return (
    <>
      <Navbar />

      { isSent && <div className='bg-green-100 border-l mt-5 border-l-2 border-green-500 text-sm text-green-500 w-[94%] md:w-[80%] mx-auto px-2 py-3'>
          An email with reset password link is successfully sent to <b>{adminForm.email}</b>
      </div>}

      <section className='mt-12 w-[90%] xl:w-[70%] mx-auto block border border-gray-200'>
        <div className='grid md:grid-cols-2 p-0 m-0'>
             <div className='p-4 md:p-12'>
                    <img src='/assets/avatar.jpg' alt='avatar' className='block mx-auto w-[70px] h-[70px] rounded-full' />
                   <h1 className='text-[1.4rem]  mt-2 text-center'> Reset Password </h1>
                   <p className='mb-4 text-sm text-center'>
                     Please enter your email address to reset your password. An email 
                     will be sent to the provided email address.
                   </p>
                   <label htmlFor='' className='text-sm text-gray-800'> Email Address: </label>
                     <input 
                          type='email' className='admin-form-input' 
                          placeholder='email address...' name='email'
                          value={ adminForm.email } onChange = { handleChange }
                      />
                      <p className='text-sm text-red-500 mt-3'> { error.email } </p>
                   <br />                   
                    {
                       !loading ?
                         <button  onClick={ sentMail } className='mt-0 bg-green-small text-center block mx-auto w-[280px] py-3 text-white rounded-lg text-sm'>
                           Sent Mail
                         </button>
                         :
                         <button className='mt-0 bg-green-small text-center block mx-auto w-[280px] py-3 text-white rounded-lg text-sm opacity-50'>
                           Loading...
                        </button>
                    }
             </div>
             <img src='/assets/login.png' alt='avatar' className='m-hidden w-full h-full p-0 m-0' />
        </div> 
      </section>         
    </>
  )
}

export default ForgotPasswordEmail