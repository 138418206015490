export const dummy = {
  success: true,
  competency: [
    {
      competency_name: null,
      competency_score: 73,
      country: [
        {
          country: "nigeria",
          total_obtained_score: "91.4961",
        },
      ],
      subsidiary: [
        {
          subsidiary: "access bank plc",
          total_obtained_score: "91.4961",
        },
      ],
      state: [
        {
          state: null,
          total_obtained_score: "91.4961",
        },
      ],
      branch: [
        {
          branch: "ilorin, umaru audi road",
          total_obtained_score: "91.4961",
        },
      ],
    },
  ],
};
