import React from "react";
import Navbar from "../admin/layouts/Navbar";
import "./Coach.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "../../components/SideBar/SideBar";
import Dashboard from "./Dashboard/Dashboard";
function Coach() {
  const sidebarLinks = [
    {
      link: "#",
      label: "Dashboard",
      icon: "bi-building",
    },
  ];
  return (
    <div className="coach">
      <Navbar />
      <section className="md:flex justify-between mt-3">
        <Sidebar
          links={sidebarLinks}
          className="coach__SideBar w-[20%] h-[95vh] bg-gray-200"
        />
        <div className="coach__Content w-full md:w-[79%] md:h-[95vh]">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />

            {/* <Route path="/feedback" element={<Feedback />} /> */}
          </Routes>
        </div>
      </section>
    </div>
  );
}

export default Coach;
