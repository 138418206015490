import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuth : false,
    adminName : '',
    token : '',
    id : '',
    adminType : '',
    hasCompany : false
}

export const adminLogin = createSlice({
    name : 'admin login' ,
    initialState,

    reducers : {
        authenticateAdmin : ( state,{ payload }) => {
           state.isAuth = true;
           state.adminName = payload.user.admin_name
           state.token = payload.user.token
           state.id = payload.user.id
        },
        authenticateClientAdmin : ( state,{ payload }) => {
            state.isAuth = true;
            state.adminName = payload.user.admin_name
            state.token = payload.user.token
            state.id = payload.user.id
            state.adminType = 'client admin'
         },
        hasCompany : state => {
            state.hasCompany = true
        },
        logoutAdmin : (state) => {
            state.isAuth = false
            state.adminName = ''
            state.token = ''
            state.id = ''
            state.adminType = ''
        },
    }
})

export const {
    authenticateAdmin,
    authenticateClientAdmin,
    hasCompany,
    logoutAdmin
 } = adminLogin.actions
               
export default adminLogin.reducer

