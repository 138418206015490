import React from "react";
import "./MapEvaluator.css";

import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";
import { Field, FieldArray, Formik, Form, ErrorMessage } from "formik";
import { object, string, array } from "yup";
import axios from "axios";

import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import { Autocomplete, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
function MapEvaluator(params) {
  const {
    setOpen,
    open = true,
    title = "Map Evaluators",
    participant = [{ value: "123", label: "Femi" }],
    isSuccessful = true,
    setSuccess,
    isError = true,
    setError,
    requestType = "evaluators",
    label = "Evaluator",
    submitFunc,
    project_id = "40b14649-45cc-44bc-af74-d1f8501e38ac",
  } = params;
  const validationSchema = object({
    [requestType]: array().min(
      1,
      "You need to add at least one Evaluator/Developer Coach"
    ),
  });
  return (
    <div className="mapEvaluator">
      <Modal
        className="mapEvaluator__Modal"
        open={open}
        handleClose={(e) => setOpen(false)}
      >
        <div className="map-modal">
          {!isSuccessful && !isError && (
            <button
              className="modal__Close"
              onClick={(e) => {
                setOpen(false);
              }}
            >
              x
            </button>
          )}
          {isSuccessful && (
            <Alert
              severity="success"
              color="info"
              onClose={() => {
                setSuccess(false);
              }}
            >
              {label} Added Successfully
            </Alert>
          )}
          {isError && (
            <Alert
              severity="error"
              onClose={() => {
                setError(false);
              }}
            >
              Failed to add {label}
            </Alert>
          )}
          <div className="mapEvaluator__Content">
            <h2 className="">{title}</h2>
            <div className="mapEvaluator__Form">
              <Formik
                className="mapEvaluator__Formik"
                key={requestType}
                validationSchema={validationSchema}
                initialValues={{
                  participant_id: "",
                  project_id,
                  [requestType]: [
                    {
                      evaluator_name: "",
                      evaluator_email: "",
                      evaluator_role: "Manager",
                    },
                  ],
                }}
                onSubmit={(values, { resetForm }) => {
                  submitFunc(values, resetForm);
                }}
              >
                {({ values, errors, isSubmitting, setFieldValue }) => (
                  <Form className="formik-form">
                    {/* <Field
                      as="select"
                      name="participant_id"
                      className="formik-participant"
                      // required
                    >
                      <option value="" key="select">
                        Select Participant
                      </option>
                      {participant.map((e, i) => (
                        <option value={e?.id} key={e?.id + i}>
                          {e?.name}
                        </option>
                      ))}
                    </Field> */}

                    <Autocomplete
                      className="formik-participant"
                      id="tags-standard"
                      options={participant}
                      label={"Select Participant"}
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, newValue) => {
                        setFieldValue("participant_id", newValue?.id);
                        // console.log({ value });
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={uuidv4()}>
                            {option?.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={"Select Participant"}
                          placeholder="Select Participant"
                        />
                      )}
                    />

                    <div className="formik-evaluators-field-array">
                      <FieldArray
                        name={requestType}
                        className="field-arrays"
                        required
                      >
                        {({ push, remove, insert }) => (
                          <div className="field-array">
                            {values[requestType].map((e, i) => (
                              <>
                                <Field
                                  name={`${[requestType]}.${i}.evaluator_name`}
                                  type="text"
                                  // placeholder={`${requestType} Name`}
                                  placeholder={`${[label]} name`}
                                  className="formik-text-field"
                                  required
                                />
                                <Field
                                  name={`${[requestType]}.${i}.evaluator_email`}
                                  type="email"
                                  placeholder={`${[label]} email`}
                                  className="formik-text-field"
                                  required
                                />
                                <Field
                                  name={`${[requestType]}.${i}.evaluator_role`}
                                  as="select"
                                  placeholder={`${[label]} Role`}
                                  className="formik-text-field"
                                  required
                                >
                                  <option value="Manager">Manager</option>
                                  <option value="peer">Peer</option>
                                  <option value="Direct Report">
                                    Direct Report
                                  </option>
                                </Field>
                                <button
                                  className="modal-remove-field"
                                  type="button"
                                  onClick={(e) => remove(i)}
                                >
                                  <DeleteIcon />
                                </button>
                              </>
                            ))}

                            <button
                              className="add-new"
                              onClick={(e) =>
                                push({
                                  evaluator_email: "",
                                  evaluator_name: "",
                                  evaluator_role: "",
                                })
                              }
                              type="button"
                            >
                              Add New
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                    <button
                      type="submit"
                      className="modal-submit"
                      // disabled={isSubmitting}
                    >
                      Submit
                    </button>
                    <ErrorMessage
                      name={requestType}
                      classname="error-message"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MapEvaluator;
