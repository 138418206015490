import React from 'react'
import axios from 'axios'
import { useSelector,useDispatch } from 'react-redux'
import { updateProject } from '../../../../../features/view-projects/view-projects-slice'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import swal from 'sweetalert'

const DeleteCampaign = ({ project,target }) => {
    
    const dispatch = useDispatch()
    const [ loading , setLoading ] = React.useState(false)
    const { company_id  }  = useSelector( state => state.viewProjects ) 

    const deleteCampaign = () => {
        document.querySelector('.close-campaign-delete-modal').click()
        setLoading(true)
        axios.delete(`${ process.env.REACT_APP_BASE_URL }/project/${ project.id }`)      
        .then( response => {
              if( response.data.success){
                 axios.get(`${ process.env.REACT_APP_BASE_URL }/project/all/${ company_id }`)
                  .then(
                    res => {
                          if( res.data.success ){
                              dispatch( updateProject( res.data ) )                
                              setLoading(false)
                              swal('Success!', 'Campaign is deleted successfully.' , 'success')
                          }
                     }
                  )      
              }
         })     
    }
  
  return (
    <>   
     { loading && <LoadingSpinner /> }
     <div
      data-twe-modal-init
      className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
      id={target}
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div
        data-twe-modal-dialog-ref
        className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]">
        <div
          className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark">
          <div
            className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 p-4 dark:border-white/10">
            <h5
              className="text-xl font-medium leading-normal text-surface dark:text-white"
              id="exampleModalLabel">
               Confirm Action
            </h5>
            <button
              type="button"
              className="box-content close-campaign-delete-modal rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
              data-twe-modal-dismiss
              aria-label="Close">
              <span className="[&>svg]:h-6 [&>svg]:w-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12" />
                </svg>
              </span>
            </button>
          </div>
          <div className="relative flex-auto p-4" data-twe-modal-body-ref>
            <div className='pt-3 text-center'>
                  <i className='mt-16 bi bi-exclamation-triangle text-center text-[4.5rem] text-red-500'></i>
                  <h3 className='text-3xl  text-center mt-2'> Warning </h3>
                  <div className='text-sm mt-4 whitespace-normal'>  
                      Did you really want to delete a compaign <strong> { project.project_name }</strong>.
                      Please note that you cannot undo this action.                 
                  </div>
                  <div className='mt-8 flex justify-end mb-3'>                                                  
                      <button className='py-2 text-sm px-8  close-campaign-delete-modal bg-gray-200 rounded-lg' data-bs-dismiss='modal' aria-label='Close'>
                          Cancel
                      </button>
                      <button
                              className='py-2 text-sm ml-3 px-8 text-white bg-red-500 rounded-lg' 
                              data-bs-dismiss='modal' aria-label='Close'
                              onClick={ deleteCampaign }
                          >
                          Yes, Proceed & Delete Campaign
                      </button>
                  </div>  
             </div>  
          </div>   
          </div>
        </div>
      </div>
   </>
  )
}

export default DeleteCampaign