import React from "react";
import SliderProgress from "../../../../../../components/SliderProgress/SliderProgress";
import { Box, Paper, Typography } from "@mui/material";
function ScoreCard(props) {
  const { key, title, summary, imageList, sliderColor } = props;
  return (
    <Paper key={key} sx={{ padding: "20px", paddingBottom: "40px" }}>
      <Typography variant="h6" fontWeight="700">
        {" "}
        {title}
      </Typography>
      <Typography variant="body1" mt={2} mb={3}>
        {" "}
        {summary}
      </Typography>
      <Box>
        <SliderProgress imageList={imageList} color={sliderColor} />
      </Box>
    </Paper>
  );
}

export default ScoreCard;
