import React, { useState } from "react";
import "./Tab.css";
import UpdateParticipant from "./Modal/Modal";
import EvaluatorTab from "./EvaluatorTab";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePagination from "@mui/material/TablePagination";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import axios from "axios";
import _ from "lodash";
function Tablet(params) {
  const { data, evaluatorData, deleteEvaluator, refetch } = params;
  const [filterCharacter, setFilterCharacter] = useState("");
  const [participantPage, setParticipantPage] = useState(0);
  const [participantRowPage, setParticipantRowPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [participantUpdateData, setParticipantUpdateData] = useState([
    { name: "", email: "", dev_coach: "", dev_coach_email: "" },
  ]);
  const handleParticipantChange = (event, value) => {
    setParticipantPage(value);
  };
  const handleParticipantRowChange = (event) => {
    setParticipantRowPage(parseInt(event.target.value, 10));
    setParticipantPage(0);
  };

  const deleteParticipant = async (id, name) => {
    // alert(id);
    if (window.confirm(`Are you sure you want to delete ${name}`)) {
      try {
        let req = await axios.delete(
          `https://sandboxapi.360degree.testassessify.com/project/participant/${id}`
        );
        alert(await JSON.stringify(req.data?.message));
        refetch();
      } catch (e) {
        alert("An error occured");
      }
    }
  };

  const editParticipant = (data) => {
    setParticipantUpdateData([
      {
        name: data?.name,
        email: data?.email,
        dev_coach: data?.dev_coach_name,
        dev_coach_email: data?.dev_coach_email,
        password: data?.password,
        id: data?.id,
      },
    ]);
    setOpenModal(true);
  };
  return (
    <div className="tab">
      <input
        type="search"
        name="tab-table-action"
        id="tab-table-action"
        className="tab-table-action"
        placeholder="Participant Search"
        onChange={(e) => setFilterCharacter(e.target.value)}
      />
      <Tabs className="tabs">
        <TabList className="tablist">
          <Tab className="tablet-tab participant-tab">Participant</Tab>
          <Tab className="tablet-tab ">Evaluator</Tab>
          <Tab className="tablet-tab ">Development Coach</Tab>
        </TabList>

        <TabPanel className="participant-tab-panel">
          <div className="participant-table">
            <div className="table-paginate">
              <div className="table-row table-header">
                <div className="table-td"></div>
                <div className="table-td">Participant</div>
                <div className="table-td">Email</div>
                <div className="table-td">Dev.Coach</div>
                <div className="table-td">Pending</div>
                {/* <div className="table-td table-sub-td"> */}
                <div className="table-td">Evaluators</div>
                {/* <input
                    type="search"
                    name="tab-table-action"
                    id="tab-table-action"
                    className="tab-table-action"
                    placeholder="Participant Name Search"
                    onChange={(e) => setFilterCharacter(e.target.value)}
                  /> */}
                {/* </div> */}
              </div>

              <div className="table-rows">
                {_.orderBy(
                  [...data].filter((e) =>
                    e.name
                      .replaceAll(" ", "")
                      .toLowerCase()
                      .includes(
                        filterCharacter.replaceAll(" ", "").toLowerCase()
                      )
                  ),
                  ["name"],
                  ["asc"]
                )
                  ?.slice(
                    participantPage * participantRowPage,
                    (participantPage + 1) * participantRowPage
                  )
                  .map((e, i) => (
                    <div className="table-row table-content" key={i}>
                      <div className="table-data">
                        {/* icon */}
                        <div className="table-download-icon ">
                          <SystemUpdateAltIcon />
                        </div>
                        <button className="table-download ">
                          Download Report
                        </button>
                      </div>
                      <div className="table-data">{e?.name}</div>
                      <div className="table-data">{e?.email}</div>
                      <div className="table-data">{e?.dev_coach_name}</div>
                      <div className="table-data">
                        {" "}
                        {e?.totalSubmittedResponse}/{e?.evaluator.length}
                      </div>
                      <div className="table-data">
                        <div className="table-data-boxes">
                          <div
                            className={`data-box 
                            ${!e?.isDoneAssessment && "orange-bg"}
                            
                              `}
                          >
                            S
                          </div>
                          {e?.evaluator.map((e, i) => (
                            <div
                              className={`data-box ${
                                e?.hasEvaluateParticipant
                                  ?.toLowerCase()
                                  .includes("not started") && "orange-bg"
                              }
                              ${
                                e?.hasEvaluateParticipant
                                  ?.toLowerCase()
                                  ?.includes("in progress") && "yellow-bg"
                              }
                              `}
                              key={i}
                            >
                              {e?.evaluator_role?.toUpperCase()}
                            </div>
                          ))}
                          {/* <div className="data-box orange-bg">M</div>
                        <div className="data-box yellow-bg">P</div> */}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <TablePagination
                component="div"
                count={data?.length}
                page={participantPage}
                rowsPerPage={participantRowPage}
                onRowsPerPageChange={handleParticipantRowChange}
                onPageChange={handleParticipantChange}
                labelRowsPerPage=""
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel className="evaluator-tab-label">
          <EvaluatorTab
            data={_.orderBy(
              [...data].filter((e) =>
                e.name
                  .replaceAll(" ", "")
                  .toLowerCase()
                  .includes(filterCharacter.replaceAll(" ", "").toLowerCase())
              ),
              ["name"],
              ["asc"]
            )}
            deleteEvaluator={deleteEvaluator}
            page={participantPage}
            row={participantRowPage}
            refetch={refetch}
            filterCharacter={filterCharacter}
          />
          <div className="table-paginate">
            <TablePagination
              component="div"
              count={data?.length}
              page={participantPage}
              rowsPerPage={participantRowPage}
              onRowsPerPageChange={handleParticipantRowChange}
              onPageChange={handleParticipantChange}
              labelRowsPerPage=""
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="participant-table development-coach-table">
            <UpdateParticipant
              participantData={participantUpdateData}
              setParticipantData={setParticipantUpdateData}
              open={openModal}
              setOpen={setOpenModal}
              formName="Update Participant"
              refetch={refetch}
            />
            <div className="table-row table-header">
              <div className="table-td"></div>
              <div className="table-td">Participant</div>
              <div className="table-td">Dev Coach Name</div>
              <div className="table-td">Dev Coach Email</div>
              <div className="table-td"></div>
              <div className="table-td table-sub-td">
                <div className=""></div>
                {/* <input
                  type="search"
                  name="tab-table-action"
                  id="tab-table-action"
                  className="tab-table-action"
                  placeholder="Participant Name Search"
                  onChange={(e) => setFilterCharacter(e.target.value)}
                /> */}
              </div>
            </div>
            <div className="table-rows">
              {_.orderBy(
                [...data].filter((e) =>
                  e.name
                    .replaceAll(" ", "")
                    .toLowerCase()
                    .includes(filterCharacter.replaceAll(" ", "").toLowerCase())
                ),
                ["name"],
                ["asc"]
              )
                ?.slice(
                  participantPage * participantRowPage,
                  (participantPage + 1) * participantRowPage
                )
                .map((e, i) => (
                  <div className="table-row table-content">
                    <div className="table-data">
                      {/* icon */}
                      <div className="table-download-icon">
                        <SystemUpdateAltIcon />
                      </div>
                      <button className="table-download">
                        Download Report
                      </button>
                    </div>
                    <div className="table-data">{e?.name}</div>
                    <div className="table-data">{e?.dev_coach_name}</div>
                    <div className="table-data">{e?.dev_coach_email}</div>
                    <div className="table-data">
                      <div className="evaluator-actions">
                        {/* icon */}
                        <button
                          className="evaluator-edit"
                          onClick={(data) => editParticipant(e)}
                        >
                          <EditIcon />
                        </button>
                        <button
                          className="evaluator-delete"
                          onClick={(data) => deleteParticipant(e?.id, e?.name)}
                        >
                          <DeleteIcon />
                        </button>
                        {/* icon */}
                      </div>
                    </div>
                    <div className="table-data">
                      <div className="table-data-boxes">
                        {/* <div className="data-box">S</div>
                  <div className="data-box orange-bg">M</div>
                  <div className="data-box yellow-bg">P</div>
                  <div className="data-box">P</div> */}
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="table-paginate">
              <TablePagination
                component="div"
                count={data?.length}
                page={participantPage}
                rowsPerPage={participantRowPage}
                onRowsPerPageChange={handleParticipantRowChange}
                onPageChange={handleParticipantChange}
                labelRowsPerPage=""
              />
            </div>
          </div>
        </TabPanel>
      </Tabs>

      {filterCharacter &&
        ![...data].filter((e) =>
          e.name
            .replaceAll(" ", "")
            .toLowerCase()
            .includes(filterCharacter.replaceAll(" ", "").toLowerCase())
        ).length && (
          <div className="no-record ">
            <h3 className="text-center">Sorry no record Found</h3>
          </div>
        )}
    </div>
  );
}

export default Tablet;
