import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { activateCampaign, resetProjectSlice, } from '../../../../../features/create-project/create-project-slice'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import axios from 'axios'
import { clearParticipantsSlice } from '../../../../../features/add-participants/add-participants-slice'
import { clearSetupMailSlice } from '../../../../../features/setup-mail/setup-mail-slice'
import { resetUploadEvaluatorForParticipantsSlice } from '../../../../../features/evaluator-for-Participants/evaluatorForParticipantsSlice'
import { mailtemplateHeader, mailtemplateFooter } from './forms/set-up-mail-form/mail-template-header-footer'
 
const ActivateMail = () => {
  const { formData } = useSelector((state) => state.setupMail);
  const { createProjectForm } = useSelector((state) => state);
  const { project_id } = useSelector((state) => state.addParticipants.formData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const sendMail = () => {

    setLoading(true)
    dispatch( activateCampaign() )
         
    axios.post(`${ process.env.REACT_APP_BASE_URL }/project/email-setup`,
              {
                 ...formData, 
                 email_template : mailtemplateHeader +  formData.email_template + mailtemplateFooter,
                 devcoach_template : mailtemplateHeader +  formData.devcoach_template + mailtemplateFooter,
                 participant_template : mailtemplateHeader +  formData.participant_template + mailtemplateFooter
              })
    .then( res => {      
         if(res.data.success){
          
          axios.patch(`${ process.env.REACT_APP_BASE_URL }/project/${ project_id }`,{
              status : 'active',
              isComplete : true,
            })
            .then( response => {
                if(response.data.success){   
                   //set the campaign status to active                                                                                  
                    setLoading(false)
                    dispatch( resetProjectSlice() )
                    dispatch( clearParticipantsSlice() )
                    dispatch( clearSetupMailSlice() )
                    dispatch( resetUploadEvaluatorForParticipantsSlice() )
                    navigate('/admin/view-campaigns')
                }
            })   
         }
    } )
  }

  return (
    <>
      <button style={{background: 'rgba(102, 129, 2,0.7)'}} className="admin-form-btn" onClick={sendMail}>
        Activate
      </button>
      {loading && <LoadingSpinner />}
    </>
  );
};

export default ActivateMail;
