import { createSlice } from "@reduxjs/toolkit";

const initialState = {        
    errors : [],
    formData : {  
           project_id : '',
           isReadOnly : false,
           participants : [
               {
                   id : 0 , name : '' , email : '' ,
                   dev_coach_name : '', dev_coach_email : '' ,
                   country: '', department : '', subsidiary: '',
                   branch: '',
                },                     
           ],
           countries: [],
           subsidiaries: [],
           departments: [],
           branches: []
    }
}

export const addParticipants = createSlice({
    name : 'add participants' ,
    initialState  ,
    reducers : {        
        clearParticipantsSlice : (state) => {
            state.errors = []
            state.formData = initialState.formData;
        },
        addMoreParticipants : (state, { payload }) => {
           state.formData.participants.push({
              id : payload,
              name : '',
              email : '',
              dev_coach_name : '',
              dev_coach_email : '',
              country: 'Nigeria', department : '', subsidiary: '',
              branch: '',                      
           })
        },
        updateParticipants : (state,{ payload }) => {
             state.formData.participants = state.formData.participants.map( (participant, index) => {
                 return {...participant, id : payload[index]?.id }
             });
        },
        deleteParticipant : (state,{payload}) => {
            state.formData.participants = state.formData.participants.filter( participant => {
                return participant.id !== payload;
            } )
        },

        readExcelFile : (state,{ payload }) => {

             const data = payload.map(( data, id ) => {
                return { 
                         id : (Date.now() + id).toString() , 
                         name : data.NAME,
                         email : data.EMAIL,
                         dev_coach_name : data['DEV COACH NAME'],
                         dev_coach_email :  data['DEV COACH EMAIL'],   
                         country: data['COUNTRY'],
                         department: data['DEPARTMENT'],
                         subsidiary: data['SUBSIDIARY'],
                         branch: data['BRANCH']
                        }
             } )

            state.formData.participants = [...data] ;
            state.formData.isReadOnly = false;
         },

        setErrors : (state,{ payload }) => {
            state.errors = payload
         },

         clearErrors : (state) => {
            state.errors = [] ;
        },

         updateInput : (state,{payload}) =>{
              state.formData.participants[payload.id] = {
                                                   ...state.formData.participants[payload.id],
                                                   [payload.field] : payload.value
                                                }              
         },

         setProjectId : (state, {payload}) => {
             state.formData.project_id = payload
         }, 
         updateUserProfileData : (state, {payload}) => {
            state.formData[payload.prop] = payload.value
        } 
    }//reducer end
  })

export const { 
             updateUserProfileData,
             addMoreParticipants, 
             deleteParticipant,
             setErrors,updateInput,
             clearErrors,
             readExcelFile,
             setProjectId,      
             updateParticipants, 
             clearParticipantsSlice,       
             } = addParticipants.actions
 
export default addParticipants.reducer