import React from 'react'
import Navbar from '../layouts/Navbar'
import axios from 'axios'
import validator from 'validator'
import { Link , useSearchParams } from 'react-router-dom'

const ResetPassword = () => {
  
  const [params] = useSearchParams();
  const [ loading, setLoading ]  = React.useState(false)
  const [ error, setError ]  = React.useState([])
  const [ adminForm, setAdminForm ]  = React.useState({ password : '', passwordConfirm : '' })
  const [ isSent, setIsSent ] = React.useState(false)

  const handleChange = e => {
     e.preventDefault()
     setAdminForm({...adminForm, [e.target.name] : e.target.value })
  }

  const validate = () => {
      const ignoreWhiteSpace = { ignore_whitespace : true } ;
      let validated = false;

      if( validator.isEmpty(adminForm.password, ignoreWhiteSpace) ){
         setError({ password : "Error! admin password can't be empty" })      
     }
     else if(adminForm.password.toString().length < 8 ) {
        setError({ password : "Error! admin password can't be less than 8 characters" })
     }

     else if(adminForm.password !== adminForm.passwordConfirm ) {
        setError({ passwordConfirm : "Error! password mismatch." })
     }
     
     else validated = true

     return { validated }
  }

  const resetPassword = () => {
      const _validator = validate()          
               
      if( _validator.validated ){  
          setError([])   
          setLoading( true )  
          setIsSent( false )   
         //send a request to reset password 
        const updatePassword = async () => {
               const response = await axios.post(`${ process.env.REACT_APP_BASE_URL }/user/password-update`,
                                              { 
                                                  email : params.get('email'),
                                                  password : adminForm.password,
                                                  userType : params.get('userType')
                                                }
                                              )
          if( response.data.success ){
              setLoading( false )
              setIsSent( true )
          }
          else{
              setLoading( false )
              setIsSent( true )
              setError({ email : "Error! unknown error occured." }) 
          }
         }
         
         updatePassword() 
      }
  }

  return (
    <>
      <Navbar />  
      { isSent && <div className='bg-green-100 border-l mt-5 border-l-2 border-green-500 text-sm text-green-500 w-[94%] md:w-[80%] mx-auto px-2 py-3'>
          Hello,  <b>{ params.get('name') }</b> your passowrd is succesfully updated  
          <Link to='/client-admin-login' className='text-sm ml-2 font-semibold text-green-small'> click here to login </Link>
            with your new password.
      </div>}
      <section className='mt-12 w-[90%] xl:w-[70%] mx-auto block border border-gray-200'>
        <div className='grid md:grid-cols-2 p-0 m-0'>
             <div className='p-4 md:p-12'>
                    <img src='/assets/avatar.jpg' alt='avatar' className='block mx-auto w-[70px] h-[70px] rounded-full' />
                   <h1 className='text-[1.4rem]  mt-2 text-center'>Client Admin Reset Password </h1>
                   <p className='mb-4 text-sm text-center'>
                     Enter a new password to update you password.
                   </p>
                   <label htmlFor='' className='text-sm text-gray-800'> New password: </label>
                     <input 
                          type='password' className='admin-form-input' 
                          placeholder='new password...' name='password'
                          value={ adminForm.password } onChange = { handleChange }
                      />
                      <p className='text-sm text-red-500 mt-3'> { error.password } </p>
                      <br />
                      <label htmlFor='' className='text-sm text-gray-800'> Confirm password: </label>
                     <input 
                          type='password' className='admin-form-input' 
                          placeholder='confirm password...' name='passwordConfirm'
                          value={ adminForm.passwordConfirm } onChange = { handleChange }
                      />
                      <p className='text-sm text-red-500 mt-3'> { error.passwordConfirm } </p>
                   <br />                   
                    {
                       !loading ?
                         <button  onClick={ resetPassword } className='mt-0 bg-green-small text-center block mx-auto w-[280px] py-3 text-white rounded-lg text-sm'>
                           Reset Password
                         </button>
                         :
                         <button className='mt-0 bg-green-small text-center block mx-auto w-[280px] py-3 text-white rounded-lg text-sm opacity-50'>
                           Loading...
                        </button>
                    }
             </div>
             <img src='/assets/login.png' alt='avatar' className='m-hidden w-full h-full p-0 m-0' />
        </div> 
      </section>         
    </>
  )
}

export default ResetPassword