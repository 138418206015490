import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { Formik } from "formik";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Autocomplete,
  Stack,
  TextField,
  Typography,
  Grid,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function FilterAutoComplete({
  title = "Filter by Subsidiary",
  label,
  optionLabel,
  options,
  setValue,
  value,
}) {
  return (
    <Stack fullWidth>
      <Accordion elevation={0} disableGutters disableFocusRipple disableRipple>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" fontWeight={700}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {options?.length > 0 ? (
            <Autocomplete
              multiple
              id="tags-standard"
              options={options}
              label={optionLabel}
              getOptionLabel={(option) => option[optionLabel]}
              onChange={(event, newValue) => {
                setValue(newValue);
                // console.log({ value });
              }}
              value={value}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={uuidv4()}>
                    {option[optionLabel]}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  placeholder={label}
                />
              )}
            />
          ) : (
            "Please Wait..."
          )}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}

export default FilterAutoComplete;
