import axios from 'axios'
import React,{ useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import { updateTestMailInput } from '../../../../../../../features/setup-mail/setup-mail-slice'
import LoadingSpinner from '../../../../../../../components/LoadingSpinner'

const TestMail = () => {
    
    const [ loading, setLoading ] = useState(false)
    const [ isMailSent , setIsmailSent ] = useState(false)
    const [ error, setError ] = useState('')
    const dispatch = useDispatch()
    const {test_email, formData} = useSelector( state => state.setupMail)

    const handleTestEmailInput = e => {
        dispatch( updateTestMailInput( e.target.value ) )
    }

  const sentTestEMail = (e) => {
      e.preventDefault()
      if(!validator.isEmail(test_email)){
         setError('Invalid test email address')
      }
      else{
          setError('')
          setLoading(true)
            axios.post(`${ process.env.REACT_APP_BASE_URL }/project/test-mail`, {
                email_template : formData.email_template,
                email_subject : '360 degree feedback',
                receiever : test_email,
            })
            .then(
                res => {
                if( res.data.success ){                    
                    setIsmailSent(true)
                    setLoading(false)
                   }
                }
            )
      }
  }

  return (
    <div className='mt-5 border border-gray-200 pb-4 px-2'>
        <h5 className='text-sm pl-3 py-3 border-b border-gray-200'>
        Send Test Email
        </h5>
        <div className='flex items-center mt-8 pl-8'> 
            <div className='w-[120px] text-sm'> Recipient: </div>
            <div className='w-[400px]'>
                <input 
                    type='text' className='admin-form-input'
                    placeholder='recipient email address...'
                    style={{ padding:'10px' }}                                       
                    name='test_email'
                    value={ test_email }  
                    onChange={ handleTestEmailInput }                                    
                />
            </div>
        </div>
         {  loading &&  <LoadingSpinner /> } 
         {
            isMailSent &&
            <div className='border-l rounded-md border-l-2 mt-5 ml-4 w-[60%] border-green-500 text-[13px] bg-green-100 py-3 pl-2 text-green-500 mt-2'>
               Test mail is sent successfully!                                                       
            </div>
         }
         {
            error &&
            <div className='border-l rounded-md border-l-2 mt-5 ml-4 w-[60%] border-red-500 text-[13px] bg-red-100 py-3 pl-2 text-red-500 mt-2'>
                { error }                                         
            </div>
         }
        
        <div className='flex justify-end'>
            <button                                     
                className='bg-green-small py-2 text-white text-sm px-8 mt-5 rounded-lg'
                onClick={ sentTestEMail }
            >
                Sent Mail
            </button>  
        </div>
    </div>
  )
}

export default TestMail