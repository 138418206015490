import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
const Sidebar = (params) => {
  const {
    links = [
      {
        link: "/campaign",
        label: "Femi",
        icon: "bi-bag-plus",
      },
    ],
    className,
  } = params;
  return (
    <div className={`${className} sidebar`}>
      {/* <NavLink to={"/admin/create-company"} path="company" className="navlink">
        <span className="text-2xl bi bi-building ml-2 text-red-500"></span>
        <span className="text-sm ml-2">Company</span>
      </NavLink> */}
      {links.map(({ link, icon, label }) => (
        <NavLink to={link} path="project" className="navlink">
          <span className={`text-2xl bi ${icon} ml-2 text-red-500`}></span>
          <span className="text-sm ml-2">{label}</span>
        </NavLink>
      ))}
      {/* <NavLink className="navlink">
        <span className="text-2xl bi bi-pencil-square ml-2 text-red-500"></span>
        <span className="text-sm ml-2">Survey</span>
      </NavLink>
      <NavLink className="navlink">
        <span className="text-2xl bi bi-people ml-2 text-red-500"></span>
        <span className="text-sm ml-2">Participants</span>
      </NavLink>
      <NavLink className="navlink">
        <span className="text-2xl bi bi-chat-left ml-2 text-red-500"></span>
        <span className="text-sm ml-2">Feedback</span>
      </NavLink>
      <NavLink className="navlink">
        <span className="text-2xl bi bi-journals ml-2 text-red-500"></span>
        <span className="text-sm ml-2">Report</span>
      </NavLink> */}
    </div>
  );
};

const NavLink = ({ className, to, children, path }) => {
  const location = useLocation();
  //simulate a click event to hide sidebar in mobile devices after a link is clicked.
  const closeMobileSidebar = () => {
    if (window.matchMedia("(max-width:500px)")) {
      document.querySelector("button.mobile-sidebar-close-btn").click();
    }
  };

  return (
    <Link
      to={to}
      className={className}
      onClick={closeMobileSidebar}
      id={location.pathname.includes(path) ? "active-link" : ""}
    >
      {children}
    </Link>
  );
};
export default Sidebar;
