import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addMoreParticipants, deleteParticipant, updateInput, readExcelFile, updateUserProfileData } 
           from '../../../../../../features/add-participants/add-participants-slice'
import PreviewParticipantsModal from '../PreviewParticipantsModal'
import * as XLSX from 'xlsx/xlsx.mjs'
import axios from 'axios'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import DownloadFile from 'js-file-download'
import UserProfileDropDown from './UserProfileDropdown'

const AddParticipantsForm = () => {
   
  const dispatch = useDispatch()
  const { formData, errors } = useSelector( state => state.addParticipants );
  const [ isExcelFile, setIsExcelFile ] = useState(true)
  const [ downloadLoading, setDownloadLoading ] = useState(false)
  const [ isFileDownloaded, setIsFileDownloaded ] = useState(false)
  const [ userProfle, setUserProfile] = useState({ 
         branches : [] ,
       subsidiary : [], departments : []

    })
 const [countries, setCountries] = useState([])
 const [departments, setDepartments] = useState([])
 const [branches, setBranches] = useState([])
 const [subsidiary, setSubsidiary ] = useState([])

  const getUserProfile = async (url) => {         
    const ctrReq = await axios.get(`${ process.env.REACT_APP_BASE_URL }/company/${url}`)          
    if( ctrReq.status){         
        return ctrReq;
     }                       
  }

  useEffect( () => {              
    //---------------------------------------------------------------------------------------//   
      const fetchCountryDataAsync = async () => {        
          const responseData = await getUserProfile('country')
          if(responseData.status){
             const _countries = responseData.data.countries.map( ctr => ctr.name )             
            setCountries( _countries )                                           
            dispatch( updateUserProfileData({ prop: "countries" , value: _countries }) )
          }   
        }
      fetchCountryDataAsync()
   //--------------------------------------------------------------------------------------//
      const fetchBranchDataAsync = async () => {        
        const responseData = await getUserProfile('branches')
        if(responseData.status){
            const  _branches = responseData.data.branches.map( ctr => ctr.branch_name )
            setBranches( responseData.data.branches )
            sessionStorage.setItem('participant_branches_list', JSON.stringify(responseData.data.branches))
            dispatch( updateUserProfileData({ prop: "branches" , value: _branches }) )
          }      
      }
      fetchBranchDataAsync()
    //----------------------------------------------------------------------------------------//
    //--------------------------------------------------------------------------------------//
    const fetchSubsdaryDataAsync = async () => {        
        const responseData = await getUserProfile('subsidiary')
        if(responseData.status){
            const _subsidaries = responseData.data.subsidiary
            setSubsidiary( _subsidaries )
            dispatch( updateUserProfileData({ prop: "subsidiaries" , value: _subsidaries }) )
          }      
      }
      fetchSubsdaryDataAsync()
    //----------------------------------------------------------------------------------------//
    //--------------------------------------------------------------------------------------//
    const fetchDepartmentsDataAsync = async () => {        
        const responseData = await getUserProfile('departments')
        if(responseData.status){
          const _departments = responseData.data.departments
          setDepartments( _departments )
          dispatch( updateUserProfileData({ prop: "departments" , value: _departments }) )
        }      
      }
      fetchDepartmentsDataAsync()
    //----------------------------------------------------------------------------------------//    
  },[])
 
  const handleAddMoreParticipants = (id) => {
      dispatch( addMoreParticipants(id) )
  }

  const HandleDeleteParticipant = id => {
      dispatch( deleteParticipant(id) )
  }

  const handleInputChange = (id,field,value) => {
      dispatch( updateInput( { id, field, value } ) )
  }

  const downloadExcelTemplate = () => {
        setDownloadLoading(true)        
        const fileType = { responseType : 'blob' }
        axios.get(`${ process.env.REACT_APP_BASE_URL }/project/download-template`,fileType)
        .then( response => {
            DownloadFile(response.data,`360-degree-feedback.xlsx`)
            setIsFileDownloaded(true)
            setDownloadLoading(false)
        } )
  }

  const uploadExcelTemplate =  e => {

       const excelSheet = e.target.files[0]

       if(!excelSheet.name.includes('.xl')){
           setIsExcelFile(false)
       }
       else{
          setIsExcelFile(true)
          const promise = new Promise( ( resolve , reject ) => {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(excelSheet);

              fileReader.onload = event => {
                  const bufferArray = event.target.result;
                  const workBook = XLSX.read(bufferArray, { type : 'buffer' });
                  const workSheetName = workBook.SheetNames[0]
                  const workSheet = workBook.Sheets[ workSheetName ]
                  const dataArray = XLSX.utils.sheet_to_json( workSheet ) ;
                  resolve(dataArray)
              }
              fileReader.onerror = error => {
                  reject(error)
              }
          })

          promise.then( data => {
             //check if the data is in the rigth format
             const checkKey = (key) => data[0].hasOwnProperty(key)
             if( 
                    !checkKey('NAME') || !checkKey('EMAIL') || !checkKey('DEV COACH NAME') 
                    || !checkKey('DEV COACH EMAIL') || !checkKey('COUNTRY')  || !checkKey('BRANCH')  
                    || !checkKey('DEPARTMENT')  || !checkKey('SUBSIDIARY')    
                ){
                 setIsExcelFile(false)                                 
               }
             else {
                 dispatch( readExcelFile( data ) )                
                 setIsExcelFile(true)                
                 document.querySelector('.admin-panel').scrollTo({ top : 600 , behavior:'smooth'})     
                 e.target.value = null             
             }
          })
       }
  }

  return (
    <section className='px-2 md:px-8' style={{ zIndex: -1 }}>       
         <h5 className='text-md md:text-xl text-red-500 md:ml-8 bi bi-people'> 
            &nbsp; Add Participants & Development Coach
          </h5>
          <div className='flex justify-center mt-8'>
               <button className='py-2 hover:cursor-default  px-12 text-sm bi bi-plus-circle'>
                  &nbsp; Bulk Upload Participants
               </button>
          </div>
          <div className='pl-5 md:pl-0 mt-5 md:flex justify-between'>
              <div className='md:ml-0'>
                    <span className='block text-md'> 1. Download Excel Template </span>
                    <div className='w-[70px] h-[70px] mt-3 mx-auto bg-green-small flex items-center justify-center'>
                        <span onClick={downloadExcelTemplate} className='text-4xl text-white bi bi-cloud-arrow-down'></span>                      
                    </div>
                    <p className='text-sm sm:text-[13px] md:w-[260px] mt-3'>
                             <button onClick={ downloadExcelTemplate } className='text-red-500 mr-2'> Download </button> 
                             the excel template to upload   participants and 
                             development coach.
                     </p>
                     {
                        isFileDownloaded && <div className='border-l border-l-2 border-green-500 text-[13px] bg-green-100 py-3 pl-2 text-green-500 mt-2'>
                              Your file is successfully downloaded. &nbsp;                              
                              <label htmlFor='file' className='text-[14px] mr-3 hover:cursor-pointer'> Click here to upload  file </label>
                              <input type='file' name='file' onChange={ uploadExcelTemplate } id='file' className='hidden' />                              
                         </div>
                      }
                     { downloadLoading && <LoadingSpinner /> }
              </div>
              <div className='mt-5 sm:mt-0'>
                    <span className='block text-md'> 2. Upload Participants Using Template </span>
                    <div className='w-[70px] h-[70px] mt-3 mx-auto bg-green-small flex items-center justify-center'>
                        <label htmlFor='file' className='text-4xl text-white bi bi-cloud-arrow-up'></label>                      
                    </div>
                    <p className='text-sm sm:text-[13px] md:w-[260px] mt-3'>
                             <input type='file' name='file' onChange={ uploadExcelTemplate } id='file' className='hidden' />
                             <label htmlFor='file' className='text-red-500'> Browse </label>
                              and upload participants and 
                             development coach.
                     </p>
                      {
                         !isExcelFile && <div className='border-l border-l-2 border-red-500 text-[13px] bg-red-100 py-3 pl-2 text-red-500 mt-2'>
                             Error:  File is not an valid excel document.
                         </div>
                      }
              </div>
          </div>
          <div className='flex justify-center mt-8'>
               <button className='py-2 hover:cursor-default  text-red-500 px-5  text-sm bi bi-plus-circle'>
                  &nbsp;  OR
               </button>
          </div>
          <h5 className='text-md md:text-lg md:ml-8 bi bi-people mt-5'> 
              &nbsp; Add Participants & Development Coach. 
          </h5>
          <div className='flex flex-col'>
                    <div className='overflow-x-auto participant-table sm:-mx-6 lg:-mx-8'>
                        <div className='py-2 inline-block min-w-full md:px-3 mb-5'>
                        <div className='overflow-x-auto bg-gray-100 h-[]  w-[100%]' style={{overflowX:'scroll !important', zIndex: 100}}>
                            <table   
                            className='min-w-full overflow-x-auto py-3 md:w-[120vw] text-center mt-3'
                            >
                               <thead>
                                    <tr>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-3 py-2'>
                                            S/N
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-16 md:px-3 py-2'>
                                             Name
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                            Email Address
                                        </th>                                       
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                             Development Coach Name
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                             Development Coach Email
                                        </th>                                         
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                            Country
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                           Department  
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                            Subsidiary  
                                        </th>
                                        <th scope='col' className='text-[13px] font-medium text-gray-900 px-12 md:px-3 py-2'>
                                            Branch  
                                        </th>
                                    </tr>
                            </thead>
                            <tbody>                            
                                {
                                    (countries?.length && departments.length && branches.length && subsidiary.length) ?
                                    formData.participants.map(
                                        (participant,id) => {
                                            return (
                                                <tr key={id}>
                                                    <td className='px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900'>
                                                        { id + 1}.  
                                                    </td>
                                                    <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                                        <input 
                                                             type="text" name="name" 
                                                              readOnly = { formData.isReadOnly }
                                                              value={ participant.name }
                                                              onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }
                                                             id="" className='admin-form-input'
                                                              style={{ paddingTop : 7 , paddingBottom : 7}} 
                                                        />
                                                    </td>
                                                    <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                                        <input 
                                                            type="text" name="email" id="" 
                                                             value={ participant.email }
                                                             readOnly = { formData.isReadOnly }
                                                             onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }
                                                            className='admin-form-input' 
                                                            style={{ paddingTop : 7 , paddingBottom : 7}}
                                                         />
                                                    </td>                                                                                                           
                                                    <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                                        <input 
                                                             value={ participant.dev_coach_name }
                                                             type="text" name="dev_coach_name" id="" 
                                                             className='admin-form-input' 
                                                             readOnly = { formData.isReadOnly }
                                                             onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }
                                                             style={{ paddingTop : 7 , paddingBottom : 7}} 
                                                        />                                                        
                                                    </td>  
                                                    <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>
                                                        <input 
                                                             value={ participant.dev_coach_email }
                                                             type="text" name="dev_coach_email" id="" 
                                                             className='admin-form-input' 
                                                             readOnly = { formData.isReadOnly }
                                                             onChange = { (e) => handleInputChange(id,e.target.name,e.target.value) }
                                                             style={{ paddingTop : 7 , paddingBottom : 7}} 
                                                        />
                                                    </td>   
                                                    <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>                                                         
                                                        { 
                                                          <UserProfileDropDown
                                                             participant={participant.country}
                                                             userProfle={ countries}                                                           
                                                             id={id}
                                                             name={'country'}
                                                             handleInputChange={handleInputChange}
                                                          />
                                                        }   
                                                    </td>  
                                                    <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>                                                        
                                                        <UserProfileDropDown
                                                             participant={participant.department}
                                                             userProfle={ departments }                                                              
                                                             id={id}
                                                             name={'department'}
                                                             handleInputChange={handleInputChange}
                                                          />
                                                    </td> 
                                                    <td className='text-sm text-gray-900 font-light px-3 py-2 whitespace-nowrap'>                                                       
                                                      <UserProfileDropDown
                                                             participant={participant.subsidiary}
                                                             userProfle={ subsidiary }                                                             
                                                             id={id}
                                                             name={'subsidiary'}
                                                             handleInputChange={handleInputChange}
                                                    />                                                           
                                                    </td>                                                      
                                                    <td className='text-sm flex items-center text-gray-900 font-light px-3 py-2 whitespace-nowrap'>                                                         
                                                          <UserProfileDropDown
                                                             participant={participant.branch}
                                                             userProfle={ branches }                                            
                                                             id={id}
                                                             name={'branch'}
                                                             country = {participant.country}
                                                             handleInputChange={handleInputChange}
                                                          />
                                                         {  (id > 0 ) && <span 
                                                            onClick={ () => HandleDeleteParticipant(participant.id) }
                                                            className='bi bi-trash ml-3 text-red-500 text-md'
                                                           ></span>}
                                                  </td>                                                
                                              </tr>   
                                            )
                                        }
                                    )
                                :
                                 <LoadingSpinner />
                                }
                           </tbody>
                    </table>
                  </div>
               </div>
             </div>
         </div>
          { errors.participant &&
            <div className='border-l border-l-2 mt-8 border-red-500 text-red-500 text-sm text-center px-2 py-3 md:w-[480px] mx-auto bg-red-100'> 
                {errors.participant} 
             </div>
          }
         <div className='flex justify-end mt-5 md:w-[100%]'>                    
                   {   <button 
                      className='py-2 text-white text-sm bi bi-plus-circle px-8 rounded-lg bg-green-small'
                      onClick={() => handleAddMoreParticipants( Date.now() ) }
                    >
                     &nbsp; Add more
                   </button>}
         </div>
         <PreviewParticipantsModal />
    </section>
  )
}

export default AddParticipantsForm