import React, { useEffect, useState } from 'react'
import SearchCompany from './components/SearchCompany'
import { Link } from 'react-router-dom'
import CompaniesTable from './components/CompaniesTable'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import EmptyCompany from './components/EmptyCompany'

const ViewCompany = () => {

  const [ loading, setLoading ] = useState(true)
  const [ companiesList , setCompaniesList ] = useState([])

  //pagination using react paginate package
  const [ pageNumber , setPageNumber ] = useState(0)
  const NumberOfCompaniesPerpage = 12 ;
  const companiesPerPage = Math.ceil( companiesList.length / NumberOfCompaniesPerpage )
  const pagesVisited = ( NumberOfCompaniesPerpage * pageNumber )
  const activeCompanies = companiesList.slice( pagesVisited , pagesVisited + NumberOfCompaniesPerpage )

  /* a function that handles pagination actions like next prev and active link. */
  const handlePageChange = ({ selected }) => {
     setPageNumber( selected )
}

  useEffect( () => {
       axios.get(`${ process.env.REACT_APP_BASE_URL }/company/all`)
       .then(
          response => {
               if( response.data.success ){
                   setCompaniesList(response.data.data)                    
                   setLoading(false)
               }
          }
       ).catch( error => {
             if( error.response.status === 404 ){
               setCompaniesList([])                    
               setLoading(false)
             }
       } )
  },[])

  if( loading && !companiesList.length ){
     return <LoadingSpinner />
  }

  return (
    <section className='md:mr-2 h-full'>
        <h1 className='py-3 text-xl text-white bg-green-small font-semibold pl-3 uppercase'>
           View  Company 
        </h1>
        <div className='mt-5 md:flex  justify-between items-center'>
             <SearchCompany setCompaniesList = { setCompaniesList } />      
             <Link className='bg-red-500 mt-5 w-[300px] md:w-[260px]  md:mt-0 py-2 ml-8 sm:ml-0 md:py-[4px] flex items-center px-5 text-white rounded-md text-sm'
                        to={'/admin/add-new-company'}
              >
                   <span className='bi bi-plus-circle mr-2 text-xl'></span>    Add New Company
             </Link>
        </div>
        {
          !companiesList.length ? <EmptyCompany setCompaniesList = { setCompaniesList } /> :
           <>
              <CompaniesTable  companiesList = { activeCompanies } setCompaniesList = { setCompaniesList } />
               <ReactPaginate 
               previousLabel='Prev'
               nextLabel = 'Next'
               pageCount={ companiesPerPage }
               onPageChange = { handlePageChange }
               containerClassName='pagination flex items-center justify-center mt-2  py-2'
               previousLinkClassName='text-sm pagination-btn bg-green-small py-1 px-3 text-white rounded-lg'
               nextLinkClassName='text-sm ml-3 pagination-btn bg-green-small py-1 px-3 text-white rounded-lg'
               activeLinkClassName=' text-sm px-2 py-1 rounded-full border border-green-small text-green-small'
               breakLabel='...'
               pageLinkClassName='text-sm ml-3'
               /> 
           </>
        }
    </section>
  )
}

export default ViewCompany