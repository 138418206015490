import React from 'react'

const TextEditorOptionDropdown = ({setActiveTemplate}) => {
    return (
        <>               
                <ul
                    className='w-full py-3'
                    aria-labelledby='dropdownMenuButton1'
                > 
                  <li>
                      <a
                        className='border-t border-b dropdown-item text-sm py-2 px-4 capitalize font-normal flex items-center
                                w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'
                        href='#'
                        onClick={ (event) => {
                            event.preventDefault()     
                            setActiveTemplate('evaluator')
                        }}
                       >
                        <span className='bi bi-envelope text-lg mr-2'></span> Evaluator Email
                      </a>
                      <a
                        className='border-b dropdown-item text-sm py-2 px-4 capitalize font-normal flex items-center
                                w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'
                        href='#'
                        onClick={ (event) => {
                            event.preventDefault()      
                            setActiveTemplate('development coach')                      
                        }}
                       >
                         <span className='bi bi-envelope text-lg mr-2'></span> Development Coach Email
                      </a>
                      <a
                        className='border-b dropdown-item text-sm py-2 px-4 capitalize font-normal flex items-center
                                w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'
                        href='#'
                        onClick={ (event) => {
                            event.preventDefault() 
                            setActiveTemplate('participants')                           
                        }}
                       >
                         <span className='bi bi-envelope text-lg mr-2'></span>  Participants Email
                      </a>
                    </li>                                                  
                </ul>               
        </>
      )
}

export default TextEditorOptionDropdown