import React, { useState } from "react";
import { Formik } from "formik";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
  Grid,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function FilterAccordion({
  title = "Filter by Subsidiary",
  handleChange,
  options,
  values,
}) {
  return (
    <Stack fullWidth>
      <Accordion elevation={0} disableGutters disableFocusRipple disableRipple>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" fontWeight={700}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <Grid container spacing={2} fullWidth>
              {options?.map(
                (option, index) => (
                  //   index % 2 == 0 ?
                  <Grid item xs={6} key={index}>
                    {/* {console.log(values, values[option?.name], option?.name)} */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableFocusRipple
                          disableRipple
                          checked={values[option?.name]}
                          onChange={handleChange}
                          name={option?.name}
                        />
                      }
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2",
                          }}
                        >
                          <Avatar
                            src={option?.imageUrl}
                            height="10px"
                            width="10px"
                          />
                          <Typography variant="body2">
                            {option?.label}
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>
                )
                //       : (
                //   <Grid item xs={6} key={index}>
                //     <FormControlLabel
                //       control={
                //         <Checkbox
                //           disableFocusRipple
                //           disableRipple
                //           checked={option?.value}
                //           onChange={handleChange}
                //           name={option?.name}
                //         />
                //       }
                //       label={
                //         <Box
                //           sx={{
                //             display: "flex",
                //             alignItems: "center",
                //             gap: "2",
                //           }}
                //         >
                //           <Avatar
                //             src={option?.imageUrl}
                //             height="10"
                //             width="10"
                //           />
                //           <Typography variant="body2">
                //             {" "}
                //             {option?.label}
                //           </Typography>
                //         </Box>
                //       }
                //     />
                //   </Grid>
                // )
              )}
            </Grid>
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}

export default FilterAccordion;
