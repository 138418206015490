import React from "react";
import MyDocument from "../../../components/ReportPDF/ReportPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useQuery } from "react-query";
import axios from "axios";
function Report({ children, project_id = "", participant_id = "", className }) {
  const { data, isLoading } = useQuery(
    ["getReport", project_id, participant_id],
    () => getReportData(project_id, participant_id),
    {
      enabled: !!participant_id,
    }
  );

  async function getReportData(project_id, participant_id) {
    let res = await axios.post(
      `https://sandboxapi.360degree.testassessify.com/survey/report`,
      {
        participant_id,
        project_id,
      }
    );
    return await res.data;
  }
  return (
    <div className={className}>
      <PDFDownloadLink
        document={<MyDocument data={data?.report} project={data?.project} />}
        fileName="Report.pdf"
      >
        {({ loading, error }) => (loading || isLoading ? "loading" : children)}
      </PDFDownloadLink>
    </div>
  );
}

export default Report;
